import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { AuthenticationService } from '../shared/auth/authentication.service'
import { UserService } from '@aifs-shared/user/user.service';
import { AllowedForKYPRegistration, DBSStatusResponse, KYPApplicationStatus, PaperPoliceCheckStatusResponse, PoliceCheckService } from './police-check.service';
import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

@Component({
    templateUrl: './police-check.component.html',
    styleUrls: ['./police-check.component.scss']
})

export class PoliceCheckComponent implements OnInit {
    title: string = '';
    returnUrl?: string
    form?: FormGroup
    displayAU: boolean = false;
    displayNZ: boolean = false;
    displayPL: boolean = false;
    displayDefault: boolean = true;
    isPaperPoliceCheck?: boolean;
    statusCode?: string;
    result?: string;
    policeCheckVerified?: string;
    policeCheckUri?: string;
    policeCheckReceived?: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private policeCheck: PoliceCheckService,
        private authService: AuthenticationService,
        private userService: UserService) {
    }


    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home'
        this.isLoading = true;

        this.title = "Police Check";

        var u = this.userService.user();
        this.countryCode = u.countryCode;
        const applicationId = this.userService.applicantRole()?.applicationId;

        if (applicationId) {
            this.policeCheck
                .isApplicantInGBGCountry(applicationId)
                .subscribe({
                    next: (r:AllowedForKYPRegistration) => {
                        this.registrationType = r.allowedForKYP && !r.isOverriddenByPaperCheck ? 'Online' : 'Paper';
                        this.nonGbgCountry = !r.allowedForKYP || r.isOverriddenByPaperCheck;

                        if(this.registrationType == 'Online'){
                            this.policeCheck
                            .getDBSStatus(applicationId)
                            .subscribe({
                                    next: (data: DBSStatusResponse) => {
                                        this.isRegistered = 
                                        data.details.applicationStatus != KYPApplicationStatus.Unknown && 
                                        data.details.applicationStatus != KYPApplicationStatus.NotRegistered;
                                        this.pleaseRegister = data.details.applicationStatus == KYPApplicationStatus.NotRegistered;
                                        this.pleaseComplete = data.details.applicationStatus == KYPApplicationStatus.ToBeCompleted 
                                        || data.details.applicationStatus == KYPApplicationStatus.InProgress
                                        || data.details.applicationStatus == KYPApplicationStatus.AwaitingPayment;
                                        this.pleaseContact = data.details.applicationStatus == KYPApplicationStatus.Withdrawn;
                                        this.isLoading = false;
                                    }
                                }
                            );
                        }
                        else{
                            this.isLoading = false;
                        }
                    },
                    error: (error:any) => {
                        this.message = "An unexpected error occured reading from the server.";
                        this.isLoading = false;
                        this.inError = true;
                    }
                });

            this.policeCheck
                .getPaperPoliceCheckStatus(applicationId)
                .subscribe({
                    next: (data: PaperPoliceCheckStatusResponse) => {
                        if (data?.isPaperCheck) {
                            // this.policeCheckReceived = data?.crbReceived;
                            this.policeCheckReceived = data?.paperPoliceCheckReceived;
                        }
                    }
                });
        }
    }

    register() {
        this.isLoading = true;
        const applicationId = this.userService.applicantRole()?.applicationId;

        if(applicationId) {
            this.policeCheck
                .manualRegistration(applicationId)
                .subscribe({
                    next: (r: BaseResponse) => {
                        this.isLoading = false;
                        if (r.resultCode == ResponseResultCode.Success) {
                            this.onlineCheckStatus = KYPApplicationStatus.ToBeCompleted;
                            this.isRegistered = true;
                        } else {
                            this.inError = true;
                            this.message = r.error;
                        }
                    },
                    error: (error: any) => {
                        this.message = "An unexpected error occured writing to the server.";
                        this.isLoading = false;
                        this.inError = true;
                    }
                });
        }
    }

    returnHome() {
        this.router.navigateByUrl("/home");
    }

    uploadPoliceCheck(){
      sessionStorage.setItem("r:upr", "4");
      this.router.navigateByUrl('uploads/police-check/add');
    }

    isLoading = false;
    inError = false;
    isRegistered = false;
    message?: string;

    nonGbgCountry = true;
    countryCode?: string;

    pleaseRegister = false;
    pleaseComplete = false;
    pleaseContact = false;

    registrationType = '';
    onlineCheckStatus = KYPApplicationStatus.Unknown;
}
