import { Component, Input } from '@angular/core'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'pwned',
    templateUrl: 'pwned.modal.html',
    styleUrls: ['./less/alert.scss']
})
export class PwnedModal {
    @Input() breachCount!: string;
    @Input() cssClass: string = "alert";

    constructor(public activeModal: NgbActiveModal) { }

    buttonText: string = "OK";
}