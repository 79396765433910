<tracker-progress></tracker-progress>
<div class="content-wrapper">
    <h3>My Application</h3>
    <h4>{{title}}</h4>
    <div [ngSwitch]="displayState">
        <div *ngSwitchCase="0">
            <div class="load_container">
                <p>Getting reference information...</p>
                <img src="assets/img/blu-loader.gif" class="bl">
            </div>
        </div>
        <div *ngSwitchCase="1">
            <p>Your referees should have recent knowledge of you in a professional or supervisory capacity.</p>
            <div *ngIf="isRepeat">
                <p>As you have worked on a US Summer Camp before, one of your referees must be your
                    previous Camp Director.</p>
                <div class="suggestion" *ngIf="campDisplay" (click)="onSelectReference(campDisplay.suggestion)">
                    <div class="text">
                        <h6>{{campDisplay.referenceName}}</h6>
                        <!-- <p class="note-already-added" *ngIf="campDisplay.previousReferees">{{campDisplay.previousReferees}}</p> -->
                        <p class="note">{{campDisplay.note}}</p>
                    </div>
                    <div class="action">
                        <i class="fas fa-user-plus"></i>
                    </div>
                </div>
            </div>
            <div *ngIf="hasSuggestions; else no_suggestions">
                <p>Based on your application, we suggest choosing a referee from one of the following places:</p>
                <div class="suggestion" *ngFor="let d of displayItems" (click)="onSelectReference(d.suggestion)">
                    <div class="text">
                        <h6>{{d.referenceName}}</h6>
                        <!-- <p class="note-already-added" *ngIf="d.previousReferees">{{d.previousReferees}}</p> -->
                        <p class="note">{{d.note}}</p>
                    </div>
                    <div class="action">
                        <i class="fas fa-user-plus"></i>
                    </div>
                </div>
            </div>
            <ng-template #no_suggestions>
                <p>Please ensure your education and work history are up to date so we can suggest where you should obtain references from.</p>            
            </ng-template>
            <br>
            <p>Alternatively nominate someone else you know recently in a professional or supervisory 
            capacity. Make sure the organisation is listed in the education or work history section of your application.</p>
            <div class="suggestion" (click)="onSelectReference(null)">
                <div class="text">
                    <h6>Other</h6>
                    <p class="note">Someone else you know in a professional or supervisory capacity</p>
                </div>
                <div class="action">
                    <i class="fas fa-user-plus"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<tracker-buttons [hasPreviousButton]="true"></tracker-buttons>