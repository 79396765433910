import { Component, OnInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { Metaform, MfQuestion, Question, MfValueChangeEvent, IMfShortDateModel, IMfDateModelDatePart } from '@aifs-shared/metaform/metaform';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';

import { Lookup } from '@aifs-shared/lookup/lookup';

import { ErrorField } from '@aifs-shared/metaform/metaform-question-display.component';

// import * as moment from 'moment-timezone';
import { MetaformDateTime } from '@aifs-shared/metaform/datetime/metaform-datetime';
import { IMfDateModel } from '../../metaform';
import { DateTime } from 'luxon';

@Component({
    // moduleId: module.id,
    selector: 'mf-my',
    templateUrl: './date-month.component.html',
    styleUrls: ['../../less/metaform-question-display.component.scss']
})
export class DateMonthYearComponent implements OnInit, DoCheck {
    @Input() readonly: boolean = false;
    @Input() metaform!: Metaform;
    @Input() parent!: MfQuestion;
    @Input() questionItem!: Question<any>;
    @Input() questionName!: string;
    @Input() formGroup!: FormGroup;
    @Input() dataProvider!: MetaformDataProvider;
    @Input() validationErrorMessages!: Map<string, string>;
    @Output() valueChanged = new EventEmitter<MfValueChangeEvent>();

    constructor() { }

    ngOnInit() {
        // moment.tz.setDefault('Europe/London');

        this.questionItemKey = this.questionItem.key;
        this.initialiseDateControl();
        this.canDisplayError = true;

        // For composite controls, we can only display the error message
        // if this item is the first in the targetFields list
        if (this.parent && this.parent.items && this.parent.items[0].targetFields) {
            this.canDisplayError = (this.parent.items[0].targetFields[0] == this.questionItemKey);
            if (this.canDisplayError) {
                // console.info(`${this.questionItemKey} is the first item in a composite field, or is a sole field`);
                this.canDisplayError = true;
            } else {
                // console.info(`${this.questionItemKey} is not able to display it's own errors`);
            }
        }

        this.updateValidity.subscribe((error) => {
            if (error) {
                if (this.errorMessages == null || this.errorMessages !== error.message) {
                    // console.info(`${this.id} - ${this.errorMessages} : Got message : ${error.message} for field ${error.questionItem}`);
                    this.errorMessages = error.message;
                }
            } else {
                this.errorMessages = undefined;
            }
        });

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                this.formGroup.get(field)!.valueChanges.subscribe(data => {
                    this.valueChanged.emit(new MfValueChangeEvent(field, data));
                });
            });
        } else {
            // console.log(`Looking for ${this.questionItemKey}`, this.formGroup);

            this.formGroup.get(this.questionItemKey)!.valueChanges.subscribe(data => {
                this.valueChanged.emit(new MfValueChangeEvent(this.questionItemKey, data));
            });
        }

        this.formGroup.get(this.questionItemKey)!.markAsPristine();

        this.formGroup.updateValueAndValidity();
    }

    ngDoCheck() {
        let valid: boolean = true;

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                if ((this.formGroup.controls[field].touched)) {
                    // console.info(`Checking: ${field}`);
                    valid = valid && this.formGroup.controls[field].valid;

                    this.questionItem.errorField = undefined;

                    this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
                }
            });
        } else {
            if (this.formGroup.controls[this.questionItemKey].touched) {
                valid = this.formGroup.controls[this.questionItemKey].valid;
                this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
            }
        }

        this.isValid = valid;
    }

    get formControl() {
        return this.formGroup.controls[this.questionItemKey];
    }

    formControlFor(targetIndex: 0) {
        return this.formGroup.controls[this.questionItem.targetFields[targetIndex]];
    }

    get hasLabel() { return this.questionItem.label !== undefined && this.questionItem.label !== null; }

    /**
     * Get all possible failing validation messages for this field
     */
    getValidationMessages(question: string): ErrorField | null {

        if (this.questionItem.targetFields) {
            if (this.questionItem.errorField && this.questionItem.currentError) {
                // console.warn(`already have an error: ${this.questionItem.errorField} (${this.questionItem.currentError}), aborting`);
                return null;
            }

            for (let i = 0; i < this.questionItem.targetFields.length; i++) {
                // console.warn(`Checking ${this.questionItem.targetFields[i]}`);
                if (this.formGroup.controls[this.questionItem.targetFields[i]].touched && !this.formGroup.controls[this.questionItem.targetFields[i]].valid) {
                    let message = this.validationMessageForItem(this.questionItem.targetFields[i]);
                    // Early exit?
                    if (message) {
                        // console.info(`Got error for ${this.questionItem.targetFields[i]}: '${message}'`)
                        this.questionItem.errorField = this.questionItem.targetFields[i];
                        this.questionItem.currentError = message;
                        return new ErrorField(this.questionItem.targetFields[i], message);
                    }
                } else {
                    this.questionItem.errorField = undefined;
                    this.questionItem.currentError = undefined;
                }
            }
            this.questionItem.errorField = undefined;
        } else {
            if (this.formGroup.controls[this.questionItem.key].touched && !this.formGroup.controls[this.questionItem.key].valid) {
                let message = this.validationMessageForItem(question);
                if (message) {
                    this.questionItem.errorField = this.questionItem.key;
                    this.questionItem.currentError = message;
                    const msg = this.validationMessageForItem(question);
                    if (msg) return new ErrorField(this.questionItem.key, msg);                }
            }
        }

        // No errors
        return null;
    }

    public isMandatory(key: string): boolean {
        return (this.questionItem.required);
    }

    validationMessageForItem(item: string): string | null {
        const errors = this.formGroup.get(item)?.errors;
        if (errors) {
            // console.info(`getValidationMessages: for question ${item}, errors: ${JSON.stringify(errors)}`)

            let keys = [];
            let message = null;
            for (let key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const msg = (this.validationErrorMessages.get(`${item}_${key}`));
                    if (msg) return msg;
                }
            }
        }
        return null;
    }

    initialiseDateControl() {
        let year = this.questionItem.value?.substring(0,4);
        let month = this.questionItem.value?.substring(5,7);

        this.dateModel.month = +month; //in case anyone forgot, days start with 1, but months with 0
        this.dateModel.year = +year;

        if(year && month){
            this.dateModel.minDate = {
                year: +year - 50,
                month: +month,
                day: 1,
            }
            this.dateModel.maxDate = {
                year: +year + 5,
                month: +month,
                day: 1,
            }
        }
        else{
            let today = MetaformDateTime.roundToNearestFirstOfMonth(MetaformDateTime.now());
            this.dateModel.minDate = {
                year: today.year - 50,
                month: today.month,
                day: 1,
            }
            this.dateModel.maxDate = {
                year: today.year + 5,
                month: today.month,
                day: 1,
            }
        }
        

        let mq = this.dateModel.minDate;

        if (this.questionItem.min) {
            if (this.questionItem.min.startsWith('[')) {
                const linkedField = this.questionItem.min.substring(1, this.questionItem.min.length - 1);
                var lfDateTime = MetaformDateTime.dateFromSetting(this.formGroup.get(linkedField)!.value);
            } 
            mq = lfDateTime ? MetaformDateTime.roundToNearestFirstOfMonth(lfDateTime) : mq;
        }

        const minDate = mq

        if (this.questionItem.min && !isNaN(Date.parse(this.questionItem.min))) { this.dateModel.minDate = minDate; }
        if (this.questionItem.max) { this.dateModel.maxDate = MetaformDateTime.dateFromSetting(this.questionItem.max); }

        this.dateModel.years.push(new Lookup('', 'Year'));
        if (this.dateModel.maxDate && this.dateModel.minDate) {
            for (let y = this.dateModel.maxDate.year; y >= this.dateModel.minDate.year; y--) {
                this.dateModel.years.push(new Lookup(`${y}`, `${y}`));
            }
        } else {
            console.warn(`No valid dates for either min or max date`);
        }

        if (this.questionItem.value) {
            this.calculateAsDate();
        }
    }

    isMonthSelected(monthCode: number | string) {
        return monthCode == this.dateModel.month;
    }

    isYearSelected(year: number | string) {
        return year == this.dateModel.year;
    }

    selectMonthOption(event: any) {
        if (event.target.value == '') {
            this.dateModel.month = undefined;
        } else {
            this.dateModel.month = event.target.value;
        }
        this.formGroup.get(this.questionItemKey)!.markAsTouched();
        this.formGroup.get(this.questionItemKey)!.markAsDirty();
        this.calculateAsDate();
    }

    selectYearOption(event: any) {
        if (event.target.value == '') {
            this.dateModel.year = undefined;
        } else {
            this.dateModel.year = event.target.value;
        }
        this.formGroup.get(this.questionItemKey)!.markAsTouched();
        this.formGroup.get(this.questionItemKey)!.markAsDirty();
        this.calculateAsDate();
    }

    calculateAsDate() {
        if (!this.dateModel.year || !this.dateModel.month) {
            this.formGroup.get(this.questionItemKey)!.setValue(undefined);
            // console.log(`early exit`);
            return;
        }

        let month = this.dateModel.month < 10 ? `0${this.dateModel.month}` : `${this.dateModel.month}`
        const date = `${this.dateModel.year}-${month}-01`;

        this.formGroup.get(this.questionItemKey)!.setValue(date);
        this.formGroup.markAsTouched();
        this.formGroup.updateValueAndValidity();
    }

    isInvalidDate(): boolean {
        const invalid = this.formGroup.get(this.questionItemKey)!.dirty && this.questionItem.required && isNaN(this.formGroup.get(this.questionItemKey)!.value);
        return invalid;
    }

    updateValue(key: string, value: any) {
        this.formGroup.controls[key].setValue(value);
        this.valueChanged.emit(new MfValueChangeEvent(key, value));
    }

    keyForDateMonth(key: string) {
        return `${key}_mm`;
    }

    keyForDateYear(key: string) {
        return `${key}_yy`;
    }


    // NOTE(Ian): Yes, hacky.
    dateModel: IMfShortDateModel = {
        month: 0,
        year: 0,
        months:
            [
                { code: '', description: "Month" },
                { code: '1', description: "January" },
                { code: '2', description: "February" },
                { code: '3', description: "March" },
                { code: '4', description: "April" },
                { code: '5', description: "May" },
                { code: '6', description: "June" },
                { code: '7', description: "July" },
                { code: '8', description: "August" },
                { code: '9', description: "September" },
                { code: '10', description: "October" },
                { code: '11', description: "November" },
                { code: '12', description: "December" }
            ],
        years: [],
        minDate: undefined,
        maxDate: undefined
    };

    canDisplayError?: boolean;

    isValid: boolean = true;
    questionItemKey!: string;
    errorMessages?: string;

    updateValidity: BehaviorSubject<ErrorField | null> = new BehaviorSubject<ErrorField | null>(null);
}
