<div>
    <div [ngSwitch]="questionItem.controlType">
        <div *ngSwitchCase="'html'">
            <mf-html [questionItem]="questionItem"></mf-html>
        </div>
        <div *ngSwitchCase="'text'">
            <mf-text [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-text>
        </div>
        <div *ngSwitchCase="'email'">
            <mf-text [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-text>
        </div>
        <div *ngSwitchCase="'password'">
            <mf-text [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-text>
        </div>
        <div *ngSwitchCase="'number'">
            <mf-text [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-text>
        </div>

        <div *ngSwitchCase="'multiline'">
            <mf-multi-text [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages" [scrambleValues]="scrambleValues"></mf-multi-text>
        </div>

        <div *ngSwitchCase="'dropdown'">
            <mf-drop [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-drop>
        </div>

        <div *ngSwitchCase="'optionselect'">
            <mf-os [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages" [scrambleValues]="scrambleValues"></mf-os>
        </div>

        <div *ngSwitchCase="'optionselectmulti'">
            <mf-osm [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages" [scrambleValues]="scrambleValues"></mf-osm>
        </div>

        <div *ngSwitchCase="'date'">
            <mf-dmy [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-dmy>
        </div>

        <div *ngSwitchCase="'datemonthyear'">
            <mf-my [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-my>
        </div>

        <div *ngSwitchCase="'time'">
            <mf-time [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-time>
        </div>

        <div *ngSwitchCase="'telephone'">
            <mf-telephone [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-telephone>
        </div>

        <div *ngSwitchCase="'address'">
            <mf-address [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-address>
        </div>

        <div *ngSwitchCase="'compactaddress'">
            <mf-address [readonly]="isReadOnly" [questionItem]="questionItem" [formGroup]="formGroup"
                [validationErrorMessages]="validationErrorMessages"></mf-address>
        </div>
    </div>
</div>