import { Component, OnInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { Metaform, MfQuestion, Question, MfOption, MfValueChangeEvent, ValidationError } from './metaform';
import { MetaformDataProvider } from './metaform-data-provider';


@Component({
    // moduleId: module.id,
    selector: 'mf-question',
    templateUrl: './metaform-question-display.component.html',
    styleUrls: ['./less/metaform-question-display.component.scss']
})
export class MetaformQuestionDisplayComponent implements OnInit { //, DoCheck {
    @Input() metaform!: Metaform;
    @Input() parent!: MfQuestion;
    @Input() questionItem!: Question<any>;
    @Input() questionName!: string;
    @Input() formGroup!: FormGroup;
    @Input() dataProvider!: MetaformDataProvider;
    @Input() validationErrorMessages!: Map<string, string>;
    @Input() scrambleValues: boolean = false;
    @Output() valueChanged = new EventEmitter<MfValueChangeEvent>();

    constructor() { }

    ngOnInit() {
        this.questionItemKey = this.questionItem.key;

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                // console.info(`key: ${field}`);
                this.formGroup.get(field)!.valueChanges.subscribe(data => {
                    this.valueChanged.emit(new MfValueChangeEvent(field, data));
                });
            });
        } else {
            this.formGroup.get(this.questionItemKey)!.valueChanges.subscribe(data => {
                this.valueChanged.emit(new MfValueChangeEvent(this.questionItemKey, data));
            });
        }
    }

    get formControl() {
        return this.formGroup.controls[this.questionItemKey];
    }

    formControlFor(targetIndex: 0) {
        return this.formGroup.controls[this.questionItem.targetFields[targetIndex]];
    }

    get isReadOnly() {
        // Read only if this form, or this question is readonly
        if (!this.metaform) {
            console.warn(`QuestionDisplayComponent: Metaform not specified in @Input properties. Assuming read-only is FALSE.`);
            return true;
        }

        if (this.metaform.isReadOnly) {
            return true;
        }

        return false;
    }

    questionItemKey!: string;
}

export class ErrorField {
    public questionItem: string;
    public message: string;
    constructor(q: string, m: string) {
        // console.info(`Creating ErrorField: ${q}, ${m}`)
        this.questionItem = q;
        this.message = m;
    }
}