import { environment } from '../../../environments/environment'

import { Injectable } from '@angular/core'

import { UserData, User } from '../user/user'
import { Observable, Subject } from 'rxjs';

import { UserService } from '@aifs-shared/user/user.service';
import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { Router, NavigationEnd } from '@angular/router'
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';

@Injectable()
export class TimeoutService {
    constructor(
        private idle: Idle,
        private router: Router,
        private tracker: TrackerService,
        private user: UserService,
        public authService: AuthenticationService,) { }

    public setSessionTimeout() {
        if (this.authService.isLoggedIn) {
            //sets an idle timeout in seconds.
            this.idle.setIdle(environment.sessionTimeout.idle);
            // sets a timeout period in seconds. After the idle seconds of inactivity plus timeout seconds, the user will be considered timed out.
            this.idle.setTimeout(environment.sessionTimeout.timeout);
            // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
            this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
            this.idle.watch();
            this.idle.onTimeout.subscribe(() => {
                this.sessionTimedOut();
            });
        }
    }

    sessionTimedOut(): void {
        this.user.userHasSignedOut();
        this.tracker.clearTasks();
        this.authService.logout();
        this.router.navigateByUrl("/sign-in");
    }
}