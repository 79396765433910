import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { BehaviorSubject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { UploadStarting, UploadProgress, UploadDetails } from '@aifs-shared/upload/upload-progress';
import { UploadComplete } from '@aifs-shared/amazon-s3/upload-complete';

@Component({
    selector: 'alert',
    templateUrl: 'progress.modal.html',
    styleUrls: ['./less/alert.scss']
})
export class ProgressModal implements OnInit, OnDestroy {
    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
        this._data
            .subscribe({
                next: (x: UploadStarting | UploadProgress | UploadComplete | null) => {
                    if (x == null)
                        return;

                    // console.info(`Progress: Received update: `);
                    if (x instanceof UploadStarting) {
                        const start = x as UploadStarting;
                        // console.info(`Got upload starting: ${JSON.stringify(x)}`);
                        this.uploadProgress = [];
                        const plural = start.filesToUploadCount == 1 ? "" : "s"
                        this.body = `Uploading ${start.filesToUploadCount} file${plural}...`;
                    } else if (x instanceof UploadProgress) {
                        const progress = x as UploadProgress;
                        this.uploadsStarted = true;
                        this.uploadProgress = progress.uploads;
                    } else if (x instanceof UploadComplete) {
                        const complete = x as UploadComplete;
                        // console.info(`Got upload complete: ${JSON.stringify(complete)}`);
                        this.title = "Processing...";
                        this.uploadProgress = [];
                    }
                },
                error: (error: any) => {
                    throw error;
                }
            });
    }

    ngOnDestroy(): void {
        this.uploadsComplete = true;
    }

    @Input() title: string = "Uploading files...";
    @Input() body?: string

    @Input()
    set data(value) {
        this._data.next(value);
    };

    get data() {
        return this._data.getValue();
    }

    @Input() cssClass: string = "info";

    buttonText: string = "Cancel";
    _data = new BehaviorSubject<UploadStarting | UploadProgress | UploadComplete | null>(null);
    uploadsComplete: boolean = false;
    uploadsStarted: boolean = false;

    uploadProgress: UploadDetails[] = [];
}

class ProgressBar {
    percentComplete: number = 0;
    title: string = '';
}