<div class="content-wrapper">
    <h3>{{title}}</h3>
    <div class="question-block">
        <h5>Before departing to the USA</h5>
        <p>If you need to contact us before or during your application process please contact us via the details below.
            Once
            you have flown to the US please use the contact details at the bottom of the page.</p>

        <div *ngIf="displayAU">
            <h5>Australia</h5>
            <p>Camp America Sydney
                <br> PO Box 1319
                <br> Darlinghurst
                <br> NSW 2010
                <br> Sydney
            </p>
            <p>
                <i class="fa-solid fa-phone"></i>
                <a href="tel:1300 889 067">1300 889 067</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:info@campamerica.com.au">info@campamerica.com.au</a>
                <br>
                <i class="fa-solid fa-up-right-from-square"></i>
                <a href="https://www.campamerica.com.au" target="_blank">www.campamerica.com.au</a>
            </p>
        </div>
        <div *ngIf="displayPL">
            <h5>Poland</h5>
            <p>American Institute for Foreign Study (Poland) Sp. z o.o.
                <br> ul. Grzybowska 43
                <br> pok. 220
                <br> 00-855 Warszawa
                <br> Poland
            </p>
            <p>
                <i class="fa-solid fa-phone"></i>
                <a href="tel:+48 22 101 00 22">+48 22 101 00 22</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:help.campamerica@aifs.pl">help.campamerica@aifs.pl</a>
                <br>
                <i class="fa-solid fa-up-right-from-square"></i>
                <a href="https://www.campamerica.pl" target="_blank">www.campamerica.pl</a>
            </p>
            <div *ngIf="!showUKAddress">
                <h5>United Kingdom</h5>
                <p>American Institute for Foreign Study UK Ltd.<br>
                    Camp America<br>
                    37 Queens Gate<br>
                    London SW7 5HR</p>
                <p>Registered company number &mdash; 939488</p>
            </div>
        </div>
        <div *ngIf="displayDE">
            <h5>Germany/Switzerland/Austria</h5>
            <p>Camp America,
                <br>AIFS Friedensplatz 1
                <br>53111 Bonn
                <br>Germany
            </p>
            <p>
                <i class="fa-solid fa-phone"></i>
                <a href="tel:+49 (0)228 95730 350">+49 (0)228 95730 350</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:camp@aifs.de">camp@aifs.de</a>
                <br>
                <i class="fa-solid fa-up-right-from-square"></i>
                <a href="https://www.aifs.de" target="_blank">www.aifs.de</a>
            </p>
        </div>
        <div *ngIf="displayNZ">

            <h5>New Zealand</h5>
            <p>International Working Holidays
                <br>P.O. Box 99581
                <br>Newmarket
                <br>Auckland 1149
                <br>New Zealand
            </p>
            <p>
                <i class="fa-solid fa-phone"></i>
                <a href="tel:09 632 1138">09 632 1138</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:info@campamerica.co.nz">info@campamerica.co.nz</a>
            </p>
        </div>
        <div *ngIf="displayRU">

            <h5>Russia</h5>
            <p>Inter Air
                <br>101000 MOSCOW
                <br>Russia
                <br>Sretenskiy blvd, 6/1, bld.1, entrance 6.
            </p>
            <p>
                <i class="fa-solid fa-phone"></i>
                <a href="tel:(007) 495 748-1-748">(007) 495 748-1-748</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:morozova@interair.ru">morozova@interair.ru</a>
                <br>
                <i class="fa-solid fa-up-right-from-square"></i>
                <a href="https://www.interair.ru" target="_blank">www.interair.ru</a>
            </p>
        </div>
        <div *ngIf="displayUA">
            <h5>Ukraine</h5>
            <p>International Programmes Centre
                <br>"Alternative-V" LLC 13/21-v
                <br>Taras Shevchenko lane (provulok),
                <br>Office 302
                <br>Kyiv 01001
                <br>Ukraine
            </p>
            <p>
                <i class="fa-solid fa-phone"></i>
                <a href="tel:+380 44 279 25 62">+380 44 279 25 62</a>
                <br>
                <i class="fa-solid fa-phone"></i>
                <a href="tel:+380 67 549 62 99">+380 67 549 62 99</a>
            </p>
        </div>
        <div *ngIf="showUKAddress">
            <h5>United Kingdom</h5>
            <p>Camp America
                <br>37A Queens Gate
                <br>London
                <br>SW7 5HR
            </p>

            <p *ngIf="showUKAddress && !displayUK">
                <i class="fa-solid fa-phone"></i>
                <a href="tel:+44 20 7581 7373">020 7581 7373</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:enquiries@campamerica.co.uk">General Information</a>
            </p>

            <p *ngIf="displayUK">
                <i class="fa-solid fa-phone"></i>
                <a href="tel:+44 20 7581 7373">020 7581 7373</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:enquiries@campamerica.co.uk">General Information</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:visa@campamerica.co.uk">J1 Visa/Passport</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:payments@campamerica.co.uk">Payments &amp; Refunds </a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:campplacement@campamerica.co.uk">Placement Queries</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:insurance@campamerica.co.uk">Insurance Queries</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:enquiries@campamerica.co.uk">Flight Queries</a>
                <br>
                <i class="fa-solid fa-at"></i>
                <a href="mailto:policechecks@campamerica.co.uk">Police check/DBS queries </a>
            </p>
        </div>

        <h5>Once you have arrived in the USA</h5>
        <p>
            Camp America AIFS
            <br> 1 High Ridge Park
            <br> Stamford CT 06905
        </p>
        <p>
            <i class="fa-solid fa-phone"></i>
            <a href="tel:1 866 222 2074">1 866 222 2074</a>
            <br>
            <i class="fa-solid fa-phone"></i>
            <a href="tel:1 203 399 5000">Main Switchboard - 1 203 399 5000</a>
            <br>
            <i class="fa-solid fa-phone"></i>
            <a href="tel:1 203 399 5409">Main Switchboard - 1 203 399 5409
            </a>
        </p>

    </div>
</div>
<ng-template ngbModalContainer></ng-template>