import { Sequence } from './sequence'
import { TaskIntro } from './task-intro';
import { TaskOutro } from './task-outro';

export class TaskDetails {
    public tasks: Task[] = [];
    public isParticipant: boolean = false;
    public isPlaced: boolean = false;
    public isReturner: boolean = false;
    public override?: string;
    public chainTasks: boolean = false;
    public homeRoute: string = '';
    public toDoItem: number = 0;
}

export class Task {
    id!: number;
    name: string = '';
    title: string = '';
    ruleToMatch?: string;
    routerUrl?: string;
    routerUrlReview?: string;
    taskStatus?: TaskStatus;
    introTemplate?: TaskIntroTemplate;
    outroTemplate?: TaskOutroTemplate;
    complete: boolean = false;
    skipped: boolean = false;

    // totalSteps: number;
    // currentStep: number;

    sequenceId!: number;
    isValid: boolean = false;

    taskType!: TaskType;

    sequence?: Sequence;
    intro?: TaskIntro;
    outro?: TaskOutro;

    next?: number;
    prev?: number;

    groupId?: number;
    // childTask?: number;

    // NOTE(Ian); These are preloaded by the tracker service
    // and the types are likely dependent upon the TaskType
    taskData: any;
    taskRuleData: any;

    taskCompleteFn?: { fn: Function, args: any[] }
}

export class UserTaskStatus {
    taskId!: number;
    complete!: boolean;
    skipped!: boolean;
    lastModified?: Date;
}

export enum TaskIntroTemplate {
    None = 0,
    Default
}

export enum TaskOutroTemplate {
    None = 0,
    Default
}

export enum TaskStatus {
    Initialising = -1,
    Intro = 0,
    Stepping,
    Outro,
    Complete
}

export enum TaskType {
    Default = 0,
    Metaform = 1
}
