import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { EMPTY, Observable, Subject } from 'rxjs'

import { AuthenticationService } from '../shared/auth/authentication.service'
import { UserService } from '../shared/user/user.service'
import { TermsAndConditions } from '../shared/user/terms-and-conditions'

import { TrackerService } from '../shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { ITaskProvider } from '../shared/tracker/task-provider';

@Component({
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: [
        './less/terms-and-conditions.component.scss',
        '../shared/metaform/less/metaform-question-display.component.scss',
        '../shared/tracker/less/tracker-button.component.scss'
    ]
})
export class TermsAndConditionsComponent extends TrackerTaskComponent implements OnInit, OnDestroy, ITaskProvider {

    constructor(
        router: Router,
        tracker: TrackerService,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private userService: UserService
    ) {
        super(router, tracker);

        this.requiresTask = false;

        // It really makes NO SENSE to step back from here.
        this.previousButtonEnabled = false;
    }

    override ngOnInit() {
        super.ngOnInit();

        // // console.debug(`Check for whether we're displaying the latest, or a stored version for the signed-in user`);
        const termType = this.route.snapshot.params['termsType'];
        if (termType === 'MWPTC' || termType === 'TC' || termType === 'ANY') {
            this.title = "Agree to latest terms & conditions";
            this.agreeToLatestTerms = true;

            // NOTE(ian): This function could use a property on 
            // the user table to determine whether the applicant must
            // agree to new terms (in order to get the latest), or
            // return the agreed terms. We could then use the user, rather
            // than a param value to decide what mode we work under.

            // If you address this class as part of a 'display agreed terms' story,
            // please consider the above!
            this.userService.getTermsForUser(termType)
                .subscribe(
                    terms => this.storeTerms(terms),
                    error => this.pageError(<any>error)
                );
            if (termType !== 'MWPTC') {
                this.mustAgree = true;
            }
        } else if (termType === 'minimum-wage-placement') {
            this.title = "Latest terms & conditions";
            this.agreeToLatestTerms = false;
            this.mustAgree = false;

            this.userService.getCurrentMinimumWagePlacementTerms()
                .subscribe(
                    terms => this.storeTerms(terms),
                    error => this.pageError(<any>error)
                );

        } else {
            this.title = "Agreed terms & conditions";
            this.userService.getAgreedTermsForUser()
                .subscribe(
                    terms => this.storeTerms(terms),
                    error => this.pageError(<any>error)
                );
            this.mustAgree = false;
        }

    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    override stepPrevious(): boolean {
        history.back();
        return true;
    }

    override stepNext(): void {
        let s = new Subject<boolean>()

        // console.log(`Stepping Next to agree terms`)
        this.agreeToTerms().subscribe(
            success => {
                // console.log(`Got terms response: ${success}`);
                this.checkTermsResponse(success);

                if (this.userService.isApplicant()) {
                    // console.log(`User has applicant role, will be reading tasks for the new application`);
                    this.userService.reloadUserData()
                        .subscribe(
                            d => {
                                this.router.navigateByUrl(this.tracker.homeRoute);
                                // this.tracker.loadTasksForApplication(this.userService.applicantRole().applicantId);

                            });
                }
            },
            error => this.pageError(<any>error)
        )
    }

    /** User selects one of the options
     *  @param agreed (boolean) - did they select the 'agree' option?
     */
    agreeTerms(agreed: boolean) {
        this.selectedOption = agreed ? 1 : 2;
        this.termsAgreed = true;

        this.setNextEnabled(this.selectedOption == 1);
    }

    /**
     * Agree to the displayed terms
     */
    agreeToTerms(): Observable<any> {
        if (!this.terms) return EMPTY;
        return this.userService.agreeTermsForUser(this.terms.agreedId)
    }

    /**
     * Extract the returned terms
     * @param terms (TermsAndConditions) - returned terms
     */
    storeTerms(terms: TermsAndConditions): void {
        // // console.info(`Store Terms`);
        // // console.info(`TermsID: ${terms.agreedId}`);

        this.terms = terms;
        this.termsText = terms.termsBody;
    }

    /**
     * Did the server store our agreement?
     * @param success (boolean) - response from server after call
     */
    checkTermsResponse(success: boolean) {
        // Redirect to the home page?
        if (success) {
            // console.info(`Server stored our terms agreement`);
            // console.info(`Navigate to home`);
            this.userService.termsAgreed();
            this.router.navigateByUrl(this.tracker.homeRoute);

            return;
        }
        this.pageError('There was an error while updating terms and conditions. Please try again!');
    }

    isSelected(t: string): boolean {
        return this.termsAgreed;
    }

    termsAgreed = false;

    // The mode we're working as
    agreeToLatestTerms: boolean = false;

    // Selected an option?
    selectedOption: number = 0;

    // The actual terms and conditions we're displaying
    title: string = '';
    terms?: TermsAndConditions;
    termsText?: string;
    mustAgree: boolean = false;
}