import { Component, Input } from '@angular/core'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'confirmation',
    templateUrl: 'confirmation.modal.html',
    styleUrls: ['./less/alert.scss']
})
export class ConfirmationModal {
    @Input() title!: string
    @Input() body!: string
    @Input() extra?: string;
    @Input() cssClass: string = "info";

    constructor(public activeModal: NgbActiveModal) { }
}