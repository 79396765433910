import { Component, OnInit, ViewChild } from '@angular/core';

import { EMPTY, Observable, Subject } from 'rxjs'

import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms'

import { AuthenticationService } from '@aifs-shared/auth/authentication.service'
import { UserService, BreachResponse } from '@aifs-shared/user/user.service'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from '@aifs-shared/modals/alert.modal';

import { ResponseResultCode } from '@aifs-shared/common/base-response';

import { Lookup } from '@aifs-shared/lookup/lookup';

import { Metaform } from '@aifs-shared/metaform/metaform';
import { MetaformViewerComponent } from '@aifs-shared/metaform/metaform-viewer.component';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';
import { PwnedModal } from '@aifs-shared/modals/pwned.modal';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./less/change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, MetaformDataProvider {

    constructor(
        private authService: AuthenticationService,
        private userService: UserService,
        private modalService: NgbModal) { }

    ngOnInit() {
    }

    doChangePassword() {
        const pd = this.passwordData;
        if(!pd) return;

        this.userService.changePassword(pd.password, pd.newPassword, pd.passwordConfirmation)
            .subscribe(r => {
                if (r.resultCode == ResponseResultCode.Success) {
                    this.showModal("Change Password", "You have successfully updated your password");
                    this.formViewer.clearForm();
                    this.updatePasswordButtonEnabled = false;
                } else {
                    this.showError("Change Password", "We couldn't update your password:", r.error);
                }

            }, error => {
                this.showError("Change Password", "There was an unexpected error attempting to update your password:", error);
            });
    }

    public initialiseCallbackProvider(form: Metaform, formGroup: FormGroup) {
        // For data retention, probably. 
        formGroup.valueChanges.subscribe(data => this.onValueChanged(formGroup, data));
    }

    /**
     * @param source (string) - lookup optionSource
     */
    public loadLookup(form: Metaform, formGroup: FormGroup, source: string): Observable<Lookup[]> { return EMPTY; }
    public lookupLoaded(form: Metaform, formGroup: FormGroup, questionKey: string, options: any[]) { }
    public displayIf(form: Metaform, formGroup: FormGroup, source: string): boolean { return true; }
    public formLoaded(form: Metaform, formGroup: FormGroup): { preventDisplay: boolean } { return { preventDisplay: false }; }
    onValueChanged(formGroup: FormGroup, data?: any) {
        if (!formGroup) { return; }

        this.passwordData = data;
        const form = formGroup;

        if (form.valid) {
            // const sha1Value = sha1(formGroup.get('newPassword').value);
            // this.userService.checkPasswordForBreaches(sha1Value)
            //     .subscribe(
            //         d => {
            //             const br: BreachResponse = d.result;
            //             this.updatePasswordButtonEnabled = !br.isBreached;
            //             if (br.isBreached) {
            //                 // console.log(`Password breached in ${br.count} instances`);
            //                 this.showPwnedModal(br.count.toLocaleString());
            //             }
            //         },
            //         error => {
            //             console.error(error);
            //             // In case the service call fails
            //             this.updatePasswordButtonEnabled = true;
            //         }
            //     );

            this.updatePasswordButtonEnabled = true;
        }
    }

    showError(title: string, message: string, extra?: string) {
        this.showModal(title, message, extra, "danger");
    }

    showModal(title: string, message: string, extra: string | undefined = undefined, cssClass: string = "info") {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;
        if (extra) modalRef.componentInstance.extra = extra;
        modalRef.componentInstance.cssClass = cssClass;
        modalRef.result.then(result => {
            if (result) {

            }
        });
    }

    showPwnedModal(breachCount: string) {
        const modalRef = this.modalService.open(PwnedModal);
        modalRef.componentInstance.breachCount = breachCount;
        modalRef.result.then(result => {
            if (result) {

            }
        });
    }

    title: string = '';
    signInName?: string;

    updatePasswordButtonEnabled: boolean = false;
    passwordData?: PasswordData;

    @ViewChild(MetaformViewerComponent) formViewer!: MetaformViewerComponent;

}

export interface PasswordData {
    password: string;
    newPassword: string;
    passwordConfirmation: string;
}
