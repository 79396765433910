import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AlertModal } from '@aifs-shared/modals/alert.modal';

// Animation support
import {
    trigger,
    style,
    transition,
    animate
} from '@angular/animations';

import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { UserService } from '../user/user.service';
import { AuthenticationService } from '../auth/authentication.service';

import { ApplicationService } from '../application/application.service';

import { LookupService } from '../lookup/lookup.service';

import { MetaformService } from './metaform.service';
import { Metaform, MetaformSection, MfQuestion, MfValueChangeEvent, ValidationError } from './metaform';
import { MetaformDataProvider } from './metaform-data-provider';
import { ReloadOptions } from './reload-options';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    // moduleId: module.id,
    selector: 'mf-named-form',
    templateUrl: './metaform-viewer.component.html',
    styleUrls: ['./less/metaform-display.component.scss'],
    animations: [
        trigger(
            'enterAnimation', [
            transition(':enter', [
                style({ transform: 'none', opacity: 0 }),
                animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('500ms', style({ transform: 'translateX(0)', opacity: 0 }))
            ])
        ]
        )
    ],
})

export class MetaformViewerComponent implements OnInit {
    @Input() metaform!: string;
    @Input() dataProvider!: MetaformDataProvider;
    @Input() data: any = undefined;
    @Input() allowSaveForLater: boolean = false;
    @Input() loadFormData: boolean = false;
    @Input() scrambleValues: boolean = false;
    @Output() valueChange: EventEmitter<MfValueChangeEvent> = new EventEmitter();
    @Output() metaFormLoaded: EventEmitter<Metaform> = new EventEmitter();
    @Output() displayChanged: EventEmitter<Metaform> = new EventEmitter();
    @Output() saveForLaterRequested: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private authService: AuthenticationService,
        private userService: UserService,
        private applicationService: ApplicationService,
        private formService: MetaformService,
        private modalService: NgbModal,
        private lookup: LookupService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        // console.info(`init for ${this.metaform}`);
        this.applicationService.resetFormCallCount();

        if (this.loadFormData) {
            this.formService
                .loadForm(this.metaform)
                .subscribe(
                    metaform => {
                        // console.info(`Loaded form: ${metaform.name}`);
                        this.applicationService
                            .loadFromUrl(this.userService.applicantRole()!.applicantId, this.userService.applicantRole()!.activeApplicationId!, metaform.dataSource!)
                            .subscribe({
                                next: (result: any) => {
                                    var data = result;

                                    // console.info(`Loaded form data:`, data);
                                    this.formLoaded(metaform, data);
                                },
                                error: (error:any) => {
                                    console.error(error);
                                }
                            });
                    });
        } else {
            this.formService.loadForm(this.metaform)
                .subscribe({
                    next: (metaform: Metaform) => this.formLoaded(metaform, this.data),
                    error: (error: any) => console.error(`Couldn't load form ${this.metaform}: ${error}`)
                });
        }
    }

    public requestRefresh(questionName: string): void {
        // console.debug(`requestRefresh`);
        const target = this.findQuestionByName(questionName);
        if(target) this.formService.reloadDataSource(this.form!, this.formGroup!, target.items[0], this.lookup, this.dataProvider, this.reloadObserver);
    }

    public findQuestionByName(questionName: string): MfQuestion | undefined {
        const target = this.form!.questions.find((t: any) => t.name === questionName);
        if (!target) {
            console.error(`Was expecting a component for question '${questionName}' in ${this.form!.questions.length} questions but didn't see one: did have:`);

            this.form!.questions.forEach(q => {
                // console.log(`${q.name}`);
            })
        }
        return target;
    }

    public findQuestionItemByName(question: MfQuestion, itemName: string) {
        const target = question.items.find((t: any) => t.key === itemName);
        if (!target) {
            console.error(`Was expecting a component for item name '${itemName}' in ${question.items.length} items but didn't see one`);
        }
        return target;
    }

    formLoaded(metaform: Metaform, withRules: any = undefined) {
        this.form = metaform;
        this.currentQuestion = 0;
        this.form.totalQuestionCount = this.form.questions.length - 1;

        // Okay, I don't want to reload the rules if we're stepping backwards
        // as that will reset prior to saving.
        if (withRules) {
            // console.info(`Got rules`, withRules);
            this.applicationService.loadRuleData(withRules);
            this.form.isReadOnly = withRules.isReadOnly || false;
        }

        let overrideDisplay: boolean = false;
        if (this.dataProvider) {
            //console.info(`Got dataProvider`);
            const result = this.dataProvider.formLoaded(this.form, this.formGroup!);
            overrideDisplay = result.preventDisplay;
        }

        // console.info(`Notifying output of form loaded`, metaform);
        this.metaFormLoaded.emit(metaform);

        if (!overrideDisplay) {
            // console.info(`Displaying questions in formLoaded`);
            this.displayQuestions();
        }
    }

    public startFromBegining() {
        this.trimExcessiveLengths();
        this.currentQuestion = 0;
        this.stepNext();
    }

    public startFromEnd() {
        // TODO:: can't go to the very last question (for now)
        this.displayLastValidQuestion();
    }

    public stepNext(): { noMoreSteps: boolean, atEnd?: boolean, atStart?: boolean, lastQuestion: number, lastSection: number } {
        let res = this.displayQuestions(true)

        return { noMoreSteps: res.atEnd, atEnd: res.atEnd, atStart: res.atStart, lastQuestion: res.lastQuestion, lastSection: res.lastSection }
    }

    public formComplete(authService: AuthenticationService, userService: UserService, applicationService: ApplicationService, form: Metaform): Observable<any> {
        let subject = new Subject<boolean>();

        if (!form.isReadOnly) {
            applicationService.writeDataForForm(
                userService.applicantRole()!.applicantId!,
                userService.applicantRole()!.applicationId!,
                form.dataSource!)
                .subscribe(
                    r => {
                        subject.next(r);
                    },
                    error => {
                        // something unexpected occured
                        subject.error(error);
                    });
        } else {
            console.warn(`Form is readonly, subject will not fire`);
            subject.next(true);
        }
        // console.log(`Returning subject`);
        return subject;
    }

    public stepPrevious(): { noMoreSteps: boolean, atEnd?: boolean, atStart?: boolean, lastQuestion: number, lastSection: number } {
        let res = this.displayQuestions(false)

        return { noMoreSteps: res.atEnd, atEnd: res.atEnd, atStart: res.atStart, lastQuestion: res.lastQuestion, lastSection: res.lastSection }
    }

    public clearForm(): void {
        this.formGroup!.reset();
    }

    public saveForLater(): void {
        this.saveForLaterRequested.emit(true);
    }

    public saveForm(): void {
        //console.log(`save for later`);
        if (this.form && !this.form.isReadOnly) {
            // We set interim to true so that the
            // target module knows that this is not a final
            // save...
            this.applicationService.setValue("interim", true);
            this.applicationService.writeDataForForm(
                this.userService.applicantRole()!.applicantId,
                this.userService.applicantRole()!.applicationId!,
                this.form.dataSource!)
                .subscribe(
                    r => {
                        //console.debug(`Written form data`);
                        this.showAlert('Save', `Successfully saved your current progress on this form.`);
                        this.applicationService.setValue("interim", undefined);
                    },
                    error => {
                        console.error(error);
                        // Safety
                        this.applicationService.setValue("interim", undefined);
                    });
        }
    }

    displayLastValidQuestion(): { atStart: boolean, atEnd: boolean } {
        //console.info(`displayLastValidQuestion`);
        // debugger;
        let result = this.formService.getLastQuestionBlock(this.form!, this.applicationService);

        if (result[2]) {
            // console.debug(`At the start`)
            return { atStart: true, atEnd: false }
        }
        if (result[3]) {
            // console.debug(`At the end`)
            return { atStart: false, atEnd: true }
        }

        this.questionsToDisplay = result[0];
        this.currentQuestion = result[1];

        // anims
        this.form!.questions.forEach(q => {
            q.animState = new Map<string, string>();
            q.animState.set("enterAnimationState", q.enterAnimation || '');
        });

        if (this.questionsToDisplay) {
            let reactive = this.formService.toFormGroup(this.questionsToDisplay)
            this.formGroup = reactive[0];
            this.formErrors = reactive[1];
            this.validationMessages = reactive[2];

            // Short-circuit
            let hasNonReadOnly: boolean = false;
            this.questionsToDisplay.forEach(element => {
                if (element.items) {
                    element.items.forEach(c => {
                        if (!c.readonly) {
                            hasNonReadOnly = true;
                        }
                    })
                }
            });

            // console.info(`Do I have any read-write questions? ${hasNonReadOnly}`);
            this.skipValidation = !(hasNonReadOnly);
        }

        this.formGroup!.markAsPristine();
        this.formGroup!.markAsUntouched();

        // if (!this.formGroup) { console.info(`No formGroup?`); } else { console.info(`Have formGroup`); }

        this.dataProvider?.initialiseCallbackProvider(this.form!, this.formGroup!);

        this.questionsToDisplay.forEach(q => {
            q.items.forEach(i => {
                if (i.optionSource) {
                    // console.log(`Want to load source for ${q.name}, item ${i.key}: ${i.optionSource}`);
                    this.formService.reloadDataSource(this.form!, this.formGroup!, i, this.lookup, this.dataProvider, this.reloadObserver);
                }
            })
        });

        setTimeout(() => {
            //console.info(`Trying to get formGroup`);
            this.formGroup!.valueChanges.subscribe(data => this.onValueChanged(data));
            this.pageIsValid = this.isPageValid();
        });

        this.displayChanged.emit(this.form);

        return { atStart: false, atEnd: false }
    }

    displayQuestions(forward = true): { atStart: boolean, atEnd: boolean, lastQuestion: number, lastSection: number } {
        let result = this.formService.getNextQuestionBlock(this.form!, this.applicationService, false, this.currentQuestion, forward);

        if (result[2]) {
            // console.debug(`At the start`)
            return { atStart: true, atEnd: false, lastQuestion: result[4], lastSection: result[5] }
        }
        if (result[3]) {
            //console.debug(`At the end`)
            return { atStart: false, atEnd: true, lastQuestion: result[4], lastSection: result[5] }
        }

        this.questionsToDisplay = result[0];
        this.currentQuestion = result[1];
        const actualCurrentQuestion = result[4];
        const sectionId = this.form!.questions[actualCurrentQuestion].sectionId;
        this.currentSection = this.form!.sections.find(s => s.id === sectionId);

        // anims
        this.form!.questions.forEach(q => {
            q.animState = new Map<string, string>();
            q.animState.set("enterAnimationState", (q.enterAnimation || ''));
        });

        if (this.questionsToDisplay) {
            let reactive = this.formService.toFormGroup(this.questionsToDisplay)
            this.formGroup = reactive[0];
            this.formErrors = reactive[1];
            this.validationMessages = reactive[2];
        }

        this.formGroup!.markAsPristine();

        // if (!this.formGroup) { console.info(`No formGroup?`); } else { console.info(`Have formGroup`); }

        this.dataProvider?.initialiseCallbackProvider(this.form!, this.formGroup!);

        this.questionsToDisplay.forEach(q => {
            q.items.forEach(i => {
                if (i.optionSource) {
                    // console.info(`#2 Want to load source for ${q.name}, item ${i.key}: ${i.optionSource}`);
                    this.formService.reloadDataSource(this.form!, this.formGroup!, i, this.lookup, this.dataProvider, this.reloadObserver);
                }
            })
        });

        this.trimExcessiveLengths();

        setTimeout(() => {
            this.formGroup!.valueChanges.subscribe(data => this.onValueChanged(data));
            this.pageIsValid = this.isPageValid();
        })

        // IAS must be done here, AFTER the initialiseCallbackProvider, since we may have changed
        // question attributes (e.g. min/max)
        // IAS - prevent ng-untouched complaining about 'ExpressionChangedAfterItHasBeenCheckedError'
        this.cdr.detectChanges();

        this.formGroup!.markAsUntouched();

        this.displayChanged.emit(this.form);

        return { atStart: false, atEnd: false, lastQuestion: result[4], lastSection: result[5] }
    }

    trimExcessiveLengths() {
        // iterate through the questions, checking for those with
        // a maxLength set and ensure that the answers are pre-trimmed
        this.form!.questions.forEach(q => {
            q.items.forEach(i => {
                if (i.maxLength && i.maxLength > 0) {
                    const current: string = this.applicationService.getValue(i.key);

                    if (current && current.length > i.maxLength) {
                        // debug
                        // console.log(`Trimming ${i.key} to be ${i.maxLength} in size`);
                        this.applicationService.setValue(i.key, current.substr(0, i.maxLength));
                    }
                }
            });
        });
    }

    animState(question: MfQuestion, stateName: string) {
        if(question && question.animState)
            return question.animState.get(stateName) || '';
        else
           return '';
    }

    displayIfResolver(displayIf: string) {
        //console.info(`displayIf? ${displayIf}`)
        if (displayIf) {
            var value = this.dataProvider?.displayIf(this.form!, this.formGroup!, displayIf);
            this.applicationService.setValue(displayIf, value);
            return value;
        } else {
            return true;
        }
    }

    onValueChanged(data?: any) {
        if (!this.formGroup) { return; }

        // console.info(`${JSON.stringify(data)}`);

        // console.debug(`onValueChanged in viewer component`);
        const form = this.formGroup;
        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';
            const control = form.get(field);

            // control.markAsDirty();
            if (control && control.dirty) {
                this.applicationService.setValue(field, control.value);
            }

            // if (!control.valid)
            //     // console.log(`${field} ValFn: ${control.status}, Page: ${this.isPageValid()}, dirty: ${control.dirty}, valid: ${control.valid}}`);

            // this.formGroup.updateValueAndValidity();
        }

        this.pageIsValid = this.isPageValid();
    }

    valueChanged(event: MfValueChangeEvent) {
        const control = this.formGroup!.get(event.name);

        //console.info(`value changed: ${event.name} = '${event.value}'`);
        // console.info(`value changed: ${event.name} = '${control.value}'`);
        this.applicationService.setValue(event.name, event.value);

        this.valueChange.emit(new MfValueChangeEvent(event.name, event.value, this.formGroup));
    }

    isPageValid(): boolean {
        // this.questionsToDisplay.forEach(q => {
        //     var res = this.formService.isQuestionValid(q, this.applicationService);
        // });
        return this.formGroup!.valid || this.skipValidation;
    }

    showAlert(title: string, explain: string, error: string | undefined = undefined, navigateToUrl: string | undefined = undefined) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = error ? 'danger' : 'info';
        if (error) {
            modalRef.componentInstance.error = error;
        }
        modalRef.result.then(e => {

        });
    }

    public reloadObserver: BehaviorSubject<ReloadOptions | null> = new BehaviorSubject<ReloadOptions | null>(null);
    //@ViewChildren(MetaformQuestionDisplayComponent) questionComponents: QueryList<MetaformQuestionDisplayComponent>;

    form?: Metaform;
    formGroup?: FormGroup;
    formErrors?: any;
    validationMessages!: Map<string, string>;

    currentSection?: MetaformSection;
    questionsToDisplay?: MfQuestion[];
    overrideCurrentQuestion: boolean = false;

    currentQuestion: number = 0;

    pageIsValid: boolean = false;
    questionStates = new Map<string, string>();

    loaded: boolean = false;
    skipValidation: boolean = false;
}
