import { Pipe, PipeTransform } from '@angular/core'
import { environment } from '@environments/environment'


export class CloudimagePipe implements PipeTransform {
    transform(value: string): string {
        return `https://${environment.cloudImage.id}.cloudimg.io/${getCloudImageUrlFrom(value)}&ci_cache=2592000`
    }
}

@Pipe({
    name: 'cloudimageWidth'
})
export class CloudimageWidthPipe implements PipeTransform {
    transform(value: string, width: number): string {
        return `https://${environment.cloudImage.id}.cloudimg.io/${getCloudImageUrlFrom(value)}?w=${width}&ci_cache=2592000`
    }
}

@Pipe({
    name: 'cloudimageHeight'
})
export class CloudimageHeightPipe implements PipeTransform {
    transform(value: string, height: number): string {
        return `https://${environment.cloudImage.id}.cloudimg.io/${getCloudImageUrlFrom(value)}?h=${height}&ci_cache=2592000`
    }
}

@Pipe({
    name: 'cloudimageCrop'
})
export class CloudimageCropPipe implements PipeTransform {
    transform(value: string, width: number, height: number): string {
        return `https://${environment.cloudImage.id}.cloudimg.io/${getCloudImageUrlFrom(value)}?w=${width}&h=${height}&func=crop&ci_cache=2592000`
    }
}

@Pipe({
    name: 'cloudimageFace'
})
export class CloudimageFacePipe implements PipeTransform {
    transform(value: string, width: number, height: number): string {
        // return `https://${environment.cloudImage.id}.cloudimg.io/${getCloudImageUrlFrom(value)}?w=${width}&h=${height}&func=face&ci_cache=2592000`
        return `https://${environment.cloudImage.id}.cloudimg.io/${getCloudImageUrlFrom(value)}?w=${width}&h=${height}&func=crop&ci_cache=2592000`
    }
}

function getCloudImageUrlFrom(url: string): string {
    const replace = environment.cloudImage.replace;

    if (url.indexOf(replace.s3url) === -1) return url;

    const idx = url.lastIndexOf('/resources/') + '/resources'.length;
    if (idx > -1) {
        const newUrl = `${replace.alias}${url.substring(idx)}`;
        //console.log(`Could use url: ${newUrl}`);
        return newUrl;
    }

    return url;
}