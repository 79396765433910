import { Component } from '@angular/core';

import { Router } from '@angular/router';

import { UserService } from '@aifs-shared/user/user.service';

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';

import { PreviousCampReferenceResponse, ReferenceService, SuggestedReferee, SuggestedRefereeResponse } from './reference.service';
import { RefereeDetails } from './referee-details';
import { ReferenceType } from './referee-details';
import { DateTime } from 'luxon';

@Component({
    templateUrl: './suggested-referee.component.html',
    styleUrls: ['./less/add-reference.component.scss']
})
export class SuggestedRefereeComponent extends TrackerTaskComponent  {

    constructor(
        router: Router,
        tracker: TrackerService,
        private userService: UserService,
        private referenceService: ReferenceService
    ) {
        super(router, tracker);
    }

    override ngOnInit() {
        super.ngOnInit();

        this.title = "Referee";
        this.previousButtonEnabled = true;

        const role = this.userService.applicantRole();
        if(role){
            this.isReturner = role.isReturner!;
            this.isRepeat = role.isRepeat!;        
            
            // CAT-189 - new 'choose reference type' initial option
            const applicationId = role.applicationId!;
            this.loadSuggestedReferees(applicationId);
        }
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }


    override stepNext(): void {
        // Set selected?
        this.router.navigateByUrl("/references/add-reference");
    }

    override stepPrevious(): boolean {
        this.router.navigateByUrl("/references/review");
        return true;
    }

    loadSuggestedReferees(applicationId: number): void {
        this.displayState = 0; // Loading
        this.hasSuggestions = false;
        
        this.loadSuggestedReferee(applicationId);
        this.loadPreviousCampReference(applicationId);

        setTimeout(() => { this.checkCompletion(); }, 250);
    }

    callsPending = 0;

    checkCompletion(): void {
        // console.log(`checkCompletion? ${this.callsPending}`);

        if(this.callsPending === 0) {
            // console.log('all calls returned');
            this.hasSuggestions = (this.displayItems.length > 0);
            this.displayState = 1;
            // if (this.hasSuggestions) {
            //     // console.log(`Got one of the suggestions`);
            // } else {
            //     // console.log(`Got no suggestions`);
            //     //this.displayState = 2;
            // }
            //if (this.displayState == 2) this.onSelectReference(null);               
        } else {
            setTimeout( () => {this.checkCompletion();}, 250);
        }
    }

    /* Load reference type (CAT-189) */
    loadSuggestedReferee(applicationId: number): void {
        // this.displayState = 0; // Loading
        // this.hasSuggestions = false;
        this.callsPending += 1;

        this.referenceService
            .getSuggestedReferees(applicationId)
            .subscribe({
                next: (r: SuggestedRefereeResponse) => {
                    if (r.data.length > 0) {
                        
                        // Sort?
                        // this.randomiseArray(r.data)
                        this.sortSuggestions(r.data);
                        
                    }
                    this.callsPending -= 1;

                    // // If we have no choice, jump straight to the add-reference page
                    // // as before
                    // if (this.displayState == 2) this.onSelectReference(null);
                },
                error: (error: any) => {
                    console.error(error);
                    //this.displayState = 2;
                    this.callsPending -= 1;
                }
            });
    }

    loadPreviousCampReference(applicationId: number) {
        if(this.isRepeat) {
            this.callsPending += 1;

            this.referenceService
                .getPreviousCampReference(applicationId)
                .subscribe({
                    next: (data: PreviousCampReferenceResponse) => {
                        if(data && data.data) {
                            this.suggestedPreviousCamp = data.data;
                            this.campDisplay = this.getDisplayItemFrom(this.suggestedPreviousCamp);
                        }
                        this.callsPending -= 1;
                    },
                    error: (error: any) => {
                        console.error(error);
                        this.callsPending -= 1;
                    }
                });
        }
    }

    onSelectReference(r: SuggestedReferee | null): void {
        this.tracker.setTaskStorage("reference_add", "Y");
        this.tracker.setTaskStorage("referenceSource", r);
        if (r) {
            this.tracker.setTaskStorage("reference", this.referenceFromReferee(r));
        } else {
            this.tracker.setTaskStorage("reference", null);
        }

        this.router.navigateByUrl("/references/add-reference");
    }

    getReferenceSuggestion(r: SuggestedReferee): string {
        switch (r.referenceType) {
            case 'CW':
            case 'WH':
                return 'e.g. a boss or supervisor';
            case 'CE':
            case 'EH':
                return 'e.g. any former teacher';
            case 'SK':
                if (["64", "71"].includes(r.referenceTypeId)) {
                    return 'e.g. a scout or guide leader';
                } else if (["181", "184"].includes(r.referenceTypeId)) {
                    return 'e.g. a boss or supervisor';
                } else {
                    return 'e.g. a head coach or club supervisor';
                }
            case 'PP':
                return r.referenceNameOther ? r.referenceNameOther : 'The camp director';
        }
        return '';
    }

    referenceFromReferee(r: SuggestedReferee): RefereeDetails {
        // console.log(`Company name: ${(r.referenceName ? r.referenceName : r.referenceNameOther)}`);
        let dateFrom = '';
        let dateTo = '';

        if(r.dateFrom) {
            const df = DateTime.fromISO(r.dateFrom);
            // console.log(`r.dateFrom: ${r.dateFrom}  ${df.toISODate()}`)
            if(df) dateFrom = df.toISODate()!;
        }

        if (r.dateTo) {
            const dt = DateTime.fromISO(r.dateTo);
            // console.log(`r.dateTo: ${dt.toISODate()}`)
            if(dt) dateTo = dt.toISODate()!;
        }
        var companyName = r.referenceName ? r.referenceName : r.referenceNameOther;
        if (r.referenceType === 'SK' ) companyName = '';

        let rd: RefereeDetails = {
            id: -1, 
            referenceType: ReferenceType.Online, 
            referenceStatus: '', 
            referenceStatusApplicant: '',
            firstName: '', 
            lastName: '',             
            position: '', 
            emailAddress: '', 
            emailAddressConfirmation: '', 
            validFrom: dateFrom, 
            validTo: dateTo, 
            currentlyPresent: r.referenceGroup == 1 ? true : false, 
            companyName: companyName, 
            organisationWebsite: '', 
            addressLine1: '', 
            addressLine2: '', 
            addressLine3: '', 
            addressLine4: '', 
            addressLine5: '', 
            addressCountryCode: '', 
            addressPostalCode: '', 

            canDelete: false, 
            canEdit: false, 
            alreadyReminded: false     
        };

        return rd;
    }

    private randomiseArray(array: any[]): any[] {
        // Fisher-Yates
        // console.log(`1# Array: ${JSON.stringify(array)}`);

        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        // console.log(`2# Array: ${JSON.stringify(array)}`);

        return array;
    }

    // CAT-189 update from MT:
    //
    // The way it should work is there are 3 slots for suggested references. 
    // 1 education, 1 work, 1 skill based.
    //
    // Any current education or education history entry with end date < 18 months ago, 
    // qualifies for education slot, prioritising current education if more than one apply
    //
    // Any work history entry with end date < 18 months ago, qualifies for work slot, 
    // prioritising current work place if more than one apply.
    //
    // Any of the following skills(Scouting, Girl Guides, School teacher, Youth work) 
    // or any skill with formal coaching qualifies for the skill slot, 
    // prioritising the named skills(in that order) if more than one apply
    private sortSuggestions(raw: SuggestedReferee[]): void {
        const result: SuggestedReferee[] = [];

        // console.log(`Got suggestions: ${raw.length}`);

        var educationSlot: SuggestedReferee | undefined = this.bestGuess(raw, 'CE', 'EH');
        var workSlot: SuggestedReferee | undefined = this.bestGuess(raw, 'CW', 'WH');
        var skillSlot: SuggestedReferee | undefined = this.bestGuessSkill(raw.filter(sr => sr.referenceType === 'SK'));

        // if(educationSlot) this.suggestedCurrentWorkEducation = educationSlot;
        // if(workSlot) this.suggestedRecentWorkEducation = workSlot;
        // if(skillSlot) this.suggestedSkill = skillSlot

        if (educationSlot) this.addDisplayItem(educationSlot);
        if (workSlot) this.addDisplayItem(workSlot);
        if (skillSlot) this.addDisplayItem(skillSlot);
    }

    private addDisplayItem(r: SuggestedReferee): void {       
        this.displayItems.push(this.getDisplayItemFrom(r));
    }

    private getDisplayItemFrom(r: SuggestedReferee): DisplayItem {
        let name = r.referenceName ? r.referenceName : r.referenceNameOther;
        if (r.referenceType === 'CE') {
            // CA-2688 also for MX apps
            if (
                (name.trim().toLowerCase().substring(0, 5) == 'other' || 
                 name.trim().toLowerCase().substring(0, 4) == 'otra'
                 )  && r.referenceNameOther) {
                name = r.referenceNameOther;
            }
        }
        const dr = new DisplayItem(r, name, this.getReferenceSuggestion(r));
        if (r.requestedReferees && r.requestedReferees.length > 0) {
            dr.previousReferees = `${r.requestedReferees.join(', ')} already added.`;
        }
        return dr;
    }

    // Any current or history entry with end date < 18 months ago,
    // qualifies for slot, prioritising current if more than one apply
    private bestGuess(raw: SuggestedReferee[], current: string, history: string): SuggestedReferee | undefined {
        const filtered = raw.filter(sr => sr.referenceType === current || sr.referenceType === history );
        let chosenSlot: SuggestedReferee | undefined = undefined;

        // console.log(`bestGuess for types ${current}/${history} has ${filtered.length} items`);

        filtered.forEach( s => {
            // console.log(`checking: ${JSON.stringify(s)}`);
            // is it less than 18 months ago?
            if( !s.dateTo ) { // It's ongoing
                // console.log(`Got an empty dateTo`);

                if( s.referenceType === current ) {
                    chosenSlot = s;
                } else {
                    if(!chosenSlot) chosenSlot = s;
                }
            } else {
                const dt = DateTime.fromISO(s.dateTo);
                // console.log(`Got a date: ${dt.toISODate()}`);
                const eighteenMonthsAgo = DateTime.now().minus( {month: 18} );
                if(dt > eighteenMonthsAgo) {
                    // console.log(`Got an entry of type ${s.referenceType} which is less than eighteen months old: ${dt.toISODate()} > ${eighteenMonthsAgo.toISODate()}`);
                    if (s.referenceType === current) {
                        // console.log(`Prioritising current`);
                        chosenSlot = s;
                    } else {
                        if (!chosenSlot) chosenSlot = s;
                    }
                }
            }
        });

        return chosenSlot;
    }

    private bestGuessSkill(raw: SuggestedReferee[]): SuggestedReferee | undefined {
        let chosenSkill: SuggestedReferee | undefined = undefined;

        raw.forEach(s => {
            // console.log(`checking: ${JSON.stringify(s)}`);

            // Preference to scouting/guiding/youth work/teaching
            if (["64", "71", "181", "184"].includes(s.referenceTypeId)) {
                // if (s.requestedReferees?.length == 0) {
                    // console.log(`Choosing a childcare skill ${s.requestedReferees?.length} ${JSON.stringify(s)}`);
                    // console.log(`no previously requested referees`);
                    chosenSkill = s;
                // } 
            } else {
                if (!chosenSkill && s.referenceNameOther == 'F') { // Needs to be formal skill level
                    // console.log(`Choosing a generic skill ${JSON.stringify(s)}`);
                    // if (s.requestedReferees?.length == 0) {
                        chosenSkill = s;
                    // }
                }
            }
        });
        
        return chosenSkill
    }

    title = 'References';
    loaded = false;
    formPage = 0;
    isReturner = false;
    isRepeat = false;

    campDisplay: DisplayItem | undefined;
    displayItems: DisplayItem[] = [];

    displayState = 0;
    hasSuggestions = false;
    cameFromSuggestion = false;
    // suggestedCurrentWorkEducation?: SuggestedReferee;
    // suggestedRecentWorkEducation?: SuggestedReferee;
    // suggestedSkill?: SuggestedReferee;
    suggestedPreviousCamp?: SuggestedReferee;
}

export class DisplayItem {
    suggestion: SuggestedReferee;
    referenceName: string;
    note: string;
    previousReferees: string | undefined;

    constructor(suggestion: SuggestedReferee, referenceName: string, note: string) {
        this.suggestion = suggestion;
        this.referenceName = referenceName;
        this.note = note;
    }
}
