import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountMenuComponent } from './account-menu.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [AccountMenuComponent],
    exports: [AccountMenuComponent]
})
export class AccountMenuModule { }
