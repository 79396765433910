<div class="content-wrapper">
    <h4>Account password</h4>
    <p>If you wish to change your account password, please enter your new password below.</p>
    <div class="question-block">
        <mf-named-form [metaform]="'change-password'" [dataProvider]="this"></mf-named-form>
    </div>
    <div class="actions">
        <button [disabled]="!updatePasswordButtonEnabled" class="btn profile-btn btn-focus-shadow" (click)="doChangePassword()">Update
            password</button>
    </div>
</div>
<ng-template ngbModalContainer></ng-template>