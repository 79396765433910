import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ApplicationService } from '../application/application.service';

import { TrackerTaskComponent } from '../tracker/tracker-task.component';

import { TrackerService } from '../tracker/tracker.service';
import { TaskOutro } from './task-outro';
import { TrackerEvent, TrackerEventType } from './tracker-event';

@Component({
    templateUrl: './task-outro.component.html',
    styleUrls: ['./less/task-outro.component.scss']
})

export class TaskOutroComponent extends TrackerTaskComponent {
    constructor(
        route: ActivatedRoute,
        applicationService: ApplicationService,
        router: Router,
        tracker: TrackerService
    ) {
        super(router, tracker);
        // console.log(`TaskOutroComponent`);
    }

    override ngOnInit() {
        super.ngOnInit();
        // console.log(`TaskOutroComponent:ngOnInit`);
        this.initialise();
    }

    override initialise(): void {
        // console.log(`TaskOutroComponent:initialise`);
        var path = window.location.pathname;
        this.task = this.tracker.activeTask;// this.tracker.taskByPathName(path);

        if (!this.task) {
            console.error(`I should have a task defined by the pathName ${path} - please check whether this is the case!`);

            // console.log(`No task for component found, redirecting!`);
            this.router.navigateByUrl('home');
        } else {
            this.sequenceTitle = this.task.sequence!.title;
            this.displayTaskOutro();
            // this.tracker.calculateCurrentProgress();
        }
    }

    displayTaskOutro() {
        // console.log(`displayTaskOutro: Active: ${this.task.name} - '${this.task.title}'`);
        if (this.task) {
            if (this.sequenceTitle != this.task.title)
                this.title = this.task.title;

            const ti: TaskOutro | undefined = this.task.outro;

            if (ti) {
                let bodyText = ti.bodyText;
                this.imageUrl = ti.image;
                this.footerText = ti.footerText;

                // Any replacements to make?
                if (ti.taskStorageReplacements) {
                    // console.info(`Before replacement: ${bodyText}`);
                    let temp: string | undefined = undefined;
                    ti.taskStorageReplacements.forEach(sr => {
                        temp = bodyText.split("{{" + sr + "}}").join(this.tracker.getTaskStorage(sr));
                    });

                    if (temp) bodyText = temp;
                    // console.info(`After replacement: ${bodyText}`);
                }

                this.bodyText = bodyText;
            } else {
                console.error(`Didn't get a taskOutro from the tracker for task Id: ${this.tracker?.activeTask?.id}}`);
            }

            // Hey, we're just the outro!
            this.setNextEnabled(true);;
            this.previousButtonEnabled = true;
        }
    }


    override stepNext(): void {
        // console.log(`Completing task ${this.task.name}`);
        if (this.task) this.tracker.finishAndStepNext(this.task);
    }

    /**
     * Override if you do anything special
     */
    override stepPrevious(): boolean {
        return false
    }

    title: string = '';
    bodyText?: string;
    imageUrl?: string;
    footerText?: string;
}