import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { switchMap, startWith, throttleTime, debounceTime } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { BaseResponse } from '@aifs-shared/common/base-response';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private readonly idleDuration = 60 * 1000;
  private readonly events = ['mousemove', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove'];

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService) {
    this.init();
  }

  init(): void {
    if (this.authService.isLoggedIn && environment.applicantView.isActivityCheckActive) {
      this.updateLastActivity();
      const activityEvents = this.events.map((event) => fromEvent(document, event));
      const activityObservable = merge(...activityEvents).pipe(startWith(null));

      const idleCheckObservable = activityObservable.pipe(
        debounceTime(this.idleDuration)
      );

      idleCheckObservable.subscribe(() => {
        this.updateLastActivity();
      });
    }
  }

  updateLastActivity() {
    this.http
      .post<BaseResponse>(`${environment.ServiceUrl_UpdateApplicantViewLastActivity()} `,
        {})
      .subscribe({
        next: (data: any) => {
        },
        error: (error: any) => {
          console.log(error);
        }
      });
  }
}
