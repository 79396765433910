<div class="content-wrapper">
    <h3>User Profile</h3>
    <div class="question-block">
        <div *ngIf="currentUserApplicationId" class="content-wrapper">
            <app-upload [applicationId]="currentUserApplicationId" [documentType]='DocumentType.Portrait'
                [autoUpload]='true' title='Portrait picture' (uploadedFileChanged)='uploadedFileChanged($event)'>
            </app-upload>
        </div>
        <app-change-sign-in></app-change-sign-in>
        <app-change-password></app-change-password>
    </div>
</div>
<ng-template ngbModalContainer></ng-template>