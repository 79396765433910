<div class="display-card" *ngFor="let item of items" [class.selected]="!autoExpand && isSelected(item)" (click)="selectItem(item)">
    <div class="{{item.titleClass}}">
        {{item.title}}
        <!-- <img *ngIf="!isSelected(item)" class="exp" src="assets/img/sequence/png/right_circle.png">
        <img *ngIf="isSelected(item)" class="exp" src="assets/img/sequence/png/down_circle.png"> -->
        <span *ngIf="!isSelected(item) && showChevron(item)" class="exp fa-solid fa-chevron-down"></span>
        <span *ngIf="isSelected(item) && showChevron(item)" class="exp fa-solid fa-chevron-up"></span>
        <span *ngIf="item.subtitle" class="{{item.subtitleClass}}">{{item.subtitle}}</span>
    </div>
    <p class="details" *ngIf="isSelected(item) && displayDetails">{{item.details}}</p>
    <div *ngIf="isSelected(item)">
        <p class="details" *ngIf="item.detailsExtra">{{item.detailsExtra}}</p>
        <p class="details" *ngIf="item.detailsExtraLine2">{{item.detailsExtraLine2}}</p>
        <p *ngIf="item.innerHTML" class="{{item.innerHTMLClass}}"><span [innerHTML]="item.innerHTML"></span></p>
        <div *ngIf="item.additionalButtonItems">
            <button *ngFor="let b of item.additionalButtonItems" type="button" class="btn btn-no-border {{b.cssClass}}"
                (click)="buttonPressed(item, b.id)">{{b.label}}</button>
        </div>
        <div *ngIf="item.canEdit || item.canDelete">
            <button *ngIf="item.canEdit" type="button" class="btn btn-small {{item.editOptionClass}}"
                [class.btn-ca-default]="!autoExpand" [class.btn-ca-success]="autoExpand" (click)="editItem($event, item)">{{item.editOptionText}}</button>
            <button *ngIf="item.canDelete" type="button" class="btn btn-small btn-ca-danger" (click)="deleteItem(item)">{{item.deleteOptionText}}</button>
        </div>
    </div>
</div>