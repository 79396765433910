<div>
    <p *ngIf="isNextSeason" class="alert alert-info-centre" role="alert">
        <i class="fa-solid fa-circle-info"></i> You are applying for the {{applicationSeason}} season
    </p>

    <h4>Hi {{name}}</h4>
    <p class="centre">Welcome to your homepage!</p>

    <div *ngIf="userState">
        <p class="centre">
            <span innerHTML="{{userState}}"></span>
        </p>
        <p *ngIf="userStateExtra" class="left">
            <span innerHTML="{{userStateExtra}}"></span>
        </p>
    </div>
</div>