import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { BaseResponse } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

@Injectable()
export class LanguageService extends BaseService {

    public constructor(private http: HttpClient) {
        super();
    }

 
    initialiseLanguage(browserLocale: string) {
        const enMap = new Map<string, string>();
        enMap.set("refereeFirstName", "Your first name");
        enMap.set("refereeLastName", "Your last name");
        enMap.set("applicantFirstName", "The applicant's first name");
        enMap.set("applicantLastName", "The applicant's last name");
        enMap.set("email", "Email address");
        enMap.set("emailType", "Please confirm the type of email address provided above");
        enMap.set("phoneAreaCode", "Contact telephone number");
        enMap.set("phone", "");
        enMap.set("phoneType", "The contact phone number provided is");
        enMap.set("organisationName", "Organisation");
        enMap.set("position", "Job title");
        enMap.set("website", "Organisation Website");
        enMap.set("address1", "Organisation Address");
        enMap.set("address2", "");
        enMap.set("address3", "");
        enMap.set("addressCountry", "");
        enMap.set("addressPostCode", "");
        enMap.set("necessaryCorrections","");
        enMap.set("contactEmail", "Company/University/School Email");
        enMap.set("relationshipType", "What is your relationship to the applicant?");
        enMap.set("relationshipDescription", "Other relationship");
        enMap.set("isRelation", "Is the applicant a family member?");
        enMap.set("relationDetails", "If yes, how are you related?");
        enMap.set("attendanceFrom", "Dates of applicant's employment or attendance");
        enMap.set("attendanceTo", "Date to (if applicable)");
        enMap.set("periodKnownYears", "How long have you known the applicant?");
        enMap.set("periodKnownMonths", "");
        enMap.set("contactLevel", "Do you currently remain in contact with the applicant?");
        enMap.set("role", "What role did the applicant play in the organisation?");
        enMap.set("performanceSummary", "Please describe the applicant's performance (e.g. effectiveness, abilities, character and work ethic).");
        enMap.set("departureReasonDescription", "Why did the applicant leave the organisation?");
        enMap.set("anyDisciplinary", "Was the applicant subject to any disciplinary action while at this organisation?");
        enMap.set("wouldRehire", "If given the opportunity would you rehire this applicant");
        enMap.set("wouldRehireReasons", "Please tell us why");
        enMap.set("summaryForCamp", "Given your assessments, please summarise the applicant's suitability for a position of responsibility for the care and welfare of campers at a U.S summer camp. Please detail reasons for any poor or average ratings above.");
        enMap.set("recommended", "What is your conclusion in terms of recommendation of this applicant for the Camp America programme.");
        enMap.set("comments", "");
        enMap.set("confirmsInformation", "By checking this box I confirm that these answers have been given to the best of my ability and knowledge.");

    }
}