import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { BaseResponse } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { UserService } from '@aifs-shared/user/user.service';

@Injectable()
export class PoliceCheckService extends BaseService {

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private userService: UserService) { super(); }

    public isApplicantInGBGCountry(applicationId: number): Observable<AllowedForKYPRegistration> {
        let s = new Subject<AllowedForKYPRegistration>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_DBS_GetDBSProspectDetails(applicationId))
            .subscribe(
                data => {
                    const response = data.result as AllowedForKYPRegistration;

                    s.next(response);
                },
                error => {
                    s.error(error);
                });

        return s;
    }

    public getDBSStatus(applicationId: number): Observable<DBSStatusResponse> {
        const s = new Subject<DBSStatusResponse>();

        this.http
            .get(environment.ServiceUrl_Applicant_DBSDetails(applicationId))
            .subscribe({
                next: (data: any) => {
                    if (data.result) {
                        const response = this.getResultData<DBSStatusResponse>(data);
                        s.next(response);
                    }
                },
                error: (error: any) => {
                    s.error(error);
                }
            });

        return s;
    }

    public manualRegistration(applicationId: number): Observable<BaseResponse> {
        let s = new Subject<BaseResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_DBS_Register(applicationId), {})
            .subscribe(
                data => {
                    const response = data as BaseResponse;
                    s.next(response);
                },
                error => {
                    s.error(error);
                });

        return s;
    }

    public getPaperPoliceCheckStatus(applicationId: number): Observable<PaperPoliceCheckStatusResponse> {
        const s = new Subject<PaperPoliceCheckStatusResponse>();

        this.http
            .get(environment.ServiceUrl_PaperPoliceCheck_Status(applicationId))
            .subscribe({
                next: (data: any) => {
                    if (data.result) {
                        const response = this.getResultData<PaperPoliceCheckStatusResponse>(data);
                        s.next(response);
                    }
                },
                error: (error: any) => {
                    s.error(error);
                }
            });

        return s;
    }
}


export class DBSStatusResponse {
    details!: DBSStatus;
}

export interface DBSStatus {
    applicationStatus: KYPApplicationStatus;
    awaitingActionBy: KYPAwaitingActionBy;
}

export interface AllowedForKYPRegistration {
    allowedForKYP: boolean;
    isRegistered: boolean;
    isPostOffice: boolean;
    isOverriddenByPaperCheck: boolean;
}

export interface PaperPoliceCheckStatusResponse {
    isPaperCheck: boolean;
    paperPoliceCheckReceived: boolean;
    paperPoliceCheckStatus: string;
}

export interface DBStatus {
    applicationId: number;
    isOnline: boolean;
    isPostOffice: boolean;
    organisationType: number;
    organisationId: number;
    agencyId: number;
    url: string;
    crbReceived: boolean;
    crbVerified: Date;
    crbStatus: string;
    crbResult: string;
}

export enum KYPApplicationStatus
{
    Unknown,
    ToBeCompleted,
    InProgress,
    AwaitingAction,
    Completed,
    Withdrawn,
    AwaitingVerification,
    AwaitingCountersign,
    AwaitingPayment,
    AwaitingDbsReference,
    NotRegistered
}

export enum KYPAwaitingActionBy
{
    NA,
    Customer,
    KYP,
    Applicant
}