<tracker-progress></tracker-progress>
<div class="content-wrapper">
    <h3>My Application</h3>
    <h4>Profile Picture</h4>
    <p *ngIf="!nextButtonEnabled; else uploadedTop">
        Please upload a profile picture. Make sure it’s a happy, smiley one as this is what the camps will see first!
        Make sure it’s cropped and rotated the right way up before uploading
    </p>
    <ng-template #uploadedTop>
        <p>You can change this image at any time by clicking on the edit button.</p>
    </ng-template>
    <app-upload [applicationId]="currentUserApplicationId" [documentType]='DocumentType.Portrait' [autoUpload]='true'
        title='Portrait picture' (currentOfType)="currentFileFound($event)" (onUploadError)="uploadError($event)" (uploadedFileChanged)='uploadedFileChanged($event)'></app-upload>
    <p *ngIf="!nextButtonEnabled; else uploadedBottom">Don't worry, you will be able to change this!</p>
    <ng-template #uploadedBottom>
        <p><span class="tip">TIP!</span> Make sure your image is cropped and rotated the right way up before uploading.</p>
    </ng-template>
    <div class="extra" *ngIf="noPicture && errorDetected">
        <button type="button" class="btn btn-lower btn-wide btn-no-border-plain" (click)="skipTask()">Upload profile picture later...</button>        
    </div>
    <tracker-buttons [overrideOffscreenProcessing]="true" [noAssociatedTask]="true"></tracker-buttons>
</div>