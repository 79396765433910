import { Injectable, Injector } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaderResponse } from '@angular/common/http'

import { BaseService } from '@aifs-shared/common/base-service';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';
import { ResponseData } from '@aifs-shared/common/response-data';

import { Observable, forkJoin, BehaviorSubject, Subject, EMPTY } from 'rxjs';
import { first, mergeMap } from "rxjs/operators";

import { environment } from '../../../environments/environment';

// Events
import { TrackerEvent, TrackerEventType } from './tracker-event';

// Service injectors
import { UserService } from '../user/user.service';
import { AuthenticationService } from '../auth/authentication.service';
import { ApplicationService } from '../application/application.service';
import { MetaformService } from '../metaform/metaform.service';
import { BusinessRuleService } from '../rule/business-rule.service';

import { IBusinessRuleData } from '../rule/business-rule';

// Providers
import { ITaskProvider } from './task-provider';

// Service-specific classes
import { Sequence } from './sequence';
import { Task, TaskDetails, TaskStatus, TaskIntroTemplate, TaskOutroTemplate, UserTaskStatus } from './task';
import { TaskIntro } from './task-intro';
import { TaskOutro } from './task-outro';

import { SkillService, SkillsForCurrentApplicantResponse } from '../../skills/skill.service';

@Injectable()
export class TrackerService extends BaseService implements IBusinessRuleData {
    /**
     * @description Constructor
     */
    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthenticationService,
        private userService: UserService,
        private applicationService: ApplicationService,
        private formService: MetaformService,
        private rules: BusinessRuleService,
        private injector: Injector,
        private skillService: SkillService) {
        super();
    }


    /**
     * @description Initialise. Call from application bootstrap
     */
    public initialise(): void {
        // ////console.log(`Tracker Service Initialising`);
        this.updateSendEvent(TrackerEventType.Initialising);
        this.rules.loadRules();

        this.router.events
            .subscribe(e => {
                if (e instanceof NavigationStart) {
                    this.previousUrl = this.router.url;
                }
            });
    }

    /**
     * @description Determine the next possible task for the
     * current user.
     */
    public findNextStep(): TaskAction {
        let action: TaskAction | null = null;
        let priority = ToDoPriorityItem.None;

        if (!this.currentTasks) {
            throw new Error("TrackerService should have a list of valid tasks by now!");
        }

        // Check terms?
        if (!this.userService.hasLatestTermsAndConditions()) {
            // We must go to the terms and conditions page
            action = new TaskAction(null, "/user/terms-and-conditions/latest/TC");
        } else {
            // Firstly, we need to check to see whether there is an
            // override task specified
            if (this.overrideTask) {
                // console.log(`We have an override task`, this.overrideTask);
                return new TaskAction(null, this.overrideTask);
            }

            // We can check to see whether we are chaining tasks,
            // in which case find the first available task
            if (this.chainTasks) {
                // console.info(`We're chaining tasks, and the number of tasks are ${this.currentTasks.length}`, this.currentTasks);
                // Find the first task which is not complete
                const nextTask = this.currentTasks.find(t => !t.complete && (this.rules.evaluateRule(t.ruleToMatch, this)));
                if (nextTask) {
                    // console.log(`got a next task: ${JSON.stringify(nextTask, null, 2)}`);
                    nextTask.taskStatus = TaskStatus.Initialising;
                    action = new TaskAction(nextTask);

                    this.updateSendEvent(TrackerEventType.TaskLoadComplete);
                } else {
                    // console.log(`No chained task found`);
                    action = new TaskAction(null, "home");
                }
            } else {
                // ////console.log(`We're not chaining tasks`);

                // // We're not chaining, so we may require priority
                // // items to be displayed to the user
                // // NOTE(ian): Not final implementation!
                // if (this.todoPriority != ToDoPriorityItem.None) {
                //     // ////console.log(`I have a to-do priority item: ${this.todoPriority}`);
                //     priority = this.todoPriority;
                // }
            }

            // If we are not chaining tasks, or we do not have
            // an active task, return the home page (or hub) as
            // specified
            if (!action) {
                // console.log(`no action, no chaining tasks`);
                action = new TaskAction(null, this.homeRoute, priority);
            }
        }

        return action;
    }

    /**
     * @description Get the active task
     */
    public get activeTask(): Task | undefined {
        var task: Task | undefined = this.currentTask;
        const at = sessionStorage.getItem("tfs:at");
        if (!task && at) {
            const taskId: number = parseInt(at);

            if (this.currentTasks) {
                task = this.currentTasks.find(t => t.id == taskId);
            }
            if (!task) console.warn(`No active task id in storage`);
        }

        //console.log(`active: ${JSON.stringify(task,null,2)}`);

        return task;
    }

    public setReturnUrl(url: string): void {
        localStorage.setItem("tracker:returnUrl", url);
        //console.log(`Setting return Url to ${url} ${this.getCaller()}`);
    }

    private getCaller(): string {
        try {
            throw new Error();
        } catch (e: any) {
            // matches this function, the caller and the parent
            const allMatches = e.stack.match(/(\w+)@|at (\w+) \(/g);
            // match parent function name
            const parentMatches = allMatches[2].match(/(\w+)@|at (\w+) \(/);
            // return only name
            return parentMatches[1] || parentMatches[2];
        }    
    }

    /**
     * @description Set the active task for subsequent use
     * @param t (Task)
     */
    public setActiveTask(t: Task) {
        const current = this.activeTask;
        const different = t != current;

        this.updateActiveTask(t);

        // Clear out taskStorage
        this.taskStorage.clear();

        if (different) {
            this.updateSendEvent(TrackerEventType.ActiveTaskChanged);
        }
    }

    public clearActiveTask(): void {
        sessionStorage.removeItem("tfs:at");
    }

    /**
     * @description Remove any stored task information
     * Call from sign-out
     */
    public clearTasks() {
        sessionStorage.removeItem("tfs:at");
        this.currentTasks = undefined;
        this.currentTask = undefined;
    }

    /**
     * @description Store the passed task
     * @param t (Task)
     */
    public updateActiveTask(t: Task) {
        // if (t) ////console.debug(`Storing task in localStorage: ${t.name}`);
        sessionStorage.setItem("tfs:at", JSON.stringify(t.id));

        this.currentTask = t;
    }

    /**
     * @description Find all matching tasks in the passed
     * group (complete or otherwise) which pass rule evaluation
     * @param groupId (number) - the task group id
     */
    public tasksForGroup(groupId: number): Task[] {
        // console.log(`Got group: ${groupId}, got ${this.currentTasks?.length} tasks`);
        const taskArray = this.currentTasks?.filter(t => t.groupId == groupId && this.rules.evaluateRule(t.ruleToMatch, this));
        if(!taskArray) {
             console.warn(`Didn't find a task for group ${groupId}`);
             return [];
        } else {
            // console.log(`Got a task list:`, taskArray);
            return taskArray;
        }
    }

    /**
     * @description We are starting the current task
     */
    public startTask(t: Task) {
        // console.info(`StartTask: ${JSON.stringify(t)}`);
        this.applicationService.resetFormCallCount();

        // Set the active task now if it is not already
        this.setActiveTask(t);

        // Depending on the task status this will dictate
        // whether (and if there is one) we go to the intro
        // or to the actual task itself
        let url = this.getUrlForTaskStart(t);

        // console.log(`Starting task ${t.name} at Url ${url}`);

        // Override - if the action is Create Application, we
        // have a pre-condition to apply instead
        if (t.name === 'CreateApplication') {
            // ////console.log(`Got a create application task`);
            url = '/user/create-application';
            this.router.navigateByUrl(url);
        }
        else if (t.name === 'SkillsSecondTime') {
            //Check if no skills then redirect to skills/overview
            this.skillService
                .getSkillsForCurrentApplicant()
                .subscribe({
                    next: (skillsRes: SkillsForCurrentApplicantResponse) => {
                        if (!skillsRes.addedSufficientSkillsForPreInterview) {
                            this.router.navigateByUrl('/skills/overview');
                        }
                        else {
                            this.router.navigateByUrl(url);
                        }
                    },
                    error: (error: any) => {
                        ////console.error(error);
                    }
                });
        } else {
            this.router.navigateByUrl(url);
        }
    }

    public getTaskForApplicant(applicantId: number, taskId: number): Observable<Task | undefined> {
        const s = new Subject<Task | undefined>();

        this.http.get<BaseResponse>(environment.ServiceUrl_GetTrackerTasksForApplicantTask(applicantId, taskId))
        .subscribe({
            next: (r: any) => {
                const result = r;
                if (result) {
                    const task: TaskResponse = this.getResultData<TaskResponse>(result);
                    if(task.tasks && task.tasks.length > 0){
                        // console.log(`Task: ${JSON.stringify(task.tasks[0], null, 2)}`);
                        s.next(task.tasks[0]);
                        return;
                    }
                }
                s.next(undefined);
            },
            error: (error: any) => {
                if(error instanceof HttpErrorResponse) {
                    const he = error as HttpErrorResponse;
                    // console.log(`${JSON.stringify(he)}`);
                    if(he.status == 404) {
                        s.next(undefined);
                        return;
                    }
                }
                console.error(error);
                s.error(error);
            }
        });

        return s;
    }

    /**
     * @description Complete the current task
     */
    public finishTask() {
        const task = this.activeTask;
        //console.log(`finishTask`);
        if (!task) {
            this.router.navigateByUrl(this.homeRoute);
            //console.log(`no active task`);
            return;
            //throw new Error(`There is no active task!`);
        }

        const isOnTaskStartPage = this.router.url.includes(task.routerUrl!);
        const isOnLastPage = (task.routerUrlReview != "" && this.router.url.includes(task.routerUrlReview!));
        const specialCase = this.router.url === '/interview/requested';

        if (isOnTaskStartPage || isOnLastPage || specialCase) {
            //console.log(`update send event`);
            this.updateSendEvent(TrackerEventType.TaskCompleting);
            this.completeCurrentTask(task)
                .subscribe({
                    next: (result: boolean) => {
                        if (result) {
                            // Send another task event type?
                            //console.log(`Task ${task.name} completed successfully`);

                            let url: string = task.routerUrl ?? '';
                            let params;

                            if (task.outroTemplate !== TaskOutroTemplate.None) {
                                url = `${url}/finished`;
                                //console.log(`Navigate to ${url}`);
                                this.router.navigate([url], { queryParams: params });
                            } else {
                                this.finishAndStepNext(task);
                            }

                        } else {
                            //console.error(`Task ${task.name} NOT completed!`);
                        }
                    },
                    error: (error: any) => {
                        console.error(error);
                    }
                });
        }
        else {
            //console.log(`not completing? ${this.router.url} - ${task.routerUrl} - ${task.routerUrlReview} (${this.router.url.includes(task.routerUrl)}), (${task.routerUrlReview != ""} && ${this.router.url.includes(task.routerUrlReview)})`);
            this.finishAndStepNext(task);
        }
    }

    /**
     * @description When we have no outro, or when the step next is
     * called from outro, let's see where we go from here..
     * @param task (Task)
     */
    public finishAndStepNext(task: Task) {
        task.complete = true;

        //console.log(`Task completed is '${task.name}'`);

        if (task.name === "CreateApplication") {
            // ////console.debug(`moveToNextTask: Completed CreateApplication -- waiting for createapplication message from PUSH`);
            this.router.navigateByUrl("/hub");
            return;
        } else {
            // Check whether the current task is part of a group
            const thisGroup = task.groupId;
            //console.log(`Current groupId is ${thisGroup}, next is ${task.next}`);
            const nextInGroup = this.currentTasks?.find(tc => tc.id == task.next && tc.groupId == thisGroup);

            if (nextInGroup) {
                //console.log(`Chaining next task`);
                nextInGroup.taskStatus = TaskStatus.Initialising;
                this.startTask(nextInGroup);
            } else {
                this.loadTasksForApplicant()
                    .subscribe({
                        next: (result: Task[]) => {
                            var action = this.findNextStep();
                            // If we're chaining tasks and there is a next, go
                            // to it, otherwise, return to the home/hub page
                            if (this.chainTasks && action.task) {
                                // console.log(`Got a next action`);
                                this.startTask(action.task);
                            } else {
                                // console.log(`Not chaining tasks, navigate to ${this.homeRoute}`);
                                //localStorage.setItem("hu", "Y");

                                const returnUrl = localStorage.getItem("tracker:returnUrl");
                                if (returnUrl) {
                                    localStorage.removeItem("tracker:returnUrl");
                                    // console.log(`CHECK CHECK CHECK ::: Override task complete url: ${returnUrl}`);
                                    this.router.navigateByUrl(returnUrl);
                                    return;
                                } else {
                                    // console.log(`Navigating to this.homeRoute`);
                                    this.router.navigateByUrl(this.homeRoute);
                                    return;
                                }
                            }
                        }
                    });
            }
        }

    }

    /**
     * @description Complete the current task
     */
    public completeCurrentTask(task: Task): Observable<boolean> {
        let subject = new Subject<boolean>();

        // ////console.info(`completeCurrentTask`);
        //console.log(`Completing task ${task.name}`);

        if (task.taskCompleteFn) {
            //console.log(`Task has a process complete function specified`);
            task.taskCompleteFn.fn.apply(this, task.taskCompleteFn.args)
                .subscribe({
                    next: (r: any) => {
                        ////console.log(`Got result: `, r);
                        subject.next(r);
                    },
                    error: (error: any) => {
                        ////console.log(`Got error: `, error);
                        subject.error(error);
                    }
                });
        } else if (task) {
            //console.log(`Task is being marked as complete`);
            this.markTaskAsComplete(task)
                .subscribe({
                    next: (r: any) => {
                        ////console.log(`Got result: `, r);
                        subject.next(r);
                    },
                    error: (error: any) => {
                        ////console.log(`Got error: `, error);
                        subject.error(error);
                    }
                });
        } else {
            //console.log(`Task not present.. Is this bad?`);
            subject.next(false);
        }

        return subject;
    }

    /**
     * @description Mark the passed task as complete
     * @param task (Task)
     */
    public markTaskAsComplete(task: Task): Observable<boolean> {
        let subject = new Subject<boolean>();

        task.taskStatus = TaskStatus.Complete;
        task.complete = true;

        //console.log(`MarkTaskAsComplete ${JSON.stringify(task)}`);

        this.updateTaskStatusForApplication(this.userService.applicantRole()!.applicantId, task)
            .subscribe(
                r => {
                    ////console.log(`Marked task ${task.id} as complete`);
                    subject.next(r);
                },
                error => {
                    ////console.log(`Error marking task as complete`, error);
                    subject.error(error);
                }
            );

        return subject;
    }

    /**
     * @description Mark the passed task as skipped
     * @param task (Task)
     */
    public markTaskAsSkipped(task: Task): Observable<boolean> {
        let subject = new Subject<boolean>();

        task.skipped = true;

        this.updateTaskStatusForApplication(this.userService.applicantRole()!.applicantId, task)
            .subscribe(r => {
                subject.next(r);
            },
                error => {
                    subject.error(error);
                })

        return subject;
    }

    /**
     * @description Mark the passed task as incomplete
     * @param task (Task)
     */
    public markTaskAsIncomplete(task: Task): Observable<boolean> {
        let subject = new Subject<boolean>();

        task.taskStatus = TaskStatus.Complete;
        task.complete = false;

        this.updateTaskStatusForApplication(this.userService.applicantRole()!.applicantId, task)
            .subscribe(r => {
                subject.next(r);
            },
                error => {
                    subject.error(error);
                })

        return subject;
    }

    /**
     * @description This will reload all task information and rules
     * for the current user/applicant. Once data has been retrieved,
     * it will call the task event stream with TasksLoaded
     *
     * NOTE(ian): This does not update the active task as I can't be
     * certain that the user won't be in the midst of a task if this
     * event is called (e.g. we receive a user push event regarding
     * the interviewer confirming the interview and need to collect
     * the new event for that)
     */
    public reloadForCurrentUser() {
        if (this.userService.isApplicant()) {
            // ////console.info(`reloadForCurrentUser`);
            this.loadTasksForApplicant()
                .subscribe(
                    tasks => {
                        // Only sent by this method!
                        this.updateSendEvent(TrackerEventType.UserStatusUpdateTasksReloaded);
                    });
        }
    }

    /**
     * ITaskProvider Handling
     */

    /**
     * @description Can we go forwards from this task?
     * Calls out to any registered taskProvider
     */
    public get canStepNext(): boolean {
        return (this.taskProvider ? this.taskProvider.nextEnabled() : true);
    }

    /**
     * @description Can we step backwards?
     */
    public get canStepPrevious(): boolean {
        return (this.taskProvider ? this.taskProvider.previousEnabled() : false);
    }

    /**
     * @description Step to the next step in the sequence
     */
    public next(): void {
        if (this.taskProvider) this.taskProvider.stepNext();
    }

    /**
     * @description Step to the previous step in the sequence
     * Only really used now by the metaforms (and I may want to
     * factor this out since it's an in-task function)
     */
    public previous(): void {
        if (this.taskProvider) this.taskProvider.stepPrevious();
    }

    /**
     * ITaskProvider Handling Ends
     */

    /**
     * @description Load task graph for the current applicant
     * NOTE: this will also load the rules, since it's a little
     * hard to balance the rule loading as being distinct from
     * the tasks; each is reliant upon the other (although it's
     * marginally possible that the rules may change more often)
     */
    public loadTasksForApplicant(): Observable<Task[]> {
        let subject = new Subject<Task[]>();
        let url: string = `/assets/tasks/`;

        const userId = this.userService.user().id;
        const applicantId = this.userService.applicantRole()!.applicantId;

        forkJoin(
            this.http.get<Sequence[]>(`${url}sequences.json`),
            this.http.get<BaseResponse>(`${environment.ServiceUrl_GetTrackerTasks(applicantId)}`),
            this.http.get<TaskIntro[]>(`${url}intros.json`),
            this.http.get<TaskOutro[]>(`${url}outros.json`)
        ).subscribe(
            data => {
                var sequences = (data[0]);
                var td: TaskDetails = new TaskDetails();
                if (data[1]) {
                    td = this.getResultData<TaskDetails>(data[1]);
                }
                var taskIntros = (data[2]);
                var taskOutros = (data[3]);

                var applicationTasks = td.tasks;

                this.isParticipant = td.isParticipant;
                this.isPlaced = td.isPlaced;
                this.returnerTasks = td.isReturner;
                this.overrideTask = td.override;
                this.chainTasks = td.chainTasks;
                this.homeRoute = td.homeRoute;
                this.todoPriority = td.toDoItem;

                var previous: Task;

                // ////console.info(`Got tasks for applicant ${applicantId}: ${JSON.stringify(td.tasks, null, 2)}`);
                this.getTaskStatus(applicantId)
                    .subscribe(
                        uts => {
                            // ////console.log(`Task Status:`, uts);
                            // if (applicationTasks) ////console.info(`Got ${applicationTasks.length} tasks for applicant ${applicantId}`);

                            // Build the task graph
                            applicationTasks.forEach(task => {
                                task.sequence = sequences.find(s => s.id == task.sequenceId);
                                task.intro = taskIntros.find(t => t.taskId == task.id);
                                task.outro = taskOutros.find(t => t.taskId == task.id);

                                // For stepping next and previous
                                if (previous) {
                                    task.prev = previous.id;
                                    previous.next = task.id;
                                }

                                previous = task;
                            });

                            this.currentTasks = applicationTasks;
                            ////Force rollover when applicable
                            //var rolloverTask = this.currentTasks.find(element => element.id === 23);
                            //if (rolloverTask) {
                            //    this.setActiveTask(rolloverTask)
                            //}

                            this.userTaskStatus = uts;

                            this.reloadTaskRuleData()
                                .subscribe(
                                    result => {
                                        if (result) {
                                            this.updateSendEvent(TrackerEventType.TasksLoaded);

                                            subject.next(applicationTasks);
                                        } else {
                                            subject.error(`Couldn't load task rule data!`);
                                        }
                                    });
                        },
                        error => {
                            ////console.error(error);
                        }
                    );
            },
            error => {
                subject.error(error);
            });

        return subject
    }

    /**
     * @description Initialises rollover
     * @param t (Task)
     */
    public initialiseRollover() {
        if (!this.currentTasks) {
            this.loadTasksForApplicant()
                .pipe(
                    mergeMap(tasks => tasks),
                    first()
                ).toPromise();
        }
        const rolloverTask = this.currentTasks?.find(element => element.id === 23);
        if (rolloverTask) {
            this.setActiveTask(rolloverTask);
        }
    }

    /**
     * @description Find a given task's user status (complete or skipped)
     * @param taskId (number) - The task to find
     */
    public getUserTaskStatusById(taskId: number): UserTaskStatus | null {
        if (this.userTaskStatus) {
            const found = this.userTaskStatus.find(t => t.taskId == taskId);
            if (found) return found;
        }
        return null;
    }

    /**
     * @description Find a given task
     * @param taskId (number) - the task to find
     */
    public getTaskById(taskId: number): Task | null {
        if (this.currentTasks) {
            const found = this.currentTasks.find(t => t.id === taskId);
            if(found) return found;
        }

        return null;
    }

    /**
     * @description Get the task rule data from the server
     */
    reloadTaskRuleData(): Observable<boolean> {
        let subject = new Subject<boolean>();

        const userId = this.userService.user().id;

        this.http.get<BaseResponse>(`${environment.ServiceUrl_GetTrackerTaskRuleData(userId)}`)
            .subscribe({
                next: (data: any) => {
                    // Rules
                    if (data.result) {
                        const ruleData = data.result["data"];

                        for (let k in ruleData) {
                            let value = ruleData[k];
                            this.setValue(k, value);
                        }
                    }

                    subject.next(true);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    /**
     * @descript Return the task status from the database
     * @param applicantId (number) - current applicant Id
     */
    getTaskStatus(applicantId: number): Observable<UserTaskStatus[]> {
        let s = new Subject<UserTaskStatus[]>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_GetTaskStatusForApplicant(applicantId)}`)
            .subscribe(
                r => {
                    const result = this.getResultData<UserTaskStatusResponse>(r);
                    s.next(result.tasks);
                },
                e => {
                    s.error(e);
                }
            );
        return s;
    }

    /**
     * Update the task status for the given applicant
     * @param applicantId (number) - user's applicantId
     * @param t (Task) - the completed task
     */
    updateTaskStatusForApplication(applicantId: number, t: Task): Observable<boolean> {
        let subject = new Subject<boolean>();
        //////console.info(`updateTaskStatusForApplication for applicant ${ applicantId } from '${environment.ServiceUrl_UpdateTaskStatusForApplicant(applicantId)}'`);

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_UpdateTaskStatusForApplicant(applicantId)} `,
                { applicantId: applicantId, taskId: t.id, complete: t.complete, skipped: t.skipped })
            .subscribe({
                next: (data: BaseResponse) => {
                    if (data.resultCode == ResponseResultCode.Success) {
                        // ////console.log(`Got status response, task details are: ${t.name} is complete: ${t.complete}, is skipped ${t.skipped} `);
                        //this.checkUpdateTaskStatusResponse(applicantId, t.id, t.complete);
                        subject.next(true);
                    } else {
                        subject.next(false);
                    }
                },
                error: (error:any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    /**
     * Store an item for the passed key value
     * @param key (string) - task storage item name
     * @param value (any) - value for key
     */
    public setTaskStorage(key: string, value: any) {
        this.taskStorage.set(key, value);
    }

    /**
     * Return an item for the passed key value
     * @param key (string) - task storage item name
     */
    public getTaskStorage(key: string): any {
        return this.taskStorage.get(key);
    }

    /**
     * @description Write out a TrackerEvent to the event stream
     * @param event (TrackerEventType)
     */
    updateSendEvent(event: TrackerEventType) {
        // ////console.debug(`TrackerService -> Sending event '${event}'`);
        this._trackerEventSource.next(new TrackerEvent(event));
    }

    /**
     * @description Find the initial url for the given task
     * @param task (Task)
     */
    getUrlForTaskStart(task: Task): string {
        let url = task.routerUrl;

        // ////console.log(`GetUrlForTaskStart: Status is ${task.taskStatus}, template = ${task.introTemplate} `);
        if (task.taskStatus == TaskStatus.Initialising && task.introTemplate !== TaskIntroTemplate.None) {
            url = `${task.routerUrl}/intro`;
        }

        // ////console.log(`Returning ${url}`);

        return url!;
    }

    public getReturnerSubmittedApplication(applicantId: number): Observable<boolean> {
        let subject = new Subject<boolean>();
        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_ReturnerReadyToSubmit(applicantId)}`)
            .subscribe({
                next: (data: any) => {
                    if (data.resultCode == ResponseResultCode.Success) {
                        subject.next(data.result["returnerReadyToSubmit"]);
                    } else {
                        subject.next(false);
                    }
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });
        return subject;
    }

    // -------------------------------------------------------------
    // Implementation of IBusinessRuleData interface

    public loadFromUrl(applicantId: number, applicationId: number, dataSource: string): Observable<any> { return EMPTY; }
    public writeToUrl(applicantId: number, applicationId: number, dataSource: string): Observable<any> { return EMPTY; }
    public getValue(name: string): any { if (this.taskRuleData.has(name)) { return this.taskRuleData.get(name); } }
    public setValue(name: string, value: any) { this.taskRuleData.set(name, value); }
    public getCurrentData() { let data: any = {}; this.taskRuleData.forEach((value: string, key: string) => { data[key] = value; }); return data; }

    // Implementation of IBusinessRuleData interface Ends
    // -------------------------------------------------------------

    // -------------------------------------------------------------
    // Handling for ITaskProviders

    /**
     * @description Register a task status provider
     * @param provider (ITaskProvider) - who can tell us about this task and its steps
     */
    public registerTaskProvider(provider: ITaskProvider) {
        this.taskProvider = provider;
    }

    /**
     * @description Unregister an existing task status provider
     * @param provider (ITaskProvider) the provider to remove
     */
    public unregisterTaskProvider(provider: ITaskProvider) {
        if (this.taskProvider === provider) {
            this.taskProvider = undefined;
        }
    }

    // -------------------------------------------------------------
    // ITaskProviders End
    // -------------------------------------------------------------

    /**
     * Data Members
     */

    /**
     * Public
     */
    public isParticipant: boolean = false;
    public isPlaced: boolean = false;

    public returnerTasks: boolean = false;
    public overrideTask?: string;
    public chainTasks: boolean = false;
    public todoPriority: number = 0;
    public homeRoute: string = "/home";
    public previousUrl: string = '';

    /**
     * Private
     */

    // Observable event source
    _trackerEventSource = new BehaviorSubject<TrackerEvent>(new TrackerEvent(TrackerEventType.Initialising));
    public trackerEventStream$ = this._trackerEventSource.asObservable();

    taskProvider?: ITaskProvider;

    currentTasks?: Task[];
    currentTask?: Task;
    userTaskStatus?: UserTaskStatus[];

    // In session storage
    taskRuleData: Map<string, any> = new Map<string, any>();
    taskStorage: Map<string, any> = new Map<string, any>();
}

export class TaskAction {
    public urlTarget?: string;
    public task: Task | null;
    public priorityItem?: ToDoPriorityItem;

    public constructor(task: Task | null, urlOverride?: string, priorityItem?: ToDoPriorityItem) {
        this.task = task;
        if (urlOverride) {
            this.urlTarget = urlOverride;
        }
        this.priorityItem = priorityItem;
        // ////console.debug(`TaskAction: Url is ${this.urlTarget}`);
    }
}

export enum ToDoPriorityItem {
    None = 0,
    MedicalDisclosure,
    CriminalDisclosure
}

export class UserTaskStatusResponse implements ResponseData {
    tasks: UserTaskStatus[] = [];
}

export class TaskByIdResponse implements ResponseData {
    result?: TaskResponse;
}

export interface TaskResponse {
    tasks?: Task[];
    homeRoute: string;
    chainTasks: boolean;
    isReturner: boolean;
}
