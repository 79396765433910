import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { PoliceCheckRoutingModule } from './police-check.routing'

// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { MetaformCoreModule } from '@aifs-shared/metaform/core/metaform.core.module';

import { TrackerModule } from '@aifs-shared/tracker/tracker.module';
import { TrackerService } from '@aifs-shared/tracker/tracker.service';

import { PoliceCheckService } from './police-check.service';
import { PoliceCheckComponent } from './police-check.component';

@NgModule({
    // Inputs
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        PoliceCheckRoutingModule,
        MetaformCoreModule,
        TrackerModule
    ],
    // Outputs
    declarations: [
        PoliceCheckComponent
    ],
    // Services
    providers: [
        TrackerService,
        PoliceCheckService
    ]

})
export class PoliceCheckModule { }
