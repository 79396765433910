import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs'

import { WindowSize } from './shared/framework/window-size';

import { AuthenticationService } from './shared/auth/authentication.service'
import { UserService } from './shared/user/user.service';

import { TrackerService } from './shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { ITaskProvider } from './shared/tracker/task-provider';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AlertModal } from '@aifs-shared/modals/alert.modal';

import { ApplicationService } from './shared/application/application.service';

import { InterviewService } from './interview/interview.service';
import { ApplicationInterviewDetails } from './interview/application-interview-details';
import { MessageService } from './messages/message.service';
import { EventService } from './event/event.service';
import { ConfirmationModal } from '@aifs-shared/modals/confirmation.modal'
import { Season } from '@aifs-shared/application/season';
import { PlacementService } from './placement/placement.service';
import { PlacementDates } from './placement/placement-dates';
import { DateTime } from 'luxon';
import { ToDoItem } from '@aifs-shared/todo/todo-item';
import { AllEvents } from './event/all-events';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']

})
export class HomeComponent extends TrackerTaskComponent implements OnInit, OnDestroy, ITaskProvider {
    /**
     * NOTE(ian): This component will require refactoring as we progress,
     * since it'll be the entry point into the application everytime someone
     * logs in.
     *
     * For example, once the applicant has exhausted their task list, we need
     * to display what they are waiting for, or if their interviewer has
     * made an interview date, or if there are priority tasks set up
     */

    constructor(
        route: ActivatedRoute,
        router: Router,
        tracker: TrackerService,
        private window: WindowSize,
        private authService: AuthenticationService,
        private userService: UserService,
        private applicationService: ApplicationService,
        private modalService: NgbModal,
        private InterviewService: InterviewService,
        private messageService: MessageService,
        private eventService: EventService,
        private placementService: PlacementService
    ) {
        super(router, tracker);
        this.requiresTask = false;
    }

    override ngOnInit() {
        super.ngOnInit();

        //console.debug(`Home::ngOnInit()`)

        if (!this.userService.isApplicant()) {
            // console.debug(`Is not an applicant, redirect!`);
            if (this.userService.isInterviewer()) {
                this.router.navigateByUrl("/interviewer");
                return;
            }

            // Well, they're neither fish nor fowl, so
            // let's clear out what is in storage and
            // require a sign-in
            this.authService.logout();
            this.router.navigateByUrl("/sign-in");

            // Most important - exit
            return;
        }

        this.previousButtonEnabled = false;
        // this.window.height$.subscribe(h => this.wsy = h);
        // this.window.width$.subscribe(w => this.wsx = w);

        // Just in case no-one else has done this yet
        this.userService.userHasSignedIn();

        this.tracker
            .loadTasksForApplicant()
            .subscribe(
                result => {
                    this.initialise();
                });

    }

    override ngOnDestroy() {
        super.ngOnDestroy();

        if (this.subscription)
            this.subscription.unsubscribe();
    }

    override initialise(): void {
        super.initialise();
        this.userService.reloadUserData()
            .subscribe(
                r => {
                    if (this.userService.isApplicant()) {
                        this.taskDataLoaded = true;

                        // console.log(`CHECK FOR TERMS`);
                        if (!this.userService.hasLatestTermsAndConditions()) {
                            // console.log(`USER NEEDS NEW TERMS AND CONDITIONS`);
                            this.tracker.homeRoute = "/home";
                            this.router.navigateByUrl("/user/terms-and-conditions/latest/ANY");
                            return;
                        }

                        if (this.tracker.overrideTask) {
                            this.router.navigateByUrl(this.tracker.overrideTask);
                            // Don't bother continuing at this point
                            return;
                        } else {
                            // console.log(`No override task found`);
                        }
                        const role = this.userService.applicantRole()
                        if (role) {
                            var activeApplicationId = role.activeApplicationId!;
                            var applicantId = role.applicantId!;

                            this.getReturnerReadyToSubmit(applicantId);
                            this.getInterviewerDetails(activeApplicationId);
                            this.getAvailability();
                            this.getNewMessages();
                            this.checkApplyForNewSeason(activeApplicationId);
                            this.checkUrgentContactStatus();
                        }
                    }
                });
    }

    checkApplyForNewSeason(applicationId: number) {
        this.applicationService
            .checkForNewSeason(applicationId)
            .subscribe(
                season => {
                    if (season && season.isCurrent && !season.rolloverApplicant) {
                        this.season = season;
                        this.season.seasonNumber = season.seasonNumber;
                        this.showNewSeason = true;
                        this.showToDoList = false;
                        if (season.userState && season.userState == "ParticipantPlaced") {
                            this.applicantAtCamp = true;
                        }
                    }
                    else {
                        var countryCode = this.authService.currentUser!.countryCode;
                        this.getEvents(applicationId, countryCode);
                        this.showToDoList = true;
                    }
                },
                error => this.pageError(<any>error)
            );
    }

    getEvents(applicationId: number, countryCode: string) {
        this.eventService
            .getAllEvents(applicationId, countryCode)
            .subscribe({
                next: (eventDetails: AllEvents) => {
                    if (eventDetails.allAttendingEvents.length > 0) {
                        switch (eventDetails.allAttendingEvents[0].eventType) {
                            case 400://Orientation
                                this.eventButtonText = "Upcoming Orientations";
                                this.eventClass = 'orientation';
                                break;
                            default:
                                this.eventButtonText = "Get Hired On The Spot!";
                                this.eventClass = 'fair';
                                this.registerButtonText = "Upcoming camp job fairs";
                                this.showSignUpForCDFair = (!(this.userService.isPlaced() || this.userService.isOutOfProgramme()));
                                break;
                        }
                        this.showEventButton = true;
                    }
                    if (eventDetails.allUnattendingEvents.length > 0) {                       
                        switch (eventDetails.allUnattendingEvents[0].eventType) {
                            case 200://Recruitment Fairs
                                this.eventButtonText = "Get Hired On The Spot!";
                                this.eventClass = 'fair';
                                this.showSignUpForCDFair = (!(this.userService.isPlaced() || this.userService.isOutOfProgramme()));
                                break;
                            default:
                                this.eventButtonText = "Upcoming Orientations";
                                this.eventClass = 'orientation';
                                break;
                        }
                        this.showEventButton = true;
                    }
                    const role = this.userService.applicantRole()
                    if (role) {
                        var userState = role.state!;
                        var season = role.activeApplicationSeason!; //applicants from 2020 of status UNS or PFT should be shown a rollover offer instead
                        if (userState == 'RejectedByOffice'
                            || userState == 'RejectedByInterviewer'
                            || userState == 'ParticipantOutOfProgrammeCNX'
                            || userState == 'ParticipantOutOfProgrammeEXP'
                            || userState == 'ParticipantOutOfProgrammeFAI'
                            || userState == 'ParticipantOutOfProgrammeREJ'
                            || (userState == 'ParticipantOutOfProgrammeUNS' && season != 2020)
                            || userState == 'ParticipantOutOfProgrammeTER') {
                            this.showEventButton = false;
                            this.showToDoList = false;
                        }
                    }
                },
                error: (error: any) => this.pageError(<any>error)
            });
    }

    getReturnerReadyToSubmit(applicantId: number) {
        this.tracker
            .getReturnerSubmittedApplication(applicantId)
            .subscribe({
                next: (readyToSubmit: boolean) => {
                    this.showSubmitApplicationButton = readyToSubmit;
                },
                error: (error: any) => this.pageError(<any>error)
            });
    }

    checkUrgentContactStatus(){
      this.needsUrgentContact = this.userService.mustContactCAUrgently();
    }

    getAvailability() {
        const role = this.userService.applicantRole()
        if( role ) {
            var currentState = role.state!;
            if (currentState.includes("ParticipantApplied") || currentState.includes("ParticipantReadyToPlace")) {
                this.applicationService
                .loadFromUrl(role.applicantId!, role.activeApplicationId!, "availability")
                .subscribe({
                    next: (result: any) => {
                        const e0 = DateTime.fromISO(result.earliestAvailabilityDate);
                        const e1 = DateTime.fromISO(result.dateDueHome);
                        if(e0 && e1) {
                            this.e0ord = this.getOrdinal(e0.day);
                            this.e1ord = this.getOrdinal(e1.day);
                        }

                        this.earliestAvailabilityDate = result.earliestAvailabilityDate
                        this.dateDueHome = result.dateDueHome
                        this.showEditAvailabilty = true;        
                    },
                    error: (error: any) => {
                        this.pageError(error);
                    }
                });
            } else if (currentState === "ParticipantPlaced") {
                this.placementService
                    .getPlacementDates(role.activeApplicationId!)
                    .subscribe( {
                        next: (result: PlacementDates) => {
                            if(!result.isOT){
                                if(result.startDate) {
                                    this.showPlacementDates = true;
                                    this.startDate = result.startDate;
                                    this.endDate = result.endDate!;
                                }
                            }
                        },
                        error: (error: any) => {
                            this.pageError(error);
                        }
                    });
            }
        }
    }

    getOrdinal(day: number): string {
        switch(day){
            case 1:
            case 21:
            case 31:
                return 'st';
            case 2:
            case 22:
                return 'nd';
            case 3:
            case 23:
                return 'rd';
            default:
                return 'th';
        }
        return '';
    }

    getNewMessages() {
        this.messageService
            .getUnreadMessageCount()
            .subscribe({
                next: (result: number) => {
                    this.unreadCount = result;
                },
                error: (error: any) => {
                    this.pageError(error);
                }
            });
    }

    readMessages() {
        this.router.navigateByUrl("/messages");
    }

    getInterviewerDetails(applicationId: number) {
        if (applicationId === 0) return;

        this.InterviewService
            .getApplicationInterviewDetails(applicationId)
            .subscribe({
                next: (applicationInterviewDetails: ApplicationInterviewDetails) => this.storeInterviewReturned(applicationInterviewDetails),
                error: (error: any) => this.pageError(<any>error)
            });
    }

    storeInterviewReturned(applicationInterviewDetails: ApplicationInterviewDetails): void {
        this.interviewInfo = applicationInterviewDetails;
        const interview = this.interviewInfo?.interview;
        if (interview) {
            if ((interview.isOnline && (interview.status == 'PEND' || interview.status == 'REQ'))
            || (!interview.isOnline && interview.status == 'PEND')) {
                this.displayInterviewInfo = true;
            }
        }
    }

    showAlert(title: string, explain: string, error: string | undefined = undefined) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = "danger";
        if (error)
            modalRef.componentInstance.error = error;

        modalRef.result.then(result => {
            if (result) {
                this.initialise();
            }
        }).catch(err => { });
    }

    showInfoDialog() {
        const modalRef = this.modalService.open(ConfirmationModal)
        modalRef.componentInstance.title = 'Change Availability'
        modalRef.componentInstance.body = 'By extending your availability date range you could increase your placement chances by being eligible for more camps! Would you like to edit your availability date?';
        modalRef.result.then(result => {
            if (result) {
                this.tracker.setReturnUrl( "/home");
                this.tracker.setActiveTask(this.tracker.tasksForGroup(8)[0]);
                this.router.navigateByUrl("/form/availability");
            }
        }).catch(err => { });
    }

    showPlacementInfoDialog(ev: Event){
        ev.preventDefault();
        const modalRef = this.modalService.open(AlertModal)
        modalRef.componentInstance.title = 'Change Availability'
        modalRef.componentInstance.body = 'If your availability changes and you are no longer able to complete your placement on these dates, please <a href="/contact-us/">contact us</a> immediately.';
        modalRef.result.then(result => {}).catch( err => {});
    }

    upcomingEvents() {
        this.router.navigateByUrl("/events");
    }

    editItem(item: ToDoItem) {
        // CA-2511 some of these items are also tasks
        if(item.associatedTask) {
            this.tracker.setActiveTask(item.associatedTask);
        }
        this.router.navigateByUrl(item.url);
    }

    apply() {
        this.tracker.setActiveTask(this.tracker.tasksForGroup(1)[0]);
        this.router.navigateByUrl("/user/create-application");
    }

    submitApplication() {
        this.router.navigateByUrl("/payments/returner");
    }

    protected subscription?: Subscription;

    earliestAvailabilityDate?: Date;
    dateDueHome?: Date;
    e0ord = '';
    e1ord = '';

    unreadCount: number = 0;
    showNewSeason = false;
    season?: Season;
    showSignUpForCDFair = false;
    eventButtonText?: string;
    eventClass?: string;
    showSubmitApplicationButton = false;
    showToDoList = false;
    displayInterviewInfo = false;
    interviewInfo!: ApplicationInterviewDetails;
    showEditAvailabilty = false;
    showPlacementDates = false;
    startDate = '';
    endDate = '';
    showEventButton = false;
    registerButtonText = 'Register for upcoming camp job fairs';
    applicantAtCamp = false;
    needsUrgentContact = false;
}
