<tracker-progress></tracker-progress>
<div class="content-wrapper">
    <h3>{{ title }}</h3>

    <div [ngSwitch]="createApplicationResponse" class="createApplication">
        <!--Too young (but over 16)-->
        <ng-container *ngSwitchCase="1">
            <p>Thanks for signing up!</p>
            <p>
                You need to be 18 years old by 15th of June to take part in the Camp America programme. We've added you
                to
                our mailing
                list and we'll be in touch to help you with your application once you're old enough.
            </p>
            <div class="homeImage">
                <img src="assets/img/task/alert.png">
            </div>
        </ng-container>

        <!--Season closed-->
        <ng-container *ngSwitchCase="2">
            <p>Thanks for signing up!</p>
            <p>
                We'll be up and running shortly for next summer. We’ve added you to our mailing list and when we reopen
                you'll
                be the first to know.
            </p>
            <div class="homeImage">
                <img src="assets/img/task/alert.png">
            </div>
        </ng-container>

        <!--Not application Country-->
        <ng-container *ngSwitchCase="4">
            <p>Thanks for signing up!</p>
            <p>
                We don't currently recruit in {{countryName}}. We've added you to our mailing list and if this changes,
                you'll
                be the first to know.
            </p>
            <div class="homeImage">
                <img src="assets/img/task/alert.png">
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="8">
            <p>Thanks for signing up!</p>
            <p>Applications are not open in {{countryName}} at this time.</p>
            <p>However you can still complete the application form for use in future.</p>
            <div class="homeImage">
                <img src="assets/img/task/alert.png">
            </div>
        </ng-container>

        <!-- // Code from CA-3239
        <ng-container *ngSwitchCase="997">
            <h4>Register with Agency</h4>
            <p>Which agency do you wish to register with?</p>
            <div class="agents flex flex-row justify-left" *ngFor="let a of agencies">
                <button class="option-item flex {{isSelected(a.code) ? 'picked' : ''}}" (click)="select(a.code)">
                    <div class="br--left pa3 flex flex-column items-center justify-center fa-fw">
                        <i *ngIf="isSelected(a.code); else unchecked" class="fa fa-check-square fa-3x"></i>
                        <ng-template #unchecked>
                            <i class="fa-regular fa-square fa-3x unpicked"></i>
                        </ng-template>
                    </div>
                    <div class="br--right pa3 flex flex-column">
                        <p class="mv0 lh-copy f1 fw3 option-text{{isSelected(a.code) ? '-picked' : ''}}">{{a.description}}</p>
                    </div>
                </button>
            </div>
            <div class="agents flex flex-row justify-left">
                <p class="footnote-text small">Choose Camp America, unless you have already been in contact with the other agencies.</p>
            </div>  
        </ng-container>
        -->

        <ng-container *ngSwitchCase="998">
            <h4>Choose your Agent</h4>
            <div class="agents flex flex-row justify-left" *ngFor="let a of agencies">
                <button class="option-item flex {{isSelected(a.code) ? 'picked' : ''}}" (click)="select(a.code)">
                    <div class="br--left pa3 flex flex-column items-center justify-center fa-fw">
                        <i *ngIf="isSelected(a.code); else unchecked" class="fa fa-check-square fa-3x"></i>
                        <ng-template #unchecked>
                            <i class="fa-regular fa-square fa-3x unpicked"></i>
                        </ng-template>
                    </div>
                    <div class="br--right pa3 flex flex-column">
                        <p class="mv0 lh-copy f1 fw3 option-text{{isSelected(a.code) ? '-picked' : ''}}">{{a.description}}</p>
                    </div>
                </button>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="999">
            <h4>{{sectionTitle}}</h4>
            <mf-named-form [metaform]="'create-application'" [dataProvider]="this" [loadFormData]="true"
                (displayChanged)="questionDisplayChanged($event)"></mf-named-form>
            <ng-container *ngIf="metaform; else still_loading">
                <tracker-buttons [hasPreviousButton]="true">
                </tracker-buttons>
            </ng-container>
            <ng-template #still_loading>
                <div class="load_container">
                    <p>Loading...</p>
                    <img src="assets/img/blu-loader.gif" class="bl">
                </div>
            </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="1000">
            <p class="introText">That's it, your application for the summer of a lifetime is now underway.</p>
            <div class="introImage">
                <img class="introImage" src="assets/img/task/outro.svg">
            </div>
            <p class="introText">Keep clicking the 'Next' buttons and you'll be done in no time!</p>
        </ng-container>

    </div>
</div>
<ng-container *ngIf="createApplicationResponse != 999">
    <tracker-buttons [noAssociatedTask]="true" [isProcessing]="isWorking" [nextButtonText]="buttonCaption">
    </tracker-buttons>
</ng-container>