export class UploadStarting {
    filesToUploadCount: number;

    constructor(count: number) {
        this.filesToUploadCount = count;
    }
}

export class UploadProgress {
    allComplete: boolean = false;
    uploads: UploadDetails[] = [];
}

export class UploadDetails {
    id!: number;
    percent!: number;
    filename!: string;
}