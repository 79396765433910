<tracker-progress></tracker-progress>
<div class="content-wrapper">
    <h3>My Application</h3>
    <h4>{{title}}</h4>
    <div *ngIf="isNextSeason" class="alert alert-info-centre" role="alert">
        You can't add your references yet as we need them to be fairly recent at the time you depart to the US.
        <br />Please come back here around July.
    </div>
    <div *ngIf="!isNextSeason">
        <div *ngIf="displayHistory && displayHistory.length > 0">
            <!-- <p *ngIf="displayHistory.length > 1">You have added {{displayHistory.length}} referees.</p>
            <p *ngIf="displayHistory.length == 1">You have added {{displayHistory.length}} referee.</p> -->
            <p>Make sure you enter details of at least 2 &ndash; 3 referees</p>
            <p>You can click on an entry below to
                <span class="text-ca-success">EDIT</span> or
                <span class="text-ca-error">DELETE</span>.
            </p>
        </div>
        <app-display-card [items]="displayHistory" (itemSelected)="selectItem($event)" (itemEdit)="editItem($event)"
            (itemDelete)="deleteItem($event)" (buttonPress)="additionalButtonPressed($event)"></app-display-card>
        <div *ngIf="canAddHistory">
            <p>
                <span class="text-ca-success tip">TIP:</span> Add {{addRefereeCountString}} referees to speed up your
                application! Click on the button below to
                add more NOW...
            </p>
            <button class="btn btn-wide btn-ca-add-new" (click)="addReferee()">+Add Referee</button>
        </div>
        <div *ngIf="!canAddHistory">
            <p>You have entered enough referees now. Click Next to continue with your application!</p>
        </div>
    </div>
</div>
<tracker-buttons></tracker-buttons>
<ng-template ngbModalContainer></ng-template>