import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisplayCardModule } from '@aifs-shared/display-card/display-card.module';

import { ToDoService } from './todo.service';
import { ToDoComponent } from './todo.component';

@NgModule({
    imports: [
        CommonModule,
        DisplayCardModule
    ],
    declarations: [
        ToDoComponent,
    ],
    providers: [
        ToDoService
    ],
    exports: [
        ToDoComponent
    ]
})
export class ToDoModule { }
