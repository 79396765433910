import { Injectable } from '@angular/core';

import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, HttpEventType } from '@angular/common/http';

import { UploadPolicyResponse } from '../upload/upload-policy-response';

import { UploadStarting, UploadProgress, UploadDetails } from '@aifs-shared/upload/upload-progress';
import { UploadComplete } from './upload-complete';

import { Subject } from 'rxjs';

@Injectable()
export class AmazonS3Service {

    constructor(private http: HttpClient) { }

    upload(options: { policy: UploadPolicyResponse, files: File[] }): Subject<UploadStarting | UploadProgress | UploadComplete> {
        let subject = new Subject<UploadStarting | UploadProgress | UploadComplete>();

        let files = options.files.map((file, idx) => {
            // var re = /(?:\.([^.]+))?$/;
            // var ext = re.exec(file.name)[1];  
            let pageId = idx + 1
            let key = options.policy.filePattern
            var ext = "";
            if (file.type.includes("video")) {
                ext = ".mp4";
            }
            // CA-1864 we need to save the extension so that
            // the lambda function knows to convert the pdf
            // into image files (with a jpg extension). The
            // jpgs will be used for display, but will link
            // to the PDF (eventually via an access token)
            if(file.name.toLowerCase().endsWith(".pdf")) {
                // console.warn(`Got a PDF file`);
                ext = ".pdf";
            }
            return {
                pageId: pageId, path: key.replace('[PAGE]', `-${pageId}`) + ext, file: file
            }
        });

        let filesRemainingToUpload = files.length;

        let progress: UploadProgress = new UploadProgress();
        progress.uploads = options.files.map((file, idx) => {
            return {
                id: idx,
                filename: file.name,
                percent: 0
            }
        });

        let sentFirst = false;

        files.forEach(file => {
            this.http.request(this.uploadFile(options, file)).subscribe(
                event => {
                    // console.log(`http.request: ${JSON.stringify(event)}`);
                    // Via this API, you get access to the raw event stream.
                    // Look for upload progress events.
                    if (event.type === HttpEventType.UploadProgress) {
                        if (!sentFirst) {
                            // console.log(`Sending start up message`);
                            const upstart = new UploadStarting(filesRemainingToUpload);

                            // Initialisation for the progress dialog to appear
                            subject.next(upstart);
                            sentFirst = true;

                            // if (upstart instanceof UploadStarting) {
                            //     // console.log(`sent object checks out AOK`);
                            // }
                        }

                        // This is an upload progress event. Compute and show the % done:
                        var etotal = event?.total ?? 0;
                        const percentDone = Math.round(100 * event.loaded / etotal);
                        progress.uploads[file.pageId - 1].percent = percentDone;

                        subject.next(progress);
                    } else if (event instanceof HttpResponse) {
                        filesRemainingToUpload--;

                        if (filesRemainingToUpload == 0) {
                            progress.allComplete = true;
                            subject.next(progress);

                            const uc: UploadComplete = new UploadComplete(
                                options.policy.uploadTicketId,
                                "notes",
                                "description",
                                files.map(file => { return { pageId: file.pageId, path: file.path } }),
                                '',
                                undefined,
                                undefined);

                            subject.next(uc);

                            subject.complete();
                        }
                    }
                },
                error => {
                    console.error(`Got error: ${JSON.stringify(error, null, 2)}`);
                    subject.error(error);
                },
                () => {

                }
            );
        });

        return subject;
    }

    uploadFile(options: any, file: any): HttpRequest<FormData> {
        let formData = new FormData()
        formData.append('key', file.path)
        formData.append('AWSAccessKeyId', options.policy.accessKeyId)
        formData.append('acl', options.policy.acl)
        formData.append('policy', options.policy.policy)
        formData.append('signature', options.policy.policySignature)
        formData.append('Content-Type', file.file.type)
        formData.append('file', file.file)

        let headers = new HttpHeaders()
        headers.append('enctype', 'multipart/form-data')

        const req = new HttpRequest('POST', `//${options.policy.bucket}.s3.amazonaws.com/`, formData,
            {
                headers: headers,
                reportProgress: true
            });

        return req;
    }
}