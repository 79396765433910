import { DisplayCardButton } from './display-card-button';

export class DisplayCardItem {
    public id?: number;
    public title: string = '';
    public titleClass: string;
    public subtitle?: string = '';
    public subtitleClass: string;
    public innerHTML?: string;
    public innerHTMLClass?: string;
    public details: string;
    public detailsExtra?: string;
    public detailsExtraClass: string;
    public detailsExtraLine2?: string;
    public detailsExtraLine2Class: string;
    public canEdit: boolean;
    public canDelete: boolean;

    public selected: boolean;
    public toggled: boolean;

    public editOptionText: string;
    public editOptionClass?: string;
    public deleteOptionText: string;

    public additionalButtonItems?: DisplayCardButton[];
    public buttonSelected?: number;

    public index: number = 0;

    public constructor(options: { id?: number, title: string, titleClass?: string, subtitle?: string, subtitleClass?: string, innerHTML?: string, innerHTMLClass?: string, details: string, detailsExtra?: string, detailsExtra2?: string, canEdit?: boolean, canDelete?: boolean, editOptionText?: string, editOptionClass?: string, deleteOptionText?: string, buttons?: DisplayCardButton[], isSelected?: boolean, isToggled?: boolean, index?: number, detailsExtraLine2Class?: string, detailsExtraClass?: string }) {
        this.id = options.id || undefined;
        this.title = options.title;
        this.titleClass = options.titleClass || "title";
        this.subtitle = options.subtitle || undefined;
        this.subtitleClass = options.subtitleClass || "subtitle";
        this.innerHTML = options.innerHTML;
        this.innerHTMLClass = options.innerHTMLClass;
        this.details = options.details;
        this.detailsExtra = options.detailsExtra || undefined;
        this.detailsExtraLine2 = options.detailsExtra2 || undefined;
        this.canDelete = options.canDelete || false;
        this.canEdit = options.canEdit || false;

        this.selected = options.isSelected || false;
        this.toggled = options.isToggled || false;

        this.editOptionText = options.editOptionText || "Edit";
        this.editOptionClass = options.editOptionClass;
        this.deleteOptionText = options.deleteOptionText || "Delete";

        this.detailsExtraClass = options.detailsExtraClass || "text";
        this.detailsExtraLine2Class = options.detailsExtraLine2Class || "text";

        this.additionalButtonItems = options.buttons || undefined;
        this.index = options.index || 0;
    }
}