<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
    <p>{{body}}</p>
    <p *ngIf="extra" class="{{cssClass}}">{{extra}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-modal-yes" (click)="activeModal.close(true)">Yes</button>
    <button type="button" class="btn btn-modal-no" (click)="activeModal.close(false)">No</button>
</div>