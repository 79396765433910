import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AddReferenceComponent } from './add-reference.component';
import { ReviewReferencesComponent } from './review-references.component';
import { ReferenceCriteriaComponent } from './reference-criteria.component';

import { ReferenceFormLandingComponent } from './reference-form-landing.component';
import { ReferenceFormComponent } from './reference-form.component';
import { SuggestedRefereeComponent } from './suggested-referee.component';

const routes: Routes = [
    { path: 'add-referee', component: SuggestedRefereeComponent },
    { path: 'add-reference', component: AddReferenceComponent },
    { path: 'upload', component: ReferenceCriteriaComponent },
    { path: 'review', component: ReviewReferencesComponent },
    { path: 'referee/:id', component: ReferenceFormLandingComponent },
    { path: 'referee/:id/reference', component: ReferenceFormComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(
            routes
        )
    ],
    exports: [
        RouterModule
    ]
})
export class ReferencesRoutingModule { }
