import { Component, Input } from '@angular/core'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { CalendarDetails, CalendarItem } from '../calendar/calendar-item';

@Component({
    selector: 'calendar',
    templateUrl: 'calendar.modal.html',
    styleUrls: ['./less/calendar.scss']
})
export class CalendarModal {
    @Input() title!: string
    @Input() body!: string
    @Input() extra?: string;

    // Passed through to the calendar component
    @Input() calendar!: CalendarDetails;
    @Input() showMonth: number = 0;
    @Input() showMonthNavigation: boolean = false;

    constructor(public activeModal: NgbActiveModal) { }

    dateSelected(e: CalendarItem): void {
        this.selected = e.date;
    }

    buttonText: string = "OK";
    selected?: Date;
}