import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';;
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';

// NgBootstrap
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { PipesModule } from '../shared/pipe/pipes.module'

import { UserService } from '../shared/user/user.service';
import { TrackerModule } from '../shared/tracker/tracker.module';
import { MetaformCoreModule } from '../shared/metaform/core/metaform.core.module';

import { DisplayCardModule } from '../shared/display-card/display-card.module';

import { UploadModule } from '../shared/upload/upload.module';
import { UploadService } from '../shared/upload/upload.service';

import { ModalsModule } from '@aifs-shared/modals/modals.module';

import { ReferencesRoutingModule } from './reference.routing';

import { ReferenceService } from './reference.service';

import { AddReferenceComponent } from './add-reference.component';
import { ReviewReferencesComponent } from './review-references.component';
import { ReferenceCriteriaComponent } from './reference-criteria.component';

// Referee stuff moved from COLA
import { ReferenceFormLandingComponent } from './reference-form-landing.component';
import { ReferenceFormComponent } from './reference-form.component';
import { ReferenceStatusPreviewComponent } from './reference-status-preview.component';
import { SuggestedRefereeComponent } from './suggested-referee.component';
import { MetaFormModule, MetaFormService, BusinessRuleService } from '@aifs/ngx-metaform';
import { LanguageService } from '@aifs-shared/i18n/language.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        DisplayCardModule,
        UploadModule,
        ReferencesRoutingModule,
        TrackerModule,
        MetaformCoreModule,
        MetaFormModule,
        ModalsModule,
        PipesModule
    ],
    declarations: [
        AddReferenceComponent,
        SuggestedRefereeComponent,
        ReferenceCriteriaComponent,
        ReviewReferencesComponent,
        ReferenceFormLandingComponent,
        ReferenceFormComponent,
        ReferenceStatusPreviewComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        UserService,
        // UploadService,
        ReferenceService,
        LanguageService,
        NgbActiveModal,
        MetaFormService,
        BusinessRuleService
    ],
    exports: [
        ReferenceStatusPreviewComponent
    ]
})
export class ReferenceModule { }
