import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../shared/user/user.service';
import { AuthenticationService } from '../shared/auth/authentication.service';
import { ApplicationService } from '../shared/application/application.service';

import { ReferenceFormDetails, ReferenceService } from './reference.service';
import { Reference } from './reference-details';

import { StoredData } from '@aifs-shared/common/stored-data';

@Component({
    templateUrl: './reference-form-landing.component.html',
    styleUrls: [
        './less/add-reference.component.scss',
        '../shared/tracker/less/tracker-button.component.scss'
    ]
})
export class ReferenceFormLandingComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        private application: ApplicationService,
        private referenceService: ReferenceService,
    ) {
        this.inError = false;
        this.isLoaded = false;
    }

    ngOnInit() {
        this.sequenceTitle = "Camp America Reference";
        this.referenceId = +this.route.snapshot.params['id'];

        this.loadReference();
    }

    stepNext(): void {
        // Store 
        StoredData.storeWithKeyAndData(`rd:ref:${this.referenceId}`, this.referenceDetails, 999);
        this.router.navigateByUrl(`/references/referee/${this.referenceId}/reference`);
    }

    loadReference(): void {
        this.referenceService
            .getReferenceFormDataFromToken(this.referenceId)
            .subscribe({
                next: (d: ReferenceFormDetails) => {
                    if( d ) {
                        // console.log(`Got details: ${JSON.stringify(d, null, 2)}`);
                        this.isLoaded = true;
                        this.submitted = d.completed;
                        this.referenceDetails = d.data;
                        //remove the timezone
                        this.referenceDetails.request.dateFrom = this.referenceDetails.request.dateFrom.substring(0,10);
                        this.referenceDetails.request.dateTo = this.referenceDetails.request.dateTo?.substring(0,10);

                        if (this.submitted) {
                            this.title = "Reference Completed";

                            // Remove
                            sessionStorage.removeItem(`rd:ref:${this.referenceId}`);
                        } else {
                            if (this.referenceDetails.id != this.referenceId) {
                                console.warn(`Reference Id ${this.referenceDetails.id} doesn't match passed!`);
                                //return;
                            } else {
                                this.applicantName = `${this.referenceDetails.data.applicantFirstName} ${this.referenceDetails.data.applicantLastName}`;
                                this.sequenceTitle = `Reference for ${this.applicantName}`
                                this.nextEnabled = true;
                            }
                        }
                    } else {
                        console.error(`Didn't get a response from the token reference: ${JSON.stringify(d)}`);
                    }
                },
                error: (error: any) => {
                    console.error(`Errored:`, error);
                    if (error.message)
                        this.errorMessage = error.message;
                    else
                        this.errorMessage = "An unknown error occurred trying to read your reference data.";
                    this.inError = true;
                    //this.isLoaded = true;
                }
            });
    }

    sequenceTitle: string = '';
    title: string = '';
    isLoaded = false;
    inError = false;
    errorMessage?: string;

    referenceId!: number;
    referenceDetails!: Reference;
    submitted: boolean = false;

    applicantName: string = '';
    nextEnabled: boolean = false;
    nextButtonText: string = "Next";
}
