import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'

import { UserService } from '@aifs-shared/user/user.service'
import { TermsAndConditions } from '@aifs-shared/user/terms-and-conditions'

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { ITaskProvider } from '@aifs-shared/tracker/task-provider';

@Component({
    templateUrl: './public-terms-and-conditions.component.html',
    styleUrls: [
        './public-terms-and-conditions.component.scss',
        './user/less/terms-and-conditions.component.scss',
        './shared/tracker/less/tracker-button.component.scss'
    ]
})
export class PublicTermsAndConditionsComponent extends TrackerTaskComponent implements OnInit, OnDestroy, ITaskProvider {

    constructor(
        router: Router,
        tracker: TrackerService,
        private route: ActivatedRoute,
        private userService: UserService
    ) {
        super(router, tracker);

        this.requiresTask = false;

        // It really makes NO SENSE to step back from here.
        this.previousButtonEnabled = false;
    }

    override ngOnInit() {
        super.ngOnInit();
        this.loading = true;
        this.userService.getLatestTermsAndConditions()
            .subscribe(
                terms => this.storeTerms(terms),
                error => {
                    this.pageError(<any>error)
                    this.loading = false;
                }
            );
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    override initialise(): void {
    }

    override stepPrevious(): boolean {
        history.back();
        return true;
    }

    /**
     * Extract the returned terms
     * @param terms (TermsAndConditions) - returned terms
     */
    storeTerms(terms: TermsAndConditions): void {
        // // console.info(`Store Terms`);
        // // console.info(`TermsID: ${terms.agreedId}`);

        this.terms = terms;
        this.termsText = terms.termsBody;

        this.loading = false;
    }

    loading = true;

    // The actual terms and conditions we're displaying
    title: string = '';
    terms?: TermsAndConditions;
    termsText?: string;
}