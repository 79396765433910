import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { DisplayCardItem } from './display-card-item';

@Component({
    selector: 'app-display-card',
    templateUrl: './display-card.component.html',
    styleUrls: ['./less/display-card.component.scss']
})
export class DisplayCardComponent implements OnInit {

    @Input() items: DisplayCardItem[] = [];
    @Input() autoExpand: boolean = false;
    @Input() displayDetails: boolean = true;
    @Output() itemSelected: EventEmitter<DisplayCardItem> = new EventEmitter();
    @Output() itemDelete: EventEmitter<DisplayCardItem> = new EventEmitter();
    @Output() itemEdit: EventEmitter<DisplayCardItem> = new EventEmitter();
    @Output() buttonPress: EventEmitter<DisplayCardItem> = new EventEmitter();

    constructor() { }

    ngOnInit() { }

    /**
     * Is the passed item selected?
     * @param item DisplayCardItem - item
     */
    isSelected(item: DisplayCardItem): boolean {
        return this.autoExpand || item.selected;
    }

    /**
     * Deselect any previous item and select the new item. 
     * @param item  DisplayCardItem - item
     */
    selectItem(item: DisplayCardItem): void {
        let currentSelection = this.items.find(i => i.selected);

        if (currentSelection && currentSelection != item) currentSelection.selected = false;

        // We toggling
        //console.info(`Selecting ${item.title}: ${item.selected} -> ${!item.selected}`);
        item.selected = !item.selected;
        //console.info(`After selecting ${item.title}: ${item.selected}`);

        // Perform post-selection processing (in host component)
        this.itemSelected.emit(item);
    }

    /**
     * Call the edit item functionality on the host component
     * @param item  DisplayCardItem - item
     */
    editItem(e: MouseEvent, item: DisplayCardItem): void {
        // console.log(`DisplayCard: Edit ${item.id}`, item);
        this.itemEdit.emit(item);

        e.cancelBubble = true;
    }

    /**
     * Call the delete item functionality on the host component
     * @param item  DisplayCardItem - item
     */
    deleteItem(item: DisplayCardItem): void {
        this.itemDelete.emit(item);
    }

    /**
     * An additional button has been selected
     * @param item DisplayCardItem - item
     * @param buttonId number - the id of the selected optional item as set by the caller
     */
    buttonPressed(item: DisplayCardItem, buttonId: number): void {
        item.buttonSelected = buttonId;
        this.buttonPress.emit(item);
    }

    showChevron(item: DisplayCardItem) {
        return item.details || item.detailsExtra || item.detailsExtraLine2 || item.additionalButtonItems || item.canEdit || item.canDelete || item.innerHTML;
    }
}
