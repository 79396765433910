<div class="navbar navbar-brand">
    <img src="assets/img/CA-Colour.svg" class="top-logo" alt="Responsive image">
</div>
<div class="content-wrapper">
    <h3>Terms and Conditions</h3>
    <h4>{{ title }}</h4>
    <div *ngIf="loading; else showTerms">
        <div class="load_container">
            <p>Loading data...</p>
            <img src="assets/img/blu-loader.gif" class="bl">
        </div>
    </div>
    <ng-template #showTerms>
        <form>
            <div class="terms" [innerHTML]="termsText"></div>
        </form>
    </ng-template>
    <div id="tracker-buttons" class="tracker-buttons trackbuttons trackbuttons-bottom">
        <button type="button" class="btn btn-track-previous" (click)="stepPrevious()">
            <span class="fa-solid fa-chevron-left"></span>&nbsp;</button>
    </div>
</div>