import { Component } from '@angular/core'
import { Router, ActivatedRoute, Params } from '@angular/router'

import { Observable } from 'rxjs'

import { AuthenticationService } from '../shared/auth/authentication.service'
import { TrackerService } from '../shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { Task } from '../shared/tracker/task';

import { DocumentType } from '../shared/upload/document-type'
import { UploadedFile } from '../shared/upload/uploaded-file'
import { UserService } from '@aifs-shared/user/user.service';

@Component({
    templateUrl: './upload-profile-photo.component.html',
    styleUrls: [
        './less/upload-profile-photo.component.scss']
})

export class UploadProfilePhotoComponent extends TrackerTaskComponent {
    constructor(
        router: Router,
        tracker: TrackerService,
        route: ActivatedRoute,
        private authService: AuthenticationService,
        private userService: UserService
    ) {
        super(router, tracker);
    }

   override ngOnInit() {
        super.ngOnInit();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    override stepNext(): void {
        // This should only actually be done once we have the observable
        // return successful upload
        // console.log(`Finished Process Task Complete`);
        this.tracker.finishTask();
    }

    get currentUserApplicationId(): number {
        const role = this.userService.applicantRole();
        if (role && role.activeApplicationId)
            return role.activeApplicationId;
        else
            return -1;
    }

    skipTask(): void {
        const t = this.tracker.currentTask;
        if (t) {
            // console.log(`Skip this task: ${t.name}`);
            this.tracker.markTaskAsSkipped(t).subscribe({
                next: (r: boolean) => {
                    if(r) {
                        // console.log(`Skipped; move on`);
                        this.router.navigateByUrl(this.tracker.homeRoute);
                        return;
                    }                   
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
        }
    }
    
    currentFileFound(uploadedFile: UploadedFile): void {
        // console.log(`Got a current file: ${uploadedFile.id}`);
        this.checkValidity();
    }

    uploadedFileChanged(uploadedFile: UploadedFile): void {
        // console.log(`Got an uploaded file: ${uploadedFile.id}`);
        this.uploadedFile = uploadedFile;
        this.checkValidity();
    }

    uploadError(event: boolean): void {
        // console.log(`upload Error!`);
        this.errorDetected = true;
        this.checkValidity();
    }

    checkValidity(): void {
        // console.log(`checkValidity: ${JSON.stringify(this.uploadedFile, null, 2)}`);
        this.noPicture = (this.uploadedFile === undefined || this.uploadedFile.id == 0);
        this.setNextEnabled(this.uploadedFile !== undefined);
    }

    uploadedFile: UploadedFile | undefined;
    title: string = 'Add a profile picture';
    DocumentType = DocumentType;
    noPicture = true;
    errorDetected = false;
}
