import { Injectable } from '@angular/core';

function _window(): any {
    // return global browser native window object
    return window;
}

@Injectable()
export class WindowRef {
    get nativeWindow(): any {
        return _window();
    }

    scrollToTop(): void {
        if (_window().document.getElementById("header"))
            _window().document.getElementById("header").scrollIntoView();
    }
}