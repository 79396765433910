import { Injectable, Injector, ReflectiveInjector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http'

import { BaseService } from '@aifs-shared/common/base-service';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

// Events

// Service injectors
import { UserService } from '@aifs-shared/user/user.service';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';

// Providers

// Service-specific classes
import { ToDoItem } from './todo-item';

@Injectable()
export class ToDoService extends BaseService {
    /**
     * @description Constructor
     */
    constructor(
        router: Router,
        private http: HttpClient,
        private authService: AuthenticationService,
        private userService: UserService) {
        super();
    }

    public getToDoForApplication(): Observable<ToDoItem[]> {
        let subject = new Subject<ToDoItem[]>();

        const applicantId = this.userService.applicantRole()!.applicantId;
        const activeApplicationId = this.userService.applicantRole()!.activeApplicationId;

        this.http
            .get<BaseResponse>(environment.ServiceUrl_ApplicationToDo(applicantId, activeApplicationId!))
            .subscribe({
                next: (data: any) => {
                    if(data.result && data.result['toDo']) {
                        const response = data.result['toDo'];
                        subject.next(response);
                    } else {
                        subject.next([]);
                    }
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

}
