import { Injectable, Inject } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { environment } from '@environments/environment';
import { User } from "@aifs-shared/user/user";

export enum ConnectionState {
    Connecting = 1,
    Connected = 2,
    Reconnecting = 3,
    Disconnected = 4
}

export class ChannelConfig {
    url: string;
    hubName: string;
    channel: string;

    constructor(url: string, hubName: string, channel: string) {
        this.url = url;
        this.hubName = hubName;
        this.channel = channel;
    }
}

export class ChannelEvent {
    Name: string = '';
    ChannelName: string = '';
    Timestamp: Date;
    Data: any;
    Json: string = '';

    constructor() {
        this.Timestamp = new Date();
    }
}

class ChannelSubject {
    channel: string = '';
    subject?: Subject<ChannelEvent>;
}

@Injectable()
export class PushAzureService {

    /**
     * starting$ is an observable available to know if the signalr 
     * connection is ready or not. On a successful connection this
     * stream will emit a value.
     */
     starting$: Observable<any>;

     /**
      * connectionState$ provides the current state of the underlying
      * connection as an observable stream.
      */
     connectionState$: Observable<ConnectionState>;
 
     /**
      * error$ provides a stream of any error messages that occur on the 
      * SignalR connection
      */
     error$: Observable<string>;
 
     // These are used to feed the public observables 
     //
     connectionStateSubject = new Subject<ConnectionState>();
     startingSubject = new Subject<any>();
     errorSubject = new Subject<any>();

     connection?: HubConnection;
  
     // An internal array to track what channel subscriptions exist 
     //
     subjects = new Array<ChannelSubject>();

     /**
     * Start the SignalR connection. The starting$ stream will emit an 
     * event if the connection is established, otherwise it will emit an
     * error.
     */
    start(): void {
        if (!this.connection) {
            console.error(`Hub connection not set`);
        } else {
            // Now we only want the connection started once, so we have a special
            //  starting$ observable that clients can subscribe to know know if
            //  if the startup sequence is done.
            //
            // If we just mapped the start() promise to an observable, then any time
            //  a client subscried to it the start sequence would be triggered
            //  again since it's a cold observable.
            //

            if (this.connection.state == HubConnectionState.Disconnected) {
                this.connection.start()
                    .then(() => {
                        // console.info(`HubConnection started`);
                        this.startingSubject.next(undefined);
                    })
                    .catch((error: any) => {
                        console.error(`HubConnection Error ${error}`);
                        this.startingSubject.error(error);
                    });
            }
        }
    }

    /**
     *
     */
    constructor() {
        this.connectionState$ = this.connectionStateSubject.asObservable();
        this.error$ = this.errorSubject.asObservable();
        this.starting$ = this.startingSubject.asObservable();

        if (!environment.signalRUrl) {
            console.error(`No environment.signalRUrl specified. Environment is: ${JSON.stringify(environment, null, 2)}`);
            return;
        }

        this.connection = new HubConnectionBuilder()
            .withUrl(`${environment.signalRUrl}`)
            .configureLogging(LogLevel.Information)
            .build();

        this.connectionStateSubject.next(ConnectionState.Connecting);

        this.connection.start().then(
            () => {
                this.connectionStateSubject.next(ConnectionState.Connected);
            }
        );

        this.connection.onclose(cb => {
            this.connectionStateSubject.next(ConnectionState.Disconnected);
        });

        this.connection.onreconnected(cb => {            
            this.connectionStateSubject.next(ConnectionState.Connected);
        });

        this.connection.onreconnecting(cb => {
            this.connectionStateSubject.next(ConnectionState.Reconnecting);
        });

        this.connection.on('OnEvent', (data: ChannelEvent) => {
            let channelSub = this.subjects.find((x: ChannelSubject) => {
                return x.channel === data.ChannelName;
            }) as ChannelSubject;

            // If we found a subject then emit the event on it
            //
            if (channelSub !== undefined) {
                return channelSub.subject!.next(data);
            }
        });
    }

    public registerForUser(user: User): void {
        
    }

     /** 
     * Get an observable that will contain the data associated with a specific 
     * channel 
     * */
      sub(channel: string): Observable<ChannelEvent> {

        // Try to find an observable that we already created for the requested 
        //  channel
        //
        let channelSub = this.subjects.find((x: ChannelSubject) => {
            return x.channel === channel;
        }) as ChannelSubject;

        // If we already have one for this event, then just return it
        //
        if (channelSub !== undefined) {
            //console.debug(`Found existing observable for ${channel} channel`)
            return channelSub.subject!.asObservable();
        }

        //
        // If we're here then we don't already have the observable to provide the
        //  caller, so we need to call the server method to join the channel 
        //  and then create an observable that the caller can use to received
        //  messages.
        //

        // Now we just create our internal object so we can track this subject
        //  in case someone else wants it too
        //
        channelSub = new ChannelSubject();
        channelSub.channel = channel;
        channelSub.subject = new Subject<ChannelEvent>();
        this.subjects.push(channelSub);

        // Now SignalR is asynchronous, so we need to ensure the connection is
        //  established before we call any server methods. So we'll subscribe to 
        //  the starting$ stream since that won't emit a value until the connection
        //  is ready
        //
        this.starting$.subscribe({
            complete: () => {
                if (!this.connection) {
                    console.error(`Connection is not defined!`);
                } else {
                    this.connection.invoke("Subscribe", channel)
                        .then(() => {
                            console.debug(`Successfully subscribed to ${channel} channel`);
                        })
                        .catch(error => {
                            channelSub.subject!.error(error);
                        })
                }
            },
            error: (error: any) => {
                channelSub.subject!.error(error);
            }
        });
        
        return channelSub.subject.asObservable();
    }

    /**
     * Unsubscribe from a user channel
     * TODO(ian): Determine why the hubProxy and hubConnections don't appear to be
     * available!
     * @param channel (user channel to unsubscribe from)
     */
     unsub(channel: string): void {
        if (!this.connection) return;

        if (this.connection.state != HubConnectionState.Connected) {
            // console.info(`HubProxy started`);
            return;
        }        

        this.connection.invoke("Unsubscribe", channel)
            .then(() => {console.info(`Successfully unsubscribed from ${channel}`)})
            .catch(error => {
                //console.error(`Unsub: Error: ${error}`);
            });        
    }

}