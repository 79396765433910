import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TaskIntroComponent } from './task-intro.component';
import { TaskOutroComponent } from './task-outro.component';

const routes: Routes = [
    { path: ':taskName/intro', component: TaskIntroComponent },
    { path: ':taskName/finished', component: TaskOutroComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(
            routes
        )
    ],
    exports: [
        RouterModule
    ]
})
export class TrackerRoutingModule { }