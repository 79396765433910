import { Component, OnInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { MapToIterable } from '@aifs-shared/pipe/map-to-iterable.pipe';

import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { Metaform, MfQuestion, Question, MfOption, MfValueChangeEvent, ValidationError } from '@aifs-shared/metaform/metaform';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';

import { Lookup } from '@aifs-shared/lookup/lookup';

import { ErrorField } from '@aifs-shared/metaform/metaform-question-display.component';

@Component({
    // moduleId: module.id,
    selector: 'mf-osm',
    templateUrl: './option-select-multi.component.html',
    styleUrls: ['../../less/metaform-question-display.component.scss']
})
export class OptionSelectMultiComponent implements OnInit, DoCheck {
    @Input() readonly: boolean = false;
    @Input() metaform!: Metaform;
    @Input() parent!: MfQuestion;
    @Input() questionItem!: Question<any>;
    @Input() questionName!: string;
    @Input() formGroup!: FormGroup;
    @Input() dataProvider!: MetaformDataProvider;
    @Input() validationErrorMessages!: Map<string, string>;
    @Input() scrambleValues: boolean = false;
    @Output() valueChanged = new EventEmitter<MfValueChangeEvent>();

    constructor() { }

    ngOnInit() {
        this.questionItemKey = this.questionItem.key;
        this.canDisplayError = false;

        this.canDisplayError = true;

        this.updateValidity.subscribe((error) => {
            if (error) {
                if (this.errorMessages == null || this.errorMessages !== error.message) {
                    // console.info(`${this.id} - ${this.errorMessages} : Got message : ${error.message} for field ${error.questionItem}`);
                    this.errorMessages = error.message;
                }
            } else {
                this.errorMessages = undefined;
            }
        });

        this.formGroup.get(this.questionItemKey)!.valueChanges.subscribe(data => {
            this.valueChanged.emit(new MfValueChangeEvent(this.questionItemKey, data));
        });

        this.formGroup.get(this.questionItemKey)!.markAsPristine();

        this.formGroup.updateValueAndValidity();

        if (this.readonly) {
            this.selectedItemDescriptions = this.getSelectedItemDescriptionsForDisplay();
        }
    }

    ngDoCheck() {
        let valid: boolean = true;

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                if ((this.formGroup.controls[field].touched)) {
                    // console.info(`Checking: ${field}`);
                    valid = valid && this.formGroup.controls[field].valid;

                    this.questionItem.errorField = undefined;

                    this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
                }
            });
        } else {
            if (this.formGroup.controls[this.questionItemKey].touched) {
                valid = this.formGroup.controls[this.questionItemKey].valid;
                this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
            }
        }

        if (this.isValid != valid)
            // console.log(`Change in validity: ${valid}`);

            this.isValid = valid;
    }

    get formControl() {
        return this.formGroup.controls[this.questionItemKey];
    }

    formControlFor(targetIndex: 0) {
        return this.formGroup.controls[this.questionItem.targetFields[targetIndex]];
    }

    get controlMaximumInput() {
        if (this.questionItem.maxLength) {
            return this.questionItem.maxLength;
        } else {
            return 0;
        }
    }

    get hasLabel() { return this.questionItem.label !== undefined && this.questionItem.label !== null; }

    /**
     * Get all possible failing validation messages for this field
     */
    getValidationMessages(question: string): ErrorField | null {

        if (this.questionItem.targetFields) {
            if (this.questionItem.errorField && this.questionItem.currentError) {
                // console.warn(`already have an error: ${this.questionItem.errorField} (${this.questionItem.currentError}), aborting`);
                return null;
            }

            for (let i = 0; i < this.questionItem.targetFields.length; i++) {
                // console.warn(`Checking ${this.questionItem.targetFields[i]}`);
                if (this.formGroup.controls[this.questionItem.targetFields[i]].touched && !this.formGroup.controls[this.questionItem.targetFields[i]].valid) {
                    let message = this.validationMessageForItem(this.questionItem.targetFields[i]);
                    // Early exit?
                    if (message) {
                        // console.info(`Got error for ${this.questionItem.targetFields[i]}: '${message}'`)
                        this.questionItem.errorField = this.questionItem.targetFields[i];
                        this.questionItem.currentError = message;
                        return new ErrorField(this.questionItem.targetFields[i], message);
                    }
                } else {
                    this.questionItem.errorField = undefined;
                    this.questionItem.currentError = undefined;
                }
            }
            this.questionItem.errorField = undefined;
        } else {
            if (this.formGroup.controls[this.questionItem.key].touched && !this.formGroup.controls[this.questionItem.key].valid) {
                let message = this.validationMessageForItem(question);
                if (message) {
                    this.questionItem.errorField = this.questionItem.key;
                    this.questionItem.currentError = message;
                    const msg = this.validationMessageForItem(question);
                    if (msg) return new ErrorField(this.questionItem.key, msg);
                }
            }
        }

        // No errors
        return null;
    }

    public isMandatory(key: string): boolean {
        return (this.questionItem.required);
    }

    validationMessageForItem(item: string): string | null {
        const errors = this.formGroup.get(item)?.errors;
        if (errors) {
            // console.info(`getValidationMessages: for question ${item}, errors: ${JSON.stringify(errors)}`)

            let keys = [];
            let message = null;
            for (let key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const msg = (this.validationErrorMessages.get(`${item}_${key}`));
                    if(msg) return msg;
                }
            }
        }
        return null;
    }

    isMultiSelected(itemOption: MfOption) {
        if (this.scrambleValues) {
            return true;
        }
        // console.debug(`isSelected ${this.questionItemKey} - ${itemOption.code}`);
        // // console.debug(`Selected Item? ${itemOption.code} == ${this.formGroup.controls[this.questionItemKey].value}`);
        let vals: string[] = this.formGroup.controls[this.questionItemKey].value || [];
        let item = vals.find((v:string) => v == itemOption.code);


        if (item)
            return true;

        return false;

        //return this.formGroup.controls[this.questionItemKey].value.find( v => v == itemOption.code) !== undefined;
    }

    public getSelectedItemDescriptionsForDisplay(): string[] {
        let vals: string[] = this.formGroup.controls[this.questionItemKey].value || [];
        //console.info(`vals`, vals);
        let selected: string[] = [];
        if (this.scrambleValues && this.questionItem.options) {
            selected.push(this.questionItem.options[0].description);
            return selected;
        }
        vals.forEach(v => {
            let option = this.questionItem.options?.find((oi: MfOption) => oi.code == v);
            if (option) {
                selected.push(option.description)
            };
        })

        return selected;
    }

    // TODO(ian): define some better class names for options
    optionClass(index: number) {
        switch (index) {
            case 0:
                return "alert-primary";
            case 1:
                return "alert-secondary";
            case 2:
                return "alert-tertiary";
            case 3:
                return "alert-quaternary";
            default:
                return "alert";
        }
    }
    selectOptionMulti(itemOption: MfOption) {
        const control = this.formGroup.get(this.questionItemKey);

        if(!control) return;

        // console.debug(`SELECTED OPTION ${itemOption.code}`);
        let vals = control.value || [];

        if (this.isMultiSelected(itemOption)) {
            // Remove
            let tmp = vals.filter((code: any) => code != itemOption.code);
            // console.debug(`orig: ${vals}, new ${tmp}`);
            vals = tmp;
        } else {
            // Add
            vals.push(itemOption.code);
        }

        control.markAsTouched();
        control.setValue(vals);
        this.formGroup.updateValueAndValidity();
    }

    updateValue(key: string, value: any) {
        this.formGroup.controls[key].setValue(value);
        this.valueChanged.emit(new MfValueChangeEvent(key, value));
    }

    canDisplayError?: boolean;

    isValid: boolean = true;
    questionItemKey!: string;
    errorMessages?: string;
    selectedItemDescriptions: string[] = [];
    updateValidity: BehaviorSubject<ErrorField | null> = new BehaviorSubject<ErrorField | null>(null);
}
