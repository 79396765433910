<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
    <p>{{body}}</p>
    <app-calendar [calendar]="calendar" [showMonth]="showMonth" [showMonthNavigation]="showMonthNavigation" (dateSelected)="dateSelected($event)"></app-calendar>
    <p *ngIf="extra">{{extra}}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-ca-danger" (click)="activeModal.close({'selected':false})">Cancel</button>
    <button type="button" [disabled]="!selected" class="btn btn-ca-success" (click)="activeModal.close({'selected':true, 'date':selected})">Choose</button>
</div>