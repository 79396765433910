import { environment } from '../../../environments/environment'

import { Injectable } from '@angular/core'

import { UserData, User } from '../user/user'
import { Observable, Subject } from 'rxjs';


@Injectable()
export class AuthenticationService {
    constructor() { }

    localStorageUserKey: string = 'currentUser'
    signInStatus = new Subject<boolean>();

    storeUserData(ud: UserData) {
        // console.info(`Store user Data ${JSON.stringify(ud, null, 2)}`);
        if (ud && ud.token) {
            // console.info(`Got ud.token ${ud.token}`);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.localStorageUserKey, JSON.stringify(ud))
            this.signInStatus.next(true);
        } else {
            console.error("UD null or ud.token null");
        }
    }

    requestRefreshOfStatus() {
        this.signInStatus.next(this.isLoggedIn);
    }

    logout() {
        // remove user from local storage to log user out
        // console.info(`Removing user details from storage.`);
        localStorage.removeItem(this.localStorageUserKey)
        this.signInStatus.next(false);
    }

    userSignInStatus(): Observable<boolean> {
        return this.signInStatus;
    }

    get isLoggedIn(): boolean {
        return !!this.currentUserData
    }

    get isLoggedInWithStandardUser(): boolean {
        let u = this.currentUserData;

        if (u) {
            return !u.user.isGuest;
        }

        return false;
    }

    get authorisationHeader(): string {
        if (this.isLoggedIn) {
            const ud: UserData | null = this.currentUserData;

            if (ud && ud.token) {
                return `Bearer ${ud.token}`;
            }
        }
        return '';
    }

    get currentUser(): User | null {
        if (this.isLoggedIn && this.currentUserData?.user) {
            return this.currentUserData.user
        } else {
            return null
        }
    }

    get currentUserData(): UserData | null {
        const item = localStorage.getItem(this.localStorageUserKey);
        if(item) return JSON.parse(item)

        return null;
    }

    get currentUserName(): string {
        const u = this.currentUser;
        return `${u!.firstName} ${u!.lastName}`;
    }
}