import { Component, OnInit } from '@angular/core'
import { UserService, SystemAlert, SystemAlertResponse } from '@aifs-shared/user/user.service'
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-system-alert',
    templateUrl: './system-alert.component.html',
    styleUrls: ['./less/system-alert.component.scss']
})
export class SystemAlertComponent implements OnInit {

    constructor(
        private userService: UserService
    ) { }

    ngOnInit() {
        this.userService
            .getSystemAlert()
            .subscribe({
                next: (r: SystemAlertResponse) => {
                    this.alert = r.result;
                },
                error: (error: any) => {
                    var msg = error;
                    if (error instanceof HttpErrorResponse) {
                        // you could extract more info about the error if you want, e.g.:
                        msg = `${error.statusText} (${error.status})`;
                        // errMsg = ...
                    }
                    console.warn(`Received an unimportant error: ${msg}`);
                }
            });
    }

    alert?: SystemAlert
}
