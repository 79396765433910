<div *ngIf="!taskDataLoaded" class="center-block">
    <p>Loading... </p>
    <img src="assets/img/blu-loader.gif" class="bl">
</div>

<div *ngIf="taskDataLoaded" class="overview">
    <h3>Home</h3>
    <div class="urgent-contact" *ngIf="needsUrgentContact">
      Your application is on hold - contact us urgently
    </div>
    <div *ngIf="!needsUrgentContact">
      <app-system-alert></app-system-alert>
      <app-season-closed></app-season-closed>
      <app-applicant-status></app-applicant-status>
      <div *ngIf="showEditAvailabilty" class="availability">
          <p>
              Current Availability:
              <strong>{{earliestAvailabilityDate| date:'MMM d' }}{{e0ord}} - {{dateDueHome| date:'MMM d' }}{{e1ord}}</strong>
              <img src="assets/img/info_icon.png" class="info_icon" alt="Info" (click)="showInfoDialog()" />
          </p>
      </div>
      <div *ngIf="showPlacementDates" class="availability">
                  <p>
                      Placement Date:
                      <strong>{{startDate}}</strong>
                      <br>Can't make this date? <a href="#" (click)="showPlacementInfoDialog($event)">Tell us now</a>!</p>
      </div>
      <div *ngIf="unreadCount>0" class="unread-messages">
          <strong>
              <p>
                  <span (click)="readMessages()">
                      <span class="fa-regular fa-envelope"></span> {{unreadCount}} New Message<span *ngIf="unreadCount>1">s</span>
                  </span>
              </p>
          </strong>
      </div>
      <div *ngIf="showEventButton" class="event">
          <button type="button" class="btn events-btn" (click)="upcomingEvents()">
              <img src="assets/img/icons/event_icon.png" class="event-icon"><span [className]="eventClass">{{eventButtonText}}</span>
          </button>
            <button *ngIf="showSignUpForCDFair" type="submit" (click)="upcomingEvents()" class="btn btn-login btn-block btn-focus-shadow">{{registerButtonText}}</button>
      </div>

      <div *ngIf="displayInterviewInfo" class="interview-details">
          <h4>Interview Details</h4>
          <app-interview-info [interviewInfo]="interviewInfo" [isHomePage]="true"></app-interview-info>
      </div>

      <div *ngIf="showNewSeason">
          <p *ngIf="applicantAtCamp">
              If you are currently at camp this summer and want to view flights or contact our US office please use the
              menu on the
              left hand side. You can <a href="https://www.culturalinsurance.com/Camp-America-CISI/index.asp"
                  target="_blank">find insurance information here</a>.
          </p>
          <button type="button" class="new-season" (click)="apply()">
              Create your application now!
          </button>
      </div>
      <app-todo *ngIf="showToDoList" (itemEdit)="editItem($event)"></app-todo>

      <div class="submit" *ngIf=showSubmitApplicationButton>
          <button type="button" class="btn submit-btn" (click)="submitApplication()">Submit Your Application</button>
      </div>
    </div>
  </div>
<ng-template ngbModalContainer> </ng-template>
