import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthenticationService } from './authentication.service'
import { UserService } from '@aifs-shared/user/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from '@aifs-shared/modals/alert.modal';

@Injectable()
export class UrgentContactGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private userService: UserService,
        private modalService: NgbModal) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        //can only access guarded modules if it doesn't need to contact CA urgently
        if(this.userService.isGuest()) return true;
        
        if (this.authService.currentUserData!.user.isApplicant && this.userService.mustContactCAUrgently()){
            this.router.navigateByUrl(`/home`);
            return false;
        }

        // If he doesn't have to contact us, he's all good
        return true;
    }
}
