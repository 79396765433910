<tracker-progress></tracker-progress>
<div class="content-wrapper">
  <h3>My Application</h3>
  <h4>{{title}}</h4>

  <div class="upload-or-view" *ngIf="reference">
    <div *ngIf="!referenceUploaded">
      <p>As your referee does not speak English, please upload a paper reference with translation.</p>
      <button type="button" class="btn btn-ca-success" (click)="uploadReference()">Upload</button>
    </div>
    <div *ngIf="referenceUploaded">
      <button type="button" class="btn btn-ca-success" (click)="viewUploadedReference()">View</button>
    </div>
  </div>
  <div class="criteria">
    <h5>Reference Criteria</h5>
    <ul>
      <li>
        References must be written by a referee who has recent and extensive knowledge of you in a professional
        or educational
        capacity and be your supervisor/teacher.
      </li>
      <li>
        References should be on official stationery or stamped by an official stamp, or have a signed business
        card attached.
      </li>
      <li>References must be signed and have a current date.</li>
      <li>
        References should provide full business contact details of your referee (including name, title, address,
        email
        and telephone number).
      </li>
    </ul>
  </div>
  <tracker-buttons></tracker-buttons>
</div>
