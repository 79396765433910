import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { TrackerService } from './tracker.service';
import { TrackerEvent, TrackerEventType } from './tracker-event';

import { Subscription } from 'rxjs';

// import OnScreen from 'onscreen';

@Component({
    selector: 'tracker-buttons',
    templateUrl: './tracker-button.component.html',
    styleUrls: ['./less/tracker-button.component.scss']
})
export class TrackerButtonComponent implements OnInit, OnDestroy {
    @Input() overrideOffscreenProcessing: boolean = true;
    @Input() noAssociatedTask: boolean = false;
    @Input() nextButtonText: string = 'Next';
    @Input() hasPreviousButton: boolean = false;
    @Input() isProcessing: boolean = false;

    constructor(private tracker: TrackerService) { }

    ngOnInit() {
        this.taskDataLoaded = false;

        this.subscription = this.tracker.trackerEventStream$
            .subscribe(event => this.taskServiceEvent(event));
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    ngAfterViewInit() {
        // if (!this.overrideOffscreenProcessing) {
        //     // const os = new OnScreen({
        //     //     tolerance: 0,
        //     //     debounce: 100,
        //     //     container: window
        //     // });

        //     // this.buttonsVisible = OnScreen.check('.trackbuttons-bottom');
        //     // os.on('enter', '.trackbuttons-bottom', (element) => {
        //     //     this.buttonsVisible = true;
        //     // });

        //     // os.on('leave', '.trackbuttons-bottom', (element) => {
        //     //     this.buttonsVisible = false;
        //     // });
        // }
    }

    previousButton(): void {
        this.tracker.previous();
    }

    nextButton(): void {
        // console.info(`nextButton clicked`);
        this.tracker.next();
    }

    enableNext(): boolean {
        // this.buttonsVisible = OnScreen.check('.trackbuttons-bottom');
        return !this.isProcessing && this.tracker.canStepNext;
    }

    enablePrevious(): boolean {
        return !this.isProcessing && this.tracker.canStepPrevious;
    }

    taskServiceEvent(taskEvent: TrackerEvent) {
        // console.info(`trackerButtonComponent:taskServiceEvent: ${taskEvent.event}`);

        switch (taskEvent.event) {
            case TrackerEventType.Initialising:
                this.currentTaskName = "Initialising";
                break;

            case TrackerEventType.TasksLoaded:
                this.currentTaskName = "Loaded";
                break;

            case TrackerEventType.ActiveTaskChanged:
                // console.info(`Active task changed: ${JSON.stringify(this.tracker.activeTask, null, 2)}`);
                if (this.tracker.activeTask) {
                    this.currentTaskName = this.tracker.activeTask.name;
                } else {
                    this.currentTaskName = `No active task!`;
                }
                break;

            case TrackerEventType.TaskStatusUpdateCompleted:
                if (this.tracker.activeTask) {
                    // console.log(`Current task status changed: ${this.tracker.activeTask.name}`);
                }
                break;

            case TrackerEventType.TaskLoadComplete:
                // console.info(`Task Load Complete: ${JSON.stringify(this.tracker.activeTask, null, 2)}`);

                if (this.tracker.activeTask) {
                    this.currentTaskName = `Task Load Complete: ${this.tracker.activeTask.name}`;
                } else {
                    this.currentTaskName = `Task Load Complete: No activeTask`;
                }
                this.taskDataLoaded = true;
                break;
        }
    }

    currentTaskName: string = '';
    subscription?: Subscription;
    buttonsVisible: boolean = false;
    taskDataLoaded: boolean = false;
}