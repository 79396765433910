import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2, ElementRef } from '@angular/core'
import { Router, NavigationEnd } from '@angular/router'

// import { WindowSize } from './shared/framework/window-size';
import { WindowRef } from '@aifs-shared/framework/window-ref';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '@aifs-shared/modals/confirmation.modal';

import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { TimeoutService } from '@aifs-shared/timeout/timeout.service'
import { UserService } from '@aifs-shared/user/user.service';

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { TrackerEventType } from '@aifs-shared/tracker/tracker-event';

import { DocumentUploadChange, DocumentUploadChangeType, UploadService, UploadsForTypeResponse } from '@aifs-shared/upload/upload.service';
import { UploadedFile } from '@aifs-shared/upload/uploaded-file';
import { DocumentType } from '@aifs-shared/upload/document-type';
import { environment } from '@environments/environment';
import { User } from '@aifs-shared/user/user';
import { BehaviorSubject, Subscription } from 'rxjs';
import { InactivityService } from './inactivity.service';
//import * as FullStory from '@fullstory/browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./less/app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    title = 'Camp America'

    constructor(
        // windowSizer: WindowSize,
        private windowRef: WindowRef,
        private modalService: NgbModal,
        private router: Router,
        private tracker: TrackerService,
        private user: UserService,
        public authService: AuthenticationService,
        private uploadService: UploadService,
        private timeout: TimeoutService,
        private inactivity: InactivityService,
        private renderer: Renderer2, 
        private el: ElementRef
    ) { }

    ngAfterViewInit() {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = environment.hubSpotTrackingScript;
        script.async = true;
        script.defer = true;
        script.id = 'hs-script-loader';
        this.renderer.appendChild(this.el.nativeElement.ownerDocument.body, script);
      }

    ngOnInit(): void {
        this.homeRoute = this.tracker.homeRoute;
        this.timeout.setSessionTimeout();

        this.isProduction = environment.production;
        this.isApplicantViewHeaderVisible = environment.applicantView.isHeaderVisible;

        // this.windowSizer.height$.subscribe(h => this.wsy = h);
        // this.windowSizer.width$.subscribe(w => {
        //     if (w > 768) {
        //         //this.opened = true;
        //         this.closeOnClick = false;
        //         this.showBackdrop = false;
        //     } else {
        //         //this.opened = false;
        //         this.showBackdrop = true;
        //         this.closeOnClick = true;
        //     }
        //     this.wsx = w;
        // });

        // Scroll to top of page on navigation
        this.routeSubscription = this.router.events.subscribe(
            (evt) => {
                if (!(evt instanceof NavigationEnd)) {
                    return
                }
                this.windowRef.scrollToTop();
            }
        );

        this.subscription = this.tracker.trackerEventStream$
            .subscribe(event => {
                if (event.event == TrackerEventType.Initialising) {
                } else if (event.event == TrackerEventType.TasksLoaded || TrackerEventType.UserStatusUpdateTasksReloaded) {
                    // console.debug(`APP: Subscription FIRED`);
                    this.homeRoute = this.tracker.homeRoute;
                }
            });

        //this.updateUserRoles();

        this.userSubscription = this.user.userChanged.subscribe(
            user => {
                if (user && !(this.currentUser) || user && user.id !== this.currentUser!.id) {
                    this.currentUser = user;
                    
                    // console.log(`User Details Changed!`, user);
                    this.updateUserRoles();
                    //this.user.userPush.registerForUser(user);

                    // NOTE(IAN): if they are a guest user, don't even go down the remaining
                    // path.
                    if(this.user.isGuest()) return;

                    if (!this.user.isApplicant()) {
                        // console.log(`Has no applicantRole`);
                        this.applicationId = this.authService.currentUserData!.user.id; //Obtains Application ID when accessed from Interviewer Site.
                    }
                    else {
                        this.applicationId = this.user.applicantRole()!.activeApplicationId!;
                    }

                    if (this.applicationId !== 0) {
                        // console.log(`Got application Id ${applicationId}`);
                        this.loadUploadsForType(this.applicationId);
                    }
                } else if (!user) {
                    // console.debug(`User has signed out`);
                    this.currentUser = null;
                    this.isSignedIn = false;
                }
            }
        );
        // Subscribe to portrait image changes
        this.profileImageSubscription = this.uploadService.uploadServiceEventStream$.subscribe({
            next: (r: DocumentUploadChange ) => {
                if(r.changeType == DocumentUploadChangeType.Ignore) { console.log(`APPC: Ignoring`); return; }
                const applicationId = this.user.applicantRole()?.activeApplicationId;
                if(applicationId) this.loadUploadsForType(applicationId);
            },
            error: (error: any) => { console.error(error); }
        });       

        this.inactivity.init();
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
        this.userSubscription?.unsubscribe();
        this.profileImageSubscription?.unsubscribe();
    }

    updateUserRoles(): void {
        // NOTE(Ian): Hacky, but prevent NGO100 after registration
        setTimeout( () => {
            this.isSignedIn = this.authService.isLoggedIn;
            if (this.isSignedIn) {
                this.isInterviewer = this.user.isInterviewer();
                this.isApplicant = this.user.isApplicant();
            }
        }, 250);        
    }

    toggleSidebar(): void {
        this.opened = !this.opened;
    }

    toggleProfileMenu(): void {
        this.pmopened = !this.pmopened;
    }

    navigateTo(url: string): void {
        this.opened = false;
        this.pmopened = false;
        this.router.navigateByUrl(url);
    }

    signOut(): void {
        this.pmopened = false;
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = 'Sign Out';
        modalRef.componentInstance.body = 'Are you sure you want to sign out?';
        modalRef.result.then(result => {
            if (result) {
                this.user.userHasSignedOut();
                this.tracker.clearTasks();
                this.authService.logout();
                localStorage.setItem('refresh', '1');
                this.router.navigateByUrl("/sign-in");
            }
        });
    }

    loadUploadsForType(applicationId: number): void {
        this.uploadService
            .getUploadsForType({ applicationId: applicationId, documentType: DocumentType.Portrait })
            .subscribe({
                next: (uploadsResponse: UploadsForTypeResponse) => {
                    if (uploadsResponse.uploads.length > 0) {
                        this.uploadedFile = uploadsResponse.uploads[0];
                        this.profileImage = this.uploadedFile?.pages[0].uri;
                    }
                }
            });
    }

    get uploadedFile(): UploadedFile | null {
        if (this.getUploadFile && !this.__uploadedFile__.getValue()) {
            if(this.user.isApplicant()){
                var applicationId = this.user.applicantRole()!.activeApplicationId!;
                this.loadUploadsForType(applicationId);
                this.getUploadFile = false;
            }
        }
        return this.__uploadedFile__.getValue();
    }

    set uploadedFile(value: UploadedFile | null) {
        this.__uploadedFile__.next(value);
    }

    userSubscription?: Subscription;
    routeSubscription?: Subscription;
    subscription?: Subscription;
    profileImageSubscription?: Subscription;

    applicationId = 0;

    opened: boolean = false;
    showBackdrop: boolean = true;
    closeOnClick: boolean = true;

    pmopened: boolean = false;
    isSignedIn = false;

    wsx: number = 0;
    wsy: number = 0;

    isInterviewer: boolean = false;
    isApplicant: boolean = false;
    __uploadedFile__: BehaviorSubject<UploadedFile | null> = new BehaviorSubject<UploadedFile | null>(null);
    getUploadFile: boolean = true;

    DocumentType = DocumentType;

    isProduction: boolean = false;
    currentUser?: User | null;

    homeRoute = '/home';
    profileImage?: string;
    isApplicantViewHeaderVisible: boolean = false;
}
