
import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

import { ResponseData } from './response-data';
import { BaseResponse, ResponseResultCode } from './base-response';

// TODO(ian): retire this, don't think it was ever properly implemented
import { ServiceResponse, ServiceResponsePayload } from './service-response';

/**
 * Abstract Base Service class for common methods used in services throughout the project
 */
export abstract class BaseService {
    /**
     * Return the desired structured data from a service call.
     * All services guarantee that the data result will be of 
     * the format described by the BaseResponse interface, whether
     * or not they actually suceeded.
     * @param data (BaseResponse) - data from a server call
     */
    protected getResultData<T>(data: BaseResponse, throwIfError: boolean = true): T {
        var resultData: any;
        sessionStorage.removeItem('lastError');

        if (data.resultCode == ResponseResultCode.Error && throwIfError) {
            //console.error(`BaseService detected an error in data returned: ${JSON.stringify(data, null, 2)}`);
            //throw new Error(data.error);
            resultData = data.result as BaseResponse;
            sessionStorage.setItem('lastError', data.error!);
        }

        if (data.resultCode == ResponseResultCode.Success) {
            resultData = data.result as T;
        }

        // TODO(ian): we currently aren't using the
        // redirect call
        return resultData;
    }

    /**
     * Handle a service error incurred during a network service call
     * TODO(ian): Actually check properly and decide on handling strategies
     * @param error (any) Error returned by service call
     */
    protected handleError(operation: any) {
        return (err: any) => {
            let errMsg = `Error in ${operation}()`;
            console.error(`${errMsg}:`, err)
            if (err instanceof HttpErrorResponse) {
                // you could extract more info about the error if you want, e.g.:
                console.error(`status: ${err.status}, ${err.statusText}`);
                // errMsg = ...
            }
            return observableThrowError(errMsg);
        }
    }
}