import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrustboxComponent } from './trustbox.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TrustboxComponent,
    ],
    providers: [],
    exports: [
        TrustboxComponent
    ]
})
export class TrustboxModule { }
