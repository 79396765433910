import { AbstractControl, ValidatorFn } from '@angular/forms';

export function requiredIf(dependentUpon: AbstractControl, expectedValue: string): ValidatorFn {
    let subscribe: boolean = false;

    return (control: AbstractControl): { [key: string]: any } | null => {

        if (!subscribe) {
            subscribe = true;
            dependentUpon.valueChanges.subscribe(() => {
                control.updateValueAndValidity();
            });
        }

        let thisValue = control.value;
        let otherValue = dependentUpon.value;

        let thisHasValue = thisValue && thisValue != '';
        let isValid = true;

        if (otherValue == expectedValue)
            isValid = thisHasValue;

        // console.info(`This ${thisValue} Other ${otherValue} == ${expectedValue}, valid: ${isValid}}`)

        return isValid ? null : { required: true }

    };
}
