import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthenticationService } from './authentication.service'
import { UserService } from '@aifs-shared/user/user.service'

@Injectable()
export class ActiveApplicationGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private userService: UserService) { }

        canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.currentUserData) {
            var applicationId = this.userService.applicantRole()?.activeApplicationId;
            return applicationId != null && applicationId > 0;
        }

        this.router.navigate(['/home'], { queryParams: { returnUrl: state.url } })
        return false
    }
}