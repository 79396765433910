import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';

// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TrackerRoutingModule } from './tracker.routing';

// Import feature components, services and filters here
import { TrackerButtonComponent } from './tracker-button.component';
import { TrackerProgressComponent } from './tracker-progress.component';
import { TaskIntroComponent } from './task-intro.component';
import { TaskOutroComponent } from './task-outro.component';

import { TrackerService } from './tracker.service';
import { SkillService } from '../../skills/skill.service';

@NgModule({
    // Inputs
    imports: [
        NgbModule,
        CommonModule,
        TrackerRoutingModule
    ],
    // Outputs
    declarations: [
        TrackerButtonComponent,
        TrackerProgressComponent,
        TaskIntroComponent,
        TaskOutroComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        SkillService
    ],
    // Exports - if we export a component from here, we will probably import it into another module (either feature or root)
    exports: [
        TrackerButtonComponent,
        TrackerProgressComponent
    ]
})
export class TrackerModule {
    // Stop reloading my damn service, thanks, Angular...
    // http://stackoverflow.com/a/39873775
    static forRoot(): ModuleWithProviders<TrackerModule> {
        return {
            ngModule: TrackerModule,
            providers: [TrackerService]
        };
    }
}