import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '@aifs-shared/modals/confirmation.modal';
import { DocumentType } from './document-type';

@Component({
    selector: 'user-image',
    templateUrl: './user-image.component.html',
    styleUrls: [`./less/user-image.component.scss`]
})
export class UserImageComponent implements OnInit {
    constructor(
        sanitizer: DomSanitizer,
        private modalService: NgbModal) {
    }

    @Input() documentId!: number;
    @Input() documentType?: DocumentType;
    @Input() title: string = '';
    @Input() url!: string;
    @Input() imageClass!: string;

    @Output() deleteImage = new EventEmitter<number>();

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    clickDelete(): void {
        // console.log(`Clicked delete`);
        const msg = (this.isPortrait() ? "Please choose a new profile image to replace the current upload with, or select 'No' to keep the existing one." : "Are you sure you wish to delete this image?");
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = "Delete Upload";
        modalRef.componentInstance.body = msg;
        modalRef.componentInstance.cssClass = "danger";
        modalRef.result.then(result => {
            if (result) {
                // console.log(`User wants to delete, raise event`);
                this.deleteImage.emit(this.documentId);
            } else {
                // console.log(`User cancelled delete`);
            }
        });
    }

    isPortrait(): boolean {
        if(this.documentType && this.documentType === DocumentType.Portrait) return true;
        return false;
    }

}
