<div class="wrapper">

    <!-- Navigation -->
    <div id="sidebar" [ngClass]="{'active': opened}">
        <app-user-menu *ngIf="isSignedIn" [isApplicant]="isApplicant" [isInterviewer]="isInterviewer"
            (menuItemClicked)="navigateTo($event)"></app-user-menu>
    </div>

    <!-- /Navigation -->
    <div id="content" [ngClass]="{'active': opened}">        
        <div class="applicantHeader row" *ngIf="isApplicantViewHeaderVisible">
            Applicant view - Any updates will not be saved
        </div>        
        <div class="content-panel">
            
            <div class="row navbar-header" *ngIf="authService.isLoggedInWithStandardUser; else guest" id="header">
                <button id="sidebarCollapse" class="col-2 navbar-toggler left-menu-button" type="button"
                    (click)="toggleSidebar()">
                    &#9776;
                </button>
                <a class="navbar-logo" routerLink="/home">
                    <img src="assets/img/CA-Colour.svg" class="top-logo" alt="Responsive image">
                </a>
                <button class="nav-help col-2 profile-pic" type="button" (click)="toggleProfileMenu()">
                    <img *ngIf="profileImage; else noProfilePic"
                        [src]="profileImage | cloudimageFace:128:128" class="fa image">
                    <ng-template #noProfilePic>
                        <img src="assets/img/icons/Top_Nav_Profile.png" class="fa image">
                    </ng-template>
                </button>
                <app-account-menu *ngIf="pmopened" [isApplicant]="isApplicant" [isInterviewer]="isInterviewer"
                    (menuItemClicked)="navigateTo($event)" (signOutClicked)="signOut()"></app-account-menu>
            </div>
            <ng-template #guest>
                <div class="logo-container" *ngIf="authService.isLoggedIn">
                    <img src="assets/img/CA-Colour.svg" class="top-logo" alt="Responsive image">
                </div>
            </ng-template>
            <!-- content -->
            <div class="row main-content">
                <div class="col-lg-2 col-xl-3 desktop-content">

                </div>
                <div class="col-lg-8 col-xl-6 middle-content">
                    <router-outlet></router-outlet>
                </div>
                <div class="col-lg-2 col-xl-3 desktop-content">

                </div>
            </div>
        </div>
    </div>

</div>

<ng-template ngbModalContainer></ng-template>


<div *ngIf="isProduction">
    <!-- Google Tag Manager -->
    <script>
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-WRSKPRT');</script>
    <!-- End Google Tag Manager -->
</div>
