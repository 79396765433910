import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { Observable, BehaviorSubject } from 'rxjs'

import { UserService } from '../shared/user/user.service';
import { AuthenticationService } from '../shared/auth/authentication.service';
import { ApplicationService } from '../shared/application/application.service';

import { TrackerService } from '../shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { Task } from '../shared/tracker/task';

import { DisplayCardItem } from '../shared/display-card/display-card-item';

import { DocumentType } from '../shared/upload/document-type';
import { UploadService } from '../shared/upload/upload.service';
import { UploadComponent } from '../shared/upload/upload.component';
import { UploadedFile } from '../shared/upload/uploaded-file';

import { ReferenceService } from './reference.service';
import { RefereeDetails, ReferenceType } from './referee-details';

@Component({
    templateUrl: './reference-criteria.component.html',
    styleUrls: ['./less/reference-criteria.component.scss']
})
export class ReferenceCriteriaComponent extends TrackerTaskComponent implements OnInit, OnDestroy {

    constructor(
        router: Router,
        tracker: TrackerService,
        route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        private application: ApplicationService,
        private uploads: UploadService,
        private referenceService: ReferenceService
    ) {
        super(router, tracker);

        // console.log(`ReferenceCriteria`);
    }

    override ngOnInit() {
        super.ngOnInit();
        this.title = "Referee";

        if (!this.tracker.getTaskStorage("reference")) {
            this.router.navigateByUrl("/references/review");
            return;
        }

        this.reference = this.tracker.getTaskStorage("reference");
        // console.log(`Got reference id ${this.reference.id}`);

        this.setNextEnabled(true);
        this.previousButtonEnabled = true;
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    /**
     * Now we have enough, we can move forwards with the tracker
     */
    override stepNext(): void {
        this.router.navigateByUrl("/references/review");

        // this.tracker.finishTask();
    }

    override stepPrevious(): boolean {
        this.router.navigateByUrl("/references/review");

        return true;
    }

    viewUploadedReference(): void {
        sessionStorage.setItem("upload_next", "/references/upload");
        sessionStorage.setItem("upload_prev", "/references/review");
        this.router.navigateByUrl(`uploads/view/${this.reference.imageId}`)
    }

    uploadReference(): void {
        sessionStorage.setItem("r:upr", "3");
        this.router.navigateByUrl('uploads/references/add');
    }

    get referenceUploaded(): boolean {
        return this.reference.imageId! > 0;
    }

    reference!: RefereeDetails;

    // remove once task stuff in
    title: string = "Temporary Title";

    //image?: UploadedFile;
}
