export class Message {
    id!: number;
    name!: string;
    subject?: string;
    body!: string;
    date!: Date;
    isRead!: boolean;

    source: string = "i";
}

export enum MessageType {
    Received,
    Sent
}