<div class="content-wrapper">
    <h3>Terms and Conditions</h3>
    <h4>{{ title }}</h4>
    <div class="form-group">
        <form>
            <div class="terms" [innerHTML]="termsText"></div>
            <div *ngIf="mustAgree">

                <div class="flex flex-row justify-center">
                    <button class="option-item flex {{isSelected('agreeTerms') ? 'picked' : ''}}" (click)="agreeTerms(true)">
                        <div class="br--left pa3 flex flex-column items-center justify-center fa-fw">
                            <i *ngIf="isSelected('agreeTerms'); else unchecked" class="fa fa-check-square fa-3x"></i>
                            <ng-template #unchecked>
                                <i class="fa-regular fa-square fa-3x unpicked"></i>
                            </ng-template>
                        </div>
                        <div class="br--right pa3 flex flex-column">
                            <p class="mv0 lh-copy f1 fw3 option-text{{isSelected('agreeTerms') ? '-picked' : ''}}">I
                                Agree</p>
                        </div>
                    </button>
                </div>

                <!-- <div class="alert alert-primary" [class.picked]='selectedOption == 1' style="margin-top: 15px;"
                    role="alert" (click)='agreeTerms(true)'>I agree</div> -->
            </div>
        </form>
    </div>
</div>
<tracker-buttons *ngIf="mustAgree; else noAgreement" [overrideOffscreenProcessing]="true" [noAssociatedTask]="true">
</tracker-buttons>
<ng-template #noAgreement>
    <div id="tracker-buttons" class="tracker-buttons trackbuttons trackbuttons-bottom">
        <button type="button" class="btn btn-track-previous btn-focus-shadow" (click)="stepPrevious()">
            <span class="fa-solid fa-chevron-left"></span>&nbsp;</button>
    </div>
</ng-template>