import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipe/pipes.module'

import { CalendarComponent } from './calendar.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    // Inputs
    imports: [
        CommonModule,
        PipesModule,
        NgbPopoverModule, 
    ],
    // Outputs
    declarations: [
        CalendarComponent
    ],
    // Services
    providers: [
    ],
    // Exports - if we export a component from here, we will probably import it into another module (either feature or root)
    exports: [
        CalendarComponent
    ]
})
export class CalendarModule {

}