import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator } from '@angular/forms';

import { Directive, Input } from '@angular/core';
import { MetaformDateTime } from '../datetime/metaform-datetime';


export function mustBeBeforeMomentValidator(mustBeBefore: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
        // moment.tz.setDefault('Europe/London');
        //console.log(`mustBeBefore: ${mustBeBefore} Value: ${c.value}`);

        // const userEntry = moment.utc(c.value);
        // const checkedFor = moment.utc(mustBeBefore);

        let userEntry = MetaformDateTime.getDateTime(c.value);
        let checkedFor = MetaformDateTime.getDateTime(mustBeBefore);

        const result = userEntry && checkedFor && (userEntry < checkedFor); // .isBefore(checkedFor.format(), 'day');
        //console.log(`Is before? ${result}`);

        return result ? null : {
            maxDate: {
                valid: false
            }
        };
    };
}

@Directive({
    selector: '[mustBeBefore]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MustBeBeforeMomentValidatorDirective, multi: true }]
})
export class MustBeBeforeMomentValidatorDirective implements Validator {
    @Input('mustBeBefore') mustBeBefore: any;

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.mustBeBefore ? mustBeBeforeMomentValidator(this.mustBeBefore)(control) : null;
    }
}