import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';
import { PlacementDetails } from './placement-details';
import { PlacementInformation } from './placement-information';
import { PlacementCamp } from './placement-camp';
import { PlacementArea } from './placement-area';
import { PlacementContact } from './placement-contact';
import { PlacementDates } from './placement-dates';

@Injectable()
export class PlacementService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    public getPlacementDates(applicationId: number): Observable<PlacementDates> {
        const s = new Subject<PlacementDates>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_PlacementDatesById(applicationId))
            .subscribe({
                next: (data: BaseResponse) => {
                    let response = this.getResultData<PlacementDates>(data);
                    s.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return s;
    }

    public getPlacementDetails(applicationId: number): Observable<PlacementDetails> {
        let subject = new Subject<PlacementDetails>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_PlacementDetailsById}/${applicationId}`)
            .subscribe({
                next: (data: BaseResponse) => {
                    let response = this.getResultData<PlacementDetails>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public getPlacementInformation(applicationId: number): Observable<PlacementInformation> {
        let subject = new Subject<PlacementInformation>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_PlacementInfoById}/${applicationId}`)
            .subscribe({
                next: (data: BaseResponse) => {
                    let response = this.getResultData<PlacementInformation>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public getPlacementCamp(applicationId: number): Observable<PlacementCamp> {
        let subject = new Subject<PlacementCamp>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_PlacementCampById}/${applicationId}`)
            .subscribe({
                next: (data: BaseResponse) => {
                    let response = this.getResultData<PlacementCamp>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public getPlacementArea(applicationId: number): Observable<PlacementArea> {
        let subject = new Subject<PlacementArea>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_PlacementAreaById}/${applicationId}`)
            .subscribe({
                next: (data: BaseResponse) => {
                    let response = this.getResultData<PlacementArea>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public getPlacementContact(applicationId: number): Observable<PlacementContact> {
        let subject = new Subject<PlacementContact>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_PlacementContactById}/${applicationId}`)
            .subscribe({
                next: (data: BaseResponse) => {
                    let response = this.getResultData<PlacementContact>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public acceptPlacement(applicationId: number, acceptanceStatus: string): Observable<PlacementStatusUpdateResponse> {
        return this.http
            .post<PlacementStatusUpdateResponse>(environment.ServiceUrl_ConfirmPlacement(applicationId),
                {
                    'applicationId': applicationId,
                    'acceptanceStatus': acceptanceStatus
                }
            );
    }

    public readySetGoStatus(applicationId: number): Observable<ReadySetGoStatus> {
        let subject = new Subject<ReadySetGoStatus>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_RSGStatus(applicationId))
            .subscribe({
                next: (data: BaseResponse) => {
                    let response = this.getResultData<ReadySetGoStatus>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public acceptRSG(applicationId: number): Observable<ReadySetGoStatusUpdate> {
        return this.updateRSGStatus(applicationId, "RSA");
    }

    public declineRSG(applicationId: number): Observable<ReadySetGoStatusUpdate> {
        return this.updateRSGStatus(applicationId, "RSD");
    }

    updateRSGStatus(applicationId: number, status: string): Observable<ReadySetGoStatusUpdate> {
        let subject = new Subject<ReadySetGoStatusUpdate>();
        try {
            this.http
                .post<BaseResponse>(environment.ServiceUrl_UpdateRSGStatus(applicationId),
                    {
                        'applicationId': applicationId,
                        'rsgStatus': status
                    }
                )
                .subscribe({
                    next: (data: BaseResponse) => {
                        let response = this.getResultData<ReadySetGoStatusUpdate>(data);
                        subject.next(response);
                    },
                    error: (error: any) => {
                        this.handleError(error);
                    }
                });
        } catch (e) {
            if (subject) subject.error(e);

            throw e;
        }
        return subject;
    }

}

export class PlacementStatusUpdate implements ResponseData {
    success: boolean = false;
}

export class PlacementStatusUpdateResponse implements ResponseData {
    result!: PlacementStatusUpdate;
}

export class ReadySetGoStatus {
    rsgStatus!: string;
    pendingUpdate: boolean = false;
}

export class ReadySetGoStatusResponse implements ResponseData {
    result!: ReadySetGoStatus;
}

export class ReadySetGoStatusUpdateResponse implements ResponseData {
    result!: ReadySetGoStatusUpdate;
}

export class ReadySetGoStatusUpdate {
    success: boolean = false;
}