export function getCookieByName(name: string): string {
    let cookieValue = '';
    if (document.cookie) {
        const cookies = document.cookie.split(';');
        const cookie = cookies.find(c => c.trim().startsWith(name + '='));
        if (cookie) {
            cookieValue = cookie.split('=')[1];
        }
    }
    return cookieValue;
}