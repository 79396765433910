import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { UserService } from '@aifs-shared/user/user.service';
import { Component, OnInit } from '@angular/core';
import { ReferenceService } from './reference.service';

@Component({
    selector: 'app-reference-status-preview',
    templateUrl: './reference-status-preview.component.html',
    styleUrls: ['./less/reference-status-preview.component.scss']
})
export class ReferenceStatusPreviewComponent implements OnInit {

    constructor(
        private userService: UserService,
        private referenceService: ReferenceService,
        private tracker: TrackerService
    ) { }

    ngOnInit(): void {
        // console.log(`ReferenceStatusPreview`);
        const currentState = this.userService.applicantRole()!.state;
        const ts = this.tracker.getUserTaskStatusById(14); // MAGIC

        this.referenceService
            .isAwaitingReferences(currentState!, ts!.complete)
            .subscribe({
                next: (yes: boolean) => {
                    this.isAwaitingReferences = yes;
                },
                error: (error: any) => {
                    console.error(error);
                }
            });
    }

    isAwaitingReferences = false;
}
