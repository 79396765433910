import { NgModule } from '@angular/core'

import { CloudimageCropPipe, CloudimageHeightPipe, CloudimageWidthPipe, CloudimageFacePipe } from './cloudimage.pipe'
import { TruncatePipe } from './truncate.pipe'
import { MapToIterable } from './map-to-iterable.pipe'
import { DateSuffix } from './date-suffix.pipe'

@NgModule({
    declarations: [
        CloudimageCropPipe, CloudimageHeightPipe, CloudimageWidthPipe, CloudimageFacePipe,
        TruncatePipe,
        DateSuffix,
        MapToIterable
    ],
    exports: [
        CloudimageCropPipe, CloudimageHeightPipe, CloudimageWidthPipe, CloudimageFacePipe,
        TruncatePipe,
        DateSuffix,
        MapToIterable
    ]
})
export class PipesModule { }
