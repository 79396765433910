import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { UserRoutingModule } from './user.routing'

// NgBootstrap
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';

import { TrackerModule } from '@aifs-shared/tracker/tracker.module'
import { MetaformCoreModule } from '@aifs-shared/metaform/core/metaform.core.module';

import { ModalsModule } from '@aifs-shared/modals/modals.module';

import { CreateApplicationComponent } from './create-application.component';

import { UploadProfilePhotoComponent } from './upload-profile-photo.component'
import { TermsAndConditionsComponent } from './terms-and-conditions.component'
import { ProfileComponent } from './profile.component';
import { ChangeSignInComponent } from './change-sign-in.component';
import { ChangePasswordComponent } from './change-password.component'
import { SystemAlertComponent } from './system-alert.component';
import { UploadModule } from '@aifs-shared/upload/upload.module';
import { RegistrationService } from '../registration/registration.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        UserRoutingModule,
        TrackerModule,
        UploadModule,
        ReactiveFormsModule,
        NgbModule,
        ModalsModule,
        MetaformCoreModule
    ],
    declarations: [
        CreateApplicationComponent,
        UploadProfilePhotoComponent,
        TermsAndConditionsComponent,
        ProfileComponent,
        ChangeSignInComponent,
        ChangePasswordComponent,
        SystemAlertComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        NgbActiveModal,
        RegistrationService
    ],
    exports: [
        ChangeSignInComponent,
        ChangePasswordComponent,
        SystemAlertComponent
    ]
})
export class UserModule { }
