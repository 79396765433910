import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from '../environments/environment';

@Component({
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class StatusComponent {
    constructor(private http: HttpClient) {

    }

    ngOnInit(): void {
        this.checkStatus();

        setInterval(() => {
            this.checkStatus()
        }, 30000);
    }

    checkStatus(): void {
        this.checks.forEach(check => {
            this.http.get(check.url)
                .subscribe({
                    next: (result:any) => {
                        //console.info(`Received: ${check.name} = ${JSON.stringify(result)}`);
                        check.serverStatus = result['serviceStatus'];
                        check.dbStatus = result['databaseStatus'];
                    },
                    error: (error:any) => {
                        check.serverStatus = 504;
                    }
                });
        })
    }

    statusText(st: number): string {
        switch (st) {
            case -15:
                return "E_DB_TIMEOUT";
            case -14:
                return "E_SERVER";
            case 1:
                return "OK";
            case 98:
                return "REFRESH"
            case 99:
                return "?";
            case 504:
                return "E_TIMEOUT"
        }
        return "NA";
    }

    checks = [
        { url: environment.ServiceUrl_HealthCheck_Application, name: "Application", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_Flight, name: "Flights", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_Interview, name: "Interviewer", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_Payment, name: "Payment", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_Push, name: "Push", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_References, name: "References", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_Skill, name: "Skills", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_Tracker, name: "Tracker", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_Upload, name: "Upload", serverStatus: 99, dbStatus: 99 },
        { url: environment.ServiceUrl_HealthCheck_User, name: "User", serverStatus: 99, dbStatus: 99 },
    ]
}