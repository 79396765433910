import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthenticationService } from './authentication.service'
import { UserService } from '@aifs-shared/user/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from '@aifs-shared/modals/alert.modal';

@Injectable()
export class RejectedApplicantGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private userService: UserService,
        private modalService: NgbModal) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        if (this.authService.currentUserData!.user.isApplicant) {
            // logged in as applicant check status
            var userState = this.userService.applicantRole()?.state;
            var season = this.userService.applicantRole()?.activeApplicationSeason; //applicants from 2020 of status UNS or PFT should be shown a rollover offer instead

            switch (true) {
                case (userState === 'RejectedByOffice'):
                case (userState === 'RejectedByInterviewer'):
                case (userState === 'ParticipantOutOfProgrammeCNX'):
                case (userState === 'ParticipantOutOfProgrammeEXP'):
                case (userState === 'ParticipantOutOfProgrammeFAI'):
                case (userState === 'ParticipantOutOfProgrammeREJ'):
                case (userState === 'ParticipantOutOfProgrammeUNS' && season != 2020):
                    // We need to double check where we're being asked to go
                    if (route.routeConfig!.path === 'hub') {
                        this.router.navigateByUrl(`/home`);
                    } else {
                        this.showAlert('Sorry', 'This page is unavailable.');
                    }

                    return false;
            }
        }

        // Not an applicant, or application state is acceptable
        return true;
    }

    showAlert(title: string, explain: string, error: string | undefined = undefined) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = 'danger';
        if (error) modalRef.componentInstance.error = error;
        modalRef.result.then(result => {
        });
    }
}
