import { Component, OnInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { MapToIterable } from '@aifs-shared/pipe/map-to-iterable.pipe';

import { FormGroup, AbstractControl, Validators } from '@angular/forms';
import { Metaform, MfQuestion, Question, MfOption, MfValueChangeEvent, ValidationError } from '@aifs-shared/metaform/metaform';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';

import { Lookup } from '@aifs-shared/lookup/lookup';

import { ErrorField } from '@aifs-shared/metaform/metaform-question-display.component';

@Component({
    // moduleId: module.id,
    selector: 'mf-os',
    templateUrl: './option-select.component.html',
    styleUrls: ['../../less/metaform-question-display.component.scss']
})
export class OptionSelectComponent implements OnInit, DoCheck {
    @Input() readonly: boolean = false;
    @Input() metaform!: Metaform;
    @Input() parent!: MfQuestion;
    @Input() questionItem!: Question<any>;
    @Input() questionName!: string;
    @Input() formGroup!: FormGroup;
    @Input() dataProvider!: MetaformDataProvider;
    @Input() validationErrorMessages!: Map<string, string>;
    @Input() scrambleValues: boolean = false;
    @Output() valueChanged = new EventEmitter<MfValueChangeEvent>();

    constructor() { }

    ngOnInit() {
        this.questionItemKey = this.questionItem.key;
        this.canDisplayError = true;

        // For composite controls, we can only display the error message
        // if this item is the first in the targetFields list
        if (this.parent && this.parent.items && this.parent.items[0].targetFields) {
            this.canDisplayError = (this.parent.items[0].targetFields[0] == this.questionItemKey);
            if (this.canDisplayError) {
                // console.info(`${this.questionItemKey} is the first item in a composite field, or is a sole field`);
                this.canDisplayError = true;
            } else {
                // console.info(`${this.questionItemKey} is not able to display it's own errors`);
            }
        }

        if (this.questionItem.extraClass == 'alert-oneline') {
            this.isWide = false;
        }

        this.updateValidity.subscribe((error) => {
            if (error) {
                if (this.errorMessages == null || this.errorMessages !== error.message) {
                    // console.info(`${this.id} - ${this.errorMessages} : Got message : ${error.message} for field ${error.questionItem}`);
                    this.errorMessages = error.message;
                }
            } else {
                this.errorMessages = undefined;
            }
        });

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                this.formGroup.get(field)!.valueChanges.subscribe(data => {
                    this.valueChanged.emit(new MfValueChangeEvent(field, data));
                });
            });
        } else {
            this.formGroup.get(this.questionItemKey)!.valueChanges.subscribe(data => {
                this.valueChanged.emit(new MfValueChangeEvent(this.questionItemKey, data));
            });
        }

        this.formGroup.get(this.questionItemKey)!.markAsPristine();

        this.formGroup.updateValueAndValidity();
    }

    ngDoCheck() {
        let valid: boolean = true;

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                if ((this.formGroup.controls[field].touched)) {
                    // console.info(`Checking: ${field}`);
                    valid = valid && this.formGroup.controls[field].valid;

                    this.questionItem.errorField = undefined;

                    this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
                }
            });
        } else {
            // console.info(`Checking: ${this.questionItemKey} - ${this.formGroup.controls[this.questionItemKey].touched} (T) ${this.formGroup.controls[this.questionItemKey].dirty} (D)`);

            if (this.formGroup.controls[this.questionItemKey].touched) {
                // console.log(`Is touched`);
                valid = this.formGroup.controls[this.questionItemKey].valid;
                this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
            }
        }

        this.isValid = valid;
    }

    get formControl() {
        return this.formGroup.controls[this.questionItemKey];
    }

    formControlFor(targetIndex: 0) {
        return this.formGroup.controls[this.questionItem.targetFields[targetIndex]];
    }

    get hasLabel() { return this.questionItem.label !== undefined && this.questionItem.label !== null; }

    /**
     * Get all possible failing validation messages for this field
     */
    getValidationMessages(question: string): ErrorField | null {

        if (this.questionItem.targetFields) {
            if (this.questionItem.errorField && this.questionItem.currentError) {
                // console.warn(`already have an error: ${this.questionItem.errorField} (${this.questionItem.currentError}), aborting`);
                return null;
            }

            for (let i = 0; i < this.questionItem.targetFields.length; i++) {
                // console.warn(`Checking ${this.questionItem.targetFields[i]}`);
                if (this.formGroup.controls[this.questionItem.targetFields[i]].touched && !this.formGroup.controls[this.questionItem.targetFields[i]].valid) {
                    let message = this.validationMessageForItem(this.questionItem.targetFields[i]);
                    // Early exit?
                    if (message) {
                        // console.info(`Got error for ${this.questionItem.targetFields[i]}: '${message}'`)
                        this.questionItem.errorField = this.questionItem.targetFields[i];
                        this.questionItem.currentError = message;
                        return new ErrorField(this.questionItem.targetFields[i], message);
                    }
                } else {
                    this.questionItem.errorField = undefined;
                    this.questionItem.currentError = undefined;
                }
            }
            this.questionItem.errorField = undefined;
        } else {
            if (this.formGroup.controls[this.questionItem.key].touched && !this.formGroup.controls[this.questionItem.key].valid) {
                let message = this.validationMessageForItem(question);
                if (message) {
                    this.questionItem.errorField = this.questionItem.key;
                    this.questionItem.currentError = message;
                    const msg = this.validationMessageForItem(question);
                    if (msg) return new ErrorField(this.questionItem.key, msg);                }
            }
        }

        // No errors
        return null;
    }

    public isMandatory(key: string): boolean {
        return (this.questionItem.required);
    }

    validationMessageForItem(item: string): string | null {
        const errors = this.formGroup.get(item)?.errors;
        if (errors) {
            // console.info(`getValidationMessages: for question ${item}, errors: ${JSON.stringify(errors)}`)

            let keys = [];
            let message = null;
            for (let key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const msg = (this.validationErrorMessages.get(`${item}_${key}`));
                    if (msg) return msg;
                }
            }
        }
        return null;
    }

    isSelected(itemOption: MfOption) {
        //console.debug(`Selected Item? ${itemOption.code} == ${this.formGroup.controls[this.questionItemKey].value}`);
        if (this.scrambleValues) {
            if (itemOption.code == 'N') {
                return true;
            }
            return false;
        }
        return this.formGroup.controls[this.questionItemKey].value == itemOption.code;
    }

    // TODO(ian): define some better class names for options
    optionClass(index: number) {
        switch (index) {
            case 0:
                return "alert-primary";
            case 1:
                return "alert-secondary";
            case 2:
                return "alert-tertiary";
            case 3:
                return "alert-quaternary";
            default:
                return "alert";
        }
    }

    selectOption(itemOption: MfOption) {
        let control = this.formGroup.get(this.questionItemKey);
        if(control) {
            control.markAsTouched();
            control.setValue(itemOption.code);
        }
        this.formGroup.updateValueAndValidity();
    }

    selectedItemDescription() {
        var key = this.questionItemKey;

        if (!this.questionItem.options) return "?";


        let selected = this.questionItem.options.find(opt => this.formGroup.controls[key].value == opt.code);
        if (selected) {            
            if (this.scrambleValues) {
                return "N";
            }
            return selected.description;
        }

        return "";
    }

    daysInMonth(month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }

    updateValue(key: string, value: any) {
        this.formGroup.controls[key].setValue(value);
        this.valueChanged.emit(new MfValueChangeEvent(key, value));
    }

    canDisplayError?: boolean;

    isValid: boolean = true;
    questionItemKey!: string;
    errorMessages?: string;
    isWide: boolean = true;
    updateValidity: BehaviorSubject<ErrorField | null> = new BehaviorSubject<ErrorField | null>(null);
}
