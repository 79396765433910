<div class="address-box">
    <div [ngSwitch]="questionItem.controlType">
        <div *ngSwitchCase="'address'">
            <label class="sr-only">House number and street</label>
            <input id="address1" class="form-control address{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
                [formControl]="$any(formControl)" [type]="'text'" placeholder="House/Flat Number, Street" [maxLength]="35"
                autocomplete="address-line1">
            <input id="address2" class="form-control address" [formControl]="formControlFor(1)" [type]="'text'"
                placeholder="" [maxLength]="35" autocomplete="address-line2">
            <input id="address3" class="form-control address" [formControl]="formControlFor(2)" [type]="'text'"
                placeholder="" [maxLength]="35" autocomplete="address-line3">
            <input id="address4" class="form-control address" [formControl]="formControlFor(3)" [type]="'text'"
                placeholder="" [maxLength]="35">
            <label class="sr-only">City</label>
            <input id="address5" class="form-control address{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
                [formControl]="formControlFor(4)" [type]="'text'" placeholder="City" [maxLength]="35" autocomplete="address-level2">
            <div class="input-group">
                <label class="sr-only">Country</label>
                <select id="addressCountry" [formControl]="formControlFor(5)" class="form-control address{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
                    autocomplete="country-name">
                    <option *ngFor="let opt of questionItem.options" [value]="opt.code" [selected]="opt.code === questionItem.value">{{opt.description}}</option>
                </select>
                <label class="sr-only">Postal code</label>
                <input id="addressPostcode" class="form-control address" [formControl]="formControlFor(6)" [type]="'text'"
                    placeholder="Postal Code" [maxLength]="10" autocomplete="postal-code">
            </div>
        </div>

        <div *ngSwitchCase="'compactaddress'">
            <label class="sr-only">House number and street</label>
            <input id="address1" class="form-control address{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
                [formControl]="$any(formControl)" [type]="'text'" placeholder="House/Flat Number, Street" [maxLength]="35"
                autocomplete="address-line1">
            <input id="address2" class="form-control address" [formControl]="formControlFor(1)" [type]="'text'"
                placeholder="" [maxLength]="35" autocomplete="address-line2">
            <input id="address3" class="form-control address" [formControl]="formControlFor(2)" [type]="'text'"
                placeholder="" [maxLength]="35" autocomplete="address-line3">
            <div class="input-group">
                <label class="sr-only">Country</label>
                <select id="addressCountry" [formControl]="formControlFor(3)" class="form-control address{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
                    autocomplete="country-name">
                    <option *ngFor="let opt of questionItem.options" [value]="opt.code" [selected]="opt.code === questionItem.value">{{opt.description}}</option>
                </select>
                <label class="sr-only">Postal code</label>
                <input id="addressPostcode" class="form-control address" [formControl]="formControlFor(4)" [type]="'text'"
                    placeholder="Postal Code" [maxLength]="10" autocomplete="postal-code">
            </div>
        </div>
    </div>
</div>
<span *ngIf="canDisplayError && !isValid" class="has-danger">
    <span class="form-control-feedback">
        {{ errorMessages }}
    </span>
</span>