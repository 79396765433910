
import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { AuthenticationService } from '@aifs-shared/auth/authentication.service'
import { UserService } from '@aifs-shared/user/user.service'

import { ApplicationService } from "@aifs-shared/application/application.service";

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { ITaskProvider } from '@aifs-shared/tracker/task-provider';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './contact-us.component.html',
    styleUrls: ['./less/contact-us.component.scss']
})
export class ContactUsComponent extends TrackerTaskComponent implements OnInit, OnDestroy, ITaskProvider {

    constructor(
        route: ActivatedRoute,
        private authService: AuthenticationService,
        private userService: UserService,
        private application: ApplicationService,
        private modalService: NgbModal,
        router: Router,
        tracker: TrackerService
    ) {
        super(router, tracker);
        this.requiresTask = false;
    }

    override ngOnInit() {
        super.ngOnInit();
        this.title = "Contact Us";

        var u = this.userService.user();
        if (!u) return;

        switch (u.countryCode) {
            case "AU":
                this.displayAU = true;
                break;
            case "DE":
            case "AT":
            case "CH":
                this.displayDE = true;
                break;
            case "NZ":
                this.displayNZ = true;
                this.showUKAddress = true;
                break;
            case "PL":
                this.displayPL = true;
                this.showUKAddress = false;
                break;
            case "RU":
                this.displayRU = true;
                this.showUKAddress = true;
                break;
            case "UA":
                this.displayUA = true;
                this.showUKAddress = true;
                break;
            default:
                this.displayUK = true;
                this.showUKAddress = true;
                break;
        }

        // console.info(`Show UK ${this.showUKAddress}, is in UK: ${this.displayUK}`);
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    title: string = '';
    displayAU: boolean = false;
    displayDE: boolean = false;
    displayUK: boolean = true;
    displayNZ: boolean = false;
    displayPL: boolean = false;
    displayUA: boolean = false;
    displayRU: boolean = false;

    showUKAddress: boolean = true;
}
