import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';

import { AppRoutingModule } from './app.routing';
import { ReactiveFormsModule } from '@angular/forms';

// NgBootstrap
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../environments/environment'

import { AuthGuard } from '@aifs-shared/auth/auth.guard'
import { RejectedApplicantGuard } from '@aifs-shared/auth/rejected-applicant.guard';
import { UrgentContactGuard } from '@aifs-shared/auth/urgent-contact.guard';

import { PipesModule } from '@aifs-shared/pipe/pipes.module'

import { PushAzureService } from '@aifs-shared/push/push-azure.service';

import { AuthenticationService } from '@aifs-shared/auth/authentication.service'
import { TimeoutService } from '@aifs-shared/timeout/timeout.service'
import { UserService } from '@aifs-shared/user/user.service';
import { UserPushService } from '@aifs-shared/user/user-push.service';
import { WindowSize } from '@aifs-shared/framework/window-size';
import { WindowRef } from '@aifs-shared/framework/window-ref';
import { BusinessRuleService } from '@aifs-shared/rule/business-rule.service';
import { ApplicationService } from '@aifs-shared/application/application.service';

import { TrackerModule } from '@aifs-shared/tracker/tracker.module';
import { FeatureService } from '@aifs-shared/feature/feature.service';

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { MetaformService } from '@aifs-shared/metaform/metaform.service';

import { InterviewService } from './interview/interview.service';
import { UploadService } from './shared/upload/upload.service';

import { ModalsModule } from '@aifs-shared/modals/modals.module';

import { AppComponent } from './app.component';

import { PageNotFoundComponent } from './not-found.component';
import { StatusComponent } from './status.component';
import { PublicTermsAndConditionsComponent } from './public-terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy.component';

import { HomeComponent } from './home.component';
import { ContactUsComponent } from './contact-us.component';
import { ExternalSignInComponent } from './external-sign-in.component';
import { SeasonClosedComponent } from './season-closed.component';

import { ToDoModule } from '@aifs-shared/todo/todo.module';
import { InterviewInfoModule } from './shared/interview-info/interview-info.module';
import { ApplicantStatusModule } from './shared/applicant-status/applicant-status.module';

import { UserMenuModule } from './user-menu/user-menu.module';
import { AccountMenuModule } from './account-menu/account-menu.module';
import { MessageService } from './messages/message.service';
import { EventService } from './event/event.service';
import { PoliceCheckModule } from './police-check/police-check.module';
import { UserModule } from './user/user.module';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { ReferenceModule } from './reference/reference.module';

import { InsightsModule } from './insights/insights.module';
import { ActiveApplicationGuard } from '@aifs-shared/auth/active-application-guard';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { InactivityService } from './inactivity.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        TrackerModule.forRoot(),
        AppRoutingModule,
        PipesModule,
        ModalsModule,
        ToDoModule,
        PoliceCheckModule,
        InterviewInfoModule,
        ApplicantStatusModule,
        UserModule,
        UserMenuModule,
        AccountMenuModule,
        NgIdleKeepaliveModule.forRoot(),
        ReferenceModule,
        InsightsModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.measurementId),
        NgxGoogleAnalyticsRouterModule
    ],
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        StatusComponent,
        HomeComponent,
        ContactUsComponent,
        ExternalSignInComponent,
        PublicTermsAndConditionsComponent,
        PrivacyPolicyComponent,
        SeasonClosedComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        AuthGuard,
        ActiveApplicationGuard,
        RejectedApplicantGuard,
        UrgentContactGuard,
        WindowRef,
        WindowSize,
        AuthenticationService,
        ApplicationService,
        BusinessRuleService,
        EventService,
        InterviewService,
        MessageService,
        MetaformService,
        PushAzureService,
        TrackerService,
        UserPushService,
        UserService,
        NgbActiveModal,
        // UploadService,
        TimeoutService,
        FeatureService,
        InactivityService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(
        private userPush: UserPushService,
        private userService: UserService,
        private tracker: TrackerService,
        private features: FeatureService) {

        this.tracker.initialise();

        this.userPush.tracker = this.tracker;
        this.userPush.userService = this.userService;

        this.features.getFeatures()
            .subscribe(
                f => {
                    // console.log(`Got features: ${JSON.stringify(f, null, 2)}`);
                }
            );

    }
}
