<div class="input-group">
    <label class="sr-only">Country dialing code</label>
    <select class="form-control sel_idd{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}" [id]="keyForTelephoneIdd(questionItem.key)"
        [formControl]="$any(formControl)" autocomplete="country-name">
        <!-- (change)="selectIddOption($event)" -->
        <option *ngFor="let idd of questionItem.options" [selected]="isIddSelected(idd.code)" [value]="idd.code">{{idd.description}}</option>
    </select>
    <label class="sr-only">Telephone number</label>
    <input class="form-control{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}" [id]="keyForTelephoneNumber(questionItem.key)"
        [formControl]="$any(formControlFor(1))" [type]="'text'" placeholder="{{questionItem.label}}" [maxLength]="20" autocomplete="tel">
    <!-- (change)="telephoneChanged($event)" -->
</div>
<span *ngIf="canDisplayError && !isValid" class="has-danger">
    <span class="form-control-feedback">
        {{ errorMessages }}
    </span>
</span>