import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

import { ApplicationService } from '../application/application.service';

import { environment } from '../../../environments/environment';

import { Lookup } from './lookup';

@Injectable()
export class LookupService extends BaseService {
    constructor(
        private http: HttpClient,
        private applicationService: ApplicationService
    ) { super(); }

    /**
     * Return lookup data for the desired type
     * @param source (string) - lookup name
     */
    public loadOptionLookup(source: string): Observable<Lookup[]> {
        const url = this.urlTargetForLookupSource(source);
        let subject = new Subject<Lookup[]>();
        const luUrl = localStorage.getItem(`LU:${url}`);
        if (luUrl) {
            // console.info(`Hey, I've already got a lookup stored from LU:${url}`)
            let array = JSON.parse(luUrl);
            if (array.length != 0) {
                return Observable.create((x: any) => x.next(array))
            }
        }

        this.http
            .get<BaseResponse>(url)
            .subscribe(
                data => {
                    let response = this.getResultData<LookupDataResponse>(data);
                    subject.next(response.data);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Convert from a lookup name to the appropriate url
     * @param source (string) - lookup name
     */
    urlTargetForLookupSource(source: string): string {
        let urlTarget: string;

        switch (source.toLowerCase()) {
            case 'collegesincountry':
                const c_country = this.applicationService.getValue('countryCode');
                urlTarget = `${environment.ServiceUrl_Application_Lookup_CountryDetails(c_country)}/colleges`;
                break;
            case 'country':
                urlTarget = `${environment.ServiceUrl_Application_Lookup}country`;
                break;
            case 'idd':
                urlTarget = `${environment.ServiceUrl_Application_Lookup}idd`;
                break;
            case "j1organisations":
                urlTarget = `${environment.ServiceUrl_Application_Lookup}organisations`;
                break;
            case 'occupation':
                urlTarget = `${environment.ServiceUrl_Application_Lookup}occupation`;
                break;
            case "programmebycountry":
                const p_country = this.applicationService.getValue('countryCode');
                urlTarget = `${environment.ServiceUrl_Application_Lookup_CountryDetails(p_country)}/programmes`;
                break;
            case 'religion':
                urlTarget = `${environment.ServiceUrl_Application_Lookup}religion`;
                break;
            case 'studyarea':
                urlTarget = `${environment.ServiceUrl_Application_Lookup}/studyarea`;
                break;
            default:
                urlTarget = `${environment.ServiceUrl_Application_Lookup}/${source}`;
                break;
        }

        return urlTarget;
    }
}

class LookupDataResponse implements ResponseData {
    data: Lookup[] = [];
}
