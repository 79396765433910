export class TrackerEvent {
    constructor(event: TrackerEventType) {
        this.event = event;
    }

    public event: TrackerEventType;
}

export enum TrackerEventType {
    Initialising,
    TasksLoaded,
    UserStatusUpdateTasksReloaded,
    ActiveTaskChanged,
    TaskStatusUpdateCompleted,
    TaskLoadComplete,
    TaskCompleting
}
