import { AbstractControl, ValidatorFn, NG_VALIDATORS, Validator } from '@angular/forms';

// import * as moment from 'moment-timezone';
import { Directive, Input } from '@angular/core';
import { MetaformDateTime } from '../datetime/metaform-datetime';


export function mustBeAfterMomentValidator(mustBeAfter: any): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
        // moment.tz.setDefault('Europe/London');

        // console.log(`c.value = ${c.value} is after ${mustBeAfter}`);

        // const userEntry = moment.utc(c.value);
        // const checkedFor = moment.utc(mustBeAfter);

        let d = MetaformDateTime.getDateTime(c.value);
        let check = MetaformDateTime.getDateTime(mustBeAfter);

        //console.log(`MustBeAfter: ${checkedFor.toDate()} Value: ${userEntry.toDate()}`);

        const result = d && check && ( d > check); // userEntry.isAfter(checkedFor.format(), 'day');
        //console.log(`Is after? ${result}`);

        return result ? null : {
            minDate: {
                valid: false
            }
        };
    };
}

@Directive({
    selector: '[mustBeAfter]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MustBeAfterMomentValidatorDirective, multi: true }]
})
export class MustBeAfterMomentValidatorDirective implements Validator {
    @Input('mustBeAfter') mustBeAfter: any;

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.mustBeAfter ? mustBeAfterMomentValidator(this.mustBeAfter)(control) : null;
    }
}