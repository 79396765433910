import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CreateApplicationComponent } from './create-application.component';
import { UploadProfilePhotoComponent } from './upload-profile-photo.component';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
    { path: "create-application", component: CreateApplicationComponent },
    { path: 'upload-profile-photo', component: UploadProfilePhotoComponent },
    { path: 'terms-and-conditions/latest/:termsType', component: TermsAndConditionsComponent },
    { path: 'terms-and-conditions/latest/minimum-wage-placement', component: TermsAndConditionsComponent },
    { path: 'profile', component: ProfileComponent }
]

@NgModule({
    imports: [
        RouterModule.forChild(
            routes
        )
    ],
    exports: [
        RouterModule
    ]
})
export class UserRoutingModule { }