import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { DisplayCardItem } from './display-card-item';

@Component({
    selector: 'app-selectable-display-card',
    templateUrl: './selectable-display-card.component.html',
    styleUrls: ['./less/selectable-display-card.component.scss']
})
export class SelectableDisplayCardComponent implements OnInit, OnChanges {

    @Input() items: DisplayCardItem[] = [];
    @Input() multiSelect: boolean = false;
    @Input() autoExpand: boolean = false;

    @Output() itemSelected: EventEmitter<DisplayCardItem> = new EventEmitter();
    @Output() itemDelete: EventEmitter<DisplayCardItem> = new EventEmitter();
    @Output() itemEdit: EventEmitter<DisplayCardItem> = new EventEmitter();
    @Output() buttonPress: EventEmitter<DisplayCardItem> = new EventEmitter();

    constructor() { }

    ngOnInit() {
        if (this.items) {
            for (var i = 0, len = this.items.length; i < len; i++) {
                this.toggled.push(this.autoExpand || this.items[i].toggled);
            }
        }
    }

    ngOnChanges() {
    }

    /**
     * Is the passed item selected?
     * @param item DisplayCardItem - item
     */
    isSelected(item: DisplayCardItem): boolean {
        return item.selected;
    }

    /**
     * Deselect any previous item and select the new item. 
     * @param item  DisplayCardItem - item
     */
    selectItem(item: DisplayCardItem): void {
        let currentSelection = this.items.find(i => i.selected);

        if (currentSelection && currentSelection != item) {
            currentSelection.selected = false;
            this.toggled[currentSelection.index] = false;
        }

        item.selected = !item.selected;
        this.toggled[item.index] = true;

        this.itemSelected.emit(item);
    }

    /**
     * Call the edit item functionality on the host component
     * @param item  DisplayCardItem - item
     */
    toggleItem(item: DisplayCardItem): void {
        // console.log(`Item toggle: ${item.index}`, this.toggled[item.index]);
        if (!this.multiSelect) {
            for (var i = 0; i < this.toggled.length; i++) this.toggled[i] = false;
        }

        this.toggled[item.index] = !this.toggled[item.index];
    }

    isToggled(item: DisplayCardItem): boolean {
        return this.toggled[item.index];
    }

    toggled: boolean[] = [];
}
