import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

import { AuthenticationService } from '../auth/authentication.service';
import { UserPushService } from './user-push.service';

import { UserData, User, ApplicantRole } from '../user/user'
import { TermsAndConditions } from './terms-and-conditions';
import { ApplicantViewPermissions } from './applicant-view-permissions';
//import * as FullStory from '@fullstory/browser';

// TODO(ian): Wrap localStorage in a service?
@Injectable()
export class UserService extends BaseService {

    public constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        public userPush: UserPushService
    ) {
        super();
    }

    login(email: string, password: string): Observable<UserData> {
        let subject = new Subject<UserData>()

        this.http.post<BaseResponse>(environment.ServiceUrl_User_SignIn, { email: email, password: password })
            .subscribe(
                (response: BaseResponse) => {
                    switch (response.resultCode) {
                        case ResponseResultCode.Success:
                            // console.info(`Login, got ${JSON.stringify(response.result)}`);
                            if(this.getUserDataFrom(response)) {
                                subject.next(this.authService.currentUserData!);
                            } else {
                                subject.error(new Error(`User is not permitted for this application`));
                            }
                            // let userData: UserData = this.getResultData<UserData>(response);

                            // // login successful if there's a jwt token in the response
                            // if (userData && userData.token) {
                            //     // console.info(`Got ud.token ${userData.token}`)

                            //     const returnData: UserData = {
                            //         authType: userData.authType,
                            //         token: userData.token,
                            //         user: {
                            //             id: userData.user.id,
                            //             email: userData.user.email,
                            //             firstName: userData.user.firstName,
                            //             middleName: userData.user.middleName,
                            //             lastName: userData.user.lastName,
                            //             sex: userData.user.sex,
                            //             birthDate: userData.user.birthDate,
                            //             countryCode: userData.user.countryCode,
                            //             authenticationToken: userData.user.authenticationToken,
                            //             isApplicant: userData.user.isApplicant,
                            //             isInterviewer: userData.user.isInterviewer,
                            //             isGuest: userData.user.isGuest,
                            //             hasLatestTermsAndConditions: userData.user.hasLatestTermsAndConditions,
                            //             mustChangePassword: userData.user.mustChangePassword,
                            //             changePasswordSource: userData.user.changePasswordSource,
                            //             roles: userData.user.roles,
                            //             applicantRole: null,
                            //             interviewerRole: null,
                            //         }
                            //     };

                            //     if (userData.user.isApplicant) {
                            //         // console.log(`User is Applicant: ${JSON.stringify(userData.user.applicantRole)}`);
                            //         // console.log(`Got an applicant!`);
                            //         returnData.user.applicantRole = this.getApplicantRoleFromUser(userData.user);
                            //         console.log(`applicant: ${JSON.stringify(returnData.user.applicantRole, null, 2)}`);
                            //     } else {
                            //         // console.log(`User is not applicant? ${JSON.stringify(userData.user)}`);
                            //         subject.error( new Error(`User is not permitted for this application`));
                            //     }
                            //     // store user details and jwt token in local storage to keep user logged in between page refreshes
                            //     this.authService.storeUserData(returnData);

                            //     this.userChanged.next(returnData.user);

                            //     subject.next(returnData);
                            // }
                            break;
                        case ResponseResultCode.Error:
                            subject.error(response.error);
                            break;
                        default:
                            console.error(`Woah, shouldn't be here response was ${JSON.stringify(response)}`);
                            break;
                    }
                },
                error => {
                    subject.error(error)
                });

        return subject
    }

    sendResetPassword(email: string): Observable<BaseResponse> {
        return this.http.post<BaseResponse>(environment.ServiceUrl_User_ForgotPassword, { email: email });
    }

    /**
     * Reload the userdata and update localStorage accordingly
     */
    reloadUserData(): Observable<any> {
        let subject = new Subject<any>()
        // console.log(`Reload User Data: User is signed in? ${this.authService.isLoggedIn}`);
        this.http
            .get<BaseResponse>(environment.ServiceUrl_User_Refresh(this.authService.currentUser!.id))
            .subscribe({
                next: (data: BaseResponse) => {
                    // console.info(`RELOAD USER DATA:`, data.result);
                    const response: BaseResponse = data;
                    switch (response.resultCode) {
                        case ResponseResultCode.Success:
                            if (this.getUserDataFrom(response)) {
                                subject.next(null);
                                this.userChanged.next(this.authService.currentUserData!.user);
                            } else {
                                subject.error(new Error(`User is not permitted for this application`));
                            }

                            // let userData: UserData = this.getResultData<UserData>(response);

                            // // login successful if there's a jwt token in the response
                            // if (userData && userData.token) {
                            //     // store user details and jwt token in local storage to keep user logged in between page refreshes
                            //     this.authService.storeUserData(userData);
                            // }
                            // subject.next(null);
                            // this.userChanged.next(userData.user);
                            break;
                        case ResponseResultCode.Error:
                            subject.next({ error: response.error })
                            break;
                    }
                },
                error: (error: any) => {
                    subject.error(error)
                }
            })

        return subject
    }

    signInWithToken(token: string): Observable<TokenSignInDetails> {
        let subject = new Subject<TokenSignInDetails>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_SignInFromToken(token))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<TokenSignInDetails>(data);

                    // console.info(`Got data: ${JSON.stringify(response, null, 2)}`);
                    let userData: UserData = response.user;

                    // If the link has expired, DO NOT sign in.
                    if (!response.hasExpired) {
                        // login successful if there's a jwt token in the response
                        if (userData && userData.token) {
                            // console.info(`Got ud.token ${userData.token}`);
                            // console.info(`ApplicantRole: ${JSON.stringify(userData.user.roles)}`);
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            // localStorage.setItem(this.localStorageUserKey, JSON.stringify(userData));
                            this.authService.storeUserData(userData);

                        }
                    }

                    subject.next(response);

                    if (!response.hasExpired) {
                        this.userChanged.next(userData.user);
                    }
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    /**
     * Check the service if the current email is already registered
     * @param email
     */
    public checkEmail(email: string): Observable<boolean> {
        let subject = new Subject<boolean>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_User_CheckEmailAlreadyRegistered,
                { email })
            .subscribe({
                next: (data: any) => {
                    // console.info(`checkEmail: ${JSON.stringify(data)}`);
                    const response: boolean = data["exists"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            })

        return subject;
    }

    /**
     * Call from either sign-in function, registration function, or
     * first active task
     */
    public userHasSignedIn() {
        let u: User = this.authService.currentUser!;
        if (u) {
            // FullStory.identify(u.id.toString(), {
            //     displayName: u.firstName + ' ' + u.lastName,
            //     email: u.email.toString(),
            //     // Add your own custom user variables here, details at
            //     // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
            //     reviewsWritten_int: 14
            //   });
            // console.log(`User ${u.id} has signed in!`);
            this.userPush.registerForUser(u);

            this.userChanged.next(u);
            // } else {
            //     console.error(`I was told someone had logged in, but the authService doesn't agree`);
        }
    }

    /**
     * Call from sign-out function
     */
    public userHasSignedOut() {
        let u: User = this.authService.currentUser!;

        if (u) {
            this.userChanged.next(null);
            this.userPush.unregisterUser(u);
        }

        // IAS - convenience
        localStorage.removeItem("ap:r");
        sessionStorage.removeItem('vs-p-u');
    }

    /**
     * @description Is the current user (if there is one)
     * an applicant?
     */
    isApplicant(): boolean {
        let u: User = this.authService.currentUser!;
        if (u) {
            return u.roles.some(r => r.role === 'APP')
        } else {
            return false;
        }

    }

    /**
     * @description Is the current user (if there is one)
     * an interviewer
     */
    isInterviewer(): boolean {
        let u: User = this.authService.currentUser!;
        if (u) {
            return u.roles.some(r => r.role === 'INT')
        } else {
            return false;
        }

    }

    /**
     * @description Is the current user (if there is one)
     * a guest (e.g. signed in from a security token
     * )
     */
    isGuest(): boolean {
        let u: User = this.authService.currentUser!;
        if (u) {
            return u.roles.some(r => r.role === 'GST')
        } else {
            return false;
        }

    }

    isPlaced(): boolean {
        const user = this.authService.currentUser!;
        const isPlaced = user.applicantRole!.state === 'ParticipantPlaced';
        return isPlaced;
    }

    isOutOfProgramme(): boolean {
        const oop: string[] = ['ParticipantOutOfProgrammeCNX',
                    'ParticipantOutOfProgrammeEXP',
                    'ParticipantOutOfProgrammeFAI',
                    'ParticipantOutOfProgrammeREJ',
                    'ParticipantOutOfProgrammeTER',
                    'ParticipantOutOfProgrammeUNS']
        return this.isInState(oop);
    }

    isInState(list: string[]): boolean {
        const state = this.authService.currentUser?.applicantRole!.state;
        if(state) {
            if(list.findIndex( l => l == state) > -1) {
                return true;
            }
        }
        return false;
    }

    user(): User {
        return this.authService.currentUser!;
    }

    applicantRole() : ApplicantRole | null {
        const u = this.authService.currentUser;
        if (u) {
            // const role = u.applicantRole;
            return this.getApplicantRoleFromUser(u);

            // return {
            //     applicantId: role.id,
            //     applicationId: role.secondaryId,
            //     state: role.state,
            //     isReturner: role.isReturner,
            //     isRepeat: role.isRepeat,
            //     previousApplicationId: role.previousSecondaryId,
            //     previousState: role.previousState,
            //     nextSeasonApplicationId: role.nextSeasonSecondaryId,
            //     activeApplicationSeason: role.activeApplicationSeason,
            //     nextSeasonState: role.nextSeasonState,
            //     activeApplicationId: role.activeSecondaryId,
            //     activeState: role.activeState,
            //     rolloverStatus: role.rolloverStatus,
            //     isNextSeason: role.activeSecondaryId !== role.secondaryId,
            //     canSelfPropose: role.canSelfPropose
            // };
        } else {
            return null;
        }
    }

    private getUserDataFrom(response: BaseResponse): boolean {
        let userData: UserData = this.getResultData<UserData>(response);

        // login successful if there's a jwt token in the response
        if (userData && userData.token) {
            // console.info(`Got ud.token ${userData.token}`)

            const returnData: UserData = {
                authType: userData.authType,
                token: userData.token,
                user: {
                    id: userData.user.id,
                    email: userData.user.email,
                    firstName: userData.user.firstName,
                    middleName: userData.user.middleName,
                    lastName: userData.user.lastName,
                    sex: userData.user.sex,
                    birthDate: userData.user.birthDate,
                    countryCode: userData.user.countryCode,
                    authenticationToken: userData.user.authenticationToken,
                    isApplicant: userData.user.isApplicant,
                    isInterviewer: userData.user.isInterviewer,
                    isGuest: userData.user.isGuest,
                    hasLatestTermsAndConditions: userData.user.hasLatestTermsAndConditions,
                    mustChangePassword: userData.user.mustChangePassword,
                    mustContactCAUrgently: userData.user.mustContactCAUrgently,
                    changePasswordSource: userData.user.changePasswordSource,
                    roles: userData.user.roles,
                    applicantRole: null,
                    interviewerRole: null,
                }
            };

            if (userData.user.isApplicant) {
                // console.log(`User is Applicant: ${JSON.stringify(userData.user.applicantRole)}`);
                // console.log(`Got an applicant!`);
                returnData.user.applicantRole = this.getApplicantRoleFromUser(userData.user);
                // console.log(`applicant: ${JSON.stringify(returnData.user.applicantRole, null, 2)}`);
            } else {
                return false;
            }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.authService.storeUserData(returnData);

            return true;
        }
        return false;
    }

    private getApplicantRoleFromUser(u: User): ApplicantRole | null {        
        const a = u.roles.find(r => r.role === 'APP') as ApplicantRole;
        if (!a) {
            console.error(`I don't have an applicantRole! User is ${JSON.stringify(u)}`);
            return null;
        }
        return new ApplicantRole(
            a.id,
            a.id,
            true,
            a.secondaryId,
            a.state,
            a.isReturner,
            a.isRepeat,
            a.previousSecondaryId,
            a.previousState,
            a.nextSeasonSecondaryId,
            a.nextSeasonState,
            a.activeSecondaryId,
            a.activeState,
            a.mustContactCAUrgently,
            a.rolloverStatus,
            a.activeApplicationSeason,
            a.canSelfPropose
        );
    }

    interviewerRole() {
        let u: User = this.authService.currentUser!;
        if (u) {
            let role = u.roles.find(r => r.role === 'INT')

            if (!role) {
                return null;
            }

            return {
                interviewerId: role.id
            }
        } else {
            return null;
        }
    }

    guestRole() {
        let u: User = this.authService.currentUser!;
        if (u) {
            let role = u.roles.find(r => r.role === 'GST');
            if (!role) return null;

            return {
                guestId: role.id
            }
        } else {
            return null;
        }
    }

    get currentUserName(): string {
        return this.authService.currentUserName;
    }

    /**
     * Does the logged-in user have an application for the
     * current season yet?
     */
    public userHasApplication(): boolean {
        let u: User = this.authService.currentUser!;
        if (this.applicantRole()) {
            // console.info(`userHasApplication? ${(this.applicantRole().applicationId || 0)}`);
            return (this.applicantRole()!.activeApplicationId || 0) !== 0;
        }

        return false;
    }

    /**
     * Does the logged-in user have the current terms agreed to?
     */
    public hasLatestTermsAndConditions(): boolean {
        let u: User = this.authService.currentUser!;
        if (u && this.applicantRole())
            return u.hasLatestTermsAndConditions;

        // Not an applicant, so until we check for other terms types, return
        // true
        return true;
    }

    /** Does the logged-in user need to update their password to the CA18 format? */
    public mustChangePassword(): boolean {
        let u: User = this.authService.currentUser!;
        if (u && this.applicantRole())
            return u.mustChangePassword;

        // They have the CA18 format password
        return false;
    }

    /** Does the user current appllication require him to contact CA urgently? */
    public mustContactCAUrgently(): boolean {
      let u: User = this.authService.currentUser!;
      if (u && this.applicantRole())
          return u.mustContactCAUrgently;

      // They have the CA18 format password
      return false;
  }

    /**
     * Return the latest public T and Cs
     *
     */
    public getLatestTermsAndConditions(): Observable<TermsAndConditions> {
        let u: User = this.authService.currentUser!;
        let subject = new Subject<TermsAndConditions>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_GetGenericLatestTermsAndConditions)
            .subscribe(
                data => {
                    const response = this.getResultData<TermsAndConditions>(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Return the latest terms and conditions for the passed user
     */
    public getTermsForUser(termsType: string): Observable<TermsAndConditions> {
        let u: User = this.authService.currentUser!;
        let subject = new Subject<TermsAndConditions>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_GetLatestTermsAndConditions(u.id, termsType))
            .subscribe(
                data => {
                    const response = this.getResultData<TermsAndConditions>(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Return the latest public privacy policy
     *
     */
    public getLatestPrivacyPolicy(): Observable<TermsAndConditions> {
        let u: User = this.authService.currentUser!;
        let subject = new Subject<TermsAndConditions>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_GetPrivacyPolicyLatest)
            .subscribe(
                data => {
                    const response = this.getResultData<TermsAndConditions>(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Return the latest minimum wage placement terms and conditions
     * @returns
     */
    public getCurrentMinimumWagePlacementTerms(): Observable<TermsAndConditions> {
        let subject = new Subject<TermsAndConditions>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_GetLatestMWPTermsAndConditions)
            .subscribe(
                data => {
                    const response = this.getResultData<TermsAndConditions>(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Return the agreed terms and conditions for the passed user
     */
    public getAgreedTermsForUser(): Observable<TermsAndConditions> {
        let u: User = this.authService.currentUser!;
        let subject = new Subject<TermsAndConditions>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_GetUserTermsAndConditions(u.id))
            .subscribe(
                data => {
                    const response = this.getResultData<TermsAndConditions>(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Agree to the specified terms for the passed user.
     * @param u (User) - current user
     * @param termsId (number) - the terms to agree to
     */
    public agreeTermsForUser(termsId: number): Observable<boolean> {
        let u: User = this.authService.currentUser!;
        let subject = new Subject<boolean>();

        // console.info(`User ${u.id} is agreeing to terms ${termsId}`);
        this.http
            .post<BaseResponse>(environment.ServiceUrl_AgreeTermsAndConditions(u.id, termsId), null)
            .subscribe(
                data => {
                    let response: boolean = data.resultCode == ResponseResultCode.Success;
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Update the localStorage user once we've agreed terms
     */
    public termsAgreed(): void {
        if (this.authService.currentUserData) {
            let usr = this.authService.currentUser!;
            let ud = this.authService.currentUserData;
            usr.hasLatestTermsAndConditions = true;
            ud.user = usr;
            this.authService.storeUserData(ud);
            this.userChanged.next(usr);
        }
    }

    /**
     * Change the user's sign in email address
     * @param email (String) - new email address
     * @param emailConfirm (String) - confirmation
     */
    public changeSignIn(email: string, emailConfirm: string): Observable<BaseResponse> {
        let u: User = this.authService.currentUser!;
        let subject = new Subject<BaseResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_UserChangeSignIn(u.id),
                {
                    email: email,
                    emailConfirmation: emailConfirm
                })
            .subscribe(
                data => {
                    subject.next(data);
                },
                error => {
                    subject.error(error);
                });

        return subject;
    }

    /**
     * @description Is the password present in any breach record?
     * @param password (String) - user's password
     */
    public checkPasswordForBreaches(password: string): Observable<BreachBaseResponse> {
        var sha1Password = password;

        return this.http
            .post<BreachBaseResponse>(
                environment.ServiceUrl_UserCheckPassword,
                {
                    sha1: sha1Password
                });
    }

    /**
     * Change the user's password
     * @param old (String) - old password for confirmation
     * @param password (String) - new email
     * @param confirm (String) - email confirmation
     */
    public changePassword(old: string, password: string, confirm: string): Observable<BaseResponse> {
        let u: User = this.authService.currentUser!;
        let subject = new Subject<BaseResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_UserChangePassword(u.id),
                {
                    password: old,
                    newPassword: password,
                    passwordConfirmation: confirm
                })
            .subscribe(
                data => {
                    subject.next(data);
                },
                error => {
                    subject.error(error);
                });

        return subject;
    }

    public getApplicantViewPermissions(): Observable<ApplicantViewPermissions> {
        let subject = new Subject<ApplicantViewPermissions>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_GetApplicantViewPermissions())
            .subscribe(
                data => {
                    const response = this.getResultData<ApplicantViewPermissions>(data);
                    subject.next(response);
                },
                error => {
                    subject.error(error);
                }
            )

        return subject;
    }

    /**
     * Login as applicant in applicant-view process     
     */
     public loginAsApplicant(token: string): Observable<UserData> {
        let subject = new Subject<UserData>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_User_ApplicantView_Login,
                {
                    token: token
                })
            .subscribe(
                (response: BaseResponse) => {
                    switch (response.resultCode) {
                        case ResponseResultCode.Success:
                            // console.info(`Login, got ${JSON.stringify(response.result)}`);
                            if(this.getUserDataFrom(response)) {
                                subject.next(this.authService.currentUserData!);
                            } else {
                                subject.error(new Error(`User is not permitted for this application`));
                            }
                        }
                },
                error => {
                    subject.error(error);
                });

        return subject;
    }

    /**
     * Reset the user's password
     * @param password (String) - new email
     * @param confirm (String) - email confirmation
     */
    public resetPassword(password: string, confirm: string): Observable<BaseResponse> {
        let u: User = this.authService.currentUser!;

        return this.http
            .post<BaseResponse>(environment.ServiceUrl_UserResetPassword(u.id),
                {
                    newPassword: password,
                    passwordConfirmation: confirm
                });
    }

    /**
     * @description retun a valid system alert
     */
    public getSystemAlert(): Observable<SystemAlertResponse> {
        return this.http.get<SystemAlertResponse>(environment.ServiceUrl_UserSystemAlert);
    }

    userChanged: Subject<User | null> = new Subject<User | null>();
}

export class TokenSignInDetails implements ResponseData {
    invalid!: boolean;
    tokenId!: number;
    hasExpired!: boolean;
    type!: number;
    user!: UserData;
}

export class BreachResponse {
    isBreached!: boolean;
    count!: number;
}

export class BreachBaseResponse implements ResponseData {
    result!: BreachResponse;
}

export class SystemAlertResponse implements ResponseData {
    result!: SystemAlert;
}

export class SystemAlert {
    alertPresent!: boolean;
    message!: string;
    url!: string;
    until!: Date;
}
