import { ResponseData } from '../common/response-data'

export interface UserData extends ResponseData {
    authType: string
    user: User
    token: string
}

export interface User {
    id: number
    email: string

    firstName: string
    middleName: string
    lastName: string
    sex: string
    birthDate: Date

    // Country of application; used almost everywhere
    countryCode: string

    // Authentication Token
    authenticationToken: string

    roles: Role[]

    isApplicant: boolean
    isInterviewer: boolean;
    isGuest: boolean;
    applicantRole?: ApplicantRole | null;

    interviewerRole?: { interviewerId: number } | null;

    hasLatestTermsAndConditions: boolean;
    mustChangePassword: boolean;
    mustContactCAUrgently: boolean;
    changePasswordSource: number;
}

// export interface Role {
//     role: string
//     id: number
//     secondaryId?: number
//     isDefault: boolean
//     state?: string;
//     isReturner?: boolean;
//     isRepeat?: boolean;
//     previousSecondaryId?: number;
//     previousState?: string;
//     nextSeasonSecondaryId?: number;
//     nextSeasonState?: string;
//     activeSecondaryId?: number;
//     activeState?: string;
//     rolloverStatus?: string;
//     activeApplicationSeason?: number;
//     canSelfPropose?: boolean;
// }

export interface Role {
    role: string
    id: number
    secondaryId?: number
    isDefault: boolean
}

export class ApplicantRole implements Role {
    role: string = 'APP';
    id: number = 0;
    secondaryId?: number
    isDefault: boolean = true;

    applicantId: number = 0;
    applicationId?: number;
    state?: string;
    isReturner?: boolean;
    isRepeat?: boolean;
    previousSecondaryId?: number;
    previousState?: string;
    nextSeasonSecondaryId?: number;
    nextSeasonState?: string;
    activeSecondaryId?: number;
    activeState?: string;
    mustContactCAUrgently?: boolean;
    rolloverStatus?: string;
    activeApplicationSeason?: number;
    activeApplicationId?: number;
    previousApplicationId?: number;
    isNextSeason?: boolean;
    canSelfPropose?: boolean;
    orcaOnHold: any

    constructor(id: number,
        applicantId: number,
        isDefault: boolean,
        secondaryId?: number,
        state?: string,
        isReturner?: boolean,
        isRepeat?: boolean,
        previousSecondaryId?: number,
        previousState?: string,
        nextSeasonSecondaryId?: number,
        nextSeasonState?: string,
        activeSecondaryId?: number,
        activeState?: string,
        mustContactCAUrgently?: boolean,
        rolloverStatus?: string,
        activeApplicationSeason?: number,
        canSelfPropose?: boolean)
        {
            this.id = id;
            this.applicantId = applicantId;
            this.isDefault = isDefault;
            this.secondaryId = secondaryId;
            this.applicationId = secondaryId;
            this.state = state;
            this.isReturner = isReturner;
            this.isRepeat = isRepeat;
            this.previousSecondaryId = previousSecondaryId
            this.previousState = previousState;
            this.nextSeasonSecondaryId = nextSeasonSecondaryId;
            this.nextSeasonState = nextSeasonState;
            this.activeSecondaryId = activeSecondaryId;
            this.activeState = activeState;
            this.mustContactCAUrgently = mustContactCAUrgently;
            this.rolloverStatus = rolloverStatus;
            this.activeApplicationSeason = activeApplicationSeason;
            this.activeApplicationId = activeSecondaryId;
            this.isNextSeason = activeSecondaryId !== secondaryId;
            this.canSelfPropose = canSelfPropose;
            this.previousApplicationId = previousSecondaryId;

            // console.log(`ActiveData (id, season, state): ${this.activeApplicationId}, ${this.activeApplicationSeason}, ${this.activeState}`);
        }
}
