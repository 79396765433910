import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Observable, Subscription } from 'rxjs'

import { ApplicationService } from '../application/application.service';
import { IBusinessRuleData } from '../rule/business-rule';

import { TrackerTaskComponent } from '../tracker/tracker-task.component';

import { TrackerService } from '../tracker/tracker.service';
import { TaskIntroTemplate, TaskStatus } from '../tracker/task';
import { TrackerEvent, TrackerEventType } from './tracker-event';
import { TaskIntro } from './task-intro';

@Component({
    templateUrl: './task-intro.component.html',
    styleUrls: ['./less/task-intro.component.scss']
})

export class TaskIntroComponent extends TrackerTaskComponent {
    constructor(
        route: ActivatedRoute,
        router: Router,
        applicationService: ApplicationService,
        tracker: TrackerService
    ) {
        super(router, tracker);
        // console.log(`TaskIntroComponent!`);
    }

    override ngOnInit() {
        super.ngOnInit();
        this.initialise();
    }

    override ngOnDestroy() {
        super.ngOnInit();
    }

    override initialise(): void {
        // console.log(`IntroComponent:Initialise`);

        this.taskDataLoaded = false;
        this.previousButtonEnabled = true;

        var path = window.location.pathname;
        // this.task = this.tracker.taskByPathName(path);
        // this.tracker.activeTask = this.task;

        this.task = this.tracker.activeTask;

        if (!this.task) {
            console.error(`I should have a task defined by the pathName ${path} - please check whether this is the case!`);

            if (!this.task) {
                // console.log(`No task for component found, redirecting!`);
                this.router.navigateByUrl(this.tracker.homeRoute);
            }
        } else {
            // console.info(`TaskIntroComponent: Got task ${this.task.name}`);
            this.sequenceTitle = this.task.sequence!.title;

            // console.info(`TaskIntroComponent:finding task? ${this.task.name}`);
            this.displayTaskIntro();

            // this.tracker.calculateCurrentProgress();
        }
    }

    displayTaskIntro() {
        if (this.task && this.task.introTemplate !== TaskIntroTemplate.None) {
            this.taskDataLoaded = true;
            this.task.taskStatus = TaskStatus.Intro;
            //console.info(`Initialise: Active: ${this.task.name} with status ${this.task.taskStatus}`);
            this.sequenceTitle = this.task.sequence!.title;
            this.title = this.task.title;

            const ti: TaskIntro | undefined = this.task.intro; //this.tracker.taskIntroByTask(this.task);
            if( ti ) {
                this.bodyText = ti!.bodyText;
                this.imageUrl = ti!.image;
                this.footerText = ti?.footerText;

                // Hey, we're just the intro!
                this.setNextEnabled(true);;
            }
        } else {
            console.warn(`Active Task is not set, or it does not have an intro template! Navigate to ${this.tracker.homeRoute}`);
            this.router.navigateByUrl(this.tracker.homeRoute);
        }
    }

    override stepNext(): void {
        //this.tracker.startTask(this.task);
        if (this.task) this.router.navigateByUrl(this.task.routerUrl!);
    }

    sequencetitle: string = '';
    title: string = '';
    bodyText?: string;
    imageUrl?: string;
    footerText?: string;
}