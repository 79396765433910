import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMenuComponent } from './user-menu.component';
import { TrustboxModule } from '@aifs-shared/trustbox/trustbox.module';

@NgModule({
    imports: [
        CommonModule,
        TrustboxModule,
    ],
    declarations: [UserMenuComponent],
    exports: [UserMenuComponent]
})
export class UserMenuModule { }
