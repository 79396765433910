import { Component, OnInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { Metaform, MfQuestion, Question, MfValueChangeEvent } from '@aifs-shared/metaform/metaform';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';

import { Lookup } from '@aifs-shared/lookup/lookup';

import { ErrorField } from '@aifs-shared/metaform/metaform-question-display.component';

// import * as moment from 'moment-timezone';
import { MetaformDateTime } from '@aifs-shared/metaform/datetime/metaform-datetime';
import { IMfDateModel } from '@aifs-shared/metaform/metaform';
import { DateTime } from 'luxon';

@Component({
    // moduleId: module.id,
    selector: 'mf-dmy',
    templateUrl: './date.component.html',
    styleUrls: ['../../less/metaform-question-display.component.scss']
})
export class DateComponent implements OnInit, DoCheck {
    @Input() readonly: boolean = false;
    @Input() metaform!: Metaform;
    @Input() parent!: MfQuestion;
    @Input() questionItem!: Question<any>;
    @Input() questionName!: string;
    @Input() formGroup!: FormGroup;
    @Input() dataProvider!: MetaformDataProvider;
    @Input() validationErrorMessages!: Map<string, string>;
    @Output() valueChanged = new EventEmitter<MfValueChangeEvent>();

    constructor() { }

    ngOnInit() {
        // moment.tz.setDefault('Europe/London');

        this.questionItemKey = this.questionItem.key;
        this.initialiseDateControl();
        this.canDisplayError = true;

        // For composite controls, we can only display the error message
        // if this item is the first in the targetFields list
        if (this.parent && this.parent.items && this.parent.items[0].targetFields) {
            this.canDisplayError = (this.parent.items[0].targetFields[0] == this.questionItemKey);
            if (this.canDisplayError) {
                // // console.info(`${this.questionItemKey} is the first item in a composite field, or is a sole field`);
                this.canDisplayError = true;
            } else {
                // // console.info(`${this.questionItemKey} is not able to display it's own errors`);
            }
        }

        this.updateValidity.subscribe((error) => {
            if (error) {
                if (this.errorMessages == null || this.errorMessages !== error.message) {
                    // console.log(`${this.questionItem.key} - ${this.errorMessages} : Got message : ${error.message} for field ${error.questionItem}`, error);
                    this.errorMessages = error.message;
                }
            } else {
                this.errorMessages = undefined;
            }
        });

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                this.formGroup.get(field)!.valueChanges.subscribe(data => {
                    this.valueChanged.emit(new MfValueChangeEvent(field, data));
                });
            });
        } else {
            this.formGroup.get(this.questionItemKey)!.valueChanges.subscribe(data => {
                this.valueChanged.emit(new MfValueChangeEvent(this.questionItemKey, data));
            });
        }

        this.formGroup.get(this.questionItemKey)!.markAsPristine();

        this.formGroup.updateValueAndValidity();
    }

    ngDoCheck() {
        let valid: boolean = true;

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                if ((this.formGroup.controls[field].touched)) {
                    // // console.info(`Checking: ${field}`);
                    valid = valid && this.formGroup.controls[field].valid;

                    this.questionItem.errorField = undefined;

                    this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
                }
            });
        } else {
            if (this.formGroup.controls[this.questionItemKey].touched) {
                valid = this.formGroup.controls[this.questionItemKey].valid;
                this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
            }
        }

        if (this.isValid != valid)
            // console.info(`Change in validity: ${valid}`);

            this.isValid = valid;
    }

    get formControl() {
        return this.formGroup.controls[this.questionItemKey];
    }

    formControlFor(targetIndex: 0) {
        return this.formGroup.controls[this.questionItem.targetFields[targetIndex]];
    }

    // get isValid() {


    //     return valid;
    // }

    get controlMaximumInput() {
        if (this.questionItem.maxLength) {
            return this.questionItem.maxLength;
        } else {
            return 0;
        }
    }

    get hasLabel() { return this.questionItem.label !== undefined && this.questionItem.label !== null; }

    /**
     * Get all possible failing validation messages for this field
     */
    getValidationMessages(question: string): ErrorField | null {

        if (this.questionItem.targetFields) {
            if (this.questionItem.errorField && this.questionItem.currentError) {
                // console.log(`already have an error: ${this.questionItem.errorField} (${this.questionItem.currentError}), aborting`);
                return null;
            }

            for (let i = 0; i < this.questionItem.targetFields.length; i++) {
                // // console.warn(`Checking ${this.questionItem.targetFields[i]}`);
                if (this.formGroup.controls[this.questionItem.targetFields[i]].touched && !this.formGroup.controls[this.questionItem.targetFields[i]].valid) {
                    let message = this.validationMessageForItem(this.questionItem.targetFields[i]);
                    // Early exit?
                    if (message) {
                        // // console.info(`Got error for ${this.questionItem.targetFields[i]}: '${message}'`)
                        this.questionItem.errorField = this.questionItem.targetFields[i];
                        this.questionItem.currentError = message;
                        return new ErrorField(this.questionItem.targetFields[i], message);
                    }
                } else {
                    this.questionItem.errorField = undefined;
                    this.questionItem.currentError = undefined;
                }
            }
            this.questionItem.errorField = undefined;
        } else {
            if (this.formGroup.controls[this.questionItem.key].touched && !this.formGroup.controls[this.questionItem.key].valid) {
                let message = this.validationMessageForItem(question);
                if (message) {
                    // console.log(`Got message: ${message}`);
                    this.questionItem.errorField = this.questionItem.key;
                    this.questionItem.currentError = message;
                    return new ErrorField(this.questionItem.key, message);
                }
            }
        }

        // No errors
        return null;
    }

    public isMandatory(key: string): boolean {
        return (this.questionItem.required);
    }

    validationMessageForItem(item: string): string | null {
        const errors = this.formGroup.get(item)?.errors;
        if (errors) {
            // console.info(`getValidationMessages: for question ${item}, errors: ${JSON.stringify(errors)}`)

            let keys = [];
            let message = null;
            for (let key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const msg = (this.validationErrorMessages.get(`${item}_${key}`));
                    if (msg) return msg;
                }
            }
        }
        return null;
    }

    initialiseDateControl() {
        const result = MetaformDateTime.getFromString(this.questionItem.value);
        const dateValue = result?.full;
        const date = DateTime.utc();

        // console.log(`QuestionItem: ${this.questionItem.value}, Date: ${dateValue}`);

        if (this.questionItem.value && result?.month && result?.day && result?.year) {
            // mc = moment(this.questionItem.value);
            this.dateModel.month = +(result.month); //   mc.month() + 1;
            this.dateModel.day = +(result.day); // mc.date();
            this.dateModel.year = +(result.year); // mc.year();
        } else {

            this.dateModel.month = undefined;
            this.dateModel.day = undefined;
            this.dateModel.year = undefined;
        }

        this.dateModel.minDate = MetaformDateTime.getDateTime(`${+date!.year - 50}-01-01`);
        this.dateModel.maxDate = MetaformDateTime.getDateTime(`${+date!.year + 5}-12-31`);
        
        const minDate = MetaformDateTime.dateFromSetting(this.questionItem.min || '');
        if (this.questionItem.min && !isNaN(Date.parse(this.questionItem.min))) { this.dateModel.minDate = minDate; }
        if (this.questionItem.max) { this.dateModel.maxDate = MetaformDateTime.dateFromSetting(this.questionItem.max); }

        // console.debug(`max/min set: ${this.dateModel.maxDate}/${this.dateModel.minDate}`);

        // If we don't already have a value, set our current year to the specified max
        // if (!this.questionItem.value) { this.dateModel.year = this.dateModel.maxDate.getFullYear(); }

        // console.info(`GOT DATE RANGES:(${minDate}) ${this.dateModel.minDate} - ${this.dateModel.maxDate}`)

        this.dateModel.years.push(new Lookup('', 'Year'));

        // TODO(ian): question must specify future date or past date, or bounds
        if (this.dateModel.maxDate && this.dateModel.minDate) {
            for (let y = this.dateModel.maxDate.year; y >= this.dateModel.minDate.year; y--) {
                this.dateModel.years.push(new Lookup(`${y}`, `${y}`));
            }
        } else {
            console.warn(`No valid dates for either min or max date`);
        }

        // console.log(`Got dateModel: ${this.dateModel.year}-${this.dateModel.month}-${this.dateModel.day}`);

        this.populateValidDateRanges();
        if (this.questionItem.value) {
            // Ensure validation/change updates occur
            this.calculateAsDate();
        }
    }

    isDaySelected(day: number | string) {
        return day == this.dateModel.day;
    }

    isMonthSelected(monthCode: number | string) {
        return monthCode == this.dateModel.month;
    }

    isYearSelected(year: number | string) {
        return year == this.dateModel.year;
    }

    selectDayOption(event: any) {
        if (event.target.value == '') {
            this.dateModel.day = undefined;
        } else {
            this.dateModel.day = event.target.value;
        }
        this.formGroup.get(this.questionItemKey)!.markAsTouched();
        this.formGroup.get(this.questionItemKey)!.markAsDirty();
        this.calculateAsDate();
    }

    selectMonthOption(event: any) {
        if (event.target.value == '') {
            this.dateModel.month = undefined;
        } else {
            this.dateModel.month = event.target.value;
        }
        if (this.dateModel.month && this.dateModel.year) {
            let newMaxDaysInMonth = this.daysInMonth(this.dateModel.month, this.dateModel.year);
            this.populateValidDateRanges();
            if (this.dateModel.day) {
                if (newMaxDaysInMonth < this.dateModel.day) {
                    this.dateModel.day = newMaxDaysInMonth;
                }
            }
        }
        this.formGroup.get(this.questionItemKey)!.markAsTouched();
        this.formGroup.get(this.questionItemKey)!.markAsDirty();
        this.calculateAsDate();
    }

    selectYearOption(event: any) {
        if (event.target.value == '') {
            this.dateModel.year = undefined;
        } else {
            this.dateModel.year = event.target.value;
        }
        if (this.dateModel.month && this.dateModel.year) {
            let newMaxDaysInMonth = this.daysInMonth(this.dateModel.month, this.dateModel.year);
            this.populateValidDateRanges();
            
            if (this.dateModel.day) {
                if (newMaxDaysInMonth < this.dateModel.day) {
                    this.dateModel.day = newMaxDaysInMonth;
                }
            }
        }
        this.formGroup.get(this.questionItemKey)!.markAsTouched();
        this.formGroup.get(this.questionItemKey)!.markAsDirty();
        this.calculateAsDate();
    }

    calculateAsDate() {
        if (!this.dateModel.year || !this.dateModel.month || !this.dateModel.day) {
            //this.formGroup.get(this.questionItemKey)!.setValue(undefined);
            this.updateValue(this.questionItemKey, undefined);
            // console.log(`early exit`);
            return;
        }
        //const date = MetaformDateTime.getFrom({ year: this.dateModel.year, month: this.dateModel.month, day: this.dateModel.day });
        const date = MetaformDateTime.getISODateFromParts(this.dateModel.year, this.dateModel.month, this.dateModel.day);
        // console.log(`${this.questionItemKey}: ${date}`);

        // // console.log(`trying to set moment output: ${this.dateModel.year}-${this.dateModel.month}-${this.dateModel.day}`);
        // // Fucking idiotic month calculation. If months are zero-based, why
        // // not fucking days as well?
        // const d = moment.utc(`${this.dateModel.day}/${this.dateModel.month}/${this.dateModel.year}T00:00:00`, 'D/M/YYYYTHH:mm:ss', true);
        // console.debug(`got moment output: ${d.format()}`);

        //this.formGroup.get(this.questionItemKey)!.setValue(date);
        //this.formGroup.markAsTouched();
        //this.formGroup.updateValueAndValidity();
        this.updateValue(this.questionItemKey, date);

        // console.log(`Date Changed ${this.formGroup.get(this.questionItemKey)!.value}`);
    }

    isInvalidDate(): boolean {
        //// console.log(`isInvalid? ${this.formGroup.get(this.questionItemKey)!.value}`);
        const invalid = this.formGroup.get(this.questionItemKey)!.dirty && this.questionItem.required && isNaN(this.formGroup.get(this.questionItemKey)!.value);
        //// console.log(`isInvalid? ${invalid}`);
        return invalid;
    }

    populateValidDateRanges() {
        let maxDays = 31;

        if (this.dateModel.month  && this.dateModel.year) {
            maxDays = this.daysInMonth(this.dateModel.month, this.dateModel.year);

            // So that people can choose a day before selecting 
            // any other options prior to validity
            if (isNaN(maxDays))
                maxDays = 31;
        }

        this.dateModel.daysInMonth = [];
        this.dateModel.daysInMonth.push(new Lookup('', 'Day'));
        for (let d = 1; d <= maxDays; d++) {
            this.dateModel.daysInMonth.push(new Lookup(`${d}`, `${d}`));
        }
    }

    daysInMonth(month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }

    updateValue(key: string, value: any) {
        // console.log(`updateValue: ${value}`);
        const current = this.formGroup.controls[key].value;
        if( current !== value) {
            this.formGroup.controls[key].setValue(value);
            this.valueChanged.emit(new MfValueChangeEvent(key, value));
            this.formGroup.markAsTouched();
        }
        this.formGroup.updateValueAndValidity();
        // console.log(`Got dateModel: ${this.dateModel.year}-${this.dateModel.month}-${this.dateModel.day}`);
    }

    keyForDateDay(key: string) {
        return `${key}_dd`;
    }

    keyForDateMonth(key: string) {
        return `${key}_mm`;
    }

    keyForDateYear(key: string) {
        return `${key}_yy`;
    }

    // NOTE(Ian): Yes, hacky.
    dateModel: IMfDateModel = {
        day: 0,
        month: 0,
        year: 0,
        hour: 0,
        minute: 0,
        daysInMonth: [],
        months:
            [
                { code: '', description: "Month" },
                { code: '1', description: "January" },
                { code: '2', description: "February" },
                { code: '3', description: "March" },
                { code: '4', description: "April" },
                { code: '5', description: "May" },
                { code: '6', description: "June" },
                { code: '7', description: "July" },
                { code: '8', description: "August" },
                { code: '9', description: "September" },
                { code: '10', description: "October" },
                { code: '11', description: "November" },
                { code: '12', description: "December" }
            ],
        years: [],
        hours: [],
        minutes: [],
        minDate: undefined,
        maxDate: undefined
    };

    canDisplayError?: boolean;

    isValid: boolean = true;
    questionItemKey!: string;
    errorMessages?: string;

    updateValidity: BehaviorSubject<ErrorField | null> = new BehaviorSubject<ErrorField | null>(null);
}
