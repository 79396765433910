<div *ngIf="hasItems || isAwaitingReferences; else noItems">
    <h4>Next steps</h4>
    <p>If you do one thing today...</p>
    <div *ngIf="isAwaitingReferences" class="selectable-item bold-item todo-button" role="button"
        (click)="referenceHelp()">
        <img src="assets/img/icons/todo_icon.png"><span>Awaiting References</span>
    </div>
    <div *ngFor="let item of displayItems" class="selectable-item bold-item todo-button" role="button" (click)="editItem(item)">
        <img src="assets/img/icons/todo_icon.png"><span>{{item.title}}</span></div>
</div>
<ng-template #noItems>
    <h4>Next steps</h4>
    <div class="">
        <p>You’re up to date! Keep checking back for your ‘next steps’ on your application!</p>
    </div>
</ng-template>