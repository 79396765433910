import { Component, ViewChild } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { FormGroup } from '@angular/forms'

import { Observable, Subscription, EMPTY } from 'rxjs'

import { UserService } from '../shared/user/user.service';
import { AuthenticationService } from '../shared/auth/authentication.service';
import { ApplicationService } from '../shared/application/application.service';

import { TrackerService } from '../shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { TrackerEvent } from '../shared/tracker/tracker-event';

import { Lookup } from '../shared/lookup/lookup';

import { Metaform } from '../shared/metaform/metaform';
import { MetaformViewerComponent } from '../shared/metaform/metaform-viewer.component';
import { MetaformDataProvider } from '../shared/metaform/metaform-data-provider';

import { ReferenceService, SuggestedReferee, UpdateRefereeDetailsResponse } from './reference.service';
import { RefereeDetails, ReferenceType } from './referee-details';
import { DateTime } from 'luxon';

@Component({
    templateUrl: './add-reference.component.html',
    styleUrls: ['./less/add-reference.component.scss']
})
export class AddReferenceComponent extends TrackerTaskComponent implements MetaformDataProvider {

    @ViewChild(MetaformViewerComponent) formViewer!: MetaformViewerComponent

    private companyName = '';
    private validFrom = '';
    private validTo = '';

    constructor(
        router: Router,
        tracker: TrackerService,
        route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        private application: ApplicationService,
        private referenceService: ReferenceService
    ) {
        super(router, tracker);
    }


    override ngOnInit() {
        super.ngOnInit();

        this.title = "Referee";

        if (this.tracker.getTaskStorage("reference")) {
            this.refereeDetails = <RefereeDetails>this.tracker.getTaskStorage("reference")
            this.referenceId = this.refereeDetails.id;
            // console.log(`Got id ${this.referenceId}`);
            this.setValues(this.refereeDetails);
            // this.previousButtonEnabled = true;
        } else {
            this.clearValues();
            this.referenceId = -1;

            // // Adding, but if we came from the review page, we should be able
            // // to return to it
            // if (this.tracker.getTaskStorage("reference_add")) {
            //     this.previousButtonEnabled = true;
            // }

        }

        const refSource = this.tracker.getTaskStorage("referenceSource");
        if (refSource) {
            //console.log(`Have a reference source! ${JSON.stringify(refSource, null, 2)}`);
            this.referenceSource = <SuggestedReferee>refSource;
            // CA-2688 if source is college and referenceName is 'Other', use
            // referenceNameOther. Plus also 'otra'
            if (this.referenceSource.referenceType === 'CE') {
                let name = this.referenceSource.referenceName;
                console.log(`Reference Name: ${name.trim().toLowerCase().substring(0, 5)}`);
                if (
                    (name.trim().toLowerCase().substring(0, 5) == 'other' ||
                        name.trim().toLowerCase().substring(0, 4) == 'otra'
                    ) && this.referenceSource.referenceNameOther) {
                    this.companyName = this.referenceSource.referenceNameOther;
                } else {
                    this.companyName = this.referenceSource.referenceName.trim();    
                }
            } else {
                this.companyName = this.referenceSource.referenceName ? this.referenceSource.referenceName : this.referenceSource.referenceNameOther;
            }
            this.validFrom = this.referenceSource.dateFrom || '';

            // CA-2242 - check for future date
            const to = this.referenceSource.dateTo;
            if( to ) {
                // Is it in the future
                const dateTo = DateTime.fromISO(to);
                if( dateTo < DateTime.now() ) {
                    // console.log(`Setting dateTo : ${dateTo}`);
                    this.validTo = to;
                }
            }
            this.application.setValue("companyName", this.companyName);
            this.application.setValue("validFrom", this.validFrom);
            this.application.setValue("validTo", this.validTo);

            this.refereeDetails.suggestedFrom = this.referenceSource;
            //console.log(`Referee Details: ${JSON.stringify(this.refereeDetails, null, 2)}`);
        }

        const role = this.userService.applicantRole();
        if(role) {
            this.isReturner = role.isReturner!;
            this.isRepeat = role.isRepeat!;
        }
        this.previousButtonEnabled = true;

        this.subscription = this.tracker.trackerEventStream$
            .subscribe(event => this.taskServiceEvent(event));
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    override initialise(): void {
        super.initialise();
    }

    override stepNext(): void {
        const applicantId: number = this.userService.applicantRole()!.applicantId!;
        this.formPage++;

        let res = this.formViewer.stepNext();
        if (res.noMoreSteps) {
            // console.info(`Form completed: ${JSON.stringify(this.refereeDetails, null, 2)}`);

            if (this.refereeDetails.validTo == '' || !this.refereeDetails.validTo) {
                this.refereeDetails.currentlyPresent = true;
            }

            // Check for already existing imageId
            if (this.tracker.getTaskStorage("reference") !== null) {
                const rd = this.tracker.getTaskStorage("reference");
                //console.log(`Got RD: ${JSON.stringify(rd, null, 2)}`);

                this.refereeDetails.imageId = rd.imageId;
            }

            // Save first, then afterwards check the 'speaks english thing'
            this.referenceService
                .updateRefereeDetails(this.refereeDetails)
                .subscribe({
                    next: (d: UpdateRefereeDetailsResponse) => {
                        this.refereeDetails = d.details;

                        // CAT-190, removed speaksEnglish; everyone now
                        // completes online form
                        // const speaksEnglish = this.refereeDetails.speaksEnglish === 'Y';

                        // if (!speaksEnglish) {
                        //     // We must get a certificate uploaded 
                        //     this.tracker.setTaskStorage("reference", this.refereeDetails);
                        //     this.router.navigateByUrl("/references/upload");
                        // } else {
                            // We can route back to the review page
                            this.tracker.setTaskStorage("reference", undefined);
                            this.router.navigateByUrl("/references/review");
                        // }
                    },
                        error: (error: any) => { this.pageError(error); }
                });
        }
    }

    override stepPrevious(): boolean {
        //console.log(`stepPrevious: formPage = ${this.formPage}`);
        this.formPage--;
        //console.log(`formPage: ${this.formPage}`)
        if (this.formPage == 0) {
            //console.log(`Going back to first state`);
            this.router.navigateByUrl("/references/add-referee");
        } else {
            let res = this.formViewer.stepPrevious();
            if (res.atStart) {
                this.router.navigateByUrl("/references/review");
            }
        }
        return true;
    }

    /* MetaformDataProvider Interface */

    initialiseCallbackProvider(form: Metaform, formGroup: FormGroup) {
        // For data retention, probably. 
        formGroup.valueChanges.subscribe(data => this.onValueChanged(formGroup, data));
    }
    loadLookup(form: Metaform, formGroup: FormGroup, source: string): Observable<Lookup[]> {
        switch (source) {
            case "provide:telephoneIdd":
                return this.referenceService.getTelephoneIddList();
        }
        return EMPTY;
    }
    displayIf(form: Metaform, formGroup: FormGroup, source: string): boolean {
        // switch (source) {
        //     case "RefereeStillKnown":
        //         const fc = formGroup.get("currentlyPresent");
        //         const valid: boolean = (fc.value || '') == 'N';
        //         this.refereeStillKnown = valid;

        //         return valid;
        // }
        return true;
    }
    lookupLoaded(form: Metaform, formGroup: FormGroup, questionKey: string, options: any[]) { }
    formLoaded(form: Metaform, formGroup: FormGroup): { preventDisplay: boolean } {
        return { preventDisplay: false };
    }

    /* MetaformDataProvider Interface */

    onValueChanged(formGroup: FormGroup, data?: any) {
        if (!formGroup) { return; }

        // this.changeRef.detach();
        const form = formGroup;

        // Use spread operator (...) to merge incoming data with what we 
        // already have
        this.refereeDetails = { ...this.refereeDetails, ...data };

        // console.info(`Referee Details: ${JSON.stringify(this.refereeDetails)}`);

        this.setNextEnabled(form.valid);
        // this.changeRef.reattach();
    }

    setValues(rd: RefereeDetails): void {
        // console.log(`SetValues: ${JSON.stringify(rd, null,2)}`);
        this.application.setValue("id", rd.id);
        this.application.setValue("firstName", rd.firstName);
        this.application.setValue("lastName", rd.lastName);
        this.application.setValue("position", rd.position);
        this.application.setValue("emailAddress", rd.emailAddress);
        this.application.setValue("emailAddressConfirmation", rd.emailAddress);
        this.application.setValue("areaCode", rd.areaCode);
        this.application.setValue("telephoneNumber", rd.telephoneNumber);
        // this.application.setValue("speaksEnglish", rd.referenceType == ReferenceType.Online ? "Y" : "N");
        this.application.setValue("currentlyPresent", rd.currentlyPresent);
        this.application.setValue("companyName", rd.companyName);
        this.application.setValue("validFrom", rd.validFrom);
        this.application.setValue("validTo", rd.validTo);
        this.application.setValue("organisationWebsite", rd.organisationWebsite);
        this.application.setValue("addressLine1", rd.addressLine1);
        this.application.setValue("addressLine2", rd.addressLine2);
        this.application.setValue("addressLine3", rd.addressLine3);
        this.application.setValue("addressLine4", rd.addressLine4);
        this.application.setValue("addressLine5", rd.addressLine5);
        this.application.setValue("addressCountryCode", rd.addressCountryCode);
        this.application.setValue("addressPostalCode", rd.addressPostalCode);
    }

    clearValues(): void {
        this.application.setValue("id", null);
        this.application.setValue("firstName", null);
        this.application.setValue("lastName", null);
        this.application.setValue("position", null);
        this.application.setValue("emailAddress", null);
        this.application.setValue("emailAddressConfirmation", null);
        this.application.setValue("areaCode", null);
        this.application.setValue("telephoneNumber", null);
        // this.application.setValue("speaksEnglish", null);
        this.application.setValue("validFrom", null);
        this.application.setValue("currentlyPresent", null);
        this.application.setValue("validTo", null);
        this.application.setValue("companyName", null);
        this.application.setValue("organisationWebsite", null);
        this.application.setValue("addressLine1", null);
        this.application.setValue("addressLine2", null);
        this.application.setValue("addressLine3", null);
        this.application.setValue("addressLine4", null);
        this.application.setValue("addressLine5", null);
        this.application.setValue("addressCountryCode", null);
        this.application.setValue("addressPostalCode", null);
    }

    taskServiceEvent(taskEvent: TrackerEvent) {
        // switch (taskEvent.event) {
        //     case TrackerEventType.TasksLoaded:
        // const returner = this.tracker.getValue("isReturner") || false;
        // const repeat = this.tracker.getValue("isRepeat") || false;

        this.loaded = true;
        this.formPage = 1;
        //         break;

        // }
    }

    title: string = '';
    loaded = false;
    formPage!: number;
    isReturner!: boolean;
    isRepeat!: boolean;

    referenceId!: number;
    referenceSource?: SuggestedReferee;
    refereeDetails!: RefereeDetails;
    refereeStillKnown!: boolean;

    subscription?: Subscription;
}
