import { Component } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { TokenSignInDetails, UserService } from '@aifs-shared/user/user.service';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';

@Component({
    templateUrl: './external-sign-in.component.html',
    styleUrls: ['./less/external-sign-in.component.scss']
})
export class ExternalSignInComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService
    ) { }

    ngOnInit() {
        this.loading = true;

        const token = this.route.snapshot.params['token'];

        // console.log(`Attempting to find a valid token for ${token}`);

        // Ensure logged out
        this.authService.logout();

        this.userService
            .signInWithToken(token)
            .subscribe({
                next: (d: TokenSignInDetails) => {
                    this.loading = false;
                    // console.log(`Got:`, d);

                    this.linkExpired = d.hasExpired;

                    if (!d.hasExpired) {
                        // console.log(`Token has not expired`);

                        // Clear out any session storage
                        sessionStorage.clear();

                        switch (d.type) {
                            case TokenType.ApplicantResetToken:
                                this.router.navigateByUrl(`/sign-in/reset-password/${d.tokenId}`);
                                break;
                            case TokenType.ReferenceForm:
                                this.router.navigateByUrl(`/references/referee/${d.tokenId}`);
                                break;
                            case TokenType.COLAInterviewerSignIn:
                                this.router.navigateByUrl(`/application-summary/${d.tokenId}`);
                                break;
                        }
                    }
                },
                error: (error: any) => {
                    console.error(error);
                    this.loading = false;
                }
            });
    }

    loading: boolean = false;
    linkExpired: boolean = true;
}

enum TokenType {
    ApplicantResetToken = 1,
    InterviewerResetToken,
    ApplicantEOS,
    InterviewerEos,
    ApplicantRegistration,
    InterviewerRegistration,
    ApplicantSiteLogin,
    InterviewerSiteLogin,
    PrintableApplicationForm,
    PrintableInterviewReport,
    InitialEvaluationReport,
    ReturnerEvaluationReport,
    ProgressEvaluationreport,
    FinalEvaluationReport,
    PrintableApplicationFormAsApplicant,
    InterviewerEosCola,
    TokenTypeReserved,
    ViewInsuranceCertificate,
    ReferenceForm,
    ViewCompletedOnlineReferenceForm,
    InsuranceCertificateAsAttachment,
    DisclosureForm,
    COLAInterviewerSignIn
};

