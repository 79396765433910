<!-- <div id="trustbox" class="trustpilot-widget">
    <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener">
        Trustpilot
    </a>
</div> -->

<!-- TrustBox widget - Mini -->
<div id="trustbox" class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8807dec7e10d38f59f32"
    data-businessunit-id="56446ecf0000ff0005857183" data-style-height="150px" data-style-width="100%" data-theme="dark">
    <a href="https://uk.trustpilot.com/review/campamerica.co.uk" target="_blank" rel="noopener">Trustpilot</a>
</div>
<!-- End TrustBox widget -->