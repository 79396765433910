import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { ToDoService } from './todo.service';
import { ToDoItem } from './todo-item';

import { DisplayCardItem } from '@aifs-shared/display-card/display-card-item';
import { UserService } from '@aifs-shared/user/user.service';
import { ReferenceService } from '../../reference/reference.service';
import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { Task } from '@aifs-shared/tracker/task';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '@aifs-shared/modals/confirmation.modal';

@Component({
    selector: 'app-todo',
    templateUrl: './todo.component.html',
    styleUrls: ['./less/todo.component.scss']
})

export class ToDoComponent implements OnInit, OnDestroy {
    @Output() itemEdit: EventEmitter<ToDoItem> = new EventEmitter();

    constructor(
        route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private referenceService: ReferenceService,
        private tracker: TrackerService,
        private modalService: NgbModal,
        private todo: ToDoService) { }

    ngOnInit() {
        this.loading = true;

        const currentState = this.userService.applicantRole()!.state!;
        const ts = this.tracker.getUserTaskStatusById(14); // MAGIC

        this.referenceTask = this.tracker.getTaskById(14) ?? undefined; // MORE MAGIC
        if (this.referenceTask && ts) {
            this.referenceService
                .isAwaitingReferences(currentState, ts.complete)
                .subscribe({
                    next: (yes: boolean) => {
                        this.isAwaitingReferences = yes;
                    },
                    error: (error: any) => {
                        console.error(error);
                    }
                });
        }

        this.todo
            .getToDoForApplication()
            .subscribe({
                next: (r:ToDoItem[]) => {
                    this.loading = false;
                    this.todoItems = r;

                    // 18-935
                    this.hasItems = r && r.length > 0;

                    this.displayItems = [];
                    this.todoItems.forEach(ti => {
                        this.displayItems.push(this.displayItemFromToDoItem(ti));
                    });
                },
                error: (error: any) => {
                    console.error(error);
                    this.loading = false;
                }
                });
    }

    ngOnDestroy() {
    }

    referenceHelp() {
        this.showModal('Awaiting References', 'One or more of your referees have not responded to our reference request. '
            + 'If possible please contact your referee(s) and ask them to complete the reference form. '
            + 'Your application is unable to proceed until you have two approved references. '
            + 'If your referee is unable to complete the reference or you would like to nominate a new referee, please contact us.',
            'Would you like to view your references now?', 'info');
    }

    showModal(title: string, message: string, extra: string | undefined = undefined, cssClass: string = "info") {
        const rt = this.referenceTask;
        if (rt ) {
            const modalRef = this.modalService.open(ConfirmationModal);
            modalRef.componentInstance.title = title;
            modalRef.componentInstance.body = message;
            if (extra) modalRef.componentInstance.extra = extra;
            modalRef.componentInstance.cssClass = cssClass;
            modalRef.result.then(result => {
                if (result) {
                    this.tracker.setActiveTask(rt);
                    this.router.navigateByUrl('/references/review');
                }
            });
        }
    }

    selectItem(item: DisplayCardItem) {
    }

    editItem(item: DisplayCardItem) {
        let editItem = this.todoItems.find(todo => todo.item == item.id)
        if (!editItem) {
            console.error(`Expected to find item ${item.id} in ${this.todoItems.length} todo items`);
            return;
        }

        if (!editItem.url && editItem.item !== 3) return;

        if (editItem.url.startsWith('http')) {
            window.open(editItem.url, '_blank');
        } else {
            if(editItem.item === 3) {
                // References
                editItem.associatedTask = this.referenceTask;
            }
            this.tracker.setReturnUrl( this.tracker.homeRoute );
            this.itemEdit.emit(editItem);
        }
    }

    displayItemFromToDoItem(ti: ToDoItem): DisplayCardItem {

        let d = new DisplayCardItem({
            id: ti.item,
            title: ti.name,
            details: '',
            editOptionText: 'View',
            canDelete: false,
            canEdit: true
        });

        return d;
    }

    todoItems: ToDoItem[] = [];
    hasItems: boolean = false;
    displayItems: DisplayCardItem[] = [];

    isAwaitingReferences = false;
    referenceTask?: Task;

    loading: boolean = true;
}
