import { Injectable, Injector } from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';

import { AuthenticationService } from '../auth/authentication.service';

import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { BaseResponse } from '@aifs-shared/common/base-response';
import { Router } from '@angular/router';
import { DataAvalability } from './data-availability';

@Injectable()
export class DefaultRequestOptions implements HttpInterceptor {

    private lastRequestTime: number;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private http: HttpClient) {
        this.lastRequestTime = Date.now();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const now = Date.now();
        const twentySecondsHasPassed = now - this.lastRequestTime > 20 * 1000;
        this.lastRequestTime = now;

        // Get the auth header
        const authHeader = this.authService.authorisationHeader;

        if (twentySecondsHasPassed
            && environment.applicantView.isActivityCheckActive
            && req.url.indexOf('applicant-view/data-availability') == -1
            && this.authService.isLoggedIn) {
            this.http
                .get<BaseResponse>(`${environment.ServiceUrl_GetApplicantViewDataAvailability()} `,
                    {})
                .subscribe({
                    next: (data: any) => {
                        const dataAvalability = data.result as DataAvalability;
                        if (!dataAvalability.isAvailable) {
                            this.authService.logout();
                            this.router.navigateByUrl("/sign-in");
                        }
                    },
                    error: (error: any) => {
                        console.log(error);
                    }
                });
        }


        if (authHeader && req.url.indexOf("amazonaws.com") == -1) {
            // Clone the request to add the new header.
            const authReq = req.clone({ setHeaders: { 'Authorization': authHeader } });

            // Pass on the cloned request instead of the original request.
            return next.handle(authReq);
        } else {
            // No header, return the original request headers
            return next.handle(req);
        }
    }
}