import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';



import { BaseService } from '@aifs-shared/common/base-service';
import { IBusinessRuleData, BusinessRule, BusinessRulePart, RuleMatchType, RuleComparison } from './business-rule';

import { environment } from '@environments/environment';

@Injectable()
export class BusinessRuleService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    public loadRules(): void {
        // let o = Observable.from(
        //     this.getRulesFromServer()
        // );

        // // We need to be collecting these, too
        // o.subscribe(
        //     rules => { this.rules = rules },
        //     error => { this.handleError(error) }
        // );

        this
            .getRulesFromServer()
            .subscribe({
                next: (rules: BusinessRule[]) => { this.rules = rules; },
                error: (error: any) => { this.handleError(error); }
            });
    }

    public evaluateRule(name: string | undefined, data: IBusinessRuleData): boolean {
        if (!name) return true; // No rule, must be true!

        let r: BusinessRule = this.getRuleByName(name);

        // if (name === 'CollegeNameOther' || name === 'CollegeNameOtherMX') {
        //     console.debug(`Rule: `, r);
        //     console.debug(`Data:`, data);
        // }
        return this.isTrue(r, data);
    }

    public setRules(rules: BusinessRule[]): void {
        var names: string = "";

        for (let r of rules) {
            if (names.indexOf(`${r.name},`) >= 0) {
                throw new Error(`BusinessRule: The rule '${r.name}' already exists in this ruleset!`);
            } else {
                names += `${r.name},`;
            }
        }

        this.rules = rules;
    }

    getRuleByName(name: string): BusinessRule {
        // console.info(`Rule to find: ${name}`);
        for (let r of this.rules) {
            //console.info(`RuleName: ${name}, ${r.name}`);
            if (r.name == name) {
                //// console.info(`Found rule with name ${r.name}, parts count: ${r.parts.length}`);
                return r;
            }
        }

        throw new Error(`No rule with the name '${name}' found!`);
    }

    getRulesFromServer(): Observable<BusinessRule[]> {
        var magicCache = environment.magicCache;
        return this.http.get<BusinessRule[]>(`${environment.ServiceUrl_LoadRules}?c=${magicCache}`);
    }

    getRulesFromStorage(): BusinessRule[] {
        let rules: BusinessRule[] = [];

        // TODO(ian): localStorage 
        const lsrules = localStorage.getItem("rules");
        if (lsrules) {
            rules = JSON.parse(lsrules);
        }
        return rules;
    }

    rules: BusinessRule[] = [];

    /** Implementations from BusinessRule */

    isTrue(rule: BusinessRule, dataSource: IBusinessRuleData): boolean {
        var matches = (rule.matchType == RuleMatchType.All ? true : false);

        for (let p of rule.parts) {
            // console.log(`## Evaluating ${rule.name}`);
            if (this.evaluateRulePart(p, dataSource)) {
                // console.log("## Rule evaluated TRUE");

                // Short-circuit; the rule part is true, and if we match any, the whole
                // rule can be considered true.
                if (rule.matchType == RuleMatchType.Any) {
                    return true;
                }
            } else {
                // console.log("## Rule evaluated FALSE");

                // Short-circuit; the rule part is false, but we need all rule parts to
                // be matched in order to satisfy the true condition.
                if (rule.matchType == RuleMatchType.All) {
                    return false;
                }
            }
        }

        return matches;
    }

    public addPart(rule: BusinessRule, p: BusinessRulePart) {
        // We have some parts, do they make sense            
        if (!this.isValid(p)) {
            throw new Error(`BusinessRule: Rule parts are not valid for rule ${rule.name}, part was ${p.name}!`);
        }

        rule.parts.push(p);
    }

    /** Implementations from BusinessRule ends */

    /** Implementations from BusinessRulePart
     *  NOTE(ian): I'd like to move these back out to the specific class,
     *  but we need a decent deserialisation strategy in place first.
     */

    public evaluateRulePart(part: BusinessRulePart, dataSource: IBusinessRuleData): boolean {
        let comparedValue = dataSource.getValue(part.name)

        // console.info(`>> ${part.name} '${part.value}' against data: '${comparedValue}'`);

        // if (part.comparison != RuleComparison.Between) {
        //     console.debug(`Evaluating rule ${part.name} with expected value ${part.value} to match ${comparedValue}`);
        // } else {
        //     console.debug(`Evaluating rule ${part.name} with match ${comparedValue} between ${part.lowerBoundValue} and ${part.upperBoundValue}`);
        // }

        switch (part.comparison) {
            case RuleComparison.Equals:
                // console.debug("#### Equals!");
                if (typeof part.value === "string" || typeof part.value === "number") {
                    return this.evaluateEquality(part.value, comparedValue);
                } else if (part.value instanceof Date) {
                    return this.evaluateEqualityDate(part.value, comparedValue);
                } else if (typeof part.value === "boolean") {
                    // console.info(`boolean equals`);
                    return part.value === comparedValue;
                }
                break
            case RuleComparison.NotEquals:
                // console.debug("#### Not Equals!");
                if (typeof part.value === "string" || typeof part.value === "number") {
                    return !this.evaluateEquality(part.value, comparedValue);
                } else if (part.value instanceof Date) {
                    return !this.evaluateEqualityDate(part.value, comparedValue);
                } else if (typeof part.value === "boolean") {
                    return part.value !== comparedValue;
                }
                break
            case RuleComparison.Between:
                // // console.debug("#### Between!");
                if (typeof part.lowerBoundValue === "number") {
                    return this.evaluateBetween(part.lowerBoundValue, part.upperBoundValue, comparedValue);
                } else if (Date.parse(part.lowerBoundValue) > 0 && Date.parse(part.upperBoundValue) > 0) {
                    // // console.debug("This value is a date between");
                    return this.evaluateBetweenDate(new Date(part.lowerBoundValue), new Date(part.upperBoundValue), new Date(comparedValue));
                }
                break
            case RuleComparison.Contains:
                if (typeof part.value === "string" || typeof part.value === "number") {
                    return this.evaluateContains(part.value, comparedValue);
                } else if (part.value instanceof Date) {
                    console.assert(false, `No implementation for evaluating dates on rule part ${part.name}`);
                }
                break
            case RuleComparison.In:
                if (typeof part.value === "string" || typeof part.value === "number") {
                    return this.evaluateContains(comparedValue, part.value);
                } else if (part.value instanceof Date) {
                    console.assert(false, `No implementation for evaluating dates on rule part ${part.name}`);
                }
                break
            case RuleComparison.NotIn:
                if (typeof part.value === "string" || typeof part.value === "number") {
                    return !this.evaluateContains(comparedValue, part.value);
                } else if (part.value instanceof Date) {
                    console.assert(false, `No implementation for evaluating dates on rule part ${part.name}`);
                }
                break
            case RuleComparison.GreaterThan:
                if (typeof part.value === 'number') {
                    return this.evaluateGreaterThan(part.value, comparedValue)
                } else {
                    throw new Error('BusinessRulePart::evaluateRule: Greater than can be used only with numbers!')
                }
            default:
                throw new Error("BusinessRulePart::evaluateRule: No comparison matches!");
        }
        return false;
    }

    public isValid(part: BusinessRulePart): boolean {
        if (part.comparison == RuleComparison.Between) {
            if (part.lowerBoundValue === undefined || part.upperBoundValue === undefined) {
                throw new Error(`BusinessRuleService: Between comparisons required an upper and a lower bound value to be specified: part name was '${part.name}'.`);
            }
        }

        return true;
    }

    evaluateEquality(value: any, comparedValue: any): boolean {
        return value === comparedValue;
    }

    evaluateEqualityDate(value: Date, comparedValue: Date): boolean {
        return value.getTime() === comparedValue.getTime();
    }

    evaluateBetween(lower: number, upper: number, comparedValue: number): boolean {
        return lower <= comparedValue && upper >= comparedValue;
    }

    evaluateGreaterThan(value: number, comparedValue: number): boolean {
        return value > comparedValue
    }

    evaluateBetweenDate(lower: Date, upper: Date, comparedValue: Date): boolean {
        // if( lower.getTime() <= comparedValue.getTime())
        //     // console.debug("Date is larger than lower bound");

        // if( upper.getTime() >= comparedValue.getTime())
        //     // console.debug("Date is smaller than upper bound");

        return lower.getTime() <= comparedValue.getTime()
            && upper.getTime() >= comparedValue.getTime();
    }

    /**
     * Does the array have a particular item contained within?
     * @param value (any) - value to find in array
     * @param comparedValue (any) - array
     */
    evaluateContains(value: any, comparedValue: any): boolean {
        if (Array.isArray(comparedValue)) {
            const arr = comparedValue || [];
            let item = arr.find(v => v == value);

            return (item);
        } else {
            //if its not an array, it's a string and we should use string.includes instead != checks 
            return comparedValue != null && comparedValue.includes(value);
        }
    }
    /**
     * BusinessRulePart implementations end
     */
}
