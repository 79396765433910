import { DateTime } from 'luxon';
import { IMfDateModelDatePart } from '../metaform';

export class MetaformDateTime {
    static getDateTime(isoValue: string): DateTime | undefined {
        const date = isoValue ? DateTime.fromISO(isoValue) : undefined;
        if (date) {
            // console.log(`getDateTime: ${date}`);
            return date.toUTC();
        }

        return date;
    }

    static getDateTimeFromDate(value: any): DateTime | undefined {
        //console.log(`Date check:`, value);
        if (Object.prototype.toString.call(value) !== '[object Date]') {
            // console.debug(`It's not a date!`);
            const sval = value as string;
            const sdp = sval.substring(0, 10);
            const stp = sval.substring(11);
            //console.log(`Do we have date ${sdp} and time ${stp}`);
            const y = parseInt(sdp.substring(0, 4));
            const m = parseInt(sdp.substring(5, 7));
            const d = parseInt(sdp.substring(8));
            var hour = parseInt(stp.substring(0, 2));
            var minute = parseInt(stp.substring(3, 5));

            //console.log(`y,m,d,h,m: '${y}-${m}-${d}' '${hour}:${minute}'`);
            if(isNaN(hour) || isNaN(minute)) {
                hour = 0;
                minute = 0;
            }

            return DateTime.fromObject({ year: y, month: m, day: d, hour: hour, minute: minute}, {zone: 'utc' });
        } else {
            // console.debug(`It's a date?`);
            return value ? MetaformDateTime.getFromParts(value.getFullYear(), value.getMonth(), value.getDate()) : undefined;
        }
    }

    static getFromString(value: any | undefined): { full: string | undefined, year: string | undefined, month: string | undefined, day: string | undefined } | undefined {
        // console.log(`Got value: ${value}, length = ${value?.length}`);
        if (value) {
            const datePart = `${value}`;
            console.log(`DatePart: ${datePart}`);
            if (datePart && datePart.length >= 10) {
                const result = datePart.substring(0, 10);
                // YYYY-MM-DD
                const parts = result.split('-');
                // console.log(`Parts: ${parts.length}`);
                if(parts.length == 3) {
                    const year = parts[0];
                    const month = parts[1];
                    const day = parts[2];

                    const mm = `${month}`.padStart(2, '0');
                    const dd = `${day}`.padStart(2, '0');
                    const iso = `${year}-${mm}-${dd}`;

                    // console.log(`Got ISO: ${iso}`);

                    return { full: iso, year: year, month: mm, day: dd };
                }
            }
            return undefined;
        }
        return value;
    }

    //Hacky but simple and bulletproof 
    static roundToNearestFirstOfMonth(inputDateTime: DateTime): IMfDateModelDatePart {
        const year = inputDateTime.year;
        const month = inputDateTime.month + 1;
        const day = inputDateTime.day;
    
        // If the current date is before the 15th
        if (day < 15) {
            return {year: year, month: month, day : 1};
        } else {
            if (month === 12) {
                // If December, round up to the 1st of Jan next year.
                return  {year: year + 1, month: 1, day : 1};
            } else {
                // round up to the 1st of the next month.
                return  {year: year, month: month + 1, day : 1};
            }
        }
    }

    static getFrom(dateOptions: MetaformDateOptions) {
        if (!dateOptions || !dateOptions.year || !dateOptions.month || !dateOptions.day) return undefined;
        return MetaformDateTime.getFromParts(dateOptions.year, dateOptions.month, dateOptions.day);
        //return DateTime.fromObject({ year: +dateOptions.year, month: +dateOptions.month, day: +dateOptions.day}, {zone: 'Europe/London' })
    }

    static getFromParts(year: string | number, month: string | number, day: string | number): DateTime {
        const mm = `${month}`.padStart(2, '0');
        const dd = `${day}`.padStart(2, '0');
        const iso = `${year}-${mm}-${dd}`;
        // console.log(`ISO format: ${iso}`);
        return DateTime.fromISO(iso);
    }

    static getISODateFromParts(year: string | number, month: string | number, day: string | number): string | undefined {
        if(!year || !month || !day) return undefined;

        const mm = `${month}`.padStart(2, '0');
        const dd = `${day}`.padStart(2, '0');
        const iso = `${year}-${mm}-${dd}`;
        return iso;
    }

    static dateFromSetting(stringValue: string): DateTime | undefined {
        if (stringValue === "TODAY")
            return DateTime.utc();

        return this.getDateTime(stringValue);
    }

    static now(): DateTime {
        return DateTime.utc();
    }

    static getDisplayMonthAndYear(date: any): string {
        if (date) {
            const result = this.getDateTime(date);
            if(result) return result.toFormat(`LLLL yyyy`);
        }
        return '';
    }
}

export class MetaformDateOptions {
    year?: string | number;
    month?: string | number;
    day?: string | number;
    constructor(year: string | number, month: string | number, day: string | number) {
        this.year = year;
        this.month = month;
        this.day = day;
    }
}