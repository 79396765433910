import { SuggestedReferee } from "./reference.service";

export interface RefereeDetails {
    id: number;
    referenceType: ReferenceType;
    referenceStatus: string;
    referenceStatusApplicant: string;
    imageId?: number;
    addedBy?: string;
    firstName: string;
    lastName: string;
    position: string;
    emailAddress: string;
    emailAddressConfirmation: string;
    areaCode?: string;
    telephoneNumber?: string;
    //speaksEnglish: string;
    validFrom: string;
    currentlyPresent: boolean;
    validTo?: string;
    companyName: string;
    organisationWebsite: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    addressLine5: string;
    addressCountryCode: string;
    addressPostalCode: string;

    canDelete: boolean;
    canEdit: boolean;
    alreadyReminded: boolean;

    // CAT-189
    suggestedFrom?: SuggestedReferee;
}

export enum ReferenceType {
    Online = 1,
    Paper = 2
};