<div class="modal-header">
    <h4 class="modal-title alert-danger">Password Risk</h4>
</div>
<div class="modal-body">
    <p>We have checked your entered password against the <a href="https://haveibeenpwned.com/" target="_blank">Have I
            Been Pwned</a> database and found that, sadly, it is present in over {{breachCount}}
        registered data breaches.</p>
    <p><strong>This does not mean that any of your personal accounts have been breached</strong> - but it does mean that
        the password you
        were wanting to use is known to anyone who hacks into computer systems for fun or profit.</p>
    <p>If you wish to find out more, and to check for a safer password, please visit the <a
            href="https://haveibeenpwned.com/Passwords" target="_blank">Have I Been Pwned</a> website.</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-ca-success" (click)="activeModal.close(true)">{{buttonText}}</button>
</div>