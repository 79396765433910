<tracker-progress></tracker-progress>
<div class="content-wrapper">
    <h3>My Application</h3>
    <h4>{{title}}</h4>
    <div [ngSwitch]="formPage">
        <div *ngSwitchCase="1">
            <p>
                Referees must have recent &amp; extensive knowledge of you in a professional or educational capacity and
                be your
                supervisor or teacher.
            </p>
            <p *ngIf="isRepeat">If you have worked on a US Summer Camp before, one of your referees must be your
                previous Camp Director.</p>
            <p *ngIf="isReturner">Please do not enter your Camp Director/supervisor as a referee.</p>
        </div>
        <div *ngSwitchCase="2">
            <p>Dates you have known your referee in a professional or educational capacity.</p>
        </div>
        <div *ngSwitchCase="3">
            <p>About your referee's company, organisation or institution.</p>
        </div>
    </div>
    <mf-named-form [metaform]="'referee-details'" [dataProvider]="this"></mf-named-form>
</div>
<tracker-buttons [hasPreviousButton]="true"></tracker-buttons>