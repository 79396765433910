<div class="input-group">
    <label class="sr-only">Hour</label>
    <select class="form-control sel_hh{{isMandatory(questionItem.key) ? ' mand' : ''}}" [id]="keyForDateHour(questionItem.key)"
        (change)="selectHourOption($event)" [ngClass]="{' invalid': isInvalidTime()}">
        <option *ngFor="let hr of dateModel.hours" [selected]="isHourSelected(hr.code)" [value]="hr.code">{{hr.description}}</option>
    </select>
    <label class="sr-only">Minute</label>
    <select class="form-control sel_min{{isMandatory(questionItem.key) ? ' mand' : ''}}" [id]="keyForDateMinute(questionItem.key)"
        (change)="selectMinuteOption($event)" [ngClass]="{' invalid': isInvalidTime()}">
        <option *ngFor="let min of dateModel.minutes" [selected]="isMinuteSelected(min.code)" [value]="min.code">{{min.description}}</option>
    </select>
</div>