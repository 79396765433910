<div class="card-row" *ngFor="let item of items">
    <div class="selector" *ngIf="multiSelect">
        <button class="selector-button" role="checkbox" (click)="selectItem(item)">
            <i *ngIf="isSelected(item)" class="far fa-check-square fa-2x"></i>
            <i *ngIf="!isSelected(item)" class="fa-regular fa-square fa-2x"></i>
        </button>
    </div>

    <div class="selector" *ngIf="!multiSelect">
        <button class="selector-button" role="checkbox" (click)="selectItem(item)">
            <i *ngIf="isSelected(item)" class="fa-solid fa-circle-check fa-2x"></i>
            <i *ngIf="!isSelected(item)" class="far fa-circle fa-2x"></i>
        </button>
    </div>

    <div class="display-card" [class.selected]="isSelected(item)" (click)="toggleItem(item)">
        <div class="title">
            <span class="text">{{item.title}}</span>
            <span *ngIf="!isToggled(item)" class="exp fa-solid fa-chevron-down"></span>
            <span *ngIf="isToggled(item)" class="exp fa-solid fa-chevron-up"></span>
            <span *ngIf="item.subtitle" class="{{item.subtitleClass}}">{{item.subtitle}}</span>
        </div>
        <div *ngIf="isToggled(item)">
            <p class="details">{{item.details}}</p>
            <p class="details {{item.detailsExtraClass}}" *ngIf="item.detailsExtra">{{item.detailsExtra}}</p>
            <p class="details {{item.detailsExtraLine2Class}}" *ngIf="item.detailsExtraLine2">{{item.detailsExtraLine2}}</p>
        </div>
    </div>
</div>