<tracker-progress></tracker-progress>

<div class="content-wrapper">
    <h3>{{ sequenceTitle }}</h3>
    <h4 *ngIf="title">{{ title }}</h4>
    <p class="introText">{{ bodyText }}</p>
    <div class="introImage" *ngIf="imageUrl">
        <img class="introImage" [src]="imageUrl">
    </div>
    <p *ngIf="footerText" class="introText">{{ footerText }}</p>
</div>

<tracker-buttons></tracker-buttons>