import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicantStatusComponent } from './applicant-status.component';
import { PlacementService } from '../../placement/placement.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ApplicantStatusComponent],
    providers: [PlacementService],
    exports: [ApplicantStatusComponent]
})
export class ApplicantStatusModule { }
