<h3>{{title}}</h3>

<div *ngIf="!isLoading; else still_loading" class="overview">

    <!-- Three Paths -->
    <!-- First, non GBG country -->
    <div *ngIf="nonGbgCountry; else gbgcountry">
      <div *ngIf="!policeCheckReceived">
        <div *ngIf="countryCode === 'NZ'; else nonNZcountry">
            <p>Please obtain your police check - click on the link below for details.  The police certificate will be sent to our New Zealand agent who will upload it on to your record</p>
            <a href="https://campamerica.co.nz/product/iwh-client-police-check/" target="_blank">New Zealand</a>
        </div>
        <ng-template #nonNZcountry>
            <p>Please obtain your police check and upload it here (keep the original for your records).</p>
            <ul [ngSwitch]="countryCode">
                <li *ngSwitchCase="'AU'"><a
                        href="https://afpnationalpolicechecks.converga.com.au"
                        target="_blank">Australia</a></li>                
                <li *ngSwitchCase="'PL'"><a href="https://www.campamerica.co.uk/cad/police-checks-poland"
                        target="_blank">Poland</a></li>
                <li *ngSwitchDefault>Follow the instructions <a href="https://www.campamerica.co.uk/cad/police-checks-os"
                        target="_blank">based on the country you are applying from</a>.</li>
            </ul>

            <p>You can upload up to two images or one pdf file. Please take care while uploading - you will not be able to change the document once you finish the process.</p>
            <div class="upload-or-view">
            <button type="button" class="btn btn-ca-success upload-or-view" (click)="uploadPoliceCheck()">Upload</button>
            </div>
        </ng-template>
      </div>
      <div *ngIf="policeCheckReceived">
        <p>Thank you for uploading your police check. Please keep the original document for your records.
        </p>
        <div class="selectable-item bold-item todo-button" role="button" (click)="returnHome()">
          <img src="/assets/img/icons/Home_White.png"><span>Return to your home page</span>
        </div>
      </div>
    </div>
    <ng-template #gbgcountry>
        <!-- Sub-route, GBG country, already registered -->
        <div *ngIf="isRegistered; else gbgregister">
            <p>You have been successfully registered with the online DBS system - https://knowyourpeople.co.uk</p>
            <p *ngIf="pleaseRegister">Please check your email for a message from KnowYourPeople to begin the process</p>
            <p *ngIf="pleaseComplete">Please complete your police check with KnowYourPeople</p>
            <p *ngIf="pleaseContact">There was a problem with your Police Check - please contact the office</p>
            <p></p>
            <div class="selectable-item bold-item todo-button" role="button" (click)="returnHome()">
                <img src="/assets/img/icons/Home_White.png"><span>Return to your home page</span>
            </div>
        </div>
        <ng-template #gbgregister>
            <!-- Second, GBG country, Post Office or Online -->
            <p>Please complete the online DBS application and submit it within 4 weeks of registration (the form
                will expire after this time).</p>
            <p>Placements cannot be confirmed until this takes place.</p>
            <div *ngIf="message" class="alert" [ngClass]="{'alert-info': !inError, 'alert-error': inError}">
                <p>{{message}}</p>
            </div>
            <div class="selectable-item bold-item todo-button" role="button" (click)="register()">
                <img src="/assets/img/icons/todo_icon.png"><span>Register for Police Check</span>
            </div>
        </ng-template>
    </ng-template>
</div>
<ng-template #still_loading>
    <div class="load_container">
        <p>Getting details from the server...</p>
        <img src="/assets/img/blu-loader.gif" class="bl">
    </div>

</ng-template>
