<div class="sidebar-container">
    <div class="sidebar-menu">
        <nav id="sidebar">
            <!-- <div class="sidebar-header" *ngIf="isInterviewer && isApplicant">
                Interviewer
            </div>
            <ul class="sidebar-nav" *ngIf="isInterviewer">
                <li>
                    <a (click)="navigateTo('/interviewer')">Home</a>
                </li>
                <li>
                    <a (click)="navigateTo('/interviewer/applicants')">Applicants</a>
                </li>
                <li>
                    <a (click)="navigateTo('/interviewer/calendar')">Calendar</a>
                </li>
                <li>
                    <a (click)="navigateTo('/interviewer/resources')">Resources</a>
                </li>
            </ul>
            <div class="sidebar-header" *ngIf="isInterviewer && isApplicant">
                Applicant
            </div> -->
            <ul class="sidebar-nav" *ngIf="isApplicant">
                <li>
                    <a (click)="navigateTo('/home')"><img src="assets/img/icons/Home_White.png" class="">Home</a>
                </li>
                <div *ngIf="!needsUrgentContact">
                  <li *ngIf="hasApplication">
                      <a (click)="navigateTo('/hub')"><img src="assets/img/icons/Application.png" class="">Application</a>
                  </li>
                  <li *ngIf="!isReturner && hasApplication">
                      <a (click)="navigateTo('/interview')"><img src="assets/img/icons/Interview.png" class="">Interview</a>
                  </li>
                  <li *ngIf="hasApplication">
                      <a (click)="navigateTo('/disclosure')"><img src="assets/img/icons/Medical.png" class="">Medical</a>
                  </li>
                  <li *ngIf="isOrWasPlaced">
                      <a (click)="navigateTo('/placement')"><img src="assets/img/icons/Placement.png" class="">Placement</a>
                  </li>
                  <li *ngIf="hasApplication || hasPreviousApplication">
                      <a (click)="navigateTo('/payments')"><img src="assets/img/icons/Payment.png" class="">Payments</a>
                  </li>
                  <li *ngIf="hasApplication && canSelfPropose">
                      <a (click)="navigateTo('/self-proposal')"><img src="assets/img/icons/Placement.png" class="">Camp
                          Choices</a>
                  </li>
                  <li *ngIf="hasApplication || isOrWasPlaced">
                      <a (click)="navigateTo('/flights')"><img src="assets/img/icons/Flights.png" class="">Flights</a>
                  </li>
                  <li *ngIf="hasApplication || isOrWasPlaced">
                      <a (click)="navigateTo('/visa')"><img src="assets/img/icons/Visa.png" class="">Visa</a>
                  </li>
                  <li *ngIf="hasApplication || isOrWasPlaced">
                      <a (click)="navigateTo('/group-cover')"><img src="assets/img/icons/Insurance.png" class="">Group Cover</a>
                  </li>
                  <li *ngIf="canViewOrientation">
                      <a (click)="navigateTo('/orientation')"><img src="assets/img/icons/Orientation.png"
                              class="">Orientation</a>
                  </li>
                </div>
                <li>
                    <a (click)="navigateTo('/contact-us')"><img src="assets/img/icons/Contact_Us.png" class="">Contact us</a>
                </li>
            </ul>
            <ul class="sidebar-nav" *ngIf="isSignedOut">
                <li>
                    <a (click)="navigateTo('/sign-in')"><img src="assets/img/icons/Home_White.png" class="">Sign in</a>
                </li>
                <li>
                    <a (click)="navigateTo('/contact-us')"><img src="assets/img/icons/Contact_Us.png" class="">Contact us</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="sidebar-tp">
        <app-trustbox></app-trustbox>
    </div>
</div>
