export class Lookup {
    code: string;
    name?: string;
    description: string;

    constructor( code: string, description: string ) {
        this.code = code;
        this.description = description;
    }
}
