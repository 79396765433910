import { NgModule, ApplicationRef } from '@angular/core'
import { CommonModule } from '@angular/common'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CalendarModule } from '../calendar/calendar.module'
import { ConfirmationModal } from './confirmation.modal'
import { AlertModal } from './alert.modal'
import { ProgressModal } from './progress.modal'
import { CalendarModal } from './calendar.modal'
import { PwnedModal } from './pwned.modal';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        CalendarModule
    ],
    declarations: [
        ConfirmationModal,
        AlertModal,
        ProgressModal,
        CalendarModal,
        PwnedModal
    ],
    exports: [
        ConfirmationModal,
        AlertModal,
        ProgressModal,
        CalendarModal,
        PwnedModal
    ]
})
export class ModalsModule { }
