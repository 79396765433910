import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { FormControl, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

import { CustomValidators } from '@narik/custom-validators';
import { AifsCustomValidators } from './validator'

import { Observable, BehaviorSubject } from 'rxjs';

import { Metaform, MetaformSection, MfQuestion, Question, DrawType } from './metaform';
import { MetaformDataProvider } from './metaform-data-provider';
import { ReloadOptions } from './reload-options';

import { BusinessRuleService } from '../rule/business-rule.service';
import { IBusinessRuleData } from '../rule/business-rule';

import { Lookup } from '../lookup/lookup';
import { LookupService } from '../lookup/lookup.service';
import { environment } from '@environments/environment';
import { mustBeAfterMomentValidator } from './validator/moment.isAfter.validator';
import { mustBeBeforeMomentValidator } from './validator/moment.isBefore.validator';

@Injectable()
export class MetaformService {

    constructor(
        private http: HttpClient,
        private fb: FormBuilder,
        private ruleService: BusinessRuleService
    ) { }

    /**
     * Is the passed form valid (e.g. complete)?
     * @param form (Metaform) - the form to check
     * @param dataSource (IBusinessRuleData) - data to use for any rule evaluation
     * @returns [boolean, string] - whether the form is valid, and if not a list of the errored items and error reasons
     */
    public isValid(form: Metaform, dataSource: IBusinessRuleData): [boolean, string[]] {
        let errorList: string[] = [];
        let valid = true;

        // NOTE(ian): we early exit in case of falsehood
        for (let i = 0; i < form.questions.length; i++) {
            // Is the question displayable according to the rules?
            let q = form.questions[i];
            let result = this.isQuestionValid(q, dataSource);

            if (!result[0]) {
                valid = false;
                errorList.concat(result[1]);
            }
        }

        return [valid, errorList];
    }

    /**
     * Is the passed question valid according to its rules
     * @param q (MfQuestion) - the question to check
     * @param dataSource (IBusinessRuleData) - data to use for any rule evaluation
     */
    public isQuestionValid(q: MfQuestion, dataSource: IBusinessRuleData): [boolean, string[]] {
        let isValid = true;
        let errorList: string[] = [];

        if (this.isQuestionDisplayable(q, dataSource)) {
            // Find the validators for the question
            for (let iq = 0; iq < q.items.length; iq++) {
                let item = q.items[iq];
                let itemValue = dataSource.getValue(item.key);

                // console.info(`isQuestionValid: ${item.key} = '${itemValue}' -> ${q.items[iq].validators}`);

                if (item.required && (!itemValue || itemValue.length == 0)) {
                    errorList.push(`${q.name} is required but is empty`);
                    isValid = false;
                }

                if (q.items[iq].validators)
                    q.items[iq].validators!.forEach(element => {
                        let val = element;
                        if (!val) {
                            // console.debug(`isQuestionValid: validation: ${JSON.stringify(val)}`);

                        }
                    });
            }
        }

        return [isValid, errorList];
    }

    /**
     * Load a metaform by name
     * @param name (string) - the name of the form to load from storage or network
     * @returns (Metaform) - the loaded form or null.
     * @throws (Error) - if the JSON parse fails or the form is not found.
     */
    public loadForm(name: string): Observable<Metaform> {
        var magicCache = environment.magicCache;
        return this.http.get<Metaform>(`${environment.ServiceUrl_LoadForm(name)}?c=${magicCache}`);
    }

    public getLastQuestionBlock(form: Metaform, dataSource: IBusinessRuleData): [MfQuestion[], number, boolean, boolean] {
        let firstValid = -1;
        let lastValid = 0;
        let atStart = false;
        let atEnd = false;

        // Stepping backwards from the last question displayed to the start, find the
        // first valid one
        let i = form.questions.length - 1;
        while (i > 0) {
            // console.debug(`iteration ${i}, looking at ${form.questions[i].name}`);
            if (this.isQuestionDisplayable(form.questions[i], dataSource)) {
                firstValid = i;
                break;
            }

            i--;
            if (i < 0)
                break;
        }
        // Can't calculate on the fly
        lastValid = firstValid + 1;

        atStart = (firstValid < 0);
        atEnd = false;

        // // console.info(`First/Last Valid: ${firstValid}/${lastValid}`);

        let displayedQuestions: MfQuestion[] = [];

        if (!atStart && !atEnd) {
            // console.info(`Wanting to start and end at ${firstValid} - ${lastValid}`);

            displayedQuestions = form.questions.slice(firstValid, lastValid);

            // Ensure data is present
            displayedQuestions.forEach(q => {
                // // console.info(`Looking for data for ${q.name}`);
                q.items.forEach(mq => {
                    var data = dataSource.getValue(mq.key);
                    // // console.info(`Data: ${mq.key} = '${data}'`);
                    mq.value = data;
                })
            });
        }

        return [
            displayedQuestions,
            lastValid,
            atStart,
            atEnd
        ];

    }

    /**
     * Get the next question(s) for display
     * @param form (Metaform) - the form to display
     * @param dataSource (IBusinessRuleData) - data used for display and rules
     * @param defaultDisplay (boolean) - is a mobile view?
     * @param lastQuestionDisplayed (number) - index of the last question previously shown
     * @param isForward (boolean) - are we stepping forwards? Default is TRUE
     */
    public getNextQuestionBlock(
        form: Metaform,
        dataSource: IBusinessRuleData,
        defaultDisplay: boolean,
        lastQuestionDisplayed: number,
        isForward: boolean = true
    )
        : [MfQuestion[], number, boolean, boolean, number, number, number] {
        let direction = isForward ? +1 : -1;
        let firstValid = isForward ? form.questions.length : -1;
        let lastValid = 0;
        let atStart = false;
        let atEnd = false;
        let currentSection = 0;
        let currentQuestion = 0;

        // Guard
        if (!form.drawType) {
            // Set default
            form.drawType = DrawType.Default;
        }

        if (form.drawType !== DrawType.Form) {
            if (lastQuestionDisplayed < 0) lastQuestionDisplayed = 0;
            if (lastQuestionDisplayed > form.questions.length) lastQuestionDisplayed = form.questions.length - 1;
        }

        var drawType = form.drawType;
        if (form.isReadOnly) drawType = DrawType.Form;
        var drawResult: [number, number, boolean, boolean];

        // console.log(`Form readonly: ${form.isReadOnly}, draw type: ${drawType}`);
        let exit = false;
        let displayedQuestions: MfQuestion[] = [];
        var panic = 20;

        do {


            switch (drawType) {
                case DrawType.Default:
                    drawResult = this.getFirstLastValidForSingleQuestion(form, dataSource, direction, lastQuestionDisplayed);
                    break;

                case DrawType.Section:
                    drawResult = this.getFirstLastValidForSection(form, dataSource, direction, lastQuestionDisplayed);
                    // console.log(`DrawResult: ${JSON.stringify(drawResult, null, 2)}`);
                    break;

                default:
                    if (lastQuestionDisplayed == form.questions.length) {
                        // console.info(`Drawing the entire form has completed`);
                        drawResult = [0, 0, (direction < 0), (direction > 0)];
                    } else {
                        // console.info(`We are drawing the entire form`);
                        drawResult = [0, form.questions.length, false, false];
                    }
                    break;
            }

            // Move result values
            firstValid = drawResult[0];
            lastValid = drawResult[1];
            atStart = drawResult[2];
            atEnd = drawResult[3];

            const result = this.getValidQuestions(form, dataSource, atStart, atEnd, firstValid, lastValid, currentSection, currentQuestion);
            displayedQuestions = result[0];
            lastValid = result[1];
            atStart = result[2];
            atEnd = result[3];
            currentSection = result[4];
            currentQuestion = result[5];

            lastQuestionDisplayed = lastValid;

            // console.log(`atEnd: ${atEnd}, displayedQuestions: ${displayedQuestions.length}`);

            if(direction > 0){
                if(displayedQuestions.length > 0 || atEnd) {
                    // console.log(`Found some valid questions or at end`);
                    exit = true;
                } 
            } else {
                if (displayedQuestions.length > 0 || atStart) {
                    // console.log(`Found some valid questions or at start already`);
                    exit = true;
                } 
            }

            panic--;
            if (panic == 0) {
                console.warn(`We're in a loop!`);
                exit = true;
            }

        } while(!exit);

        // if (!atStart && !atEnd) {
        //     console.log(`Wanting to start and end at ${firstValid} - ${lastValid}`);
        //     if (!form.isReadOnly) {
        //         for (var i = firstValid; i < lastValid; i++) {
        //             var q = form.questions[i];

        //             if (this.isQuestionDisplayable(q, dataSource)) {
        //                 displayedQuestions.push(q);
        //                 currentSection = q.sectionId;
        //                 currentQuestion = i;
        //             }

        //         }

        //         //displayedQuestions = form.questions.slice(firstValid, lastValid);
        //     } else {
        //         //console.debug(`Form is entirely readonly`);
        //         // Form is read-only, so we can only display valid questions
        //         form.questions.forEach((q, i) => {
        //             if (this.isQuestionDisplayable(q, dataSource)) {
        //                 displayedQuestions.push(q);
        //                 currentSection = q.sectionId;
        //                 currentQuestion = i;
        //             }
        //         });
        //     }

        //     // Ensure data is present
        //     displayedQuestions.forEach(q => {
        //         // console.info(`Looking for data for ${q.name}`);
        //         q.items.forEach(mq => {
        //             var data = dataSource.getValue(mq.key);
        //             // // console.info(`Data: ${mq.key} = '${data}'`);
        //             mq.value = data;
        //         })
        //     });
        // } else {
        //     // console.info(`We are at the end or start: ${atStart}/${atEnd}`);
        // }


        return [
            displayedQuestions,
            lastValid,
            atStart,
            atEnd,
            currentQuestion,
            currentSection,
            lastQuestionDisplayed
        ];
    }

    private getValidQuestions(
        form: Metaform, 
        dataSource: IBusinessRuleData, 
        atStart: boolean, 
        atEnd: boolean, 
        firstValid: number, 
        lastValid: number, 
        currentSection: number,
        currentQuestion: number): [MfQuestion[], number, boolean, boolean, number, number] {
        let displayedQuestions: MfQuestion[] = [];
        let lastQuestionDisplayed: number;

        if (!atStart && !atEnd) {
            // console.log(`Wanting to start and end at ${firstValid} - ${lastValid}`);
            if (!form.isReadOnly) {
                for (var i = firstValid; i < lastValid; i++) {
                    var q = form.questions[i];

                    if (this.isQuestionDisplayable(q, dataSource)) {
                        displayedQuestions.push(q);
                        currentSection = q.sectionId;
                        currentQuestion = i;
                    }

                }

                //displayedQuestions = form.questions.slice(firstValid, lastValid);
            } else {
                //console.debug(`Form is entirely readonly`);
                // Form is read-only, so we can only display valid questions
                form.questions.forEach((q, i) => {
                    if (this.isQuestionDisplayable(q, dataSource)) {
                        displayedQuestions.push(q);
                        currentSection = q.sectionId;
                        currentQuestion = i;
                    }
                });
            }

            // Ensure data is present
            displayedQuestions.forEach(q => {
                // console.info(`Looking for data for ${q.name}`);
                q.items.forEach(mq => {
                    var data = dataSource.getValue(mq.key);
                    // // console.info(`Data: ${mq.key} = '${data}'`);
                    mq.value = data;
                })
            });
        } else {
            // console.info(`We are at the end or start: ${atStart}/${atEnd}`);
        }


        return [
            displayedQuestions,
            lastValid,
            atStart,
            atEnd,
            currentSection,
            currentQuestion
        ];
    }

    /**
     * Return the current section object for the passed question
     * @param form (Metaform) - the current form
     * @param question (MfQuestion) - the question
     */
    public getSectionForQuestion(form: Metaform, question: MfQuestion): MetaformSection | undefined {
        let sectionIndex = question.sectionId;
        let section = form.sections.find(s => s.id == sectionIndex);
        return section;
    }

    /**
     * Convert the passed array of questions to an Angular FormGroup
     * @param questionsToDisplay (MfQuestion[]) - the questions to convert to a FormGroup
     * @returns (FormGroup) - the FormGroup for display purposes
     */
    public toFormGroup(questionsToDisplay: MfQuestion[]): [FormGroup, any, Map<string, string>] {
        let group: any = {};
        let formErrors: any = {};
        let validationMessages: Map<string, string> = new Map<string, string>();

        let questions: MfQuestion[] = questionsToDisplay;

        questions.forEach(question => {
            question.items.forEach(item => {
                const vfn = this.validatorsForQuestion(group, question, item);
                if (vfn) item.validatorFunctions = vfn;
                // console.log(`Making: ${item.key}, validators: `, item.validatorFunctions);
                if (item.readonly) {
                    // console.info(`Item ${item.key} is read-only, value is ${item.value}`);
                    group[item.key] = new FormControl({ value: item.value || '', disabled: item.readonly }, this.readonlyValidator);
                } else {
                    group[item.key] = new FormControl(item.value || '', Validators.compose(item.validatorFunctions) || []);
                    // console.info(`Made: ${item.key}`);
                }
                formErrors[item.key] = '';
                if (question.errors) {
                    for (let e = 0; e < question.errors.length; e++) {
                        const vkey = `${item.key}_${question.errors[e].validator}`;
                        // // console.info(`e: ${vkey} = ${question.errors[e].message}`);
                        const msg = question.errors[e].message;
                        if(msg) validationMessages.set(vkey, msg);
                    }
                }
            });
        });

        // // console.log(`Validation Messages: ${JSON.stringify(validationMessages, null, 2)}`);
        return [this.fb.group(group), formErrors, validationMessages];
    }

    readonlyValidator(control: AbstractControl) {
        console.debug(`readonly val?`);
        return null;
    }

    public reloadDataSource(form: Metaform, formGroup: FormGroup, questionItem: Question<any>, lookup: LookupService | null = null, dataProvider: MetaformDataProvider | null = null, reloadObserver: BehaviorSubject<ReloadOptions | null> | null = null): void {
        if (questionItem.optionSource && questionItem.optionSource.startsWith('provide:')) {
            if (dataProvider) {
                dataProvider.loadLookup(form, formGroup, questionItem.optionSource)
                    .subscribe({
                        next:(r: Lookup[]) => {
                            // // console.debug(`loadOptions ${questionItem}`);
                            this.loadOptions(questionItem, r, reloadObserver);
                        },
                        error: (error: any) => {
                            // // console.error(`Can't get lookup values! : ${error}`);
                            if (reloadObserver) reloadObserver.error(error);
                        }
                    });
            } else {
                // // console.assert('reloadDataSource was expecting to find a dataProvider to supply options for a provide: source');
            }
        } else {
            if (lookup) {
                lookup.loadOptionLookup(questionItem.optionSource!)
                    .subscribe({
                        next: (r: Lookup[]) => {
                            // // console.debug(`loadOptions #2 ${questionItem}`);
                            this.loadOptions(questionItem, r, reloadObserver);
                        },
                        error: (error: any) => {
                            // // console.error(`Can't get lookup values! : ${error}`);
                            if (reloadObserver) reloadObserver.error(error);
                        }
                    });
            } else {
                // // console.assert('reloadDataSource was expecting to find a lookup service passed in to support reading options');
            }
        }
    }

    loadOptions(questionItem: Question<any>, options: Lookup[], reloadObserver: BehaviorSubject<ReloadOptions | null> | null = null) {
        // console.info(`Got options for question: ${questionItem.key}`);

        if (options) {
            if (questionItem.nullItem && options.length > 0) {
                const nullItem = new Lookup(
                    questionItem.nullValue !== undefined ? 
                    questionItem.nullValue : '', questionItem.nullItem!);
                const array: Lookup[] = [nullItem].concat(options);
                //array.concat(options);
                // console.log(`Got options`, options, array);
                questionItem.options = array;
            } else {
                if(options.length > 0) questionItem.options = options;
                else questionItem.options = [];
            }

            // questionItem.options =
            //     questionItem.nullItem && options.length > 0
            //         ? [].concat(
            //             new Lookup(questionItem.nullValue !== undefined 
            //                 ? questionItem.nullValue : '', 
            //                 questionItem.nullItem ? questionItem.nullItem : 'desc'), 
            //                 options)
            //         : options
            //         || []
        } else {
            // console.warn(`No options for ${questionItem.key}`)
        }

        if (reloadObserver) {
            reloadObserver.next({ key: questionItem.key, data: questionItem.options })
        }
    }

    getFirstLastValidForSingleQuestion(form: Metaform, dataSource: IBusinessRuleData, direction: number, lastQuestionDisplayed: number)
        : [number, number, boolean, boolean] {
        let firstValid: number = 0;
        let lastValid: number = form.questions.length;
        let atStart: boolean = false;
        let atEnd: boolean = false;

        if (direction > 0) {
            // // console.info(`FORWARDS: Last Question Displayed: ${lastQuestionDisplayed}`);

            // If we are at the very last question which can be displayed,
            // we won't set lastValid
            firstValid = form.questions.length;
            lastValid = form.questions.length + 1;

            // Find the first applicable question        
            for (let i = lastQuestionDisplayed; i >= 0 && i < form.questions.length; i += direction) {
                // // console.debug(`iteration ${i}`);
                if (this.isQuestionDisplayable(form.questions[i], dataSource)) {
                    firstValid = i;
                    lastValid = i + 1;
                    break;
                }
            }

            // // console.info(`FORWARDS: firstValid is ${firstValid}, last is ${lastValid}, len is ${form.questions.length}`);

            atStart = false;
            atEnd = (lastValid == form.questions.length + 1);

            // // console.info(`FORWARDS: At end is ${atEnd}`);
        } else {
            // console.info(`BACKWARDS: Last Question Displayed: ${lastQuestionDisplayed}`);
            // Stepping backwards from the last question displayed to the start, find the
            // first valid one
            let i = lastQuestionDisplayed - 1;
            while (i >= 0) {
                i--;
                if (i < 0) {
                    break;
                }

                // // console.debug(`iteration ${i}, looking at ${form.questions[i].name}`);
                if (this.isQuestionDisplayable(form.questions[i], dataSource)) {
                    firstValid = i;
                    break;
                }
            }
            // Can't calculate on the fly
            lastValid = firstValid + 1;

            //atStart = (firstValid < 0);
            atStart = i < 0;
            atEnd = false;
        }

        return [
            firstValid,
            lastValid,
            atStart,
            atEnd
        ]
    }

    getFirstLastValidForSection(form: Metaform, dataSource: IBusinessRuleData, direction: number, lastQuestionDisplayed: number)
        : [number, number, boolean, boolean] {
        let firstValid: number = 0;
        let lastValid: number = form.questions.length;
        let atStart: boolean = false;
        let atEnd: boolean = false;

        // console.info(`Drawing all in section from ${lastQuestionDisplayed}`);
        firstValid = lastQuestionDisplayed;
        let sectionId = form.currentSection + 1;

        if (direction > 0) {
            atEnd = (direction > 0 && lastQuestionDisplayed >= form.questions.length);

            if (!atEnd) {
                const q = form.questions[lastQuestionDisplayed];
                sectionId = q.sectionId;

                // console.log(`starting with sectionId: ${sectionId}`);
                do {
                    var cursec = form.sections.find(s => s.id == sectionId);
                    // console.log(`section ${cursec!.id}: ${cursec!.ruleToMatch}`);
                    if (!this.ruleService.evaluateRule(cursec!.ruleToMatch!, dataSource)) {
                        // console.log(`section ${cursec!.id} didn't pass checking ${cursec!.ruleToMatch}`);
                        firstValid += form.questions.filter(q => q.sectionId == sectionId).length;
                        sectionId++;
                        cursec = undefined;
                    } else {
                        // console.log(`section ${cursec!.id} passed evaluation`);
                        break;
                    }
                } while (sectionId <= form.sections.length)

                // console.log(`Ended with sectionId ${sectionId}, out of ${form.sections.length}`);

                for (let i = firstValid; i >= 0 && i < form.questions.length; i += direction) {
                    // console.log(`Checking idx: ${i}`);
                    if (form.questions[i].sectionId == sectionId) {
                        // console.log(`Question is ${form.questions[i].name}`);
                        lastValid = i + 1;
                    } else {
                        break;
                    }
                }

                lastQuestionDisplayed = lastValid - 1;
                atEnd = sectionId > form.sections.length || firstValid >= form.questions.length;
            }
            form.currentSection = sectionId;
        } else {
            // TODO(ian): If we start applying rules to entire sections, we must
            // check for the first valid section in the appropriate direction
            // that matches the business rules
            let newSectionId = 99;
            let startQuestionInSection = 0;
            let sectionId = form.currentSection - 1;

            //console.info(`Drawing previous section, we want section ${sectionId} `);
            if (sectionId > 0) {
                do {
                    var cursec = form.sections.find(s => s.id == sectionId);
                    // console.info(`section ${cursec.id}: ${cursec.ruleToMatch}`);
                    if (!this.ruleService.evaluateRule(cursec!.ruleToMatch!, dataSource)) {
                        // console.info(`section ${cursec.id} didn't pass checking ${cursec.ruleToMatch}`);
                        firstValid -= form.questions.filter(q => q.sectionId == sectionId).length;
                        sectionId--;
                    } else {
                        // console.info(`section ${cursec.id} passed evaluation`);
                        break;
                    }
                } while (sectionId > 0)

                for (let i = 0; i < form.questions.length; i++) {
                    // console.info(`Question ${i}, section ${form.questions[i].sectionId}`);
                    if (form.questions[i].sectionId === sectionId) {
                        newSectionId = form.questions[i].sectionId;
                        // // console.info(`Found new section: ${newSectionId}`);
                        startQuestionInSection = i;
                        break;
                    }
                }
            } else {
                console.info(`Section is zero`);
            }

            // if we didn't find a new section, it's probably because we were
            // already at the start
            atStart = sectionId == 0 || newSectionId == 99;

            if (!atStart) {
                firstValid = startQuestionInSection;
                // console.log(`looking for questions in section ${newSectionId}, starting with ${startQuestionInSection}`);
                for (let i = startQuestionInSection; i < form.questions.length; i++) {
                    // console.log(`Checking idx: ${i}`);
                    if (form.questions[i].sectionId == newSectionId) {
                        // // console.info(`Question is ${form.questions[i].name}`);
                        lastValid = i + 1;
                    } else {
                        break;
                    }
                }
                form.currentSection = newSectionId;
            } else {
                console.info(`At start!`);
            }
        }

        return [
            firstValid,
            lastValid,
            atStart,
            atEnd
        ]
    }

    /**
     * Is the specified question from the array valid for display?
     * @param questions (MfQuestion[]) - array of questions
     * @param dataSource (IBusinessRuleData) - data to check any extant rules against
     */
    isQuestionDisplayable(question: MfQuestion, dataSource: IBusinessRuleData): boolean {
        let valid = false;

        if (question.ruleToMatch) {
            // console.log(`--> Evaluating rule ${question.ruleToMatch}`);
            valid = this.ruleService.evaluateRule(question.ruleToMatch, dataSource);
        } else {
            // No rules, must be valid
            valid = true;
        }

        // console.log(`--> Is valid = ${valid}`);
        // If not valid, clear this question's answer
        if (!valid) {
            // console.log(`not valid, so clearing items for question: ${question.name}`);
            for (let i = 0; i < question.items.length; i++) {
                // // console.debug(`clearing item ${question.items[i].key}, value was ${question.items[i].value}`);
                question.items[i].value = '';
            }
        }

        return valid;
    }

    /**
     * Load FormGroup validators for the desired question
     * @param item (Question) - the question to load validators for
     */
    validatorsForQuestion(group: any, question: MfQuestion, item: Question<any>): ValidatorFn[]  | undefined {
        let vals: any[] = [];

        if (item.required) {
            if (question.displayIf) {
                // Value in this field is dependent upon a specific value in the linkedField.
                vals.push(AifsCustomValidators.requiredIf(group[question.displayIf], question.displayIfValue))
            } else {
                vals.push(Validators.required);
            }
        }

        // Additional behaviour for max length fields
        if (item.maxLength && item.maxLength > 0) {
            // // console.info(`Validator is RangeLength 0 - ${item.maxLength}`);
            vals.push(CustomValidators.rangeLength([0, item.maxLength]));
        }

        if (item.validators !== undefined) {
            item.validators.forEach(v => {
                // // console.info(`Validator is ${v}`);
                switch (v) {
                    case 'Email':
                        vals.push(CustomValidators.email);
                        break;
                    case 'AnswerMustBeYes':
                        vals.push(CustomValidators.equal('Y'));
                        break;
                    case "AnswerDateMustBeAfterMin":
                        if (item.min!.startsWith('[')) {
                            const linkedField = item.min!.substring(1, item.min!.length - 1);
                            // console.log(`${item.key}: min date coming from field ${linkedField}`, group);
                            vals.push(AifsCustomValidators.laterThan(group[linkedField]))
                        } else {
                            // console.log(`AnswerDateMustBeAfter: ${item.min}`);
                            vals.push(mustBeAfterMomentValidator(item.min));

                            //vals.push(CustomValidators.minDate(item.min));
                        }
                        break;
                    case "AnswerDateMustBeBeforeMax":
                        // console.log(`AnswerDateMustBeBefore: ${item.max}`);
                        vals.push(mustBeBeforeMomentValidator(item.max));
                        //vals.push(CustomValidators.maxDate(item.max));
                        break;
                    case "AnswerMustBeInRange":
                        vals.push(CustomValidators.range([+item.min!, +item.max!]));
                        break;
                    case "AnswerMustMatch":
                        vals.push(CustomValidators.equalTo(group[item.match!]));
                        break;
                    case "Date":
                        vals.push(CustomValidators.date);
                        break;
                    default:
                        break;
                }

            });
        }

        if (vals.length == 0) {
            return undefined;
        } else {
            //// console.info(`for question: ${item.key}, validator count is ${vals.length}`);
            return vals;
        }
    }

    // getLinkedQuestion(group: any, item: Question<any>, name: string): Question<any> {
    //     const linkedField = name.substring(1, item.min.length - 1);

    // }

    // public findQuestionByName(questionName): MfQuestion {
    //     const target = this.form.questions.find(t => t.name === questionName);
    //     if (!target) {
    //         // console.error(`Was expecting a component for question '${questionName}' in ${this.form.questions.length} questions but didn't see one`);
    //     }
    //     return target;
    // }

    // public findQuestionItemByName(question, itemName) {
    //     const target = question.items.find(t => t.key === itemName);
    //     if (!target) {
    //         // console.error(`Was expecting a component for item name '${itemName}' in ${question.items.length} items but didn't see one`);
    //     }
    //     return target;
    // }
}


