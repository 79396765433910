<div class="input-group">
    <label class="sr-only">Month</label>
    <select class="form-control sel_mon sel_mm{{isMandatory(questionItem.key) ? ' mand' : ''}}"
        [id]="keyForDateMonth(questionItem.key)" (change)="selectMonthOption($event)"
        [ngClass]="{' invalid': isInvalidDate()}">
        <option *ngFor="let mon of dateModel.months" [selected]="isMonthSelected(mon.code)" [value]="mon.code">
            {{mon.description}}</option>
    </select>
    <label class="sr-only">Year</label>
    <select class="form-control sel_yyyy{{isMandatory(questionItem.key) ? ' mand' : ''}}"
        [id]="keyForDateYear(questionItem.key)" (change)="selectYearOption($event)"
        [ngClass]="{' invalid': isInvalidDate()}">
        <option *ngFor="let year of dateModel.years" [selected]="isYearSelected(year.code)" [value]="year.code">
            {{year.description}}</option>
    </select>
</div>
<span *ngIf="canDisplayError && !isValid" class="has-danger">
    <span class="form-control-feedback">
        {{ errorMessages }}
    </span>
</span>