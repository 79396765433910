import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { UserService } from '@aifs-shared/user/user.service';
import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { map } from "rxjs/operators";
import { User } from './user';
import { Observable, throwError, Subject } from 'rxjs';
import { ChannelEvent, ConnectionState, PushAzureService } from '@aifs-shared/push/push-azure.service';

@Injectable()
export class UserPushService {

    public constructor(
        private pushAzureService: PushAzureService,
        private authService: AuthenticationService
    ) { // console.debug("UserPushService::()"); 
    }

    public registerForUser(user: User): void {
        // console.debug(`UserPush: Register for User`);

        if (!this.connectionState$) {
            // console.info(`No connection yet, initialising`);
            this.initialiseConnection();
        }

        let u: User = user;
        var channel = `tasks+${u.id}`;

        if (!this.currentUser || this.currentUser.id !== user.id) {
            this.currentUser = user;
            channel = `tasks+${user.id}`;
            // console.log(`UserPush: current user subscription changing, subbing to channel ${channel}`);
        } else {
            // console.info(`Already on channel ${channel} for user ${this.currentUser.id}`);
            return;
        }

        // console.info(`Registering interest in push channel '${channel}'`);

        // Get an observable for events emitted on this channel
        this.pushAzureService.sub(channel).subscribe(
            (x: ChannelEvent) => {
                //console.debug(`----->>>> Event on '${channel}' channel: ${JSON.stringify(x, null, 2)}`);

                if (x.Data.State == 'UserAgreedTermsAndConditions') {
                    // console.debug(`${channel}: State returned is UserAgreedTermsAndConditions, updating tracker data`);
                    // This reloads user data as well.
                    this.tracker.reloadForCurrentUser();
                }

                if (x.Data.State === 'ApplicantCreatedApplication' || x.Data.State === 'NewIssueRaised' || x.Data.Starting === 'ApplicationUpdated') {
                    // console.debug(`${channel}: State returned is ${x.Data.State}, updating data`);
                    this.userService.reloadUserData()
                        .subscribe(
                            data => {
                                // console.log(`UPS: Reloaded user service. Checking application`);
                                this.tracker.reloadForCurrentUser();
                            }
                        )

                    if (x.Data.State === 'ApplicantCreatedApplication') {
                        this.pushAlert.next(
                            {
                                data: x.Data.State,
                                extra: x.Data.Extra
                            }
                        );
                    }
                }

                if (x.Data.State == 'InterviewerSetInterviewDate') {
                    // console.log(`${channel}: Interviewer has created an interview for us!`);
                    // This reloads user data as well.
                    // this.userService.reloadUserData()
                    //     .subscribe(
                    //     data => {
                    //         // console.log(`UPS: Reloaded user service. Checking application`, x.Data);
                    //         this.tracker.reloadForCurrentUser();

                    this.pushAlert.next(
                        {
                            title: "Interview Request Completed",
                            text: "Your interviewer has created an interview for you. Click to confirm.",
                            redirect: `/interview/${x.Data.Extra}`
                        }
                    );
                    // })
                }
            },
            (error: any) => {
                console.error("Attempt to join channel failed!", error);
            }
        );

        this.pushAzureService.sub("admin").subscribe({
            next: (data: ChannelEvent) => {
                if(data.Data.State === "SlotReserved") {
                    // console.log(`Got admin event: ${JSON.stringify(data.Data)}`);
                    
                    const reservationEvent: ReservedSlotMessage = JSON.parse(data.Data.Extra);
                    if(reservationEvent) {
                        if(reservationEvent.ApplicationId != this.currentUser!.applicantRole!.applicationId) {
                            this.slotReservation$.next(reservationEvent);
                        }
                    }
                }
            },
            error: (error) => {
                console.error(`Error on admin channel: ${error}`);
            }
        });
    }

    public unregisterUser(u: User) {
        this.closeConnection(u);
    }

    initialiseConnection() {
        // console.debug(`#1`);

        if (!this.pushAzureService.connectionState$) {
            this.pushAzureService.start();
        }

        this.connectionState$ = this.pushAzureService.connectionState$
            .pipe(
                map((state: ConnectionState) => { return ConnectionState[state]; })
            );

        // console.debug(`#2`);
        this.pushAzureService.error$.subscribe(
            (error: any) => { console.warn(error); },
            (error: any) => { console.error("errors$ error", error); }
        );

        // console.debug(`#3`);
        // Wire up a handler for the starting$ observable to log the
        // success/fail result
        this.pushAzureService.starting$.subscribe(
            () => {
                //console.debug("signalr service has been started");
            },
            () => {
                //console.warn("signalr service failed to start!");
            }
        );

        //console.debug("Starting the channel service");
        this.pushAzureService.start();
    }

    closeConnection(u: User) {
        // console.warn(`NOTE: We should be unsubscribing from the user channel now.`);
        // TODO(Ian): I'm pretty sure we'd want to stop subscribing to
        // the specified channel once the user has signed-out, although
        // the current code we're using for the push service doesn't
        // illustrate such a thing, and I'm not at the point where I have
        // time to wade through the SignalR documentation. Please feel
        // free to do so
        const channel = `tasks+${u.id}`;

        // console.info(`I want to unsubscribe from channel ${channel}`);
        this.pushAzureService.unsub(channel);
    }

    handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        errMsg = error.message ? error.message : error.toString();
        console.error(errMsg);
        return throwError(errMsg);
    }

    // An internal "copy" of the connection state stream used because
    //  we want to map the values of the original stream. If we didn't 
    //  need to do that then we could use the service's observable 
    connectionState$!: Observable<string>;
    currentUser?: User;

    public tracker!: TrackerService;
    public userService!: UserService;

    public pushAlert: Subject<any> = new Subject<any>();
    public slotReservation$ = new Subject<ReservedSlotMessage>();
}

export interface ReservedSlotMessage {
    ApplicationId: number;
    InterviewerId: number;
    Date: string;
    Time: string;
}