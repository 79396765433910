export interface IStoredData {
    expires: Date;
    data: any;
}

export class StoredData implements IStoredData {
    expires!: Date;
    data: any;

    constructor(s: IStoredData) {
        if (s) {
            this.data = s.data;
            this.expires = new Date(s.expires);

            this.hasData = true;
            const nowDate = new Date(Date.now());

            // console.info(`Expiry ${this.expires} versus now ${nowDate}`);

            this.hasExpired = (nowDate > this.expires);
        }
    }

    /**
     * @description Cache some data in sessionStorage for a predetermined length of time
     * @param sessionKey (string): Key for session storage
     * @param data (any): Data to store
     * @param expiresInMinutes (number): How many minutes you want this data to persist for before it becomes stale
     */
    static storeWithKeyAndData(sessionKey: string, data: any, expiresInMinutes: number): void {

        var expiryDate = new Date(Date.now());
        expiryDate.setMinutes(expiryDate.getMinutes() + expiresInMinutes);

        var dataToStore: IStoredData = {
            data: data,
            expires: expiryDate
        };

        sessionStorage.setItem(sessionKey, JSON.stringify(dataToStore));

        setTimeout(() => {
            // console.debug(`Clearing timeout for sessionKey ${sessionKey}`);
            sessionStorage.removeItem(sessionKey);
        }, expiresInMinutes * 60000);
    }

    /**
     * @description Retrieve some data from the cache, providing it has not expired
     * @param sessionKey (string): Key for sessionStorage
     */
    static exists(sessionKey: string): StoredData | null {
        const item = sessionStorage.getItem(sessionKey);
        if (item) {
            const s: IStoredData = JSON.parse(item);
            const storedData = new StoredData(s);

            // Only return if it exists and hasn't expired
            if (!storedData.hasExpired) {
                return storedData;
            } else {
                // I want to remove this
                sessionStorage.removeItem(sessionKey);
                //     console.debug(`Key ${sessionKey} exists, but it has expired!`);
            }
        }

        return null;
    }

    hasExpired: boolean = false;
    hasData: boolean = false;
}

/*

Use case one:

 Data returned from a subscription:

 data -> {
     StoredData.storeWithKeyAndExpiry(key: string, int expiresInMinutes) {
         // Create the stored data interface and store it in sessionStorage
     }
 }

Use case two:

 Check for data in storage

    if( StoredData.exists("my-key") )
    {
        // We can assume that the exist check also 
    }

*/