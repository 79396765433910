import { AbstractControl, Validators, ValidatorFn } from '@angular/forms';

import { MetaformDateTime } from '@aifs-shared/metaform/datetime/metaform-datetime';

export const laterThan = (laterControl: AbstractControl): ValidatorFn => {
    let subscribe: boolean = false;

    return (control: AbstractControl): { [key: string]: any } | null => {
        // moment.tz.setDefault('Europe/London');

        if (!subscribe) {
            subscribe = true;
            // console.log(`subscribing to control laterControl`, laterControl);
            laterControl.valueChanges.subscribe(() => {
                control.updateValueAndValidity();
            });
        }

        // console.info(`isPresent check`);
        if (isPresent(Validators.required(control))) return null;
        // console.info(`find dates`);

        let d = MetaformDateTime.getDateTime(control.value);
        let c = MetaformDateTime.getDateTime(laterControl.value);

        const result = d && c && (d > c); // d.isAfter(c.format(), 'day');

        //        if (!isDate(d)) return { laterThan: true };

        // console.info(`d > c? ${d > c}`);

        return result ? null : { laterThan: true };
    };
};

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}

export function isDate(obj: any): boolean {
    return !/Invalid|NaN/.test(new Date(obj).toString());
}