import { ResponseData } from './response-data';

export interface BaseResponse {
    result?: ResponseData;
    resultCode: ResponseResultCode;
    url?: string;
    error?: string;
}

export enum ResponseResultCode {
    Error,
    Success,
    RedirectRequired
}