import { UserService } from '@aifs-shared/user/user.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {
    @Input() isInterviewer: boolean = false;
    @Input() isApplicant: boolean = false;
    @Output() menuItemClicked = new EventEmitter<string>();
    @Output() signOutClicked = new EventEmitter();

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.updateUserApplicationStatus();

        this.userSubscription = this.userService.userChanged.subscribe(
            () => {
                this.updateUserApplicationStatus();
            });
    }

    private updateUserApplicationStatus() {
        let activeApplicationId = this.userService.applicantRole()!.activeApplicationId!;
        this.hasActiveApplication = activeApplicationId != null && activeApplicationId > 0;
    }

    navigateTo(url: string): void {
        this.menuItemClicked.emit(url);
    }

    signOut(): void {
        this.signOutClicked.emit();
    }
    
    hasActiveApplication: boolean = false;
    userSubscription?: Subscription;
}
