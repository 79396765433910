<div class="content-wrapper">
  <h3>My Application</h3>
  <h4 *ngIf="title">{{title}}</h4>
  <div *ngIf="inError">
    <p>There was an unexpected error loading your reference details.</p>
    <p class="error-text">{{errorMessage}}</p>
  </div>

  <div *ngIf="isLoaded; else stillLoading">
    <div *ngIf="submitted; else referenceForm">
      <p>
        Thank you for completing and submitting your reference. Your help in progressing the application is very
        much appreciated.
      </p>
    </div>

    <ng-template #referenceForm>
      <h5>Guidance Notes</h5>
      <p>
        <strong>{{applicantName}}</strong> is applying to <a href="https://campamerica.co.uk" target="_blank">
          Camp
          America
        </a> to work with children/vulnerable adults in a residential setting for 9 weeks, and has
        given your name to provide a reference.
      </p>

      <p>
        Camp America is committed to Child Protection and the Safeguarding of Children and Vulnerable Adults,
        and references
        make an important contribution to our screening process.
      </p>

      <p>
        Should you have any reason to believe that the applicant is capable of harming others, or specifically
        have Child
        Protection or Safeguarding concerns, please share them with us confidentially, by phoning Charlotte
        Fletcher on <a href="tel:+442075817334">+44 207 581 7334</a>.
      </p>

      <!-- <p>
        When you are ready to start the reference, please click the 'Next' button below. If you need to take a
        break while completing the form, you may press the <span class="save-for-later">
          <i class="far fa-bookmark"></i>
          Save for Later
        </span>
        button.
      </p> -->

      <div id="tracker-buttons" class="tracker-buttons trackbuttons trackbuttons-bottom">
        <button type="button" class="btn btn-track-next" (click)="stepNext()" [disabled]="!nextEnabled">
          {{nextButtonText}}
          <div class="pull-right">
            <span class="fa-solid fa-chevron-right"></span>
          </div>
        </button>
      </div>
    </ng-template>
  </div>
  <ng-template #stillLoading>
    <div class="load_container">
      <p>Contacting server. Please wait...</p>
      <img src="assets/img/blu-loader.gif" class="bl">
    </div>
  </ng-template>
</div>


<ng-template ngbModalContainer></ng-template>
