import { Directive, Input, forwardRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import { laterThan } from './validator';

const LATER_THAN_DATE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => LaterThanDateValidator),
    multi: true
};

@Directive({
    selector: '[laterThan][formControlName],[laterThan][formControl],[laterThan][ngModel]',
    providers: [LATER_THAN_DATE_VALIDATOR]
})
export class LaterThanDateValidator implements Validator, OnInit, OnChanges {
    @Input() laterThan!: FormControl;

    validator!: ValidatorFn;
    onChange?: () => void;

    ngOnInit() {
        this.validator = laterThan(this.laterThan);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let key in changes) {
            if (key === 'laterThan') {
                this.validator = laterThan(changes[key].currentValue);
                if (this.onChange) this.onChange();
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } | null {
        return this.validator(c);
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
