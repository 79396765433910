import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from '@aifs-shared/modals/alert.modal';

import { Observable, Subject } from 'rxjs';

import { UserService } from '../shared/user/user.service';
import { AuthenticationService } from '../shared/auth/authentication.service';
import { ApplicationService } from '../shared/application/application.service';
import { WindowRef } from '@aifs-shared/framework/window-ref';

import { PostWriteRefereeDataResponse, ReferenceService } from './reference.service';
import { Reference } from './reference-details';

import { StoredData } from '@aifs-shared/common/stored-data';
import { ControlLayoutStyle, IddCode, MetaForm, MetaFormDateType, MetaFormDrawType, MetaFormService, MetaFormTextType, MetaFormUserEvent, MFOptions, MFOptionValue, MFValidator, MFValueChange, UserEventType } from '@aifs/ngx-metaform';
import { LanguageService } from '@aifs-shared/i18n/language.service';
import { DateTime } from 'luxon';

@Component({
    templateUrl: './reference-form.component.html',
    styleUrls: [
        './less/add-reference.component.scss',
        '../shared/tracker/less/tracker-button.component.scss',
        './less/reference-form.component.scss'
    ]
})
export class ReferenceFormComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        private application: ApplicationService,
        private referenceService: ReferenceService,
        private formBuilder: MetaFormService,
        private modalService: NgbModal,
        private i18n: LanguageService,
        private windowRef: WindowRef 
    ) { }

    ngOnInit() {
        this.sequenceTitle = "Camp America Reference";
        this.isLoaded = false;

        this.referenceId = +this.route.snapshot.params['id'];

        var data;
        if ((data = StoredData.exists(`rd:ref:${this.referenceId}`)) != undefined) {
            this.referenceDetails = data.data;
            
            this.loadReference();
        } else {
            this.showAlert("Reference", "There was an error attempting to load the reference. Please try again.", "danger")
                .subscribe(
                    r => {
                        this.router.navigateByUrl(`/references/referee/${this.referenceId}`)
                    }
                );
            return;
        }
    }

    ngOnDestroy(): void {
        this.contactDetails?.change$.unsubscribe();
        this.mainForm?.change$.unsubscribe();
        this.personalityForm?.change$.unsubscribe();
        this.finalForm?.change$.unsubscribe();
    }

    stepNext(): void {
        this.windowRef.nativeWindow.scroll(0, 0);
        // console.log(`At start: ${this.step}`)
        if ( this.validateForStepForward() ) {
            this.step++;
        }
        // console.log(`Now: ${this.step}`)
        this.loadFormForStep();
        this.checkButtonState();
    }

    stepPrevious(): boolean {
        if (this.validateForStepBackward()){
            // All good, go back one?
            this.step--;
        }
        this.loadFormForStep();        
        this.checkButtonState();
        return true;
    }

    checkButtonState(): void {
        this.nextEnabled = this.step !== this.STEP_5_FINAL_PAGE && this.currentFormIsValid || this.step === this.STEP_5_FINAL_PAGE;
        this.previousEnabled = this.step > this.STEP_0_CONTACT_DETAILS && this.step < this.STEP_5_FINAL_PAGE; // Can't step back from Thank you page
        // console.log(`checkButtonState for step ${this.step}: < ${this.previousEnabled} > ${this.nextButtonText}`);

        // CA-2441
        if(this.step === this.STEP_3_SUMMARY) {
            this.nextButtonText = "Submit";
        } else {
            this.nextButtonText = "Next";
        }
    }

    loadReference(): void {
        this.applicantName = `${this.referenceDetails.data.applicantFirstName} ${this.referenceDetails.data.applicantLastName}`;

        this.sequenceTitle = `Reference for ${this.applicantName}`;
        const referee = this.referenceDetails.data;
        const request = this.referenceDetails.request;

        if(!referee.refereeFirstName) {
            referee.id = request.id;
            referee.refereeFirstName = request.firstName;
            referee.refereeLastName = request.lastName;
            referee.email = request.email;
            referee.website = request.website;
            referee.phoneAreaCode = request.areaCode;
            referee.phone = request.phone;
            referee.organisationName = request.organisationName;
            referee.position = request.position;
            referee.address1 = request.address1;
            referee.address2 = request.address2;
            referee.address3 = request.address3;
            referee.addressPostCode = request.addressPostcode;
            referee.addressCountry = request.addressCountry;

            const dt = DateTime.fromISO(request.dateFrom);            
            referee.dateFrom = dt.toFormat('yyyy-MM-01');

            if(request.dateFrom) {
                //console.log(`Got a dateFrom, do we have a dateTo: ${request.dateTo}`);
                if (request.dateTo) {
                    const dt = DateTime.fromISO(request.dateTo!);
                    if (dt) referee.dateTo = dt.toFormat('yyyy-MM-01');
                }
            }
        }

        for (let [k, v] of Object.entries(referee)) {
            this.formData.set(k, v);
        }

        this.loadFormForStep();        

        this.isLoaded = true;
    }

    loadFormForStep() {
        switch(this.step) {
            case this.STEP_0_CONTACT_DETAILS: 
                if(!this.contactDetails) {
                    this.contactDetails = this.buildContactForm();
                    this.contactDetails.change$
                        .subscribe((chg: MFValueChange) => {
                            // console.log(`Value change on ${chg.name} to ${chg.value}`);
                            this.formData.set(chg.name, chg.value);

                            if(chg.name === 'addressCountry') {
                                const tn = this.contactDetails!.getControlByName('telephone');
                                const iddCode = this.formBuilder.getIddCodeForCountry(chg.value);
                                const currentValue = this.contactDetails!.getValueAsTelephoneNumberWithIdd('telephone');
                                if(iddCode) {
                                    const telephoneWithIdd = `${iddCode.country}:${iddCode.code}:${currentValue.number}`;
                                    // console.log(`Got IDD code ${telephoneWithIdd}`);
                                    this.contactDetails?.setValue('telephone', telephoneWithIdd);
                                }
                            }
                        });                
                }
                break;           
            case this.STEP_1_MAIN_FORM:
                if (!this.mainForm) {
                    this.mainForm = this.buildMainForm();
                    this.mainForm.change$
                        .subscribe((chg: MFValueChange) => {
                            // console.log(`Value change on ${chg.name} to ${chg.value}`);
                            this.formData.set(chg.name, chg.value);
                        });  
                }
                break;
            case this.STEP_2_PERSONALITY:
                if(!this.personalityForm) {
                    this.personalityForm = this.buildPersonalityForm();
                    this.personalityForm.change$
                        .subscribe((chg: MFValueChange) => {
                            // console.log(`Value change on ${chg.name} to ${chg.value}`);
                            this.formData.set(chg.name, chg.value);
                        });  
                }
                break;
            case this.STEP_3_SUMMARY:
                if(this.finalForm) {
                    this.finalForm!.change$.unsubscribe();
                }              

                this.finalForm = this.buildFinalForm();
                this.finalForm.change$
                    .subscribe((chg: MFValueChange) => {
                        // console.log(`Value change on ${chg.name} to ${chg.value}`);
                        this.formData.set(chg.name, chg.value);
                    });  
                break;
        }
    }

    validateForStepForward(): boolean {
        switch (this.step) {
            case this.STEP_0_CONTACT_DETAILS:
                const isRelated = this.isRelatedOrNotSupervisor();

                // early exit sets the reference to rejected
                this.save(true);

                if (isRelated) {
                    this.step = this.STEP_4_CANNOT_ACCEPT;
                    return false;
                }
                break;
            case this.STEP_1_MAIN_FORM:
                this.save(true);
                break;
            case this.STEP_2_PERSONALITY:
                this.save(true);
                break;
            case this.STEP_3_SUMMARY:
                // Final save (CAT-213)
                // If we are here, we noted that more information
                // had to be entered, so any movement forwards now
                // is a final save (no continue, update status)
                this.save(false);
                return false;
            case this.STEP_4_CANNOT_ACCEPT: 
                this.save(false, this.isRelatedOrNotSupervisor());
                return false;
                
            case this.STEP_5_FINAL_PAGE:
                break;
        }

        return true;
    }

    validateForStepBackward(): boolean {
        switch(this.step){
            case this.STEP_4_CANNOT_ACCEPT:
                // Permit changing answers (CAT-251)
                this.step = this.STEP_0_CONTACT_DETAILS;
                return false;     
        }
        return true;
    }

    isRelatedOrNotSupervisor(): boolean {
        return (this.contactDetails!.getValue('isRelation') ?? '') === 'Y' || (this.contactDetails!.getValue('isSupervisor') ?? '') === 'N';
    }

    needsMoreInformation(): {needed: boolean, disciplinary: boolean, rehire: boolean, recommend: boolean, concerns: boolean} {
        const wouldRehire = this.formData.get('wouldRehire');
        const recommend = this.formData.get('recommended');
        const concerns = this.formData.get('concerns');
        const disciplinary = this.formData.get('anyDisciplinary');
        return {
            needed: !(wouldRehire !== '0' && recommend === 'Y' && concerns === 'N' && disciplinary == 'N'),
            disciplinary: disciplinary == 'Y',
            rehire: (wouldRehire == '0'),
            recommend: (recommend == 'N'),
            concerns: (concerns == 'Y')
        };
    }

    buildContactForm(): MetaForm {
        const f = this.formBuilder.createForm('ContactDetails', '', MetaFormDrawType.EntireForm);

        // Set values
        for (let [k, v] of this.formData.entries()) {
            //console.log(`setting values: ${k} = '${v}'`);
            f.setValue(k, v);
        }

        const idd = IddCode.getIddList();
        const countries = idd.map(item => new MFOptionValue(item.country, item.name));
        
        const yesNo: MFOptionValue[] = [];
        yesNo.push(new MFOptionValue('Y', 'Yes'));
        yesNo.push(new MFOptionValue('N', 'No'));

        f.addSection('Contact Details')
            .addQuestion('name', 'Your name')
            .addTextControl('refereeFirstName', MetaFormTextType.SingleLine, 50, 'First Name')
            .addValidator(MFValidator.Required('Please enter your first name'));

        f.getQuestion('name')!    
            .addTextControl('refereeLastName', MetaFormTextType.SingleLine, 50, 'Last Name')
            .addValidator(MFValidator.Required('Please enter your last name'));

        f.addQuestion('position', 'Position')
            .addTextControl('position', MetaFormTextType.SingleLine, 50)
            .addValidator(MFValidator.Required('Please enter your job title'));

        f.addQuestion('email_address', 'Email')
            .addTextControl('email', MetaFormTextType.SingleLine, 255)
            .addValidator(MFValidator.Email('Please enter a valid email address'))
            .addValidator(MFValidator.Required('Please enter your email address'));

        f.addQuestion('org_name', 'Organisation')
            .addTextControl('organisationName', MetaFormTextType.SingleLine, 50)
            .addValidator(MFValidator.Required('Please enter your organisation\'s name'));

        f.addQuestion('org_url', 'Website')
            .addTextControl('website', MetaFormTextType.URI, 255);

        f.addQuestion('addr', 'Address')
            .addTextControl('address1', MetaFormTextType.SingleLine, 35);
        f.getQuestion('addr')!
            .addTextControl('address2', MetaFormTextType.SingleLine, 35);
        f.getQuestion('addr')!
            .addTextControl('address3', MetaFormTextType.SingleLine, 35);
        f.getQuestion('addr')!
            .addTextControl('addressPostCode', MetaFormTextType.SingleLine, 10);
        f.getQuestion('addr')!
            .addOptionControl('addressCountry', MFOptions.OptionFromList(countries, undefined, false))
            .addValidator(MFValidator.Required('Please enter your address'));      

        f.addQuestion('phoneContact', 'Phone')       
            //.addTextControl('phone',  MetaFormTextType.TelephoneNumber, 20)
            .addTelephoneAndIddControl('telephone')
            .addValidator(MFValidator.Required('Please enter your contact telephone number'));

        f.addQuestion('dates', 'Applicant dates of attendance', undefined, ControlLayoutStyle.Horizontal)
            .addLabel("From")
            .addDateControl('dateFrom', MetaFormDateType.MonthYear, 1980, DateTime.now().year)
            .addValidator(MFValidator.Required('Please enter the date this applicant attended from'))
            .addValidator(MFValidator.Date('Please enter the date this applicant attended from'));

        f.addQuestion('dates2', undefined, undefined, ControlLayoutStyle.Horizontal)
            .addLabel("To")
            .addDateControl('dateTo', MetaFormDateType.MonthYear, 1980, DateTime.now().year)
            .addValidator(MFValidator.Date('Please enter a valid date'));

        f.addQuestion('relation', 'Is the applicant a family member')
            .addOptionControl('isRelation', MFOptions.OptionFromList(yesNo, undefined, true), ControlLayoutStyle.Horizontal, 1)
            .addValidator(MFValidator.Required('Required answer'));

        f.addQuestion('supervisor', 'Do you know the applicant from a position of authority or supervision e,g, teacher/manager/coach?')
            .addOptionControl('isSupervisor', MFOptions.OptionFromList(yesNo, undefined, true), ControlLayoutStyle.Horizontal, 1)
            .addValidator(MFValidator.Required('Required answer'));

        const country = f.getValue('addressCountry');
        const areaCode = this.formData.get('phoneAreaCode');
        const phone = this.formData.get('phone');

        const currentValue = `${country}:+${areaCode}:${phone}`;
        f.setValue('telephone', currentValue);

        const to = f.getValue('dateTo');
        console.log(`Date to: ${to}`);

        return f;
    }

    buildMainForm(): MetaForm {
        const f = this.formBuilder.createForm('MainForm', '', MetaFormDrawType.EntireForm);

        // Set values
        for (let [k, v] of this.formData.entries()) {
            f.setValue(k, v);
        }

        const yesNo: MFOptionValue[] = [];
        yesNo.push(new MFOptionValue('Y', 'Yes'));
        yesNo.push(new MFOptionValue('N', 'No'));

        const yesNoNA: MFOptionValue[] = [];
        yesNoNA.push(new MFOptionValue('1', 'Yes'));
        yesNoNA.push(new MFOptionValue('0', 'No'));
        yesNoNA.push(new MFOptionValue('2', 'Not Applicable'));

        const relType: MFOptionValue[] = [];
        relType.push(new MFOptionValue('1', 'Teacher/Educator'));
        relType.push(new MFOptionValue('2', 'Manager/Supervisor'));
        relType.push(new MFOptionValue('3', 'Coach/Instructor'));
        relType.push(new MFOptionValue('4', 'Camp Director'));
        relType.push(new MFOptionValue('5', 'Other'));

        const lengthKnown: MFOptionValue[] = [];
        lengthKnown.push(new MFOptionValue('3', 'More than one year'));
        lengthKnown.push(new MFOptionValue('2', 'One month to one year'));
        lengthKnown.push(new MFOptionValue('1', 'Less than one month'));

        const contact: MFOptionValue[] = [];
        contact.push(new MFOptionValue('P', 'Yes - professionally'));
        contact.push(new MFOptionValue('S', 'Yes - socially'));
        contact.push(new MFOptionValue('N', 'No'));

        f.addSection('Reference Details')
            .addQuestion('relationship-type', 'Which of the following best describes your relationship to the applicant')
            .addOptionControl('relationshipType', MFOptions.OptionFromList(relType, undefined, true), undefined, 1)
            .addValidator(MFValidator.Required('Answer is required'));

        f.addQuestion('lengthKnown', 'How long have you known the applicant?')
            .addOptionControl('lengthKnown', MFOptions.OptionFromList(lengthKnown, undefined, true), undefined, 1)
            .addValidator(MFValidator.Required('Answer is required'));

        f.addQuestion('disciplinary', 'Are you aware of any disciplinary action or investigations regarding this applicant?')
            .addOptionControl('anyDisciplinary', MFOptions.OptionFromList(yesNo, undefined, true), ControlLayoutStyle.Horizontal, 1)
            .addValidator(MFValidator.Required('Answer is required'));

        f.addQuestion('rehire', 'Would you rehire this applicant?')
            .addOptionControl('wouldRehire', MFOptions.OptionFromList(yesNoNA, undefined, true), ControlLayoutStyle.Horizontal, 1)
            .addValidator(MFValidator.Required('Answer is required'));

        f.addQuestion('contact', 'Do you currently remain in contact with the applicant?')
            .addOptionControl('contactLevel', MFOptions.OptionFromList(contact, undefined, true), undefined, 1)
            .addValidator(MFValidator.Required('Answer is required'));

        return f;
    }

    buildPersonalityForm(): MetaForm {
        const f = this.formBuilder.createForm('Personality', '', MetaFormDrawType.EntireForm);

        // Set values
        for(let [k, v] of this.formData.entries()) {
            // console.log(`setting values: ${k} = '${v}'`);
            f.setValue(k, v);
        }

        const rating: MFOptionValue[] = [];
        rating.push(new MFOptionValue('1', 'Excellent'));
        rating.push(new MFOptionValue('2', 'Good'));
        rating.push(new MFOptionValue('3', 'Average'));
        rating.push(new MFOptionValue('4', 'Poor'));
        rating.push(new MFOptionValue('5', 'N/A'));

        const questions: string[] = ['honesty', 'maturity', 'energy', 'leadership', 
            'problemSolving', 'workEthic', 'authority', 'teamwork', 'stress', 
            'interaction'];

        const titles: string[] = ['Honesty', 'Maturity/Responsibility', 'Energy/Enthusiasm', 'Leadership',
            'Problem solving', 'Work ethic', 'Response to authority', 'Teamwork', 'Stress management',
            'Interaction with children'];

        f.addSection('Personality Matrix');

        for( let i = 0; i < questions.length; i++) {
            f.addQuestion(`q_${questions[i]}`, titles[i])
                .addOptionControl(questions[i], MFOptions.OptionFromList(rating, undefined, true), ControlLayoutStyle.Horizontal, 1)
                .addValidator(MFValidator.Required('Please choose an answer'));
        }

        const yesNoInvert: MFOptionValue[] = [];
        yesNoInvert.push(new MFOptionValue('N', 'Yes'));
        yesNoInvert.push(new MFOptionValue('Y', 'No'));

        const yesNo: MFOptionValue[] = [];
        yesNo.push(new MFOptionValue('Y', 'Yes'));
        yesNo.push(new MFOptionValue('N', 'No'));

        f.addQuestion('anyConcerns', `Do you think ${this.applicantName} is a suitable person to look after children/vulnerable adults in a 24/7 setting (overnight role)?`)
            .addOptionControl('concerns', MFOptions.OptionFromList(yesNoInvert, undefined, true), ControlLayoutStyle.Horizontal, 1)
            .addValidator(MFValidator.Required('Answer is required'));

        f.addQuestion('recommendation', `Do you recommend ${this.applicantName} to work on a US summer camp?`)
        .addOptionControl('recommended', MFOptions.OptionFromList(yesNo, undefined, true), ControlLayoutStyle.Horizontal, 1)
        .addValidator(MFValidator.Required('Answer is required'));

        f.addQuestion('coda')
            .addHtml('<p class="coda">Camp America keeps Child Protection at the centre of our practices.  We are committed to the welfare of children.</p>');

        return f;
    }

    buildFinalForm(): MetaForm {
        const f = this.formBuilder.createForm('FinalForm', '', MetaFormDrawType.EntireForm);

        // Set values
        for (let [k, v] of this.formData.entries()) {
            // console.log(`setting values: ${k} = '${v}'`);
            f.setValue(k, v);
        }

        f.addSection('Closing Details')
            .addQuestion('summary', 'Please give a description of the applicant\'s performance and role in your organisation');

        const issues = this.needsMoreInformation()

        if( issues.needed ) {
            const list: string[] = [];
            if (issues.recommend) list.push(`<li>reason for not recommending</li>`);
            if (issues.rehire) list.push(`<li>reason for not rehiring</li>`);
            if (issues.disciplinary) list.push(`<li>reason for disciplinary action</li>`);
            if (issues.concerns) list.push(`<li>concerns about working with children/vulnerable adults</li>`);

            const label = `<p>Please also explain any:<br><ul>${list.join('')}</ul></p>`;

            f.getQuestion('summary')!
                .addHtml(label);
        }

        f.getQuestion('summary')!
            .addTextControl('refereeSummary', MetaFormTextType.MultiLine, 2000)
            .addValidator(MFValidator.Required('Answer is required'));

        return f;
    }

    onFormEvent(event: MetaFormUserEvent): void {
        switch (event.type) {
            case UserEventType.FormInitialised:
                // console.log(`${event.form.name} is initialised. Is it valid? ${event.form.isValid(true)}`);
                this.currentFormIsValid = false;
                break;
            case UserEventType.FormInvalid:
                // console.log(`${event.form.name} is Invalid`)
                this.currentFormIsValid = false;
                break;
            case UserEventType.FormInvalidOnAttemptedSubmit:
                let scrollToTop = window.setInterval(() => {
                    let pos = window.pageYOffset;
                    if (pos > 0) {
                        window.scrollTo(0, pos - 180); // how far to scroll on each step
                    } else {
                        window.clearInterval(scrollToTop);
                    }
                }, 16);
                break;
            case UserEventType.FormValid:
                // console.log(`${event.form.name} is Valid`)
                this.currentFormIsValid = true;
                break;
            case UserEventType.NavigationButtonClickedBack:
                break;
            case UserEventType.NavigationButtonClickedForward:
                break;
            case UserEventType.FormSubmit:
                break;
        }
        this.checkButtonState();
    }

    save(andContinue: boolean = true, earlyExit: boolean = false) {
        let user = this.authService.currentUser!;
        let type = 'GST';
        let id = user.id;

        // console.log(`Saving - is this the final? ${!andContinue}, is it an early exit? ${earlyExit}`);

        // Do save from this.referenceDetails
        var request = { ...this.contactDetails?.getAnswers(), ...this.mainForm?.getAnswers(), ...this.personalityForm?.getAnswers(), ...this.finalForm?.getAnswers() };
        this.referenceService
            .writeReference(id, request, andContinue, earlyExit)
            .subscribe({
                next: (result: PostWriteRefereeDataResponse) => {
                    //console.log(`Save? ${JSON.stringify(result)}`);
                    if (result.success) {
                        if(andContinue) {
                            // console.log(`Successfully saved, status not updated`);
                            return;
                        }

                        // console.log(`Successfully saved for later -- show alert message to user`);
                        this.sequenceTitle = "Thank you!";
                        this.step = this.STEP_5_FINAL_PAGE;
                        // this
                        //     .showAlert("Reference",
                        //         `Your reference for ${this.referenceDetails.data.applicantFirstName} has been saved.`,
                        //         'info')
                        //     .subscribe(r => {
                        //         this.router.navigateByUrl(`/references/referee/${this.referenceId}`);
                        //     });

                    } else {
                        this.showAlert("Reference", "There was an error attempting to save your reference. Please try again.", "danger");
                    }
                },
                error: (error: any) => {
                    this.showAlert("Reference", "There was an error attempting to save your reference. Please try again.", "danger", error);
                }
            });
    }

    clearValues(): void {
        this.application.setValue("referenceId", null);
    }

    showAlert(title: string, explain: string, css: string = "danger", error: string | undefined = undefined): Observable<boolean> {
        let s = new Subject<boolean>();

        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = css;
        if (error) modalRef.componentInstance.error = error;

        modalRef.result.then(result => {
            s.next(true);
        })

        return s;
    }

    sequenceTitle: string = '';
    applicantName = '';
    title: string = '';
    isLoaded: boolean = false;
    step = 0;
    contactDetails?: MetaForm;
    mainForm?: MetaForm;
    personalityForm?: MetaForm;
    finalForm?: MetaForm;

    currentFormIsValid = false;

    formData = new Map<string, string>();

    // matrixValues: PersonalityMatrixItem[] = [];

    // isPersonalEmail: boolean = false;
    // isRelationshipOther: boolean = false;
    // relatedToApplicant: boolean = false;

    referenceId!: number;
    referenceDetails!: Reference;
    submitted: boolean = false;

    previousEnabled: boolean = false;
    nextEnabled: boolean = true;

    nextButtonText: string = "Next";

    STEP_0_CONTACT_DETAILS = 0;
    STEP_1_MAIN_FORM = 1;
    STEP_2_PERSONALITY = 2;
    STEP_3_SUMMARY = 3;
    STEP_4_CANNOT_ACCEPT = 4;
    STEP_5_FINAL_PAGE = 5;
}

export class PersonalityMatrixItem {
    name: string;
    value: string = '';
    constructor(name: string, value: string) {
        this.name = name;
        this.value = value;
    }
}
