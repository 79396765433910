import { AfterViewInit, HostListener } from '@angular/core';

export class WindowSize {
    //   width$: Observable<number>;
    //   height$: Observable<number>;

    constructor() {
        // let windowSize$ = createWindowSize$();
        // this.width$ = (windowSize$.pluck('width')).distinctUntilChanged();
        // this.height$ = (windowSize$.pluck('height')).distinctUntilChanged();
    }

    // @HostListener("window:resize", [])
    // onResize() {
    //     this.detectScreenSize();
    // }

    // ngAfterViewInit() {
    //     this.detectScreenSize();
    // }

    // detectScreenSize() {

    // }
}

// const createWindowSize$ = () =>
//     fromEvent(window, 'resize')
//     .map(getWindowSize)
//     .startWith(getWindowSize())
//     .publishReplay(1)
//     .refCount();

// const getWindowSize = () => {
//   return {
//     height: window.innerHeight,
//     width: window.innerWidth
//   }
// };