<div *ngIf="isLoaded" class="content-wrapper">
    <h3>{{sequenceTitle}}</h3>
    <h4 *ngIf="title">{{title}}</h4>
    <div [ngSwitch]="step">
        <!-- Referee Details Page -->
        <div *ngSwitchCase="STEP_0_CONTACT_DETAILS">
            <p>Please review the following details and update where necessary.</p>
            <lib-metaform-display *ngIf="contactDetails" [form]="contactDetails" [showButtons]="false" [buttonErrorText]="'Disabled'" (formEvent)="onFormEvent($event)"></lib-metaform-display>
        </div>

        <!-- Main form -->
        <div *ngSwitchCase="STEP_1_MAIN_FORM">
            <lib-metaform-display *ngIf="mainForm" [form]="mainForm" [showButtons]="false" [buttonErrorText]="'Disabled'" (formEvent)="onFormEvent($event)"></lib-metaform-display>
        </div>

        <!-- Personality -->
        <div *ngSwitchCase="STEP_2_PERSONALITY">
            <lib-metaform-display *ngIf="personalityForm" [form]="personalityForm" [showButtons]="false" [buttonErrorText]="'Disabled'" (formEvent)="onFormEvent($event)"></lib-metaform-display>
        </div>

        <!-- Final questions, text entry -->
        <div *ngSwitchCase="STEP_3_SUMMARY">
            <lib-metaform-display *ngIf="finalForm" [form]="finalForm" [showButtons]="false" [buttonErrorText]="'Disabled'" (formEvent)="onFormEvent($event)"></lib-metaform-display>
        </div>

        <!-- Family member or not supervisor warning -->
        <div *ngSwitchCase="STEP_4_CANNOT_ACCEPT">
            <p>We are unable to accept references from family members or people who do not know the applicant from a supervisory position.</p> 
            <p>If you have made an error please go back to correct.</p>
            <p>If you are a family member/not a supervisor please click <strong>Next</strong> to submit the form.</p>
        </div>

        <!-- Ending Page -->
        <div *ngSwitchCase="STEP_5_FINAL_PAGE">
            <p>Thank you for completing the reference.</p>
            <p>Kind regards<br>Camp America.
            </p>
        </div>
    </div>
</div>

<div *ngIf="step !== STEP_5_FINAL_PAGE" id="tracker-buttons" class="tracker-buttons trackbuttons trackbuttons-bottom">
    <button type="button" class="btn btn-track-previous" (click)="stepPrevious()" [disabled]="!previousEnabled">
        <span class="fa-solid fa-chevron-left"></span>&nbsp;</button>

    <button type="button" class="btn btn-track-next" (click)="stepNext()" [disabled]="!nextEnabled">{{nextButtonText}}
        <div class="pull-right">
            <span class="fa-solid fa-chevron-right"></span>
        </div>
    </button>
</div>
<ng-template ngbModalContainer></ng-template>
