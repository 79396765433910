<div class="modal-header">
    <h4 class="modal-title {{cssClass}}">{{title}}</h4>
</div>
<div class="modal-body">
    <p>{{body}}</p>
    <div *ngIf="uploadsStarted">
        <div *ngFor="let upload of uploadProgress">
            <p>
                <ngb-progressbar type="success" [value]="upload.percent" [striped]="true" [animated]="true"></ngb-progressbar>
            </p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-ca-danger" (click)="activeModal.close(false)">{{buttonText}}</button>
</div>