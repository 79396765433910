import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { BaseService } from '@aifs-shared/common/base-service';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

import { ServiceResponsePayload } from '../shared/common/service-response';

import { Lookup } from '../shared/lookup/lookup';
import { environment } from '../../environments/environment';

import { Skill, AssignedSkill, SkillCategory, SkillCategoryResponse, SkillDemand } from './skills'

@Injectable()
export class SkillService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    public getCategories(): Observable<SkillCategoryResponse> {
        let subject = new Subject<SkillCategoryResponse>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_SkillsCategories)
            .subscribe({
                next: (data:any) => {
                    const response: SkillCategoryResponse = data.result["categories"];
                    subject.next(response);
                },
                error: (error:any) => {
                    subject.error(error);
                }
                });

        return subject;
    }

    public getDemandSkillsList(categoryCode: string): Observable<SkillDemand> {
        let subject = new Subject<SkillDemand>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_SkillsForCategory(categoryCode))
            .subscribe({
                next: (data:any) => {
                    const response: SkillDemand = data.result["demand"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getSkillById(skillId: number): Observable<Skill> {
        let subject = new Subject<Skill>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_SkillById(skillId))
            .subscribe({
                next: (data: any) => {
                    const response: Skill = data.result["skillById"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;

    }

    public getAllSkillsList(): Observable<Skill[]> {
        let subject = new Subject<Skill[]>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_AllSkills)
            .subscribe({
                next: (data: any) => {
                    const response: Skill[] = data.result["allSkills"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getSkillsForCurrentApplicant(): Observable<SkillsForCurrentApplicantResponse> {
        let subject = new Subject<SkillsForCurrentApplicantResponse>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_Skills_ForCurrentApplicant)
            .subscribe(
                data => {
                    var results = this.getResultData<SkillsForCurrentApplicantResponse>(data);

                    subject.next(results);
                },
                error => {
                    this.handleError(error);
                    subject.error(error);
                });

        return subject
    }

    public saveSkillRatingForCurrentApplicant(req: SaveSkillRatingForCurrentApplicantRequest): Observable<SaveSkillRatingForCurrentApplicantResponse> {
        let subject = new Subject<SaveSkillRatingForCurrentApplicantResponse>();
        this.http
            .post<BaseResponse>(environment.ServiceUrl_Skills_SaveRatingForCurrentApplicant, req)
            .subscribe(
                data => {
                    var results = this.getResultData<SaveSkillRatingForCurrentApplicantResponse>(data);

                    subject.next(results);
                },
                error => {
                    this.handleError(error);
                    subject.error(error);
                });

        return subject
    }

    public saveSkillForCurrentApplicant(req: SaveSkillRequest): Observable<any> {
        let subject = new Subject<any>();
        this.http
            .post<BaseResponse>(environment.ServiceUrl_Skills_SaveForCurrentApplicant, req)
            .subscribe(
                data => {
                    var results = this.getResultData<any>(data);

                    subject.next(results);
                },
                error => {
                    this.handleError(error);
                    subject.error(error);
                });

        return subject
    }

    public deleteSkillForCurrentApplicant(req: DeleteSkillRequest): Observable<any> {
        let subject = new Subject<any>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_Skills_DeleteSkill, req)
            .subscribe(
                data => {
                    var results = this.getResultData<any>(data);

                    subject.next(results);
                },
                error => {
                    this.handleError(error);
                    subject.error(error);
                });

        return subject
    }

    public getAdvancedQualificationsForSkill(req: { skillId: number }): Observable<Lookup[]> {
        let subject = new Subject<Lookup[]>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_Skills_AdvancedQualificationsForSkill(req))
            .subscribe(
                data => {
                    var results = this.getResultData<AdvancedQualificationsForSkillResponse>(data);

                    subject.next(results.advancedQualifications);
                },
                error => {
                    this.handleError(error);
                    subject.error(error);
                });

        return subject;
    }
}

export interface SaveSkillRatingForCurrentApplicantRequest {
    applicantSkillId: number;
    skillId: number;
    skillRating: number;
}

export class SaveSkillRatingForCurrentApplicantResponse implements ServiceResponsePayload {
    applicantSkillId!: number;
}

export class SkillsForCurrentApplicantResponse implements ServiceResponsePayload {
    assignedSkills?: AssignedSkill[];
    programmeCode?: string;
    hasAdvancedHighDemandSkill?: boolean;
    addedSufficientSkillsForPreInterview?: boolean;
    addedSufficientSkillsForSecondIteration?: boolean;
}

export interface SaveSkillRequest {
    applicantSkillId: number;
    skillRating?: number;
    skillId: number;
    skillDetails?: string;
    advancedQualification?: string;
    coachingLevel?: string;
    coachingDetails?: string;
}

export interface DeleteSkillRequest {
    applicantSkillId: number;
}

export class AdvancedQualificationsForSkillResponse implements ServiceResponsePayload {
    /**
     * There is a lookup model but the code here is a number, not a string like in the rest of the lookups
     * Hence the custom definition
     */
    advancedQualifications: Lookup[] = [];
}