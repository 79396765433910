import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

import { Message, MessageType } from './message';

@Injectable()
export class MessageService extends BaseService {

    constructor(private http: HttpClient) {
        super();
    }

    public getUnreadMessageCount(): Observable<number> {
        var subject = new Subject<number>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_Message_GetUnread)
            .subscribe({
                next: (data: any) => {
                    subject.next(data.result["unread"]);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public getMessages(forMailboxType: MessageType): Observable<GetMessageResponse> {
        if (forMailboxType == MessageType.Received)
            return this.getReceivedMessages();

        return this.getSentMessages();
    }

    public setMessageRead(id: number): Observable<any> {
        let subject = new Subject<any>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_Message_MarkRead(id)}`, null)
            .subscribe({
                next: (data: any) => {
                    let response = data.resultCode;
                    if (data.resultCode == 1)
                        subject.next(response);
                    else
                        subject.error(data.error);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public sendReplyTo(id: number, replyText: string): Observable<any> {
        let subject = new Subject<any>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_Message_PostReply(id)}`,
                { "body": replyText })
            .subscribe({
                next: (data: any) => {
                    let response = data.resultCode;
                    if (data.resultCode == 1)
                        subject.next(response);
                    else
                        subject.error(data.error);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public sendMessageToInterviewer(interviewerId: number, subject: string, body: string): Observable<SendMessageToInterviewerResponse> {
        let s = new Subject<SendMessageToInterviewerResponse>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_Message_SendToInterviewer}`,
                { "interviewerId": interviewerId, "subject": subject, "body": body })
            .subscribe({
                next: (data: any) => {
                    let response = this.getResultData<SendMessageToInterviewerResponse>(data, false);
                    if (data.resultCode == 1)
                        s.next(response);
                    else
                        this.handleError(data.error);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return s;
    }

    getReceivedMessages(): Observable<GetMessageResponse> {
        let subject = new Subject<GetMessageResponse>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_Message_GetReceived)
            .subscribe({
                next: (data: any) => {
                    let response = this.getResultData<GetMessageResponse>(data, false);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    getSentMessages(): Observable<GetMessageResponse> {
        let subject = new Subject<GetMessageResponse>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_Message_GetSent)
            .subscribe({
                next: (data: any) => {
                    let response = this.getResultData<GetMessageResponse>(data, false);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }
}

export class GetMessageResponse implements ResponseData {
    messages!: Message[];
}

export class SendMessageToInterviewerResponse implements ResponseData {
    messageId!: number;
    recipientName!: string;
}
