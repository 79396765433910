<div class="content-wrapper">
    <h3>Service Status</h3>
    <table>
        <tr>
            <th class="name">Service</th>
            <th class="status">Status</th>
            <th class="status">Database</th>
        </tr>
        <tr *ngFor="let check of checks">
            <td class="name">{{check.name}}</td>
            <td class="status">{{statusText(check.serverStatus)}}</td>
            <td class="status">{{statusText(check.dbStatus)}}</td>
        </tr>
    </table>
</div>