<button *ngIf="allowSaveForLater" type="button" class="pull-right btn btn-save-later" (click)="saveForLater()">
    <span class="far fa-bookmark"></span>
    Save for Later
</button>
<form *ngIf="formGroup" [formGroup]="formGroup" autocomplete="false" nofill>
    <div *ngFor="let question of questionsToDisplay" class="clear-left form-group">
        <div *ngIf="!question.displayIf || displayIfResolver(question.displayIf)"
            [@enterAnimation]="animState(question, 'enterAnimationState')">
            <label *ngIf="!(form!.isReadOnly) && question.intro" class="question-intro">{{question.intro}}</label>
            <label *ngIf="question.caption" innerHtml="{{question.caption}}"></label>
            <span *ngFor="let mq of question.items">
                <mf-question [parent]="question" [questionName]="question.name" [metaform]="form!"
                    [dataProvider]="dataProvider" [questionItem]="mq" [formGroup]="formGroup"
                    [validationErrorMessages]="validationMessages" (valueChanged)="valueChanged($event)"
                    [scrambleValues]="scrambleValues"></mf-question>
            </span>
            <div class="footnote-text" *ngIf="question.captionFootnote">{{ question.captionFootnote }}</div>
        </div>
    </div> 
</form>