import { Component, Input } from '@angular/core'

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'alert',
    templateUrl: 'alert.modal.html',
    styleUrls: ['./less/alert.scss']
})
export class AlertModal {
    @Input() title!: string
    @Input() body!: string
    @Input() extra?: string;
    @Input() cssClass: string = "info";

    constructor(public activeModal: NgbActiveModal) { }

    buttonText: string = "OK";
}