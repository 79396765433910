import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-trustbox',
    templateUrl: './trustbox.component.html',
    styleUrls: ['./trustbox.component.scss']
})
export class TrustboxComponent implements OnInit {

    constructor() { }
    ngOnInit() {
        try {
            const trustboxRef = document.getElementById('trustbox');
            if ((window as any).Trustpilot) {
                (window as any).Trustpilot.loadFromElement(trustboxRef);
            } else {
                console.warn(`TP: not active on window obj`);
            }
        } catch(e: any) {
            console.warn(`TP: ${e}`);
        }
    }

}
