import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UserService } from '../user/user.service';
import { PlacementService } from '../../placement/placement.service'
import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { PlacementInformation } from '../../placement/placement-information';

@Component({
    selector: 'app-applicant-status',
    templateUrl: './applicant-status.component.html',
    styleUrls: ['./less/applicant-status.component.scss']
})
export class ApplicantStatusComponent implements OnInit {
    constructor(
        private userService: UserService,
        private placementService: PlacementService,
        private trackerService: TrackerService) { }

    ngOnInit() {
        //this.userState = this.userService.applicantRole().state;
        const applicantRole = this.userService.applicantRole();
        if(applicantRole) {
            this.applicationId = applicantRole.activeApplicationId!;
            if (this.applicationId !== 0) {
                this.placementService.readySetGoStatus(this.applicationId)
                    .subscribe(
                        status => {
                            this.setUserState(status.rsgStatus);
                        },
                        error => {
                        }
                    );

            }

            this.name = this.userService.user().firstName;
            this.applicationSeason = applicantRole.activeApplicationSeason!;
            this.isNextSeason = applicantRole.isNextSeason!;
        }
    }

    setUserState(rsgStatus: string) {
        const applicantRole = this.userService.applicantRole();
        if(!applicantRole) return;
        
        const rolloverStatus = applicantRole.rolloverStatus;

        //special case for 2020 rollover applicants
        if (rolloverStatus && (rolloverStatus === "Offered" || rolloverStatus === "Accepted" || rolloverStatus === "Declined")) {
            switch (applicantRole.rolloverStatus) {
                case "Offered":
                    this.userState = "<p>We have a special offer for you! Please see your TODO list below.</p>";
                    break;
                case "Accepted":
                    this.userState = "Thank you for choosing to defer your application to summer 2021. Once we open the 2021 season in July, your details will automatically transfer. If you have any questions please <b><a href=\"/contact-us\">get in touch </a></b> or check our <b><a href=\"https://www.campamerica.co.uk/cad/rollover-accepted \">FAQ</a></b>";
                    break;
                case "Declined":
                    this.userState = "We are sorry that due to the situation with coronavirus we are not able to get you out to camp this summer. You have chosen to reject the offer to defer your application to summer 2021. If you have any questions please <b><a href=\"/contact-us\">get in touch </a></b> or check our <b><a href=\"https://www.campamerica.co.uk/cad/rollover-declined \">FAQ</a></b>";
                    break;
            }
        }
        else {
            switch (applicantRole.state) {
                case "PreInterview":
                    this.userState = "<p>You are waiting to be interviewed</p>";
                    break;
                case "PostInterview":
                    this.userState = "<p>You have completed your interview</p>";
                    break;
                case "Returner":
                    this.userState = `<p>You are reapplying for next summer. If this is going to be your 3rd year+ at camp please check <a href="https://www.campamerica.co.uk/CAD/3rd-year-plus-returners" target="_blank">the regulations</a>.</p>`;
                    break;
                case "PendingAcceptanceAsReturner":
                    this.userState = "<p>Your application is being processed in our office</p>";
                    break;
                case "ParticipantApplied":
                    this.placementService
                        .getPlacementInformation(this.applicationId!)
                        .subscribe({
                            next: (camp: PlacementInformation) => {
                                if(camp && camp.hasData) this.userState = `<p>You are placed at ${camp.campName} but this cannot be confirmed until your <a href="/references/review">references</a> are approved</p>`;
                                else this.userState = "<p>Your application is being processed in our office</p>";
                            }
                        });
                    break;
                case "AcceptedOnProgramme":
                    if (rolloverStatus && rolloverStatus === 'Completed') {
                        // CA18-1542
                        this.userState = "<p>You have started your application process</p>";
                    } else {
                        this.userState = "<p>You have completed your interview</p>";
                    }

                    break;
                case "RejectedByInterviewer":
                    this.userState = "<p>Unfortunately, we are unable to accept your application for this summer. This could be due to a number of reasons such as skills, experience, availability or current placement prospects. We are sorry for the disappointment and wish you all the best for your summer plans.</p>";
                    break;
                case "RejectedByOffice":
                    this.userState = "<p>Unfortunately, we are unable to accept your application for this summer. This could be due to a number of reasons such as skills, experience, availability or current placement prospects. We are sorry for the disappointment and wish you all the best for your summer plans.</p>";
                    break;
                case "ORCAReject":
                    this.userState = "<p>Your application has been submitted to our office</p>";
                    break;
                case "ParticipantAppliedPFT":
                    this.userState = `<p>Your application is on hold.We need to discuss your application with you. If you have not heard from us, please <a href="/contact-us" target="_blank"> contact us</a>.</p>`;
                    break;
                case "ParticipantReadyToPlace":
                    this.userState = "<p>Your application is available for camps to view. Make sure you get back to any camps that contact you!</p>";
                    break;
                case "ParticipantReadyToPlaceGP":
                    this.userState = "<p>You are being given priority to find you a placement at camp.  Make sure you get back to any camps that contact you.</p>";
                    break;
                case "ParticipantReadyToPlaceMM":
                    if (rsgStatus == "RSO") {
                        this.userState = `<p>You have been selected to <a href="/placement" target="_blank">Get Visa Ready Now</a>.</p>`;
                    }
                    else if (rsgStatus == "RSA") {
                        this.userState = `<p>You have accepted the <a href="https://www.campamerica.co.uk/cad/rsg-accepted" target="_blank">Get Visa Ready Now</a> option. For more info see our </p><a href="https://www.campamerica.co.uk/uploads/cad/Participant_Orientation_Handbook_2024.pdf" target="_blank">Participant Handbook</a>.</p>`;
                    }
                    else if (rsgStatus == "RSD") {
                        this.userState = `<p>Your application is available for camps to view. You have declined the Get Visa Ready Now option. If you change your mind, <a href="/contact-us" target="_blank">contact us</a>.</p>`;
                    }
                    break;
                case "ParticipantPlaced":
                    this.userState = `<p>You are now placed. Congratulations! For more info see our <a href="https://www.campamerica.co.uk/uploads/cad/Participant_Orientation_Handbook_2024.pdf" target="_blank">Participant Handbook</a>.</p>`;
                    break;
                case "ParticipantOutOfProgrammeCNX":
                    this.userState = "You have cancelled from the programme. Made a mistake? Contact your local office urgently!";
                    break;
                case "ParticipantOutOfProgrammeEXP":
                    this.userState = "Your application has expired due to lack of progress. If you are still interested in taking part this summer, please contact your local office.";
                    break;
                case "ParticipantOutOfProgrammeFAI":
                    this.userState = "Unfortunately, we are unable to accept your application for this summer. This could be due to a number of reasons such as skills, experience, availability or current placement prospects. We are sorry for the disappointment and wish you all the best for your summer plans.";
                    break;
                case "ParticipantOutOfProgrammeREJ":
                    this.userState = "Unfortunately, we are unable to accept your application for this summer. This could be due to a number of reasons such as skills, experience, availability or current placement prospects. We are sorry for the disappointment and wish you all the best for your summer plans.";
                    break;
                case "ParticipantOutOfProgrammeTER":
                    this.userState = "You are no longer placed at camp. Please make contact with the US office to confirm your departure from the programme & the US.";
                    break;
                case "ParticipantOutOfProgrammeUNS":
                    //this.userState = "Unfortunately, we have been unable to find you a suitable camp for this summer. We had an extremely large number of applications this year, which resulted in strong competition for placements.<br />We would love you to reapply next season when applications re-open in July/August. With more time to find you a camp, we’re sure we can find you placement! In the meantime you can improve your application for next summer by getting more childcare experience, improving  your skills or gaining work experience.<br />Still want to spend the summer travelling? Check out Camp America Treks with a 15% discount and our sister programme Au Pair in America.";
                    this.userState = `We are sorry that we have not been able to find a camp placement for you this summer.  We realise that this must be disappointing and hope you can make alternative plans.<br><br>If you would like us to look for a camp for you next year, then you will be able to log on to your online CA account and resubmit your application when the new season opens in July.  `
                    break;
            }
        }
    }
    name?: string;
    userState?: string;
    userStateExtra?: string;
    isNextSeason?: boolean;
    applicationSeason?: number;
    applicationId?: number;
}
