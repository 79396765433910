import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApplicationService } from '@aifs-shared/application/application.service';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { UserService } from '@aifs-shared/user/user.service';

@Component({
    selector: 'app-season-closed',
    templateUrl: './season-closed.component.html'
})
export class SeasonClosedComponent implements OnInit {
    constructor(
        http: HttpClient,
        private authService: AuthenticationService,
        private userService: UserService,
        private application: ApplicationService) {
    }

    ngOnInit(): void {       
        if (this.userService.user().isApplicant) {
            const user = this.userService.user().applicantRole;
            if (user) {
                const activeApplicationId = user.activeApplicationId!;
                const userState = user.state != "" ? user.state : user.nextSeasonState;
                // https://aifsdevuk.atlassian.net/browse/CA-1542
                // This affects the message to ONLY PreInterview when the country of application
                // is temporarily closed.
                if (userState == 'PreInterview') {
                    this.application.seasonOpenStatus(activeApplicationId)
                        .subscribe(
                            s => {
                                this.seasonThis = s.result.season;
                                this.seasonNext = this.seasonThis + 1;
                                this.seasonTemporaryClosed = s.result.lateSeasonClosure;
                            },
                            e => {
                                console.error(e);
                            }
                        );
                }
            }
        }
    }

    seasonThis = 2020;
    seasonNext = 2021;
    seasonTemporaryClosed: boolean = false;
}
