<ng-container *ngIf="readonly; else rw">
    <div class="ro text-display">
        <ul class="desc">
            <li *ngFor="let opt of selectedItemDescriptions">{{opt}}</li>
        </ul>
    </div>
</ng-container>
<ng-template #rw>
    <button *ngFor="let opt of questionItem.options, let i = index" class="selectable-item{{isMultiSelected(opt) ? ' option-select-m-picked' : ''}}"
        role="checkbox" (click)="selectOptionMulti(opt)">
        <i *ngIf="isMultiSelected(opt)" class="fa fa-check fa-1x"></i>
        {{opt.description}}
    </button>

    <span *ngIf="canDisplayError && !isValid" class="has-danger">
        <span class="form-control-feedback">
            {{ errorMessages }}
        </span>
    </span>
</ng-template>


<!-- <ng-template #rw>
    <button role="checkbox">
        <i class="fa fa-check fa-1x"></i>
        {{opt.description}}
    </button>

    <span *ngIf="canDisplayError && !isValid" class="has-danger">
        <span class="form-control-feedback">
            {{ errorMessages }}
        </span>
    </span>
</ng-template> -->