<div *ngIf="!overrideOffscreenProcessing">
    <div id="toaster" class="tracker-buttons trackbuttons tracker-button-position-bottom-right"
        *ngIf="enableNext() && !buttonsVisible">
        <div class="tracker-float-button">
            <button type="button" class="btn btn-track-next" (click)="nextButton()"
                [disabled]="!enableNext()">{{nextButtonText}}
                <div class="pull-right">
                    <span *ngIf="!isProcessing" class="fa-solid fa-chevron-right"></span>
                    <img *ngIf="isProcessing" src="assets/img/white-loader.gif">
                </div>
            </button>
        </div>
    </div>
</div>

<div id="tracker-buttons" class="tracker-buttons trackbuttons trackbuttons-bottom">
    <div *ngIf="hasPreviousButton; then hasPrevious else noPrevious"></div>
    <ng-template #hasPrevious>
        <button type="button" class="btn btn-track-previous btn-focus-shadow" (click)="previousButton()" [disabled]="!enablePrevious()">
            <span class="fa-solid fa-chevron-left"></span>&nbsp;</button>
        <button type="button" class="btn btn-track-next btn-focus-shadow" (click)="nextButton()"
            [disabled]="!enableNext()">{{nextButtonText}}
            <div class="pull-right">
                <span class="fa-solid fa-chevron-right"></span>
            </div>
        </button>
    </ng-template>
    <ng-template #noPrevious>
        <button type="button" class="btn btn-track-next btn-full btn-focus-shadow" (click)="nextButton()"
            [disabled]="!enableNext()">{{nextButtonText}}
            <div class="pull-right">
                <span *ngIf="!isProcessing" class="fa-solid fa-chevron-right"></span>
                <img *ngIf="isProcessing" src="assets/img/white-loader.gif">
            </div>
        </button>
    </ng-template>
</div>
<!-- <br>
<pre>{{ currentTaskName }}</pre> -->