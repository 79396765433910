<div class="content-wrapper">
    <div *ngIf="!loading; else stillLoading">
        <div *ngIf="linkExpired">
            <p>The link you clicked has expired.</p>
        </div>
    </div>
    <ng-template #stillLoading>
        <div class="load_container">
            <p>Contacting server. Please wait...</p>
            <img src="assets/img/blu-loader.gif" class="bl">
        </div>
    </ng-template>
</div>