import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TrackerService } from './tracker.service';
import { ITaskProvider } from './task-provider';
import { Task } from './task';

@Component({
    template: ''
})
export abstract class TrackerTaskComponent implements OnInit, OnDestroy, ITaskProvider {
    constructor(
        protected router: Router,
        protected tracker: TrackerService
    ) { }

    ngOnInit() {
        // console.info(`SNAP: ${this.router.routerState.snapshot}`);
        // console.info(`Do we require a task? ${this.requiresTask}`);
        // console.info(`TrackedTask::ngOnInit - ${window.location.pathname}`);
        this.tracker.registerTaskProvider(this);

        const t = this.tracker.activeTask;

        if (t) {
            //this.sequenceTitle = t.sequence.title;
            this.task = t;
            // console.log(`Got task: ${JSON.stringify(t)}`);
        }//else {
        //if (this.requiresTask) {
        //console.error(`NO TASK REDIRECT TO ${this.tracker.homeRoute}`);
        //this.router.navigateByUrl(this.tracker.homeRoute);
        //}
        //}
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
        this.tracker.unregisterTaskProvider(this);
    }

    /**
     * Default initialise - get the current task
     */
    initialise(): void {
    }

    nextEnabled(): boolean { return this.nextButtonEnabled; }
    setNextEnabled(value: boolean) { this.nextButtonEnabled = value; }
    previousEnabled(): boolean { return this.previousButtonEnabled; }

    /**
     * Override if you do anything special
     */
    stepNext(): void { }

    /**
     * Override if you do anything special
     */
    stepPrevious(): boolean {
        return false
    }

    pageError(errorMessage: any): void {
        console.warn(`ERROR on page! - '${errorMessage}' - Disabling NEXT button`);
        const lerr = sessionStorage.getItem('lastError');
        if (lerr) {
            this.errorMessage = lerr;
        } else {
            this.errorMessage = errorMessage;
        }
        this.showErrorBox = true;
        this.setNextEnabled(false);
    }

    sequenceTitle: string = '';
    nextButtonEnabled = false;
    previousButtonEnabled = false;

    errorMessage?: string;
    showErrorBox: boolean = false;
    taskDataLoaded: boolean = false;
    //subscription: Subscription;

    task?: Task;
    requiresTask: boolean = true;
}