export enum DocumentType {
    Portrait = 1,
    Activity = 2,
    Reference = 3,
    Certificate = 4,
    RefereeDataWithReference = 5,
    InterviewerPortrait = 51,
    Video = 99,
    MedicalForm = 100,
    PoliceCheck = 104
}
