import { ChangeDetectorRef, Component, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { ApplicationService } from '@aifs-shared/application/application.service';
import { CreateApplication } from '@aifs-shared/application/create-application';

import { UserService } from '@aifs-shared/user/user.service';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service'

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from '@aifs-shared/modals/alert.modal';
import { Metaform } from '@aifs-shared/metaform/metaform';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, EMPTY } from 'rxjs';
import { Lookup } from '@aifs-shared/lookup/lookup';
import { MetaformViewerComponent } from '@aifs-shared/metaform/metaform-viewer.component';
import { UserPushService } from '@aifs-shared/user/user-push.service';
import { Task } from '@aifs-shared/tracker/task';
import { User } from '@aifs-shared/user/user';
import { RegistrationService } from '../registration/registration.service';

@Component({
    styleUrls: ['../shared/metaform/less/metaform-question-display.component.scss', 
                './less/create-application.component.scss'],
    templateUrl: './create-application.component.html'
})

export class CreateApplicationComponent extends TrackerTaskComponent implements MetaformDataProvider {
    @ViewChild(MetaformViewerComponent)
    formViewer!: MetaformViewerComponent

    constructor(
        router: Router,
        tracker: TrackerService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private authService: AuthenticationService,
        private userService: UserService,
        private push: UserPushService,
        private cdr: ChangeDetectorRef,
        private registrationService: RegistrationService
    ) {
        super(router, tracker);
    }

    override ngOnInit() {
        super.ngOnInit();              
        this.initialise();
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    override initialise(): void {
        // No application. Can we create one?
        this.applicationService.canCreateApplicationForApplicant(
            this.userService.applicantRole()!.applicantId
        ).subscribe({
            next: (canCreate:CreateApplication) => {
                this.checkApplicationCreationStatus(canCreate);
            },
            error: (error: any) => {
                this.pageError(error);
            }
        });
    }

    /**
     * Update the UI
     * @param creationStatus (CreateApplication) - response from applicationService call
     */
    checkApplicationCreationStatus(creationStatus: CreateApplication) {

        // console.log(`checkApplicationCreationStatus: ${creationStatus.currentSeason}, country ${creationStatus.countryName}`);
        this.checking = false;

        this.currentSeason = creationStatus.currentSeason;
        this.countryName = creationStatus.countryName;

        // No application. Can we create one?
        this.seasonClosed = this.applicationService.createApplicationSeasonIsClosed(creationStatus);
        this.tooYoung = this.applicationService.createApplicationTooYoung(creationStatus);
        // this.countryClosed = this.applicationService.createApplicationCountryClosed(creationStatus);
        this.countryNotApplication = this.applicationService.createApplicationNotApplicationCountry(creationStatus);

        //this.overrideTaskLoadCheck = this.seasonClosed || this.tooYoung || this.countryNotApplication;
        this.defaultAgencyClosed = this.applicationService.isDefaultAgencyClosed(creationStatus);

        if (this.tooYoung) {
            this.createApplicationResponse = 1;
            this.title = `Too young`;
            //} else if (this.seasonClosed) {
            //    this.createApplicationResponse = 2;
            //    this.title = `${this.currentSeason} season closed`;
        } else if (this.countryNotApplication) {
            this.createApplicationResponse = 4;
            this.title = `${this.countryName} information`;
        } else {
            // console.info(`Ready to create an application`);
            this.canStartApplication = true;
            this.hasPreviousButton = true;
            this.previousButtonEnabled = true;

            this.defaultAgencyId = creationStatus.defaultAgencyId;
            // revert CA-3239
            //if (this.defaultAgencyId > 0 && !this.defaultAgencyClosed) {
            if (this.defaultAgencyId > 0) {
                // console.info(`Has default agencyId, skipping straight to agency selection`);
                this.expectedNextStep = 999;
            } else {
                // console.log(`No default agency, checking for agency count in country`);
                this.checkDefaultAgency();
            }

            //this.router.navigateByUrl("/form/create-application");
            this.createApplicationResponse = this.expectedNextStep;
        }
    }

    checkDefaultAgency() {
        const countrycode = this.authService.currentUserData!.user?.countryCode;
        if (countrycode) {
            // console.log(`Got countrycode`);
            // Can't send an empty string as we'll 404
            // CA-3239 revert, removed second parameter from call ', !this.defaultAgencyClosed'
            this.registrationService
                .getAgencyList(countrycode)
                .subscribe({
                    next: (result: Lookup[]) => {
                        //console.log(`Got agencies for country ${countrycode}: ${JSON.stringify(result, null,2)}`);
                        this.agencies = result;
                        const valid: boolean = result.length > 0;
                        const optionalAgencies = result.length > 1;
                        if (valid && optionalAgencies) {
                            // There are more agencies; user must select; remove stored default
                            this.defaultAgencyId = 0;
                            // revert CA-3239
                            //this.expectedNextStep = this.defaultAgencyClosed ? 997 : 998;
                            this.expectedNextStep = 998;

                            this.hasPreviousButton = true;
                            this.previousButtonEnabled = true;
                            this.nextButtonEnabled = false;
                        } else {
                            if (valid) {
                                // console.log(`Only one option - setting value to ${result[0].code}`);
                                this.defaultAgencyId = +result[0].code;
                                this.saveDefaultAgency();
                                this.expectedNextStep = 999;
                            }
                        }

                        this.createApplicationResponse = this.expectedNextStep;
                    },
                    error: (error: any) => {
                        console.error(error);
                    }
                })
        }
    }

    override stepNext(): void {
        // Only permit if the country is temporarily closed
        if (this.createApplicationResponse === 8) {
            this.createApplicationResponse = this.expectedNextStep;
            //this.router.navigateByUrl("/form/create-application");
        } else if (this.createApplicationResponse === 998 || this.createApplicationResponse === 997) {
            this.saveDefaultAgency()
            .subscribe({
                next:(r:boolean) => {
                        if(r) {
                            this.createApplicationResponse = 999;        
                        }
                    },
                error: (error:any) => { console.error(error);}
                });
        } else if (this.createApplicationResponse === 999) {
            let res = this.formViewer.stepNext();
            if (res.noMoreSteps) {
                // console.log(`Creating application`);
                this.isWorking = true;
                this.buttonCaption = "Creating Application";
                this.createApplicationResponse = 1000;

                this.createApplicationProcess()
                    .subscribe(
                        created => {
                            if (!created) {
                                //     console.log(`Created successfully!`);
                                // } else {
                                this.showAlert('Create Application', 'There was a problem creating your application. Please try again, and if the problem persists, please contact support!', this.createError);
                            }
                            this.isWorking = false;
                        },
                        error => {
                            this.showAlert('Create Application', 'There was a problem creating your application. Please try again, and if the problem persists, please contact support!', error);
                            this.isWorking = false;
                            this.createState = CreationState.Error;
                        }
                    );

            }
        } else if (this.createApplicationResponse === 1000) {
            // console.log(`Navigate to ${this.tracker.homeRoute}`);
            this.router.navigateByUrl(this.tracker.homeRoute);
        }
    }

    override stepPrevious(): boolean {
        //this.clearItem();
        if (this.createApplicationResponse === 998) {
            this.router.navigateByUrl("/home");
        } else if (this.createApplicationResponse === 999) {
            let res = this.formViewer.stepPrevious();
            if (res.atStart) {
                console.log(`at start`);
                if(this.agencies.length > 1) {
                    console.log(`agency choice`);
                    this.createApplicationResponse = 998;
                } else {
                    this.router.navigateByUrl("/home");
                }
            }
        }

        return true;
    }

    saveDefaultAgency(): Observable<boolean> {
        const applicantId = this.userService.applicantRole()!.applicantId;
        return this.applicationService.selectAgency(applicantId, this.defaultAgencyId);
    }


    createApplicationProcess(): Observable<boolean> {
        let s = new Subject<boolean>();
        this.createState = CreationState.WritingForm;
        this.buttonCaption = "Creating Application";

        this.subscribeForChanges(s);

        this.formViewer
            .formComplete(this.authService, this.userService, this.applicationService, this.metaform!)
            .subscribe(
                r => {
                    // console.log(`form complete result: ${JSON.stringify(r)}`);

                    // Need to wait now for creation of application
                    this.createState = CreationState.WaitingForApplicationCreate;
                    this.buttonCaption = "Waiting for server...";

                    // This is the fail-safe if the push service chokes
                    setTimeout(() => {
                        this.waitForReload(s);
                    }, 5000);
                },
                error => {
                    console.error(error);
                    this.createError = error;
                    this.createState = CreationState.Error;
                    s.next(false);
                });

        return s;
    }

    waitForReload(subject: Subject<boolean>) {
        this.userService.reloadUserData()
            .subscribe(
                data => {
                    if (this.createState === CreationState.WaitingForApplicationCreate) {
                        const activeApplicationId = this.userService.applicantRole()!.activeApplicationId;
                        if (activeApplicationId && activeApplicationId > 0) {
                            this.completeTask(subject);
                        } else {
                            //console.log(`still no application id`);
                            setTimeout(() => {
                                this.waitForReload(subject);
                            }, 1500);
                        }
                        // } else {
                        //     console.debug(`Got a creation message, but we weren't waiting for one`);
                    }
                }
            );
    }

    subscribeForChanges(s: Subject<boolean>) {
        // console.debug(`Subscribing to userPush`);

        this.currentTask = this.tracker.currentTask;
        this.currentUser = this.userService.user();

        this.userService.userPush.pushAlert.subscribe(
            data => {
                //console.debug(`Data from push: ${JSON.stringify(data)}`);
                if (this.createState === CreationState.WaitingForApplicationCreate) {
                    // console.log(`Got data: ${JSON.stringify(data)}`);
                    if (data.data === 'ApplicantCreatedApplication' && data.extra === `${this.currentUser!.applicantRole!.applicantId}`) {
                        // console.log(`Application was created for applicant ${this.currentUser.applicantRole.applicantId}`);
                        this.completeTask(s);
                    }
                    // } else {
                    //     console.debug(`Got a creation message, but we weren't waiting for one`);
                }
            });
    }

    completeTask(s: Subject<boolean>) {
        this.createState = CreationState.UpdatingTask;
        this.buttonCaption = "Updating Tasks";

        this.tracker.markTaskAsComplete(this.currentTask!)
            .subscribe(
                r => {
                    // console.log(`Task marked complete successfully`);
                    this.createState = CreationState.Completed;
                    this.isWorking = false;
                    this.buttonCaption = "Next!";

                    s.next(true);
                },
                error => {
                    // console.debug(`Failed to mark task!`);
                    this.createState = CreationState.Error;
                    this.isWorking = false;

                    this.createError = error;
                    s.next(false);
                });
    }

    showAlert(title: string, explain: string, error: string | undefined = undefined, navigateToUrl: string | undefined = undefined) {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = explain;
        modalRef.componentInstance.cssClass = error ? 'danger' : 'info';
        if (error) {
            modalRef.componentInstance.error = error;
        }
        modalRef.result.then(e => {
            this.router.navigateByUrl("/home");
        });
    }

    initialiseCallbackProvider(form: Metaform, formGroup: FormGroup) {
        formGroup.valueChanges.subscribe(data => this.onValueChanged(formGroup, data));
    }

    loadLookup(form: Metaform, formGroup: FormGroup, source: string): Observable<Lookup[]> { return EMPTY; }
    public lookupLoaded(form: Metaform, formGroup: FormGroup, questionKey: string, options: any[]) { }
    public displayIf(form: Metaform, formGroup: FormGroup, source: string): boolean { return true; }

    formLoaded(form: Metaform, formGroup: FormGroup): { preventDisplay: boolean } {
        this.metaform = form;
        return { preventDisplay: false };
    }

    onValueChanged(formGroup: FormGroup, data?: any) {
        if (!formGroup) { return; }

        const form = formGroup;
        if(!form) return;

        if (form.get('isNextSeason'))
            this.isNextSeason = form.get('isNextSeason')?.value;

        if (form.get('availableBeforeCutOff'))
            this.availableBeforeCutOff = form.get('availableBeforeCutOff')?.value;

        if (form.get('student'))
            this.isStudent = form.get('student')?.value;

        if (form.get('returnerStatus'))
            this.returnerStatus = form.get('returnerStatus')?.value;

        if(form.get('defaultAgencyId'))
            this.defaultAgencyId = +form.get('defaultAgencyId')?.value;

        this.setNextEnabled(form.valid);
    }

    questionDisplayChanged(form: Metaform) {
        //console.log(`pageChanged`, this.formViewer.currentSection);
        this.sectionTitle = this.formViewer!.currentSection!.title;
    }

    isSelected(code:string) {
        return (this.defaultAgencyId === +code);
    }

    select(code:string) {
        this.defaultAgencyId = +code;
        this.setNextEnabled(this.defaultAgencyId > 0);
    }

    isNextSeason = "";
    availableBeforeCutOff = "";
    isStudent = "";
    returnerStatus = "";

    title: string = 'Create Application';

    createApplicationResponse?: number;

    checking: boolean = true;
    canStartApplication: boolean = false;
    seasonClosed: boolean = false;
    countryClosed: boolean = false;
    countryNotApplication: boolean = false;
    tooYoung: boolean = false;
    countryName: string = '';
    defaultAgencyClosed = false;
    currentSeason: number = 0;

    metaform?: Metaform;
    sectionTitle = "";
    isWorking = false;
    hasPreviousButton = false;
    buttonCaption = "Next";

    createError: any;

    currentTask?: Task;
    currentUser?: User;

    waitingForUpdate = false;

    createState?: CreationState;

    agencies: Lookup[] = [];
    hasAgencies = false;
    defaultAgencyId: number = 0;
    expectedNextStep = 999;
}

enum CreationState {
    WritingForm,
    WaitingForApplicationCreate,
    UpdatingTask,
    Completed,
    Error
};