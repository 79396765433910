export function getEnvironment(envDefinition: envDefinition) {
    var environ = {
        production: envDefinition.isProduction,
        isAzure: envDefinition.isAzure,
        cloudImage: envDefinition.cloudImage,
        resources: envDefinition.resources,
        secureTrading: envDefinition.secureTrading,
        sessionTimeout: envDefinition.sessionTimeout,
        googleMaps: envDefinition.googleMaps,
        magicCache: Math.random(),
        googleAnalytics: envDefinition.googleAnalytics,
        applicantView: envDefinition.applicantView,
        hubSpotTrackingScript: envDefinition.hubSpotTrackingScript,

        ServiceUrl_Payment_DotPay_Postback: (params: { guid: string }) => `${envDefinition.paymentServiceUrl}/payments/process/dotpay/postback?GUID=${params.guid}`,
        ServiceUrl_Payment_DotPay_Confirmation: (params: { guid: string }) => `${envDefinition.paymentServiceUrl}/payments/process/dotpay/confirmation?GUID=${params.guid}`,

        ServiceUrl_PushServiceHub: `${envDefinition.signalRServiceUrl}`,
        signalRUrl: envDefinition.signalRUrl,

        ServiceUrl_SkillsCategories: `${envDefinition.skillServiceUrl}/category/get-categories`,
        ServiceUrl_SkillsForCategory: (categoryCode: string) => `${envDefinition.skillServiceUrl}/category/${categoryCode}/skills`,
        ServiceUrl_SkillById: (skillId: number) => `${envDefinition.skillServiceUrl}/skill/${skillId}`,
        ServiceUrl_Skills_ForCurrentApplicant: `${envDefinition.skillServiceUrl}/skill/applicant/my-skills`,
        ServiceUrl_Skills_ForApplication: (applicationId: number) => `${envDefinition.skillServiceUrl}/skill/application/${applicationId}/skills`,
        ServiceUrl_Skills_SaveRatingForCurrentApplicant: `${envDefinition.skillServiceUrl}/skill/applicant/save-rating`,
        ServiceUrl_Skills_SaveForCurrentApplicant: `${envDefinition.skillServiceUrl}/skill/applicant/save`,
        ServiceUrl_Skills_DeleteSkill: `${envDefinition.skillServiceUrl}/skill/applicant/delete`,
        ServiceUrl_Skills_AdvancedQualificationsForSkill: (req: { skillId: number }) => `${envDefinition.skillServiceUrl}/skill/${req.skillId}/lookups/advanced-qualifications`,
        ServiceUrl_AllSkills: `${envDefinition.skillServiceUrl}/skill/applicant/all-skills`,

        ServiceUrl_InterviewLocationsNearest: `${envDefinition.interviewServiceUrl}/interview/location/near`, // /country/lat/long`;
        ServiceUrl_InterviewLocationById: `${envDefinition.interviewServiceUrl}/interview/location`, // {id}
        ServiceUrl_RequestInterview: `${envDefinition.interviewServiceUrl}/interview/request`,
        ServiceUrl_Interview: `${envDefinition.interviewServiceUrl}/interview`, // {applicationId}
        ServiceUrl_InterviewRejectOrApproveBase: `${envDefinition.interviewServiceUrl}/interview`,
        ServiceUrl_InterviewersAvailableInCountry: (countryCode: string) => `${envDefinition.interviewerServiceUrl}/interviewer/${countryCode}/available`,
        ServiceUrl_InterviewersAvailableInCountryOnline: (countryCode: string) => `${envDefinition.interviewerServiceUrl}/interviewer/${countryCode}/online`,
        ServiceUrl_InterviewersAvailableInCountryOnlineWithLocations: (countryCode: string) => `${envDefinition.interviewerServiceUrl}/interviewer/${countryCode}/online-locations`,
        ServiceUrl_InterviewersForApplicationOnline: `${envDefinition.interviewerServiceUrl}/interviewer/application/online`,
        ServiceUrl_OnlineInterviewAvailability: (applicationId: number) => `${envDefinition.interviewServiceUrl}/interview/application/${applicationId}/availability`,
        ServiceUrl_OnlineInterviewRemoveReservation: (applicationId: number) => `${envDefinition.interviewServiceUrl}/interview/application/${applicationId}/availability/clear`,
        ServiceUrl_OnlineInterviewCheckReservation: `${envDefinition.interviewServiceUrl}/interview/check`,

        ServiceUrl_RequestOnlineInterview: `${envDefinition.interviewServiceUrl}/interview/request-online`,
        ServiceUrl_CompleteOnlineInterview: `${envDefinition.interviewServiceUrl}/interview/complete-online`,

        //Placement Service
        ServiceUrl_PlacementDetailsById: `${envDefinition.placementServiceUrl}/placement`, // {applicationId}
        ServiceUrl_PlacementInfoById: `${envDefinition.placementServiceUrl}/placement/information`, // {applicationId}
        ServiceUrl_PlacementCampById: `${envDefinition.placementServiceUrl}/placement/camp`, // {applicationId}
        ServiceUrl_PlacementAreaById: `${envDefinition.placementServiceUrl}/placement/area`, // {applicationId}
        ServiceUrl_PlacementContactById: `${envDefinition.placementServiceUrl}/placement/contact`, // {applicationId}
        ServiceUrl_ConfirmPlacement: (applicationId: number) => `${envDefinition.placementServiceUrl}/placement/${applicationId}/confirm-placement`,
        ServiceUrl_PlacementDatesById: (applicationId: number) => `${envDefinition.placementServiceUrl}/placement/application/${applicationId}/dates`,

        // RSG
        ServiceUrl_RSGStatus: (applicationId: number) => `${envDefinition.placementServiceUrl}/placement/${applicationId}/rsg`,
        ServiceUrl_UpdateRSGStatus: (applicationId: number) => `${envDefinition.placementServiceUrl}/placement/${applicationId}/rsg-status`,

        //Issue service
        ServiceUrl_GetApplicantIssues: (applicationId: number) => `${envDefinition.issueServiceUrl}/issues/application/${applicationId}/disclosures`,
        ServiceUrl_UpdateApplicantIssueStatus: (applicationId: number) => `${envDefinition.issueServiceUrl}/issues/application/${applicationId}/update_disclosure_status`,

        // Interviewer service functions
        ServiceUrl_InterviewerApplicants: (interviewerId: number) => `${envDefinition.interviewerServiceUrl}/interviewer/${interviewerId}/applications`,
        ServiceUrl_InterviewerApplicantDetails: (interviewerId: number, applicationId: number) => `${envDefinition.interviewerServiceUrl}/interviewer/${interviewerId}/applications/${applicationId}`,
        ServiceUrl_InterviewDetails: (interviewerId: number, applicationId: number) => `${envDefinition.interviewerServiceUrl}/interviewer/${interviewerId}/applications/${applicationId}/interview`,
        ServiceUrl_UpdateInterviewDetails: (interviewerId: number, applicationId: number) => `${envDefinition.interviewerServiceUrl}/interviewer/${interviewerId}/applications/${applicationId}/interview`,
        ServiceUrl_ApplicationInterview: (applicationId: number) => `${envDefinition.interviewerServiceUrl}/interviewer/applications/${applicationId}`,

        ServiceUrl_CanCreateApplication_Request: (applicantId: number) => `${envDefinition.applicationServiceUrl}/application/create-status/applicant/${applicantId}`,
        ServiceUrl_CheckForNewSeason: (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/new-season`,
        ServiceUrl_ApplicantDetailsForApplication: (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/details`,
        ServiceUrl_ApplicationSeasonStatus: (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/season-status`,
        ServiceUrl_UpdateInterviewReady: `${envDefinition.applicationServiceUrl}/application/interview-ready`,

        // CAT1-70
        ServiceUrl_SelectAgency: (applicantId: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/choose-agent`,

        // Metaform
        ServiceUrl_LoadRules: `${envDefinition.formServiceUrl}/form/rules/`,
        ServiceUrl_LoadForm: (name: string) => `${envDefinition.formServiceUrl}/form/get/${name}`,

        ServiceUrl_LoadApplicationFormDataSet: (applicationId: number, dataSet: string) => `${envDefinition.formServiceUrl}/form/application/${applicationId}/${dataSet}`,
        ServiceUrl_LoadApplicantFormDataSet: (applicantId: number, dataSet: string) => `${envDefinition.formServiceUrl}/form/applicant/${applicantId}/${dataSet}`,
        ServiceUrl_WriteTemporaryForm: (keyId: number, keyType: string) => `${envDefinition.formServiceUrl}/form/store/${keyId}/${keyType}`,

        // Messages
        ServiceUrl_Message_GetUnread: `${envDefinition.messageServiceUrl}/message/unread`,
        ServiceUrl_Message_GetReceived: `${envDefinition.messageServiceUrl}/message/inbox`,
        ServiceUrl_Message_GetSent: `${envDefinition.messageServiceUrl}/message/sent`,
        ServiceUrl_Message_SendToInterviewer: `${envDefinition.messageServiceUrl}/message/send-to-interviewer`,
        ServiceUrl_Message_MarkRead: (messageId: number) => `${envDefinition.messageServiceUrl}/message/${messageId}/read`,
        ServiceUrl_Message_PostReply: (messageId: number) => `${envDefinition.messageServiceUrl}/message/${messageId}`,

        // Payments
        ServiceUrl_InitialPayment_Summary: `${envDefinition.paymentServiceUrl}/payments/initial`,
        ServiceUrl_Payment_Invoice: `${envDefinition.paymentServiceUrl}/payments/invoice`,
        ServiceUrl_Payment_InvoiceAddOption: `${envDefinition.paymentServiceUrl}/payments/invoice/add-option`,
        ServiceUrl_Payment_InvoiceRemoveOption: `${envDefinition.paymentServiceUrl}/payments/invoice/remove-option`,
        ServiceUrl_Payment_Defer_Payment: `${envDefinition.paymentServiceUrl}/payments/defer-payment`,
        ServiceUrl_Payment_FeeBilled: `${envDefinition.paymentServiceUrl}/payments/invoice/fee-status`,
        ServiceUrl_Payment_Remove_Unpaid_Fixed_Flights: `${envDefinition.paymentServiceUrl}/payments/invoice/remove-unpaid-fixed-flights`,

        // Tracker
        ServiceUrl_GetTrackerTaskRuleData: (userId: number) => `${envDefinition.trackerServiceUrl}/tracker/user/${userId}/rule-data`,
        ServiceUrl_GetTrackerTasks: (applicantId: number) => `${envDefinition.trackerServiceUrl}/tracker/applicant/${applicantId}`,
        ServiceUrl_GetTrackerTasksForApplicant: (applicantId: number) => `${envDefinition.trackerServiceUrl}/tracker/applicant/${applicantId}/task`,
        ServiceUrl_GetTrackerTasksForApplicantTask: (applicantId: number, taskId: number) => `${envDefinition.trackerServiceUrl}/tracker/applicant/${applicantId}/task/${taskId}`,
        ServiceUrl_UpdateTaskStatusForApplicant: (applicantId: number) => `${envDefinition.trackerServiceUrl}/tracker/applicant/${applicantId}/task`,
        ServiceUrl_GetTaskStatusForApplicant: (applicantId: number) => `${envDefinition.trackerServiceUrl}/tracker/applicant/${applicantId}/task`,
        ServiceUrl_ApplicationToDo: (applicantId: number, applicationId: number) => `${envDefinition.trackerServiceUrl}/tracker/applicant/${applicantId}/application/${applicationId}/todo`,
        ServiceUrl_ReturnerReadyToSubmit: (applicantId: number) => `${envDefinition.trackerServiceUrl}/tracker/applicant/${applicantId}/returner-submit`,

        // User
        ServiceUrl_User_GetPrefilled: (prefilledId: string) => `${envDefinition.userServiceUrl}/user/get-prefilled/${prefilledId}`,
        ServiceUrl_User_SignIn: `${envDefinition.userServiceUrl}/user/signin`,
        ServiceUrl_User_ForgotPassword: `${envDefinition.userServiceUrl}/user/forgot-password`,
        ServiceUrl_User_Register: `${envDefinition.userServiceUrl}/user/register`,
        ServiceUrl_User_Refresh: (userId: number) => `${envDefinition.userServiceUrl}/user/${userId}`,
        ServiceUrl_User_CheckEmailAlreadyRegistered: `${envDefinition.userServiceUrl}/user/check-already-present`,
        ServiceUrl_User_ApplicantView_Login: `${envDefinition.userServiceUrl}/user/applicant-view/login`,
        ServiceUrl_UserLookup_Country: `${envDefinition.userServiceUrl}/user/lookups/country`,
        ServiceUrl_UserLookup_TelephoneIdd: `${envDefinition.userServiceUrl}/user/lookups/idd`,
        ServiceUrl_UserLookup_Region: (countryCode: string) => `${envDefinition.userServiceUrl}/user/lookups/country/${countryCode}/regions`,
        ServiceUrl_UserLookup_Agency: (countryCode: string) => `${envDefinition.userServiceUrl}/user/lookups/country/${countryCode}/agency`,
        ServiceUrl_UserLookup_Sex: `${envDefinition.userServiceUrl}/user/lookups/sex`,
        ServiceUrl_User_PrintSummary: (applicationId: number) => `${envDefinition.userServiceUrl}/user/print-summary/${applicationId}`,

        ServiceUrl_AllEvents: (applicationId: number, countryCode: string) => `${envDefinition.eventServiceUrl}/events/${applicationId}/${countryCode}`,
        ServiceUrl_EventDetails: (eventId: number) => `${envDefinition.eventServiceUrl}/events/event-details/${eventId}`,
        ServiceUrl_AttendEvent: (applicationId: number) => `${envDefinition.eventServiceUrl}/events/event-details/attending/${applicationId}`,

        ServiceUrl_GetPrivacyPolicyLatest: `${envDefinition.userServiceUrl}/user/privacy-policy`,
        ServiceUrl_GetGenericLatestTermsAndConditions: `${envDefinition.userServiceUrl}/user/terms-and-conditions`,
        ServiceUrl_GetUserTermsAndConditions: (userId: number) => `${envDefinition.userServiceUrl}/user/${userId}/terms-and-conditions`,
        ServiceUrl_GetLatestTermsAndConditions: (userId: number, termsType: string) => `${envDefinition.userServiceUrl}/user/${userId}/terms-and-conditions/latest/${termsType}`,
        ServiceUrl_GetLatestMWPTermsAndConditions: `${envDefinition.userServiceUrl}/user/terms-and-conditions/minimum-wage-placement`,
        ServiceUrl_AgreeTermsAndConditions: (userId: number, termsId: number) => `${envDefinition.userServiceUrl}/user/${userId}/terms-and-conditions/${termsId}`,
        ServiceUrl_UserChangeSignIn: (userId: number) => `${envDefinition.userServiceUrl}/user/${userId}/change-sign-in`,
        ServiceUrl_UserChangePassword: (userId: number) => `${envDefinition.userServiceUrl}/user/${userId}/change-password`,
        ServiceUrl_UserResetPassword: (userId: number) => `${envDefinition.userServiceUrl}/user/${userId}/reset-password`,
        ServiceUrl_UserCheckPassword: `${envDefinition.userServiceUrl}/user-sec/breached`,
        ServiceUrl_UserSystemAlert: `${envDefinition.userServiceUrl}/user/alert`,
        ServiceUrl_GetApplicantViewPermissions: () => `${envDefinition.userServiceUrl}/user/applicant-view/permissions`,
        ServiceUrl_UpdateApplicantViewLastActivity: () => `${envDefinition.userServiceUrl}/user/applicant-view/last-activity`,
        ServiceUrl_GetApplicantViewDataAvailability: () => `${envDefinition.userServiceUrl}/user/applicant-view/data-availability`,

        ServiceUrl_Upload_UploadsForApplicationAndDocumentType: (params: { applicationId: number, documentType: number }) => `${envDefinition.uploadServiceUrl}/upload/application/${params.applicationId}/documentType/${params.documentType}`,
        ServiceUrl_Upload_UploadLimitsForApplicationAndDocumentType: (params: { applicationId: number, documentType: number }) => `${envDefinition.uploadServiceUrl}/upload/application/${params.applicationId}/documentType/${params.documentType}/limits`,
        ServiceUrl_Upload_UploadById: (id: number) => `${envDefinition.uploadServiceUrl}/upload/${id}`,
        ServiceUrl_Upload_DeleteById: (id: number) => `${envDefinition.uploadServiceUrl}/upload/${id}/delete`,
        ServiceUrl_Upload_GetMedicalFormStatus: (id: number) => `${envDefinition.uploadServiceUrl}/upload/application/${id}/medical-report`,
        ServiceUrl_Upload_Prepare: `${envDefinition.uploadServiceUrl}/upload/prepare`,
        ServiceUrl_Upload_Completed_Success: `${envDefinition.uploadServiceUrl}/upload/success`,
        ServiceUrl_Upload_UploadByApplicationId: (applicationId: number, documentType: number) => `${envDefinition.uploadServiceUrl}/upload/media/${applicationId}/documentType/${documentType}`,

        ServiceUrl_Application_Lookup_CountryDetails: (countryCode: string) => `${envDefinition.applicationServiceUrl}/application/country/${countryCode}`,
        ServiceUrl_Application_Lookup: `${envDefinition.applicationServiceUrl}/application/`,

        ServiceUrl_Visa_Status: (applicationId: number) => `${envDefinition.placementServiceUrl}/placement/application/${applicationId}/visa-status`,
        ServiceUrl_Visa_Status_Update: (applicationId: number) => `${envDefinition.placementServiceUrl}/placement/application/${applicationId}/visa-status`,

        ServiceUrl_Insurance_Status: `${envDefinition.insuranceServiceUrl}/insurance/status`,

        ServiceUrl_OrientationVideo_Status: (applicationId: number) => `${envDefinition.orientationServiceUrl}/orientation/application/${applicationId}/video-status`,
        ServiceUrl_UpdateOrientationVideo_Status: (applicationId: number) => `${envDefinition.orientationServiceUrl}/orientation/application/${applicationId}/video-status`,

        ServiceUrl_Camp_Preferences: (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/preferences`,
        ServiceUrl_References_Get: `${envDefinition.referenceServiceUrl}/reference/get-for-application`,
        ServiceUrl_References_Post: `${envDefinition.referenceServiceUrl}/reference/post`,
        ServiceUrl_Reference_Delete: (referenceId: number) => `${envDefinition.referenceServiceUrl}/reference/${referenceId}/delete`,
        ServiceUrl_Reference_LinkToImage: (referenceId: number, imageId: number) => `${envDefinition.referenceServiceUrl}/reference/${referenceId}/link/${imageId}`,
        ServiceUrl_Reference_UnlinkFromImage: (referenceId: number, imageId: number) => `${envDefinition.referenceServiceUrl}/reference/${referenceId}/unlink/${imageId}`,
        ServiceUrl_SuggestedReferees: (applicationId: number) => `${envDefinition.referenceServiceUrl}/reference/application/${applicationId}/suggestions`,
        ServiceUrl_SuggestedRepeatReference: (applicationId: number) => `${envDefinition.referenceServiceUrl}/reference/application/${applicationId}/suggested-camp-reference`,

        ServiceUrl_ReferenceById: (id: number) => `${envDefinition.referenceServiceUrl}/reference/for-referee/${id}`,
        ServiceUrl_WriteReference: (id: number) => `${envDefinition.referenceServiceUrl}/reference/for-referee/${id}`,
        ServiceUrl_SignInFromToken: (token: string) => `${envDefinition.userServiceUrl}/user/from-token/${token}`,
        ServiceUrl_ReferenceByUploadId: (id: number) => `${envDefinition.referenceServiceUrl}/reference/from-upload/${id}`,

        ServiceUrl_Flight_GatewayForApplication: `${envDefinition.flightServiceUrl}/flights/gateway`,
        ServiceUrl_Flight_TicketCollectionLink: (code: string) => `${envDefinition.flightServiceUrl}/flights/info/ticket-collection/${code}`,
        ServiceUrl_Flight_ApplicationOverview: `${envDefinition.flightServiceUrl}/flights/get`,
        ServiceUrl_Flight_DetailsForApplication_Outbound: (applicationId: number) => `${envDefinition.flightServiceUrl}/flights/application/${applicationId}/outbound`,
        ServiceUrl_Flight_DetailsForApplication_Return: (applicationId: number) => `${envDefinition.flightServiceUrl}/flights/application/${applicationId}/return`,
        ServiceUrl_Flight_ApplicationArrivalInAmerica: (applicationId: number) => `${envDefinition.flightServiceUrl}/flights/application/${applicationId}/arrival-in-america`,
        ServiceUrl_Flight_DIYOptionsApplication_Return: (applicationId: number) => `${envDefinition.flightServiceUrl}/flights/application/${applicationId}/diy-options`,
        ServiceUrl_Flight_CalendarForApplication_Return: (applicationId: number) => `${envDefinition.flightServiceUrl}/flights/application/${applicationId}/fixed-flight-dates`,
        ServiceUrl_Flight_ConfirmSelectedFlight: (applicationId: number) => `${envDefinition.flightServiceUrl}/flights/application/${applicationId}/confirm-flight`,
        ServiceUrl_Flight_UpdateFlightStatus: (applicationId: number) => `${envDefinition.flightServiceUrl}/flights/application/${applicationId}/outbound/status`,

        ServiceUrl_CurrentEducation: (applicantId: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/education`,
        ServiceUrl_EducationWorkHistory: (applicantId: number, type: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/history/${type}`,
        ServiceUrl_EducationWorkHistoryDelete: (applicantId: number, type: number, id: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/history/${type}/delete/${id}`,

        ServiceUrl_HealthCheck_Application: `${envDefinition.applicationServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Flight: `${envDefinition.flightServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Form: `${envDefinition.formServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Interview: `${envDefinition.interviewServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Payment: `${envDefinition.paymentServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_References: `${envDefinition.referenceServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Push: `${envDefinition.signalRServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Skill: `${envDefinition.skillServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Tracker: `${envDefinition.trackerServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_Upload: `${envDefinition.uploadServiceUrl}/health/status`,
        ServiceUrl_HealthCheck_User: `${envDefinition.userServiceUrl}/health/status`,

        ServiceUrl_Feature_GetStatus: `${envDefinition.featureServiceUrl}/feature/get-features`,

        ServiceUrl_CampChoice_GetChoicesByApplication: (applicationId: number) => `${envDefinition.selfProposalServiceUrl}/proposals/application/${applicationId}/`,
        ServiceUrl_CampChoice_PostChoice: (applicationId: number) => `${envDefinition.selfProposalServiceUrl}/proposals/application/${applicationId}/`,
        ServiceUrl_CampChoice_DeleteChoice: (applicationId: number, choiceId: number) => `${envDefinition.selfProposalServiceUrl}/proposals/application/${applicationId}/choice/${choiceId}`,
        ServiceUrl_CampChoice_SubmitForApplication: (applicationId: number) => `${envDefinition.selfProposalServiceUrl}/proposals/application/${applicationId}/submit`,

        // DBS
        ServiceUrl_DBS_GetDBSProspectDetails: (applicationId: number) => `${envDefinition.dbsServiceUrl}/dbs/application/${applicationId}/prospect`,
        ServiceUrl_DBS_Register: (applicationId: number) => `${envDefinition.dbsServiceUrl}/dbs/application/${applicationId}/register`,

        //Paper police checks
        ServiceUrl_PaperPoliceCheck_Status: (applicationId: number) => `${envDefinition.dbsServiceUrl}/dbs/paper/application/${applicationId}/status`,
        ServiceUrl_Applicant_DBSDetails: (applicationId: number) => `${envDefinition.dbsServiceUrl}/dbs/application/${applicationId}`,

        AppInsights_InstrumentationKey: envDefinition.appInsights.instrumentationKey
    };

    if (!envDefinition.isProduction) console.info(`Checking environment service roots for localhost entries`);

    if (envDefinition.dbsServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running form service locally...`)

        environ.ServiceUrl_DBS_Register = (applicationId: number) => `${envDefinition.dbsServiceUrl}/application/${applicationId}/register`;
        environ.ServiceUrl_DBS_GetDBSProspectDetails = (applicationId: number) => `${envDefinition.dbsServiceUrl}/application/${applicationId}/prospect`;
        environ.ServiceUrl_PaperPoliceCheck_Status = (applicationId: number) => `${envDefinition.dbsServiceUrl}/paper/application/${applicationId}/status`;
        environ.ServiceUrl_Applicant_DBSDetails = (applicationId: number) => `${envDefinition.dbsServiceUrl}/application/${applicationId}`;
    }

    if (envDefinition.formServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running form service locally...`)

        environ.ServiceUrl_LoadRules = `${envDefinition.formServiceUrl}/rules/`;
        environ.ServiceUrl_LoadForm = (name: string) => `${envDefinition.formServiceUrl}/get/${name}`;
        environ.ServiceUrl_LoadApplicationFormDataSet = (applicationId: number, dataSet: string) => `${envDefinition.formServiceUrl}/application/${applicationId}/${dataSet}`;
        environ.ServiceUrl_LoadApplicantFormDataSet = (applicantId: number, dataSet: string) => `${envDefinition.formServiceUrl}/applicant/${applicantId}/${dataSet}`;
        environ.ServiceUrl_WriteTemporaryForm = (keyId: number, keyType: string) => `${envDefinition.formServiceUrl}/store/${keyId}/${keyType}`;
    }

    if (envDefinition.formServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running form service locally...`)

        environ.ServiceUrl_LoadRules = `${envDefinition.formServiceUrl}/rules/`;
        environ.ServiceUrl_LoadForm = (name: string) => `${envDefinition.formServiceUrl}/get/${name}`;
        environ.ServiceUrl_LoadApplicationFormDataSet = (applicationId: number, dataSet: string) => `${envDefinition.formServiceUrl}/application/${applicationId}/${dataSet}`;
        environ.ServiceUrl_LoadApplicantFormDataSet = (applicantId: number, dataSet: string) => `${envDefinition.formServiceUrl}/applicant/${applicantId}/${dataSet}`;
        environ.ServiceUrl_WriteTemporaryForm = (keyId: number, keyType: string) => `${envDefinition.formServiceUrl}/store/${keyId}/${keyType}`;
    }

    if (envDefinition.paymentServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running payment service locally...`)

        environ.ServiceUrl_Payment_DotPay_Postback = (params: { guid: string }) => `${envDefinition.paymentServiceUrl}/process/dotpay/postback?GUID=${params.guid}`;
        environ.ServiceUrl_Payment_DotPay_Confirmation = (params: { guid: string }) => `${envDefinition.paymentServiceUrl}/process/dotpay/confirmation?GUID=${params.guid}`;
        environ.ServiceUrl_InitialPayment_Summary = `${envDefinition.paymentServiceUrl}/initial`;
        environ.ServiceUrl_Payment_Invoice = `${envDefinition.paymentServiceUrl}/invoice`;
        environ.ServiceUrl_Payment_InvoiceAddOption = `${envDefinition.paymentServiceUrl}/invoice/add-option`;
        environ.ServiceUrl_Payment_InvoiceRemoveOption = `${envDefinition.paymentServiceUrl}/invoice/remove-option`;
        environ.ServiceUrl_Payment_Defer_Payment = `${envDefinition.paymentServiceUrl}/defer-payment`;
        environ.ServiceUrl_Payment_FeeBilled = `${envDefinition.paymentServiceUrl}/invoice/fee-status`;
        environ.ServiceUrl_Payment_Remove_Unpaid_Fixed_Flights = `${envDefinition.paymentServiceUrl}/invoice/remove-unpaid-fixed-flights`;
    }

    if (envDefinition.trackerServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running tracker service locally...`)

        environ.ServiceUrl_GetTrackerTaskRuleData = (userId: number) => `${envDefinition.trackerServiceUrl}/user/${userId}/rule-data`;
        environ.ServiceUrl_GetTrackerTasks = (applicantId: number) => `${envDefinition.trackerServiceUrl}/applicant/${applicantId}`;
        environ.ServiceUrl_GetTrackerTasksForApplicant = (applicantId: number) => `${envDefinition.trackerServiceUrl}/applicant/${applicantId}/task`;
        environ.ServiceUrl_GetTrackerTasksForApplicantTask = (applicantId: number, taskId: number) => `${envDefinition.trackerServiceUrl}/applicant/${applicantId}/task/${taskId}`;
        environ.ServiceUrl_UpdateTaskStatusForApplicant = (applicantId: number) => `${envDefinition.trackerServiceUrl}/applicant/${applicantId}/task`;
        environ.ServiceUrl_GetTaskStatusForApplicant = (applicantId: number) => `${envDefinition.trackerServiceUrl}/applicant/${applicantId}/task`;
        environ.ServiceUrl_ApplicationToDo = (applicantId: number, applicationId: number) => `${envDefinition.trackerServiceUrl}/applicant/${applicantId}/application/${applicationId}/todo`;
        environ.ServiceUrl_ReturnerReadyToSubmit = (applicantId: number) => `${envDefinition.trackerServiceUrl}/applicant/${applicantId}/returner-submit`;
    }

    if (envDefinition.uploadServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running upload service locally...`)
        environ.ServiceUrl_Upload_UploadsForApplicationAndDocumentType = (params: { applicationId: number, documentType: number }) => `${envDefinition.uploadServiceUrl}/application/${params.applicationId}/documentType/${params.documentType}`;
        environ.ServiceUrl_Upload_UploadLimitsForApplicationAndDocumentType = (params: { applicationId: number, documentType: number }) => `${envDefinition.uploadServiceUrl}/application/${params.applicationId}/documentType/${params.documentType}/limits`;
        environ.ServiceUrl_Upload_UploadById = (id: number) => `${envDefinition.uploadServiceUrl}/${id}`;
        environ.ServiceUrl_Upload_DeleteById = (id: number) => `${envDefinition.uploadServiceUrl}/${id}/delete`;
        environ.ServiceUrl_Upload_GetMedicalFormStatus = (id: number) => `${envDefinition.uploadServiceUrl}/application/${id}/medical-report`;
        environ.ServiceUrl_Upload_Prepare = `${envDefinition.uploadServiceUrl}/prepare`;
        environ.ServiceUrl_Upload_Completed_Success = `${envDefinition.uploadServiceUrl}/success`;
        environ.ServiceUrl_Upload_UploadByApplicationId = (applicationId: number, documentType: number) => `${envDefinition.uploadServiceUrl}/media/${applicationId}/documentType/${documentType}`;
    }

    if (envDefinition.eventServiceUrl.indexOf('://localhost') > -1) {
        environ.ServiceUrl_AllEvents = (applicationId: number, countryCode: string) => `${envDefinition.eventServiceUrl}/${applicationId}/${countryCode}`;
        environ.ServiceUrl_EventDetails = (eventId: number) => `${envDefinition.eventServiceUrl}/event-details/${eventId}`;
        environ.ServiceUrl_AttendEvent = (applicationId: number) => `${envDefinition.eventServiceUrl}/event-details/attending/${applicationId}`;
    }

    if (envDefinition.flightServiceUrl.indexOf('://localhost') > -1) {
        environ.ServiceUrl_Flight_GatewayForApplication = `${envDefinition.flightServiceUrl}/gateway`;
        environ.ServiceUrl_Flight_TicketCollectionLink = (code: string) => `${envDefinition.flightServiceUrl}/info/ticket-collection/${code}`;
        environ.ServiceUrl_Flight_ApplicationOverview = `${envDefinition.flightServiceUrl}/get`;
        environ.ServiceUrl_Flight_DetailsForApplication_Outbound = (applicationId: number) => `${envDefinition.flightServiceUrl}/application/${applicationId}/outbound`;
        environ.ServiceUrl_Flight_DetailsForApplication_Return = (applicationId: number) => `${envDefinition.flightServiceUrl}/application/${applicationId}/return`;
        environ.ServiceUrl_Flight_ApplicationArrivalInAmerica = (applicationId: number) => `${envDefinition.flightServiceUrl}/application/${applicationId}/arrival-in-america`;
        environ.ServiceUrl_Flight_DIYOptionsApplication_Return = (applicationId: number) => `${envDefinition.flightServiceUrl}/application/${applicationId}/diy-options`;
        environ.ServiceUrl_Flight_CalendarForApplication_Return = (applicationId: number) => `${envDefinition.flightServiceUrl}/application/${applicationId}/fixed-flight-dates`;
        environ.ServiceUrl_Flight_ConfirmSelectedFlight = (applicationId: number) => `${envDefinition.flightServiceUrl}/application/${applicationId}/confirm-flight`;
        environ.ServiceUrl_Flight_UpdateFlightStatus = (applicationId: number) => `${envDefinition.flightServiceUrl}/application/${applicationId}/outbound/status`;
    }

    if (envDefinition.userServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running user service locally...`)

        environ.ServiceUrl_User_SignIn = `${envDefinition.userServiceUrl}/signin`;
        //environ.ServiceUrl_User_SignIn_Azure = `${envDefinition.userServiceUrl}/signin/azure`;
        //environ.ServiceUrl_User_CreateLogin_Azure = (userId: number) => `${envDefinition.userServiceUrl}/${userId}/azureLogin`;
        environ.ServiceUrl_User_ForgotPassword = `${envDefinition.userServiceUrl}/forgot-password`;
        environ.ServiceUrl_User_Register = `${envDefinition.userServiceUrl}/register`;
        environ.ServiceUrl_User_Refresh = (userId: number) => `${envDefinition.userServiceUrl}/${userId}`;
        environ.ServiceUrl_User_CheckEmailAlreadyRegistered = `${envDefinition.userServiceUrl}/check-already-present`;
        environ.ServiceUrl_UserLookup_Country = `${envDefinition.userServiceUrl}/lookups/country`;
        environ.ServiceUrl_UserLookup_TelephoneIdd = `${envDefinition.userServiceUrl}/lookups/idd`;
        environ.ServiceUrl_UserLookup_Region = (countryCode: string) => `${envDefinition.userServiceUrl}/lookups/country/${countryCode}/regions`;
        environ.ServiceUrl_UserLookup_Agency = (countryCode: string) => `${envDefinition.userServiceUrl}/lookups/country/${countryCode}/agency`;
        environ.ServiceUrl_UserLookup_Sex = `${envDefinition.userServiceUrl}/lookups/sex`;
        environ.ServiceUrl_User_PrintSummary = (applicationId: number) => `${envDefinition.userServiceUrl}/print-summary/${applicationId}`;
        environ.ServiceUrl_AllEvents = (applicationId: number, countryCode: string) => `${envDefinition.eventServiceUrl}/events/${applicationId}/${countryCode}`;
        environ.ServiceUrl_EventDetails = (eventId: number) => `${envDefinition.eventServiceUrl}/events/event-details/${eventId}`;
        environ.ServiceUrl_AttendEvent = (applicationId: number) => `${envDefinition.eventServiceUrl}/events/event-details/attending/${applicationId}`;
        environ.ServiceUrl_GetPrivacyPolicyLatest = `${envDefinition.userServiceUrl}/user/privacy-policy`;
        environ.ServiceUrl_GetGenericLatestTermsAndConditions = `${envDefinition.userServiceUrl}/terms-and-conditions`;
        environ.ServiceUrl_GetUserTermsAndConditions = (userId: number) => `${envDefinition.userServiceUrl}/${userId}/terms-and-conditions`;
        environ.ServiceUrl_GetLatestTermsAndConditions = (userId: number, termsType: string) => `${envDefinition.userServiceUrl}/${userId}/terms-and-conditions/latest/${termsType}`;
        environ.ServiceUrl_GetLatestMWPTermsAndConditions = `${envDefinition.userServiceUrl}/terms-and-conditions/minimum-wage-placement`;
        environ.ServiceUrl_AgreeTermsAndConditions = (userId: number, termsId: number) => `${envDefinition.userServiceUrl}/${userId}/terms-and-conditions/${termsId}`;
        environ.ServiceUrl_UserChangeSignIn = (userId: number) => `${envDefinition.userServiceUrl}/${userId}/change-sign-in`;
        environ.ServiceUrl_UserChangePassword = (userId: number) => `${envDefinition.userServiceUrl}/${userId}/change-password`;
        environ.ServiceUrl_UserResetPassword = (userId: number) => `${envDefinition.userServiceUrl}/${userId}/reset-password`;
        environ.ServiceUrl_UserCheckPassword = `${envDefinition.userServiceUrl}/user-sec/breached`;
        environ.ServiceUrl_UserSystemAlert = `${envDefinition.userServiceUrl}/alert`;
    }

    if (envDefinition.placementServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running placement service locally...`);
        environ.ServiceUrl_PlacementDetailsById = `${envDefinition.placementServiceUrl}`;
        environ.ServiceUrl_PlacementInfoById = `${envDefinition.placementServiceUrl}/information`;
        environ.ServiceUrl_PlacementCampById = `${envDefinition.placementServiceUrl}/camp`;
        environ.ServiceUrl_PlacementAreaById = `${envDefinition.placementServiceUrl}/area`;
        environ.ServiceUrl_PlacementContactById = `${envDefinition.placementServiceUrl}/contact`;
        environ.ServiceUrl_ConfirmPlacement = (applicationId: number) => `${envDefinition.placementServiceUrl}/${applicationId}/confirm-placement`;
        environ.ServiceUrl_PlacementDatesById = (applicationId: number) => `${envDefinition.placementServiceUrl}/application/${applicationId}/dates`;
        environ.ServiceUrl_RSGStatus = (applicationId: number) => `${envDefinition.placementServiceUrl}/${applicationId}/rsg`;
        environ.ServiceUrl_UpdateRSGStatus = (applicationId: number) => `${envDefinition.placementServiceUrl}/${applicationId}/rsg-status`;
        environ.ServiceUrl_Visa_Status = (applicationId: number) => `${envDefinition.placementServiceUrl}/application/${applicationId}/visa-status`;
        environ.ServiceUrl_Visa_Status_Update = (applicationId: number) => `${envDefinition.placementServiceUrl}/application/${applicationId}/visa-status`;
    }

    if (envDefinition.referenceServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running reference service locally...`);
        environ.ServiceUrl_References_Get = `${envDefinition.referenceServiceUrl}/get-for-application`;
        environ.ServiceUrl_References_Post = `${envDefinition.referenceServiceUrl}/post`;
        environ.ServiceUrl_Reference_Delete = (referenceId: number) => `${envDefinition.referenceServiceUrl}/${referenceId}/delete`;
        environ.ServiceUrl_Reference_LinkToImage = (referenceId: number, imageId: number) => `${envDefinition.referenceServiceUrl}/${referenceId}/link/${imageId}`;
        environ.ServiceUrl_Reference_UnlinkFromImage = (referenceId: number, imageId: number) => `${envDefinition.referenceServiceUrl}/${referenceId}/unlink/${imageId}`;
        environ.ServiceUrl_SuggestedReferees = (applicationId: number) => `${envDefinition.referenceServiceUrl}/application/${applicationId}/suggestions`;
        environ.ServiceUrl_SuggestedRepeatReference = (applicationId: number) => `${envDefinition.referenceServiceUrl}/application/${applicationId}/suggested-camp-reference`;
        environ.ServiceUrl_ReferenceById = (id: number) => `${envDefinition.referenceServiceUrl}/for-referee/${id}`;
        environ.ServiceUrl_WriteReference = (id: number) => `${envDefinition.referenceServiceUrl}/for-referee/${id}`;
        environ.ServiceUrl_ReferenceByUploadId = (id: number) => `${envDefinition.referenceServiceUrl}/from-upload/${id}`;
    }

    if (envDefinition.applicationServiceUrl.indexOf('://localhost') > -1) {
        console.warn(`Running application service locally`);
        environ.ServiceUrl_CanCreateApplication_Request = (applicantId: number) => `${envDefinition.applicationServiceUrl}/application/create-status/applicant/${applicantId}`;
        environ.ServiceUrl_CheckForNewSeason = (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/new-season`;
        environ.ServiceUrl_ApplicantDetailsForApplication = (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/details`;
        environ.ServiceUrl_ApplicationSeasonStatus = (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/season-status`;
        environ.ServiceUrl_UpdateInterviewReady = `${envDefinition.applicationServiceUrl}/interview-ready`;
        environ.ServiceUrl_SelectAgency = (applicantId: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/choose-agent`;
        environ.ServiceUrl_CurrentEducation = (applicantId: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/education`;
        environ.ServiceUrl_EducationWorkHistory = (applicantId: number, type: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/history/${type}`;
        environ.ServiceUrl_EducationWorkHistoryDelete = (applicantId: number, type: number, id: number) => `${envDefinition.applicationServiceUrl}/applicant/${applicantId}/history/${type}/delete/${id}`;
        environ.ServiceUrl_Camp_Preferences = (applicationId: number) => `${envDefinition.applicationServiceUrl}/application/${applicationId}/preferences`;
        environ.ServiceUrl_Application_Lookup_CountryDetails = (countryCode: string) => `${envDefinition.applicationServiceUrl}/country/${countryCode}`;
        environ.ServiceUrl_Application_Lookup = `${envDefinition.applicationServiceUrl}/`;
    }

    return environ;

}

export interface envDefinition {
    isProduction: boolean
    isAzure: boolean
    applicationServiceUrl: string
    insuranceServiceUrl: string
    interviewServiceUrl: string
    interviewerServiceUrl: string
    issueServiceUrl: string
    flightServiceUrl: string
    formServiceUrl: string
    messageServiceUrl: string
    paymentServiceUrl: string
    referenceServiceUrl: string
    signalRServiceUrl: string
    skillServiceUrl: string
    trackerServiceUrl: string
    uploadServiceUrl: string
    userServiceUrl: string
    placementServiceUrl: string,
    eventServiceUrl: string,
    featureServiceUrl: string,
    selfProposalServiceUrl: string,
    dbsServiceUrl: string,
    orientationServiceUrl: string,

    cloudImage: {
        id: string,
        replace: { s3url: string, alias: string },
    },
    
    resources: {
        imageNoProfileUrl: string
    }

    secureTrading: {
        paymentsUrl: string
        siteReference: string
        profile: string
        version: string
    },

    sessionTimeout: {
        idle: number
        timeout: number
    },

    googleMaps: {
        apiKey: string
    }
    signalRUrl: string,
    appInsights: {
        instrumentationKey: string
    },

    googleAnalytics: {
        measurementId: string
    }

    applicantView: {
        isPayNowEnabled: boolean
        isDs2019LinkViewable: boolean
        isMedicalInformationViewable: boolean
        isHeaderVisible: boolean,
        isActivityCheckActive: boolean
    },
    hubSpotTrackingScript: string
}
