<ng-container *ngIf="readonly; else rw">
    <div class="ro text-display">
        <p>{{ selectedItemDescription()}}</p>
    </div>
</ng-container>
<ng-template #rw>
    <div [ngClass]="{'flex flex-row': !isWide}">
        <button class="option-item flex {{isSelected(opt) ? 'picked' : ''}}"
            *ngFor="let opt of questionItem.options, let i = index" (click)="selectOption(opt)">
            <div class="br--left pa3 flex flex-column items-center justify-center fa-fw">
                <i *ngIf="isSelected(opt); else unchecked" class="fa fa-check-square fa-3x"></i>
                <ng-template #unchecked>
                    <i class="fa-regular fa-square fa-3x unpicked"></i>
                </ng-template>
            </div>
            <div class="br--right pa3 flex flex-column">
                <p class="mv0 lh-copy f1 fw3 option-text{{isSelected(opt) ? '-picked' : ''}}">{{opt.description}}</p>
            </div>
        </button>
    </div>
    <span *ngIf="canDisplayError && !isValid" class="has-danger">
        <span class="form-control-feedback">
            {{ errorMessages }}
        </span>
    </span>
</ng-template>