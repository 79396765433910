import { Component, OnInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { Metaform, MfQuestion, Question, MfOption, MfValueChangeEvent, ValidationError } from '@aifs-shared/metaform/metaform';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';

import { Lookup } from '@aifs-shared/lookup/lookup';

import { ErrorField } from '@aifs-shared/metaform/metaform-question-display.component';
import { MetaformDateTime } from '@aifs-shared/metaform/datetime/metaform-datetime';
import { IMfDateModel } from '@aifs-shared/metaform/metaform';

@Component({
    // moduleId: module.id,
    selector: 'mf-time',
    templateUrl: './time.component.html',
    styleUrls: ['../../less/metaform-question-display.component.scss']
})
export class TimeInputComponent implements OnInit, DoCheck {
    @Input() readonly: boolean = false;
    @Input() metaform!: Metaform;
    @Input() parent!: MfQuestion;
    @Input() questionItem!: Question<any>;
    @Input() questionName!: string;
    @Input() formGroup!: FormGroup;
    @Input() dataProvider!: MetaformDataProvider;
    @Input() validationErrorMessages!: Map<string, string>;
    @Output() valueChanged = new EventEmitter<MfValueChangeEvent>();

    constructor() { }

    ngOnInit() {
        this.questionItemKey = this.questionItem.key;
        this.initialiseTimeControl();
        this.canDisplayError = true;

        // For composite controls, we can only display the error message
        // if this item is the first in the targetFields list
        if (this.parent && this.parent.items && this.parent.items[0].targetFields) {
            this.canDisplayError = (this.parent.items[0].targetFields[0] == this.questionItemKey);
            if (this.canDisplayError) {
                // console.info(`${this.questionItemKey} is the first item in a composite field, or is a sole field`);
                this.canDisplayError = true;
            } else {
                // console.info(`${this.questionItemKey} is not able to display it's own errors`);
            }
        }

        this.updateValidity.subscribe((error) => {
            if (error) {
                if (this.errorMessages == null || this.errorMessages !== error.message) {
                    // console.info(`${this.id} - ${this.errorMessages} : Got message : ${error.message} for field ${error.questionItem}`);
                    this.errorMessages = error.message;
                }
            } else {
                this.errorMessages = undefined;
            }
        });

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                this.formGroup.get(field)!.valueChanges.subscribe(data => {
                    this.valueChanged.emit(new MfValueChangeEvent(field, data));
                });
            });
        } else {
            this.formGroup.get(this.questionItemKey)!.valueChanges.subscribe(data => {
                this.valueChanged.emit(new MfValueChangeEvent(this.questionItemKey, data));
            });
        }

        this.formGroup.get(this.questionItemKey)!.markAsPristine();

        this.formGroup.updateValueAndValidity();
    }

    ngDoCheck() {
        let valid: boolean = true;

        if (this.questionItem.targetFields) {
            this.questionItem.targetFields.forEach(field => {
                if ((this.formGroup.controls[field].touched)) {
                    // console.info(`Checking: ${field}`);
                    valid = valid && this.formGroup.controls[field].valid;

                    this.questionItem.errorField = undefined;

                    this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
                }
            });
        } else {
            if (this.formGroup.controls[this.questionItemKey].touched) {
                valid = this.formGroup.controls[this.questionItemKey].valid;
                this.updateValidity.next(this.getValidationMessages(this.questionItem.key));
            }
        }

        if (this.isValid != valid)
            // console.log(`Change in validity: ${valid}`);

            this.isValid = valid;
    }

    get formControl() {
        return this.formGroup.controls[this.questionItemKey];
    }

    formControlFor(targetIndex: 0) {
        return this.formGroup.controls[this.questionItem.targetFields[targetIndex]];
    }

    get controlMaximumInput() {
        if (this.questionItem.maxLength) {
            return this.questionItem.maxLength;
        } else {
            return 0;
        }
    }

    get hasLabel() { return this.questionItem.label !== undefined && this.questionItem.label !== null; }

    /**
     * Get all possible failing validation messages for this field
     */
    getValidationMessages(question: string): ErrorField | null {

        if (this.questionItem.targetFields) {
            if (this.questionItem.errorField && this.questionItem.currentError) {
                // console.warn(`already have an error: ${this.questionItem.errorField} (${this.questionItem.currentError}), aborting`);
                return null;
            }

            for (let i = 0; i < this.questionItem.targetFields.length; i++) {
                // console.warn(`Checking ${this.questionItem.targetFields[i]}`);
                if (this.formGroup.controls[this.questionItem.targetFields[i]].touched && !this.formGroup.controls[this.questionItem.targetFields[i]].valid) {
                    let message = this.validationMessageForItem(this.questionItem.targetFields[i]);
                    // Early exit?
                    if (message) {
                        // console.info(`Got error for ${this.questionItem.targetFields[i]}: '${message}'`)
                        this.questionItem.errorField = this.questionItem.targetFields[i];
                        this.questionItem.currentError = message;
                        return new ErrorField(this.questionItem.targetFields[i], message);
                    }
                } else {
                    this.questionItem.errorField = undefined;
                    this.questionItem.currentError = undefined;
                }
            }
            this.questionItem.errorField = undefined;
        } else {
            if (this.formGroup.controls[this.questionItem.key].touched && !this.formGroup.controls[this.questionItem.key].valid) {
                let message = this.validationMessageForItem(question);
                if (message) {
                    this.questionItem.errorField = this.questionItem.key;
                    this.questionItem.currentError = message;
                    const msg = this.validationMessageForItem(question);
                    if(msg) return new ErrorField(this.questionItem.key, msg);
                }
            }
        }

        // No errors
        return null;
    }

    public isMandatory(key: string): boolean {
        return (this.questionItem.required);
    }

    validationMessageForItem(item: string): string | null {
        const errors = this.formGroup.get(item)!.errors;
        if (errors) {
            // console.info(`getValidationMessages: for question ${item}, errors: ${JSON.stringify(errors)}`)

            let keys = [];
            let message = null;
            for (let key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const msg = this.validationErrorMessages.get(`${item}_${key}`);
                    return msg ? msg : null;
                }
            }
        }
        return null;
    }

    initialiseTimeControl() {

        let current: Date = new Date(Date.now());

        if (this.questionItem.value) {
            // console.log(`Time: Got ${this.questionItem.value}`);
            const utcddt = MetaformDateTime.getDateTime(this.questionItem.value);

            if(utcddt) {
                this.dateModel.hour = utcddt.hour;
                this.dateModel.minute = utcddt.minute;
            } else {
                this.dateModel.hour = undefined;
                this.dateModel.minute = undefined;
            }
        } else {
            this.dateModel.hour = undefined;
            this.dateModel.minute = undefined;
        }

        this.populateValidTimeRanges();
        if (this.questionItem.value) {
            // Ensure validation/change updates occur
            this.calculateAsTime();
        }
    }

    isHourSelected(hour: number | string) {
        return hour == this.dateModel.hour;
    }

    isMinuteSelected(minute: number | string) {
        return minute == this.dateModel.minute;
    }

    selectHourOption(event: any) {
        if (event.target.value == '') {
            this.dateModel.hour = undefined;
        } else {
            this.dateModel.hour = event.target.value;
        }

        this.formGroup.get(this.questionItemKey)!.markAsTouched();
        this.formGroup.get(this.questionItemKey)!.markAsDirty();
        this.calculateAsTime();
    }

    selectMinuteOption(event: any) {
        if (event.target.value == '') {
            this.dateModel.minute = undefined;
        } else {
            this.dateModel.minute = event.target.value;
        }

        this.formGroup.get(this.questionItemKey)!.markAsTouched();
        this.formGroup.get(this.questionItemKey)!.markAsDirty();
        this.calculateAsTime();
    }

    calculateAsTime() {
        if (!this.questionItem.required) {
            if (this.questionItem.controlType === 'time') {
                if (!this.dateModel.hour && !this.dateModel.minute) {
                    this.formGroup.get(this.questionItemKey)!.setValue(undefined);
                    return;
                }
            }
        }

        if (this.dateModel.hour && this.dateModel.minute) {
            const d: Date = new Date(2000, 0, 1, this.dateModel.hour, this.dateModel.minute);
            this.formGroup.get(this.questionItemKey)!.setValue(d);
        }
        this.formGroup.updateValueAndValidity();
    }

    isInvalidTime(): boolean {
        if (this.formGroup.get(this.questionItemKey)!.dirty) {
            console.warn(`Check the time validation stuff`);
            if (this.questionItem.required) {
                return isNaN(this.formGroup.get(this.questionItemKey)!.value); 
            } else {
                return (!(this.dateModel.hour) || !(this.dateModel.minute));
            }
        }
        return false;
    }

    populateValidTimeRanges() {
        this.dateModel.hours.push(new Lookup('', 'Hour'));
        for (let h = 7; h <= 22; h++) {
            var desc: string = ("0" + h.toString()).slice(-2);
            this.dateModel.hours.push(new Lookup(`${h}`, `${desc}`));

        }
        this.dateModel.minutes.push(new Lookup('', 'Minute'));
        for (let m = 0; m <= 59; m += 15) {
            var desc: string = ("0" + m.toString()).slice(-2);
            this.dateModel.minutes.push(new Lookup(`${m}`, `${desc}`));
        }
    }

    updateValue(key: string, value: any) {
        this.formGroup.controls[key].setValue(value);
        this.valueChanged.emit(new MfValueChangeEvent(key, value));
    }

    keyForDateHour(key: string) {
        return `${key}_hh`;
    }

    keyForDateMinute(key: string) {
        return `${key}_min`;
    }

    // NOTE(Ian): Yes, hacky.
    dateModel: IMfDateModel = {
        day: 0,
        month: 0,
        year: 0,
        hour: 0,
        minute: 0,
        minDate: undefined,
        maxDate: undefined,
        daysInMonth: [],
        months:
            [
                { code: '', description: "Month" },
                { code: '1', description: "January" },
                { code: '2', description: "February" },
                { code: '3', description: "March" },
                { code: '4', description: "April" },
                { code: '5', description: "May" },
                { code: '6', description: "June" },
                { code: '7', description: "July" },
                { code: '8', description: "August" },
                { code: '9', description: "September" },
                { code: '10', description: "October" },
                { code: '11', description: "November" },
                { code: '12', description: "December" }
            ],
        years: [],
        hours: [],
        minutes: []
    };

    canDisplayError?: boolean;

    isValid = true;
    questionItemKey!: string;
    errorMessages?: string;

    updateValidity: BehaviorSubject<ErrorField | null> = new BehaviorSubject<ErrorField | null>(null);
}

