import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DomSanitizer } from '@angular/platform-browser';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";

import { UploadService, UploadLimitsForTypeResponse, DocumentUploadChange, DocumentUploadChangeType, UploadsForTypeResponse } from './upload.service';
import { UploadedFile, UploadedFilePage } from './uploaded-file';
import { DocumentType } from './document-type';
import { UploadStarting, UploadProgress } from './upload-progress';

import { AmazonS3Service } from '../amazon-s3/amazon-s3.service';
import { UploadComplete } from '../amazon-s3/upload-complete';

import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProgressModal } from '@aifs-shared/modals/progress.modal';
import { AlertModal } from '@aifs-shared/modals/alert.modal';
import { ConfirmationModal } from '@aifs-shared/modals/confirmation.modal';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: [`./less/upload.component.scss`]
})
export class UploadComponent implements OnInit, AfterViewInit {
    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private uploadService: UploadService,
        private amazonS3Service: AmazonS3Service) {
    }

    @ViewChild('file', {static: false}) fileInput: ElementRef<HTMLInputElement> | undefined;

    @Input() title: string = '';
    @Input() documentType!: DocumentType;
    @Input() documentId!: number;
    @Input() applicationId!: number;
    @Input() linkedSkillId?: number;

    /** Start upload procedure automatically */
    @Input() autoUpload: boolean = false;
    @Output() currentOfType: EventEmitter<UploadedFile> = new EventEmitter<UploadedFile>();
    @Output() uploadedFileChanged: EventEmitter<UploadedFile> = new EventEmitter<UploadedFile>();
    @Output() onUploadError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() get canUpload(): boolean {
        return this.currentState == UploadComponentState.FileSelectedForUpload;
    }

    @Output() get currentState(): UploadComponentState {
        if (this.selectedFiles.length > 0) {
            if (!this.isCurrentlyUploadingFiles.value) {
                return UploadComponentState.FileSelectedForUpload;
            }
            else {
                return UploadComponentState.FileUploading;
            }
        }

        if (this.uploadedFile != null) {
            return UploadComponentState.FileUploaded;
        }

        return UploadComponentState.Default;
    }

    get pagesToAdd(): number {
        return this.limits.pageLimit - this.selectedFiles.length;
    }

    get canSelectAnyMoreFiles(): boolean {
        return this.pagesToAdd > 0;
    }

    ngOnInit() {
        // console.log(`ngOnInit`);
        this.__uploadedFile__.subscribe(f => {
            // console.log(`ngOnInit f:`, f);
            if (!f) return;
        })
        // console.log(`ngOnInit #2`);

        this.initialiseImage();
    }

    ngAfterViewInit(): void {
        // if(this.fileInput) console.log(this.fileInput);
        // else console.warn(`no fileInput?`);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    initialiseImage(): void {
        switch (this.documentType) {
            case DocumentType.Portrait:
                this.accept = 'image/*';
                this.imageTypeClass = "user-profile";
                this.imageAltText = "upload your profile image";
                this.uploadImage = "assets/img/icons/Upload_Profile_Pic.png";
                this.loadUploadsForType();
                break;
            case DocumentType.Activity:
                this.accept = 'image/*';
                this.imageTypeClass = "user-activity";
                this.imageAltText = "upload your activity image";
                this.uploadImage = "assets/img/icons/Upload_Pictures.png";
                this.SubHeader = "Photo Upload";
                this.uploadTextTop = "Click on the icon below to upload a photo. You can add up to 8!";
                this.uploadTextBottom = "TIP! Crop your images before uploading. They should be landscape.";
                break;
            case DocumentType.Reference:
                this.accept = '.pdf,application/pdf,image/*';
                this.imageTypeClass = "reference";
                this.imageAltText = "upload your reference";
                this.uploadImage = "assets/img/no-pic.png";
                break;
            case DocumentType.PoliceCheck:
                this.SubHeader = "Certificate Upload";
                this.accept = '.pdf,application/pdf,image/*';
                this.imageTypeClass = "police-check";
                this.imageAltText = "upload your police check document";
                this.uploadImage = "assets/img/no-pic.png";
                break;
            case DocumentType.MedicalForm:
                this.accept = '.pdf,application/pdf,image/*';
                this.imageTypeClass = "medical-form";
                this.imageAltText = "upload your completed medical form";
                this.uploadImage = "assets/img/no-pic.png";
                break;
            case DocumentType.Certificate:
                this.accept = 'image/*';
                this.imageTypeClass = "certificate";
                this.imageAltText = "upload your certificate";
                this.uploadImage = "assets/img/icons/Upload_Certificate.png";
                this.SubHeader = "Certificate Upload";
                this.uploadTextTop = "Click on the icon below to upload a certificate";
                this.uploadTextBottom = "TIP! Take a picture of it and upload that! Make sure it's one of the following file types: jpg, gif or png.";
                break;
            case DocumentType.Video:
                this.imageTypeClass = "video";
                this.imageAltText = "upload your video";
                this.accept = 'video/*';
                this.uploadImage = "assets/img/icons/Upload_Video.png";
                this.SubHeader = "Video Upload";
                this.uploadTextTop = "Click on the icon below to upload a video";
                this.uploadTextBottom = "TIP! Make sure it’s no larger than 200MB and one of the following file types: mpg, mpeg, avi, flv, m4e, m4v, mp4, mov, wmv";
                break;
            default:
                console.error(`In UploadComponent::ngOnInit, an unsupported DocumentType was specified!`)
                break;
        }

        // We can't willy-nilly load all images of a given type
        // as this only works for activity and profile images
        if (this.documentId) {
            // console.log(`Got documentId`, this.documentId);
            this.loadUploadById(this.documentId);
        } else {
            // console.log(`Check limits for Doc Type `, this.documentType);
            this.checkLimitsForDocumentType();
        }
    }

    cancelUpload(selectedFile: File): void {
        // console.log(`Cancel upload for ${selectedFile.name}?`);
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = "Change image to upload";
        modalRef.componentInstance.body = "Would you like to select a different image to upload?";
        modalRef.componentInstance.cssClass = "danger";
        modalRef.result.then(result => {
            if (result) {
                // console.log(`User wants to cancel, raise event`);
                const newSelected = this.selectedFiles.filter(f => { return f.name != selectedFile.name });
                // console.log(`Old size: ${this.selectedFiles.length}, new size: ${newSelected.length}`);
                this.selectedFiles = newSelected.slice();
            } else {
                // console.log(`User cancelled delete`);
            }
        });
    }

    /**
     * Delete the specified image
     * @param deleteImage (number) - the documentId to remove
     */
    deleteImage(deleteImage: number, type: DocumentType) {
        // console.log(`DeleteImage: ${type}`);

        if (this.currentState === UploadComponentState.FileUploaded && this.documentType === DocumentType.Portrait) {
            // console.log(`Is a previously-uploaded portrait image`);
            this.queuedImageForDelete = deleteImage;
            if(this.fileInput) {
                this.fileInput.nativeElement.click();
            }            
        } else {
            this.deleteImageByNumber(deleteImage);
        }
    }

    /**
     * Start the upload procedure:
     * 1. Prepare the upload:
     *      Ask the service for the appropriate destination page and
     *      bucket for the type of document we're uploading
     * 2. Using the upload policy details returned, set up one HttpRequest
     *      stream per selected file. This is used to notify us of
     *      upload life-cycle events
     * 3. The life-cycle events are posted to the modal progress dialog. The
     *      user may cancel the upload(s) from this dialog.
     * 4. Once the upload completes successfully, we notify the upload
     *      service once more and then refresh the page.
     */
    public upload() {
        this.isCurrentlyUploadingFiles.next(true);

        let modalRef: any = null;
        let uploadCompleteResponse: UploadComplete | null = null;

        this.uploadService
            .prepareUpload({ documentType: this.documentType, fileExtension: this.isPDF ? "pdf" : "image" })
            .subscribe({
                next: (uploadPolicy) => {
                    // console.log(`Prepare Upload Complete: ${JSON.stringify(uploadPolicy, null, 2)}`);
                    // Victory, we are about to upload; show the progress dialog
                    let uploadSub = this.amazonS3Service
                        .upload({ policy: uploadPolicy, files: this.selectedFiles })
                        .pipe(
                            takeUntil(this.ngUnsubscribe)
                        )
                    .subscribe({
                        next: (response: UploadStarting | UploadProgress | UploadComplete) => {
                            // console.log(`uploading: ${JSON.stringify(response)}`);
                            if (response instanceof UploadStarting) {
                                // Prevent clicking on the backdrop closing the progress window
                                let options: NgbModalOptions = {
                                    backdrop: 'static'
                                };

                                modalRef = this.modalService.open(ProgressModal, options);
                                modalRef.componentInstance.data = response;
                                modalRef.result
                                    .then( (result: any) => {
                                        // This is the cancel stuff
                                        uploadSub.unsubscribe();

                                        // Required to let us try again!
                                        this.isCurrentlyUploadingFiles.next(false);
                                    })
                                    // required! see: https://github.com/shlomiassaf/ngx-modialog/issues/188
                                    .catch(() => { });
                            } else if (response instanceof UploadProgress) {
                                modalRef.componentInstance.data = response;

                            } else if (response instanceof UploadComplete) {
                                modalRef.componentInstance.data = response;

                                uploadCompleteResponse = response;

                                if(this.queuedImageForDelete) {
                                    // console.log(`Deleting old image`);
                                    this.deleteImageByNumber(this.queuedImageForDelete);
                                }
                            }
                        }, 
                        error: (error: any) => {
                            this.showErrorInUploadModal();
                            if (uploadSub) uploadSub.unsubscribe();
                            // this.onUploadError.emit(true);
                            // this.isCurrentlyUploadingFiles.next(false);

                            // this.showError("Upload File", "There was a problem uploading your file.",
                            //     "Please ensure that your file is a valid image in a commonly recognised format (jpg, png) and that its size doesn't exceed 3MB");
                            // console.error(error);
                            // cancel
                        },
                        complete: () => {
                            if (!uploadSub) console.error(`Can't complete when we've unsubscribed`);
                            if (!uploadCompleteResponse) console.error(`Bad news, bears`);
                            else {
                                uploadCompleteResponse.title = this.title;
                                uploadCompleteResponse.linkedSkillId = this.linkedSkillId;

                                this.uploadService
                                    .uploadCompletedSuccessfully(uploadCompleteResponse)
                                    .subscribe({
                                        next: (response: any) => {
                                            modalRef.dismiss();

                                            this.selectedFiles = [];
                                            this.isCurrentlyUploadingFiles.next(false);

                                            this.documentId = response.uploadedItem.applicationImage.id;
                                            this.loadUploadById(this.documentId);
                                        },
                                        error: (error: any) => {
                                            this.showErrorInUploadModal();
                                            if (uploadSub) uploadSub.unsubscribe();

                                            // this.showError("Upload File", "There was a problem updating the database with the new upload details:", error);
                                            // if (uploadSub) uploadSub.unsubscribe();
                                            // this.modalService.dismissAll();
                                            // this.isCurrentlyUploadingFiles.next(false);
                                            // this.onUploadError.emit(true);
                                        }
                                    });
                                }
                            }
                        });
                    }
            });
    }

    loadUploadsForType(): void {
        this.uploadService
            .getUploadsForType({ applicationId: this.applicationId, documentType: this.documentType })
            .subscribe({
                next: (uploadsResponse: UploadsForTypeResponse) => {
                    if (uploadsResponse.uploads.length > 0) {
                        this.uploadedFile = uploadsResponse.uploads[0];
                        this.currentOfType.emit(this.uploadedFile);
                    }
                },
                error: (error: any) => {
                    console.error(error);
                    this.onUploadError.emit(true);
                }
            });
    }

    loadUploadById(id: number): void {
        this.uploadService.getUploadById(id)
            .subscribe({
                next: (uploads: UploadedFile[]) => {
                    if (uploads.length > 0) {
                        this.uploadedFile = uploads[0];
                    }
                },
                error: (error: any) => { 
                    console.error(error);
                    this.onUploadError.emit(true);
                }                
            });
    }

    checkLimitsForDocumentType() {
        this.uploadService.
            getUploadLimitsForType(
                { 
                    applicationId: this.applicationId, 
                    documentType: this.documentType 
                }
            ).
            subscribe({
                next: (uploadLimits) => {
                    this.limits = uploadLimits;
                },
                error: (error: any) => {
                    console.error(error);
                    this.onUploadError.emit(true);
                } 
            });
    }

    queuedImageForDelete: number | undefined;

    deleteImageByNumber(id: number) {
        this.uploadService
            .deleteUploadById(id)
            .subscribe(
                response => {
                    this.uploadedFile = null;
                    this.checkLimitsForDocumentType();
                    // this.uploadService.uploadServiceEventStream$.next( new DocumentUploadChange(DocumentUploadChangeType.Deleted, type));
                });
    }

    onPlayerReady(api: VgApiService) {
        this.api = api;
        this.playerReady = true;
    }

    showError(title: string, message: string, extra?: string) {
        this.showModal(title, message, extra, "danger");
    }

    showModal(title: string, message: string, extra: string | undefined = undefined, cssClass: string = "info") {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;
        if (extra) modalRef.componentInstance.extra = extra;
        modalRef.componentInstance.cssClass = cssClass;
        modalRef.result.then(result => {
            if (result) {

            }
        });
    }

    showErrorInUploadModal(): void {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = "Upload File";
        modalRef.componentInstance.body = "There was a problem uploading your file.";
        modalRef.componentInstance.extra = "Please ensure that your file is a valid image in a commonly recognised format (jpg, png) and that its size doesn't exceed 3MB";
        modalRef.componentInstance.cssClass = "danger";
        modalRef.result.then(result => {
            if (result) {
                this.onUploadError.emit(true);
                this.isCurrentlyUploadingFiles.next(false);
                this.modalService.dismissAll();
                this.isPDF = false;
                this.currentState == UploadComponentState.Default;
                this.initialiseImage();
                this.pdfFileName = '';
                this.selectedFiles = [];
            }
        });
    }

    fileChange(fileChangeEvent: any) {
        let files = <FileList>fileChangeEvent.target.files;
        // Because FileList isn't iterable for some reason
        for (let i = 0; i < files.length; ++i) {
            this.selectedFiles.push(files[i]);
            this.isPDF = (files[i].name.toLowerCase().endsWith(".pdf"));
        }

        if (this.isPDF) this.pdfFileName = files[0].name;

        if (this.autoUpload) {
            this.upload();
        }
    }

    uploadedImageUri(page: UploadedFilePage) {
        if(page.uri.toLowerCase().endsWith(".pdf")) {
            return page.uri.replace(".pdf", ".png");
        } else {
            return page.uri;
        }
    }

    handleMissingImage(event: Event) {
        // The image is missing/or has errored.
        // If this image is a pdf, it may just be that the png
        // preview has not yet been completed
        (event.target as HTMLImageElement).style.backgroundImage = 'url(/img/file-image-regular.svg)';
    }

    selectedFileURL(file: File): string {
        if(file.name.toLowerCase().endsWith(".pdf")) {
            return `/img/pdf.svg`;
        }
        return URL.createObjectURL(file);
    }

    isPDF = false;
    pdfFileName = '';

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    get uploadedFile(): UploadedFile | null {
        return this.__uploadedFile__.getValue();
    }

    set uploadedFile(value: UploadedFile | null) {
        this.__uploadedFile__.next(value);
        if( value ) this.uploadedFileChanged.emit(value);
    }

    ngUnsubscribe: Subject<void> = new Subject<void>();
    limits!: UploadLimitsForTypeResponse;
    isCurrentlyUploadingFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    /** File selected for upload */
    selectedFiles: File[] = [];
    __uploadedFile__: BehaviorSubject<UploadedFile | null> = new BehaviorSubject<UploadedFile | null>(null);
    accept!: string;
    imageTypeClass!: string;
    imageAltText?: string;
    uploadImage?: string;

    // Exposing enums to be used in the template
    UploadComponentState = UploadComponentState;
    DocumentType = DocumentType;

    api!: VgApiService;
    playerReady: boolean = false;
    SubHeader?: string;
    uploadTextTop?: string;
    uploadTextBottom?: string;
}

export enum UploadComponentState {
    Default,
    FileSelectedForUpload,
    FileUploading,
    FileUploaded
}
