import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { AuthenticationService } from '@aifs-shared/auth/authentication.service'
import { UserService } from '@aifs-shared/user/user.service'

import { ApplicationService } from "@aifs-shared/application/application.service";

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';
import { ITaskProvider } from '@aifs-shared/tracker/task-provider';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from '@aifs-shared/modals/alert.modal';

import { DocumentType } from '@aifs-shared/upload/document-type'
import { UploadedFile } from '@aifs-shared/upload/uploaded-file'

@Component({
    templateUrl: './profile.component.html',
    styleUrls: ['./less/profile.component.scss']
})
export class ProfileComponent extends TrackerTaskComponent implements OnInit, OnDestroy, ITaskProvider {

    constructor(
        router: Router,
        tracker: TrackerService,
        route: ActivatedRoute,
        private authService: AuthenticationService,
        private userService: UserService,
        private application: ApplicationService,
        private modalService: NgbModal
    ) {
        super(router, tracker);
        this.requiresTask = false;
    }

    override ngOnInit() {
        super.ngOnInit();
        this.title = "Profile Page";
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    get currentUserApplicationId(): number {
        return this.authService.currentUserData?.user?.applicantRole?.activeApplicationId!;
    }

    uploadedFileChanged(uploadedFile: UploadedFile): void {
        this.setNextEnabled(uploadedFile != undefined && uploadedFile != null);
    }

    showError(title: string, message: string, extra?: string) {
        this.showModal(title, message, extra, "danger");
    }

    showModal(title: string, message: string, extra: string | undefined = undefined, cssClass: string = "info") {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;
        if (extra) modalRef.componentInstance.extra = extra;
        modalRef.componentInstance.cssClass = cssClass;
        modalRef.result.then(result => {
            if (result) {

            }
        });
    }

    title: string = '';

    // Used so that the template understands what the enum is
    DocumentType = DocumentType;
}
