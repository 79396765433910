import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InterviewInfoComponent } from './interview-info.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [InterviewInfoComponent],
    exports: [InterviewInfoComponent]
})
export class InterviewInfoModule { }
