import { Component, OnInit, Input } from '@angular/core';
import { Metaform, MfQuestion, Question } from '@aifs-shared/metaform/metaform';

@Component({
    // moduleId: module.id,
    selector: 'mf-html',
    template: '<p class="mf-html" [innerHtml]="htmlText"></p>',
    styleUrls: ['../../less/metaform-question-display.component.scss']
})
export class HtmlComponent implements OnInit {
    @Input() metaform!: Metaform;
    @Input() parent!: MfQuestion;
    @Input() questionItem!: Question<any>;
    @Input() questionName!: string;

    htmlText?: string;
    questionItemKey!: string;

    constructor() { }

    ngOnInit() {
        this.questionItemKey = this.questionItem.key;
        this.htmlText = this.questionItem.html ?? '';
    }
}
