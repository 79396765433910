<div class="cal-control">
    <div class="heading">
        <div class="prev">
            <div class="month-nav month-prev" (click)="showPreviousMonth()"
                *ngIf="showMonthNavigation && showEarlier">
                <span class="fa fa-arrow-circle-left"></span>
            </div>
        </div>
        <div class="month">
            <span class="name">{{currentMonthName}}</span>
        </div>
        <div class="next">
            <div class="month-nav month-next" (click)="showNextMonth()" *ngIf="showMonthNavigation && showLater">
                <span class="fa fa-arrow-circle-right"></span>
            </div>
        </div>
    </div>
    <div class="cal">
        <span class="row-heading d weekend">S</span>
        <span class="row-heading d">M</span>
        <span class="row-heading d">T</span>
        <span class="row-heading d">W</span>
        <span class="row-heading d">T</span>
        <span class="row-heading d">F</span>
        <span class="row-heading d weekend">S</span>
        <ng-container *ngFor="let slot of slots; let i = index">
                <div class="row-content">
                    <div *ngIf="slot.dayOfMonth == 0; else actual_day" class="no-day"></div>
                    <ng-template #actual_day>
                        <div class="day  {{slot.day!.css}}" [class.selectable]="slot!.day!.selectable"
                            [ngClass]="{'selected-day': selectedDayMatches(slot!.day!)}" (click)="selectDay(slot!.day!)"
                            *ngIf="slot.isValid(); else empty_day">
                            <span class="day-number">{{slot.dayOfMonth}}</span>
                            <span class="day-text {{slot.day!.css}}">{{slot.day!.text}}</span>
                            <i *ngIf="slot.day!.css == 'fix_flight_date_warn'"
                                ngbPopover="This flight will have an increased cost"
                                triggers="mouseenter:mouseleave"
                                popoverTitle="Important" 
                                class="fa-solid fa-dollar-sign"></i>
                        </div>
                        <ng-template #empty_day>
                            <div class="empty-day">
                                <span class="day-number">{{slot.dayOfMonth}}</span>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
        </ng-container>
    </div>
</div>
