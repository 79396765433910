import { FormGroup, ValidatorFn } from '@angular/forms';

import { Observable } from 'rxjs';

export class Metaform {
    name: string = 'metaform';
    title?: string;
    version: number = 0;
    lastModifiedTicks?: number;
    checkModifiedAfterTicks?: number;

    dataSource?: string;
    sections: MetaformSection[] = [];
    questions: MfQuestion[] = [];
    drawType: DrawType = DrawType.Default;
    keepAnswersOnStepBack: boolean = true;

    currentSection: number = 0;

    // For the tracker: how many questions in total
    // do we have, regardless of whether they are
    // valid according to the rules
    totalQuestionCount?: number;

    // Is the form locked, according to the data load
    // from server process?
    isReadOnly: boolean = false;
    allowSaves: boolean = false;
}

export enum DrawType {
    Default = 0,
    Section = 1,
    Form = 2
}

// A section represents a number of grouped questions.
// In the desktop version, all of the contained questions will 
// probably be displayed. In the mobile version, however, we 
// will only display one at a time.
export class MetaformSection {
    id: number = 0;
    title: string = '';              // Used in displays
    ruleToMatch?: string;
    text?: string;
}

// An MfQuestion can contain more than one control;
// in the case of a telephone number, it must contain
// a country code selector AND a textbox.
export class MfQuestion {
    sectionId: number = 0;
    name: string;
    intro?: string;
    caption?: string;
    captionFootnote?: string;
    ruleToMatch?: string;
    errors?: Array<ValidationError>;
    items: Question<any>[] = [];
    enterAnimation?: string;
    animState?: Map<string, string>;
    displayIf?: string;
    displayIfValue?: string;
    constructor(options: { name: string, items: Question<any>[], caption?: string, captionFootnote?: string, errors: Array<ValidationError> }) {
        this.name = options.name;
        this.items = options.items;
        this.caption = options.caption;
        this.errors = options.errors || undefined;
        this.captionFootnote = options.captionFootnote || undefined;
    }
}

export class ValidationError {
    validator?: string;
    message?: string;
}

export class Question<T> {
    value?: T;
    key: string;
    label: string;
    required: boolean;
    controlType: string;
    options?: MfOption[];
    optionSource?: string;
    validators?: string[];
    maxLength?: number;
    readonly?: boolean;
    max?: string;
    min?: string;
    match?: string;         // For matching validators: EqualTo NotEqualTo
    optionSourceCallback?: { fn: Observable<any>, args: any[] };
    validatorFunctions: ValidatorFn[] = [];
    targetFields: string[] = [];
    extraClass?: string;
    errorField?: string;
    currentError?: string;
    html?: string;

    /** placeholder to display when value is empty or null */
    nullItem?: string;
    /** null element actual value  */
    nullValue?: T;
    autocomplete?: string;

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        maxLength?: number,
        max?: string,
        min?: string,
        match?: string,
        options?: MfOption[],
        optionSource?: string,
        validators?: string[],
        nullItem?: string,
        nullValue?: T,
        html?: string
    } = {}
    ) {
        if (options.value) this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.controlType = options.controlType || '';
        this.options = options.options || undefined;
        this.optionSource = options.optionSource || undefined;
        this.validators = options.validators || undefined;
        this.maxLength = options.maxLength || undefined;
        this.max = options.max || undefined;
        this.min = options.min || undefined;
        this.match = options.match || undefined;
        this.nullItem = options.nullItem;
        this.nullValue = options.nullValue || undefined;
        this.html = options.html || undefined;
    }
}

export class MfTextQuestion extends Question<string> {
    override controlType = 'textbox';
    type: string;
    override maxLength: number;

    constructor(options: any = {}) {
        super(options);
        this.type = options['type'] || '';
        this.maxLength = 0;
    }
}

export class MfMultiTextQuestion extends Question<string> {

}

export class MfTelephoneNumber {
    iddCode?: string;
    number?: string;
    constructor(iddCode: string, number: string) {
        this.iddCode = iddCode;
        this.number = number;
    }
}

export class MfTelephoneQuestion extends Question<MfTelephoneNumber> {
    dialingCode: string;
    number: string;
    constructor(code: string, number: string) {
        super();
        this.dialingCode = code;
        this.number = number;
    }
}

// export class MfCountryQuestion extends Question {

// }

export class MfOptionSelection extends Question<MfOption> {
    //options: MfOption[];
    override controlType = 'optionselect';
    override options: MfOption[] = [];

    constructor(options: any = {}) {
        super(options);
        this.options = options['options'] || [];
    }
}

export class MfOption {
    code: string;
    description: string;
    constructor(code: string, description: string) {
        this.code = code; 
        this.description = description;
    }
}

export class MfValueChangeEvent {
    name: string;
    value: any;
    formGroup?: FormGroup;
    constructor(name: string, value: any, formGroup?: FormGroup) {
        this.name = name;
        this.value = value;
        if (formGroup)
            this.formGroup = formGroup;
    }
}

export interface IMfDateModel {
    day: number | undefined;
    month: number | undefined;
    year: number | undefined;
    hour: number | undefined;
    minute: number | undefined;
    daysInMonth: MfOption[];
    months: MfOption[];
    years: MfOption[];
    hours: MfOption[];
    minutes: MfOption[];
    minDate: IMfDateModelDatePart | undefined;
    maxDate: IMfDateModelDatePart | undefined;
}

export interface IMfShortDateModel {
    month: number | undefined;
    year: number | undefined;
    months: MfOption[];
    years: MfOption[];
    minDate: IMfDateModelDatePart | undefined;
    maxDate: IMfDateModelDatePart | undefined;
}

export interface IMfDateModelDatePart {
    year: number;
    month: number;
    day: number;
}