import { Component, OnInit, ViewChild } from '@angular/core';

import { EMPTY, Observable } from 'rxjs'

import { FormGroup } from '@angular/forms'

import { AuthenticationService } from '@aifs-shared/auth/authentication.service'
import { UserService } from '@aifs-shared/user/user.service'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from '@aifs-shared/modals/alert.modal';
import { ConfirmationModal } from '@aifs-shared/modals/confirmation.modal';

import { ResponseResultCode } from '@aifs-shared/common/base-response';

import { Lookup } from '@aifs-shared/lookup/lookup';

import { Metaform } from '@aifs-shared/metaform/metaform';
import { MetaformViewerComponent } from '@aifs-shared/metaform/metaform-viewer.component';
import { MetaformDataProvider } from '@aifs-shared/metaform/metaform-data-provider';

@Component({
    selector: 'app-change-sign-in',
    templateUrl: './change-sign-in.component.html',
    styleUrls: ['./less/change-sign-in.component.scss']
})
export class ChangeSignInComponent implements OnInit, MetaformDataProvider {

    constructor(
        private authService: AuthenticationService,
        private userService: UserService,
        private modalService: NgbModal) { }

    ngOnInit() {
        this.reloadUserData();
    }

    doChangeSignIn() {
        const sid = this.signInData;
        if (!sid) return;

        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = 'Change Sign-In';
        modalRef.componentInstance.body = `Are you sure you want to change your sign-in email address to ${sid.email}?`;
        modalRef.result.then(result => {
            if (result) {
                // console.log(`Call service`);
                this.userService.changeSignIn(sid.email, sid.emailConfirmation)
                    .subscribe(r => {
                        if (r.resultCode == ResponseResultCode.Success) {
                            this.showModal("Change Sign In", "You have successfully changed your sign-in email address");
                            this.formViewer.clearForm();
                            this.reloadUserData();
                        } else {
                            this.showError("Change Sign In", "We couldn't change your sign-in email address:", r.error);
                        }

                    }, error => {
                        this.showError("Change Sign In", "There was an unexpected error attempting to change your sign-in:", error);
                    });

            }
        });
    }

    reloadUserData() {
        this.userService.reloadUserData()
            .subscribe(r => {
                if (r && r.error) {
                    console.error(`Error from server: ${r.error}`);
                } else {
                    this.signInName = this.authService.currentUserData!.user.email;
                }
            },
                error => {
                    // something unexpected occured
                    console.error(`updateTaskListForUser: Error reloading userData: ${error}`);
                })
    }

    showError(title: string, message: string, extra?: string) {
        this.showModal(title, message, extra, "danger");
    }

    showModal(title: string, message: string, extra: string | undefined = undefined, cssClass: string = "info") {
        const modalRef = this.modalService.open(AlertModal);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.body = message;
        if (extra) modalRef.componentInstance.extra = extra;
        modalRef.componentInstance.cssClass = cssClass;
        modalRef.result.then(result => {
            if (result) {

            }
        });
    }

    public initialiseCallbackProvider(form: Metaform, formGroup: FormGroup) {
        // For data retention, probably. 
        formGroup.valueChanges.subscribe(data => this.onValueChanged(formGroup, data));
    }

    /**
     * @param source (string) - lookup optionSource
     */
    public loadLookup(form: Metaform, formGroup: FormGroup, source: string): Observable<Lookup[]> { return EMPTY; }
    public lookupLoaded(form: Metaform, formGroup: FormGroup, questionKey: string, options: any[]) { }
    public displayIf(form: Metaform, formGroup: FormGroup, source: string): boolean { return true; }
    public formLoaded(form: Metaform, formGroup: FormGroup): { preventDisplay: boolean } { return { preventDisplay: false }; }
    onValueChanged(formGroup: FormGroup, data?: any) {
        if (!formGroup) { return; }

        this.signInData = data;
        const form = formGroup;

        this.updateSignInButtonEnabled = form.valid;
    }

    signInName?: string;

    updateSignInButtonEnabled: boolean = false;
    signInData?: SignInData;

    @ViewChild(MetaformViewerComponent) formViewer!: MetaformViewerComponent;

}

export interface SignInData {
    email: string;
    emailConfirmation: string;
}