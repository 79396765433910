import { Observable } from 'rxjs';

export interface IBusinessRuleData {
    initialise(): void;
    loadFromUrl(applicantId: number, applicationId: number, dataSource: string): Observable<any>;
    getValue(name: string): any;
    setValue(name: string, value: any): void;
}

export class BusinessRule {
    name: string;
    matchType: RuleMatchType;
    public parts: BusinessRulePart[] = [];
    constructor(name: string, match: RuleMatchType) {
        this.name = name;
        this.matchType = match;
    }
}

export class BusinessRulePart {
    name: string;
    comparison: RuleComparison;
    value: any;

    // Used only for 'between' comparisons
    lowerBoundValue: any;
    upperBoundValue: any;

    constructor(name: string, comparison: RuleComparison) {
        this.name = name;
        this.comparison = comparison;
    }
}

export enum RuleMatchType {
    Any = 0,
    All = 1
}

export enum RuleComparison {
    Equals = 1,
    NotEquals = 2,
    GreaterThan = 3,
    LessThan = 4,
    Contains = 5,
    In = 6,
    NotIn = 7,
    Between = 99
}
