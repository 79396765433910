<ng-container *ngIf="readonly; else rw">
    <div class="ro text-display">
        <p>{{ selectedItemDescription()}}</p>
    </div>
</ng-container>
<ng-template #rw>
    <select [id]="questionItem.key" class="form-control{{isMandatory(questionItem.key) ? ' mand' : ''}}" [formControl]="$any(formControl)">
        <option *ngFor="let opt of questionItem.options" [value]="opt.code" [selected]="opt.code === questionItem.value">{{opt.description}}</option>
    </select>
    <span *ngIf="canDisplayError && !isValid" class="has-danger">
        <span class="form-control-feedback">
            {{ errorMessages }}
        </span>
    </span>
</ng-template>