import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '../pipe/pipes.module'

import { DisplayCardComponent } from './display-card.component';
import { SelectableDisplayCardComponent } from './selectable-display-card.component';

@NgModule({
    // Inputs
    imports: [
        CommonModule,
        PipesModule
    ],
    // Outputs
    declarations: [
        DisplayCardComponent,
        SelectableDisplayCardComponent
    ],
    // Services
    providers: [
    ],
    // Exports - if we export a component from here, we will probably import it into another module (either feature or root)
    exports: [
        DisplayCardComponent,
        SelectableDisplayCardComponent
    ]
})
export class DisplayCardModule {

}