<ng-container *ngIf="readonly; else rw">
    <div class="ro text-display">
        <p>{{questionItem.value}}</p>
    </div>
</ng-container>
<ng-template #rw>
    <div [ngSwitch]="questionItem.controlType">
        <input *ngSwitchCase="'text'" [id]="questionItem.key" class="form-control{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
            [formControl]="$any(formControl)" [type]="questionItem.controlType" placeholder="{{questionItem.label}}"
            [maxLength]="controlMaximumInput" autocomplete="{{questionItem.autocomplete}}">

        <input *ngSwitchCase="'email'" [id]="questionItem.key" class="form-control{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
            [formControl]="$any(formControl)" [type]="questionItem.controlType" placeholder="{{questionItem.label}}"
            [maxLength]="controlMaximumInput" autocomplete="{{questionItem.autocomplete}}">

        <input *ngSwitchCase="'password'" [id]="questionItem.key" class="form-control{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
            [formControl]="$any(formControl)" [type]="questionItem.controlType" placeholder="{{questionItem.label}}"
            [maxLength]="controlMaximumInput" autocomplete="{{questionItem.autocomplete}}">

        <input *ngSwitchCase="'number'" [id]="questionItem.key" class="form-control{{!isValid ? ' invalid': ''}}{{isMandatory(questionItem.key) ? ' mand' : ''}}"
            [formControl]="$any(formControl)" [type]="questionItem.controlType" placeholder="{{questionItem.label}}" [min]="0"
            [max]="+controlMaximumInput" autocomplete="{{questionItem.autocomplete}}">
    </div>
    <span *ngIf="canDisplayError && !isValid" class="has-danger">
        <span class="form-control-feedback">
            {{ errorMessages }}
        </span>
    </span>
</ng-template>