import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { PoliceCheckComponent } from './police-check.component'

const routes: Routes = [
    { path: '', component: PoliceCheckComponent }
]

@NgModule({
    imports: [
        RouterModule.forChild(
            routes
        )
    ],
    exports: [
        RouterModule
    ]
})
export class PoliceCheckRoutingModule { }
