export class UploadComplete {
    uploadTicketId: number
    notes: string
    description: string
    files: UploadCompleteFile[]
    title: string
    linkedSkillId?: number
    issueDate?: Date
    expiryDate?: Date

    constructor(uploadTicketId: number,
        notes: string,
        description: string,
        files: UploadCompleteFile[],
        title: string,
        linkedSkillId?: number,
        issueDate?: Date,
        expiryDate?: Date
    ) {
        this.uploadTicketId = uploadTicketId;
        this.notes = notes;
        this.description = description;
        this.files = files;
        this.title = title;
        this.linkedSkillId = linkedSkillId;
        this.issueDate = issueDate;
        this.expiryDate = expiryDate;
    }
}

export class UploadCompleteFile {
    pageId?: number
    path?: string
}