import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DefaultRequestOptions } from '@aifs-shared/net/default-request-options.service';

// NgBootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ModalsModule } from '@aifs-shared/modals/modals.module';

import { UploadComponent } from './upload.component';
import { UserImageComponent } from './user-image.component';

import { AmazonS3Service } from '../amazon-s3/amazon-s3.service';
import { PipesModule } from '@aifs-shared/pipe/pipes.module';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ModalsModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        PipesModule
    ],
    declarations: [
        UploadComponent,
        UserImageComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: DefaultRequestOptions, multi: true },
        AmazonS3Service,
    ],
    exports: [
        UploadComponent,
        UserImageComponent
    ]
})
export class UploadModule {

}