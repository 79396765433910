import { NgModule } from '@angular/core';

import { LaterThanDateValidator } from './later-than/directive';
import { laterThan } from './later-than/validator';
import { requiredIf } from './requiredIf/validator';

export const AifsCustomValidators: any = {
    laterThan,
    requiredIf
};

const CUSTOM_FORM_DIRECTIVES = [
    LaterThanDateValidator
];

@NgModule({
    declarations: [CUSTOM_FORM_DIRECTIVES],
    exports: [CUSTOM_FORM_DIRECTIVES]
})
export class AifsCustomFormsModule {
}