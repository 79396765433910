import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

import { Lookup } from '../shared/lookup/lookup';

import { RefereeDetails } from './referee-details';
import { RefereeResponse, Reference, ReferenceDetails } from './reference-details';

@Injectable()
export class ReferenceService extends BaseService {

    constructor(private http: HttpClient) {
        super();
    }

    public getTelephoneIddList(): Observable<Lookup[]> {
        let subject = new Subject<Lookup[]>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_UserLookup_TelephoneIdd)
            .subscribe({
                next: (data: any) => {
                    const response: Lookup[] = data["idd"];
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getSuggestedReferees(applicationId: number): Observable<SuggestedRefereeResponse> {
        const subject = new Subject<SuggestedRefereeResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_SuggestedReferees(applicationId), { applicationId: applicationId })
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<SuggestedRefereeResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getPreviousCampReference(applicationId: number): Observable<PreviousCampReferenceResponse> {
        const subject = new Subject<PreviousCampReferenceResponse>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_SuggestedRepeatReference(applicationId))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<PreviousCampReferenceResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }


    public updateRefereeDetails(details: RefereeDetails): Observable<UpdateRefereeDetailsResponse> {
        let subject = new Subject<UpdateRefereeDetailsResponse>();

        console.log(`Attempting to send ${JSON.stringify(details, null, 2)} to ${environment.ServiceUrl_References_Post}`);
        this.http
            .post<BaseResponse>(environment.ServiceUrl_References_Post, { ...details })
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<UpdateRefereeDetailsResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public isAwaitingReferences(currentState: string, referenceTaskComplete: boolean): Observable<boolean> {
        let s = new Subject<boolean>();

        const isAtCorrectState = currentState === 'ParticipantApplied';

        if (isAtCorrectState) {
            if (referenceTaskComplete) {
                // console.log(`Loading references`);
                this.getReferees()
                    .subscribe(
                    {
                        next: (res: LoadRefereeDetailsResponse) => {
                            if (res) {
                                // const referenceLimit = res.refereeLimit;
                                const references = res.details;

                                var approvedCount = 0;
                                var remindedCount = 0;

                                // Iterate through the list and create DisplayCardItems
                                if(references) {
                                    references.forEach((r: RefereeDetails) => {
                                        if (r.referenceStatus === 'Approved') {
                                            approvedCount++;
                                        } else if (r.referenceStatus === 'Reference Requested') {
                                            if (r.alreadyReminded) {
                                                remindedCount++;
                                            }
                                        }
                                    });
                                    // console.log(`Got references: ${JSON.stringify(res)} Approved: ${approvedCount}, Reminded: ${remindedCount}`);
                                }
                                const notEnoughApproved = approvedCount < 2;
                                const remindersSent = remindedCount > 0;

                                // console.log(`Not Enough Approved: ${this.notEnoughApproved}, RemindersSent: ${this.remindersSent}`);
                                s.next(notEnoughApproved && remindersSent);
                                s.complete();
                            }
                        },
                        error: (error: any) => {
                            s.error(error);
                        }
                });
            }
        }
        
        return s;
    }

    public getReferees(): Observable<LoadRefereeDetailsResponse> {
        let subject = new Subject<LoadRefereeDetailsResponse>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_References_Get)
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<LoadRefereeDetailsResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public deleteReference(referenceId: number): Observable<DeleteReferenceResponse> {
        let subject = new Subject<DeleteReferenceResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_Reference_Delete(referenceId), {})
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<DeleteReferenceResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });


        return subject;
    }

    public linkReferenceToImage(referenceId: number, imageId: number): Observable<LinkReferenceResponse> {
        let subject = new Subject<LinkReferenceResponse>();
        // console.log(`Attempting to link reference ${referenceId} to ${imageId}`);
        this.http
            .post<BaseResponse>(environment.ServiceUrl_Reference_LinkToImage(referenceId, imageId), {})
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<LinkReferenceResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });


        return subject;
    }

    public unlinkReferenceFromImage(referenceId: number, imageId: number): Observable<LinkReferenceResponse> {
        let subject = new Subject<LinkReferenceResponse>();
        // console.log(`Attempting to unlink reference ${referenceId} from ${imageId}`);
        this.http
            .post<BaseResponse>(environment.ServiceUrl_Reference_UnlinkFromImage(referenceId, imageId), {})
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<LinkReferenceResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public getReferenceByUploadId(id: number): Observable<ReferenceFromUploadResponse> {
        const s = new Subject< ReferenceFromUploadResponse>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_ReferenceByUploadId(id))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<ReferenceFromUploadResponse>(data);
                    s.next(response);
                },
                error: (error:any) => {
                    s.error(error);
                }
            });

        return s;
    }

    public getReferenceFormDataFromToken(id: number): Observable<ReferenceFormDetails> {
        let subject = new Subject<ReferenceFormDetails>();

        console.log(`Reading: ${environment.ServiceUrl_ReferenceById(id)}`);

        this.http
            .get<BaseResponse>(environment.ServiceUrl_ReferenceById(id))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<ReferenceFormDetails>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public writeReference(id: number, data: any, saveForLater: boolean, earlyExit: boolean = false): Observable<PostWriteRefereeDataResponse> {
        let subject = new Subject<PostWriteRefereeDataResponse>();

        this.http
            .post<BaseResponse>(
                environment.ServiceUrl_WriteReference(id),
                { earlyExit: earlyExit, saveAndContinue: saveForLater, data }
            )
            .subscribe({
                next: (d: BaseResponse) => {
                    const response = this.getResultData<PostWriteRefereeDataResponse>(d);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }
} 

export class SuggestedRefereeResponse implements ResponseData {
    data: SuggestedReferee[] = [];
}

export class PreviousCampReferenceResponse implements ResponseData {
    data?: SuggestedReferee;
}

export class LoadRefereeDetailsResponse implements ResponseData {
    refereeLimit!: number;
    details?: RefereeDetails[];
}

export class UpdateRefereeDetailsResponse implements ResponseData {
    details!: RefereeDetails;
}

export class LinkReferenceResponse implements ResponseData { }

export class DeleteReferenceResponse implements ResponseData { }

export class ReferenceFormDetails implements ResponseData {
    completed: boolean = false;
    data!: any;
}

export class ReferenceFromUploadResponse implements ResponseData {
    reference!: any;
}

export class PostWriteRefereeDataResponse implements ResponseData {
    errors?: string[] | undefined = undefined;
    success: boolean = false;
}

export interface SuggestedReferee {
    referenceType: string;
    referenceTypeId: string;
    referenceName: string;
    referenceNameOther: string;
    dateFrom?: string;
    dateTo?: string;
    referenceGroup: number;
    requestedReferees: string[] | undefined;
}