import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { environment } from "../../../environments/environment";

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

import { UploadPolicyResponse } from './upload-policy-response';
import { UploadCompletedResponse } from './upload-completed-response';
import { UploadComplete } from '@aifs-shared/amazon-s3/upload-complete';

import { DocumentType } from './document-type';
import { UploadedFile } from "./uploaded-file";
import { UserService } from '@aifs-shared/user/user.service';

@Injectable({
    providedIn: 'root',
})
export class UploadService extends BaseService {
    constructor(
        private http: HttpClient,
        private userService: UserService) {
            super();
            // console.log(`UploadService ctor`);
    }

    /**
     * This does nothing more than fetching the policy and signature from the Upload Service
     * @param documentType 
     * @param payload 
     * @param uploadedById 
     */
    prepareUpload(options: { documentType: DocumentType, fileExtension: string }): Observable<UploadPolicyResponse> {
        // console.debug('fetching Policy and Signature')

        let params = JSON.stringify({
            imageType: options.documentType,
            fileExtension: options.fileExtension,
            applicationId: this.userService.applicantRole()!.activeApplicationId,
            interviewerId: 0 /* for now */
        })

        let subject = new Subject<UploadPolicyResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_Upload_Prepare, params)
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<UploadPolicyResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    uploadCompletedSuccessfully(uploadComplete: UploadComplete): Observable<UploadCompletedResponse> {
        let subject = new Subject<UploadCompletedResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_Upload_Completed_Success, uploadComplete)
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<UploadCompletedResponse>(data);
                    subject.next(response);
                    // console.log(`Response: ${JSON.stringify(response,null,2)}`);
                    if(response.uploadedItem && response.uploadedItem.applicationImage)  {
                        // console.log(`Updating event stream`);
                        this.uploadServiceEventStream$.next(new DocumentUploadChange(DocumentUploadChangeType.Uploaded, response.uploadedItem.applicationImage.type));
                    }
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;

    }

    getUploadsForType(options: { applicationId: number, documentType: number }): Observable<UploadsForTypeResponse> {
        let subject = new Subject<UploadsForTypeResponse>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_Upload_UploadsForApplicationAndDocumentType(options))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<UploadsForTypeResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    getUploadLimitsForType(options: { applicationId: number, documentType: number }): Observable<UploadLimitsForTypeResponse> {
        let subject = new Subject<UploadLimitsForTypeResponse>();
        this.http
            .get<BaseResponse>(environment.ServiceUrl_Upload_UploadLimitsForApplicationAndDocumentType(options))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<UploadLimitsForTypeResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });


        return subject;
    }

    getUploadById(id: number): Observable<UploadedFile[]> {
        let subject = new Subject<UploadedFile[]>();

        // TODO(ian): Look at the result from this 
        this.http
            .get<BaseResponse>(environment.ServiceUrl_Upload_UploadById(id))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<UploadsForTypeResponse>(data);
                    subject.next(response.uploads);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;

        // return (this.http
        //     .get<BaseResponse>(environment.ServiceUrl_Upload_UploadById(id))
        //     .map(UploadService.extractServiceData)
        //     .catch(this.handleError)
        // ).map((result: UploadsForTypeResponse) => result.uploads)
    }

    getMedicalReportStatus(id: number): Observable<MedicalReportResponse> {
        let subject = new Subject<MedicalReportResponse>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_Upload_GetMedicalFormStatus(id))
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = this.getResultData<MedicalReportResponse>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    deleteUploadById(id: number): Observable<boolean> {
        let subject = new Subject<boolean>();
        this.http
            .post<BaseResponse>(environment.ServiceUrl_Upload_DeleteById(id), null)
            .subscribe({
                next: (data: BaseResponse) => {
                    const response = data.resultCode == ResponseResultCode.Success;
                    subject.next(response);
                },
                error: (error: any) => {
                    subject.error(error);
                }
            });

        return subject;
    }

    public uploadServiceEventStream$ = new Subject<DocumentUploadChange>();
}

export class UploadsForTypeResponse implements ResponseData {
    documentType!: number;
    uploads: UploadedFile[] = [];
    canUploadMore: boolean = false;
}

export class UploadLimitsForTypeResponse implements ResponseData {
    itemLimit!: number
    pageLimit!: number
    canUploadMore!: boolean
}

export class MedicalReportResponse implements ResponseData {
    applicationId!: number;
    medicalReportRequired!: boolean;
    medicalReportId!: number;
}

export enum DocumentUploadChangeType {
    Ignore,
    Uploaded,
    Deleted
}
export class DocumentUploadChange {
    documentType?: DocumentType;
    changeType: DocumentUploadChangeType;
    constructor(changeType: DocumentUploadChangeType, documentType?: DocumentType) {
        // console.log(`DocumentUploadChange: change: ${changeType} type: ${documentType}`);
        this.documentType = documentType;
        this.changeType = changeType;
    }
}
