import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserService } from '@aifs-shared/user/user.service';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { FeatureService, FeatureType } from '@aifs-shared/feature/feature.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

    @Input() isInterviewer: boolean = false;
    @Input() isApplicant: boolean = false;
    @Output() menuItemClicked = new EventEmitter<string>();

    constructor(
        private userService: UserService,
        private authService: AuthenticationService,
        private features: FeatureService) { }

    ngOnInit() {
        this.authService
            .userSignInStatus()
            .subscribe({
                next: (signedIn: boolean) => {
                    // console.log(`Change: ${signedIn}`);
                    this.isSignedOut = !signedIn;

                    if(!this.userService.isGuest()) {
                        // For sign-in changes
                        this.isInterviewer = signedIn && this.userService.isInterviewer();
                        this.isApplicant = signedIn && this.userService.isApplicant();
                        this.needsUrgentContact = this.userService.mustContactCAUrgently();
                    }
                    // if (signedIn)
                    //     console.log(`Application Id: ${this.userService.applicantRole().applicationId}`);

                    this.calculateRoleBasedSettings();

                }
            });

        // First time through
        this.isSignedOut = !this.authService.isLoggedIn;

        this.calculateRoleBasedSettings();
    }

    calculateRoleBasedSettings(): void {
        if (!this.isSignedOut && this.isApplicant) {
            const role = this.userService.applicantRole();
            if(!role) return;

            // console.log(`Application: ${JSON.stringify(this.userService.applicantRole(), null, 2)}`);

            this.hasApplication = role.activeApplicationId! > 0;
            this.hasPreviousApplication = role.previousApplicationId! > 0;

            this.isReturner = role.isReturner!;

            this.isOrWasPlaced =
                role.state! === 'ParticipantPlaced'
                || 
                role.previousState! === 'ParticipantPlaced';

            if (!this.features.areFeaturesLoaded()) {
                this.features.getFeatures()
                    .subscribe((finished: boolean) => {
                        this.checkFeatureAvailability();
                    });
            } else {
                this.checkFeatureAvailability();
            }
        } else {
            this.hasApplication = false;
            this.hasPreviousApplication = false;
            this.isReturner = false;
            this.isOrWasPlaced = false;
            this.canSelfPropose = false;
        }
    }

    checkFeatureAvailability() {
        const role = this.userService.applicantRole();
        if (!role) return;
                
        // console.log(`Checking feature ${FeatureType.SelfProposal} = ${this.features.isFeatureEnabled(FeatureType.SelfProposal)}, Loaded = ${this.features.areFeaturesLoaded()}`);
        this.canSelfPropose = this.hasApplication && this.features.isFeatureEnabled(FeatureType.SelfProposal) && role.canSelfPropose!;
        this.canViewOrientation = this.hasApplication && this.features.isFeatureEnabled(FeatureType.Orientation);
    }

    navigateTo(url: string): void {
        this.menuItemClicked.emit(url);
    }

    isReturner: boolean = false;
    isSignedOut: boolean = false;
    hasApplication: boolean = false;
    hasPreviousApplication: boolean = false;
    isOrWasPlaced: boolean = false;
    needsUrgentContact: boolean = true;

    // Self-proposal
    canSelfPropose = false;
    canViewOrientation = false;
}
