import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthenticationService } from './authentication.service'

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {        
        if (route.url.length > 0 && route.url[0].path === 'applicant-view') {
            return true;
        }   
        if (this.authService.currentUserData) {
            // logged in so return true
            const isApplicant = this.authService.currentUser?.isApplicant;
            if(isApplicant) return true;

            const isGuest = this.authService.currentUser?.isGuest;
            if(isGuest) return true;

            console.log(`Wrong user type!`);
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } })
        return false
    }
}