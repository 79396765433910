import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { LookupService } from '../../lookup/lookup.service';
import { MetaformViewerComponent } from '../metaform-viewer.component';
import { MetaformQuestionDisplayComponent } from '../metaform-question-display.component'
import { OptionSelectMultiComponent } from '../control/optionselectmulti/option-select-multi.component';
import { OptionSelectComponent } from '../control/optionselect/option-select.component';
import { MultiLineTextComponent } from '../control/multiline/multi-line-text.component';
import { DropDownComponent } from '../control/dropdown/dropdown.component';
import { DateComponent } from '../control/date/date.component';
import { DateMonthYearComponent } from '../control/date-month/date-month.component';
import { TextInputComponent } from '../control/text/text-input.component';
import { TimeInputComponent } from '../control/time/time.component';
import { TelephoneComponent } from '../control/telephone/telephone.component';
import { AddressInputComponent } from '../control/address/address.component';
import { MustBeAfterMomentValidatorDirective } from '../validator/moment.isAfter.validator';
import { HtmlComponent } from '../control/html/html.component';

// To cover outdated Ngx-custom-validators
// remove once metaforms replaced
import { NarikCustomValidatorsModule } from '@narik/custom-validators';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NarikCustomValidatorsModule
    ],
    providers: [
        LookupService
    ],
    declarations:
        [
            MetaformViewerComponent,
            MetaformQuestionDisplayComponent,
            OptionSelectComponent,
            OptionSelectMultiComponent,
            HtmlComponent,
            TextInputComponent,
            MultiLineTextComponent,
            DropDownComponent,
            DateComponent,
            DateMonthYearComponent,
            TimeInputComponent,
            TelephoneComponent,
            AddressInputComponent,
            MustBeAfterMomentValidatorDirective
        ],
    exports: [
        MetaformViewerComponent,
        MetaformQuestionDisplayComponent,
        OptionSelectComponent,
        OptionSelectMultiComponent,
        HtmlComponent,
        TextInputComponent,
        MultiLineTextComponent,
        DropDownComponent,
        DateComponent,
        DateMonthYearComponent,
        TimeInputComponent,
        TelephoneComponent,
        AddressInputComponent
    ]
})
export class MetaformCoreModule { }
