<div class="content-wrapper">
    <h4>Account sign-in</h4>
    <p>You are signed in as
        <strong>{{signInName}}</strong>.</p>

    <p>If you wish to change the email address used to sign in with, please enter your new details below.</p>
    <div class="question-block">
        <mf-named-form [metaform]="'change-sign-in'" [dataProvider]="this"></mf-named-form>
    </div>
    <div class="actions">
        <button [disabled]="!updateSignInButtonEnabled" class="btn profile-btn btn-focus-shadow" (click)="doChangeSignIn()">Change
            sign in</button>
    </div>
</div>
<ng-template ngbModalContainer></ng-template>