<div class="account">
    <ul class="accountmenu">
        <li (click)="navigateTo('/user/profile')">My Profile
            <span class="fa fa-user" aria-hidden="true"></span></li>
        <li *ngIf="isApplicant && hasActiveApplication" (click)="navigateTo('/uploads')">Uploads
            <span class="fa-solid fa-cloud-arrow-up" aria-hidden="true"></span></li>
        <li (click)="navigateTo('/messages')">Messages
            <span class="fa-regular fa-envelope" aria-hidden="true"></span></li>
        <li *ngIf="isApplicant" class="separator"></li>
        <li *ngIf="isApplicant" (click)="navigateTo('/user/terms-and-conditions/latest/agreed')">Terms &amp; Conditions
            <span class="fa-solid fa-handshake" aria-hidden="true"></span></li>
        <li *ngIf="isApplicant" (click)="navigateTo('/privacy-policy')">Privacy Policy
            <span class="fa fa-user-secret" aria-hidden="true"></span></li>
        <li class="separator"></li>
        <li (click)="signOut()">Sign Out
            <span class="fa-solid fa-arrow-right-from-bracket" aria-hidden="true"></span></li>
    </ul>
</div>