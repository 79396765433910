<div class="content-wrapper">
    <h3>Page not found</h3>
    <div class="alert alert-intro" role="alert">
        The page you were looking for does not exist. However, you might find one of the below links helpful!
    </div>
    <nav>
        <ul>
            <li>
                <a routerLink="/home">Home Page</a>
            </li>
            <li>
                <a routerLink="/hub">Application</a>
            </li>
            <li>
                <a routerLink="/contact-us">Contact Us</a>
            </li>
        </ul>
    </nav>
</div>